// @ts-nocheck
import * as Yup from "yup";
import { boolean, string } from "yup/lib/locale";
import { Permits } from "./PermitsFormsUtils/permitsUtils";
import { checkIfPersonNumber } from "../../Utilities/common";
import { IKommunList } from "../../Services/infoService";


const tillstandsHavareObjectShape = () => {
  //Tillståndstyp finns inte på parent object Version men vi jackar på detta när en användare byter tillståndstyp
  return Yup.object().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.StadigvarandeServeringstillstand,
        Permits.TillfalligtServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.object({
      namn: Yup.string().required("Namn måste anges"),
      adress: Yup.string().required("Adress måste anges"),
      postnr: Yup.string()
        .matches(
          /^(\d{4}$)|(^\d{3} ?\d{2})$/,
          "Ange på formatet nnnn eller nnn nn"
        )
        .required("Postnummer måste anges"),
      postOrt: Yup.string().required("Postort måste anges"),
    }),
  });
};

const tillstandsObjektObjectShape = () => {
  //Tillståndstyp finns inte på parent object Version men vi jackar på detta när en användare byter tillståndstyp
  return Yup.object().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.TillfalligtServeringstillstand,
        Permits.StadigvarandeServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.object({
      namn: Yup.string().required("Namn måste anges"),
      adress: Yup.string().required("Adress måste anges"),
      postnr: Yup.string()
        .matches(
          /^(\d{4}$)|(^\d{3} ?\d{2})$/,
          "Ange på formatet nnnn eller nnn nn"
        )
        .required("Postnummer måste anges"),
      postOrt: Yup.string().required("Postort måste anges"),  
      webbutikNamn: Yup.string().test(
        "checkWebbutik",
        "Webbutikens namn måste anges",
        (val, context) => {
          if ((!context.parent.webbutikNamn && context.parent.webbutikAdress)) {
            return false;
          }
          return true;
        }
      )
      .nullable(),
      webbutikAdress: Yup.string().test(
        "checkWebbutik",
        "Webbadress måste anges",
        (val, context) => {
          if ((context.parent.webbutikNamn && !context.parent.webbutikAdress)) {
            return false;
          }
          return true;
        }
      )
      .nullable(),
    })
  });
};


const KategoriSchema = () => {
  return Yup.array().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.StadigvarandeServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
      then: Yup.array()
      .min(1, "Kategorikod måste anges")
      .required("Kategorikod måste anges")
      .nullable(),
  });
};

const kommunSchema = () => {
  return Yup.number().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.StadigvarandeServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.number()
      .min(1, "Kommun måste anges")
      .required("Måste Ange Kommun Id"),
  });
};

const orgNrSchema = () => {
  return Yup.string().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.StadigvarandeServeringstillstand,
        Permits.TillfalligtServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.string()
      .required("Organisationsnummer måste anges")
      .test(
        "checkIfValidPersonNumber",
        "Organisationsnummer måste vara 10 tecken långt och ha formatet NNNNNN-NNNN.",
        (val, context) => {
          if (checkIfPersonNumber(val)) {
            return true;
          }
          return false;
        }
      ),
  });
};

const checkWebbutik = (): boolean => {
  const  namn = context.parent.webbutikNamn;
  const  adress  = context.parent.webbutikAdress;
  if ((namn && !adress) || (!namn && adress)) {
    return false;
  }
  return true;
}


/*
Kontrollera om value fyra första siffror är samma som vald kommuns fyra första
Stockholm: 3180, 4180, 5180, 6180,
Göteborg: 3480, 4480
Malmö: 3280, 4280
*/
const checkIfCorrectKommun = (value: string, data: number) => {
  if (!value || !data) {
    return true;
  }
  let list, group;
  let x: string = localStorage.getItem("kommunList") ?? localStorage.getItem("kommunListUser") ?? null!;
  if (x !== null) {
    list = JSON.parse(x);
  }

  if (!list) {
    return true;
  }

  group = list.find((l: IKommunList) => l.id === data);

  const kommunNr = {
    Stockholm: [3180, 4180, 5180, 6180],
    Göteborg: [3480, 4480],
    Malmö: [3280, 4280],
  };

  if (Object.keys(group).length > 0) {
    if (!kommunNr[group.namn]) {
      kommunNr[group.namn] = []
    }
    kommunNr[group.namn].push(Number(group.nr))
    if (kommunNr[group.namn].indexOf(Number(value.substring(0, 4))) > -1) {
      return true;
    }
  }

  return false;
};

const objektNrSchema = () => {
  return Yup.string().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [Permits.StadigvarandeServeringstillstand].findIndex(
        (p) => p === tillstandsTypId
      ) > -1,
    then: Yup.string()
      .required("Restaurangnummer måste anges")
      .max(8, 'Restaurangnummer ska innehålla 8 siffror.')
      .min(8, 'Restaurangnummer ska innehålla 8 siffror.')
      .test(
        "objektNr",
        "Restaurangnumrets 4 första siffror överensstämmer inte med vald kommuns läns- och kommunkod.",
        (val, context) => {
          // val's fyra första siffror ska stämma överrens med kommunen
          if (context.parent.kommunId !== 0) {
            return checkIfCorrectKommun(val, context.parent.kommunId);
          }
          return true;
        }
      ),
  });
};

const beslutsDatumSchema = () => {
  return Yup.date().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.StadigvarandeServeringstillstand,
        Permits.TillfalligtServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.date()
      .typeError("Beslutsdatum har inte rätt datumformat (åååå-mm-dd)")
      .required("Beslutsdatum måste anges")
      .nullable(),
  });
};

const avslutsdatumSchema = () => {
  return Yup.date().nullable().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [Permits.TillfalligtServeringstillstand,
      Permits.TobakstillstandDetaljhandelVissTid].findIndex(
        (p) => p === tillstandsTypId
      ) > -1,
    then: Yup.date()
      .typeError("Giltig t.o.m. har inte rätt datumformat (åååå-mm-dd)")
      .min(
        Yup.ref("aktivieringsDatum"),
        "Giltigt t.o.m. kan inte vara tidigare än giltigt fr.o.m."
      )
      .required("Giltig t.o.m. måste anges")
      .nullable()
  });
};

const aktiveringsDatumSchema = () => {
  return Yup.date().when("tillstandsTypId", {
    is: (tillstandsTypId: number) =>
      [
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.StadigvarandeServeringstillstand,
        Permits.TillfalligtServeringstillstand,
      ].findIndex((p) => p === tillstandsTypId) > -1,
    then: Yup.date()
      .typeError("Giltigt fr.o.m. har inte rätt datumformat (åååå-mm-dd)")
      .min(
        Yup.ref("beslutsDatum"),
        "Giltigt fr.o.m. kan inte vara tidigare än beslutsdatum."
      )
      .required("Giltigt fr.o.m. måste anges")
      .nullable()
  });
};

const hourMinuteSchema = () => {
  return Yup.string().matches(
    /\d{2}:\d{2}/,
    "Serveringstid måste vara 5 tecken långt och ha formatet TT:MM."
  );
};

const checkTillstandsKategoriGrupp = (data: number[]) => {
  let list, group;
  let x: string = localStorage.getItem("tillstandsTypStadigvarande") ?? null!;
  if (x !== null) {
    list = JSON.parse(x);
  }
  if (data) {
    group = list.find((l: ITillstandsKategorier) => l.id === data[0]);
  }

  return group?.groupId;
};

const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

const versionDetailsObjectShape = () => {
  //Tillståndstyp finns inte på parent object Version men vi jackar på detta när en användare byter tillståndstyp
  return Yup.object()
    .when("tillstandsTypId", {
      is: (tillstandsTypId: number) =>
        [Permits.StadigvarandeServeringstillstand].findIndex(
          (p) => p === tillstandsTypId
        ) > -1,
      then: Yup.object({
        servtidSlutnaFrom: Yup.string()
          .test(
            "servtidSlutnaFrom",
            "Serveringstid måste anges",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 3) {
                if (val === null) {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .test(
            "servtidSlutnaFrom",
            "Serveringstid fr.o.m. måste vara 5 tecken långt och ha formatet TT:MM.",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 3) {
                if (!val?.match(timeRegex)) {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .nullable()
          .matches(
            timeRegex,
            "Serveringstid fr.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
          ),
        servtidSlutnaTom: Yup.string()
          .test(
            "servtidSlutnaTom",
            "Serveringstid måste anges",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 3) {
                if (val === null) {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .test(
            "servtidSlutnaTom",
            "Serveringstid t.o.m. måste vara 5 tecken långt och ha formatet TT:MM.",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 3) {
                if (!val?.match(timeRegex)) {
                  return false;
                }
                return true;
              }
              return true;
            }
          )
          .nullable()
          .matches(
            timeRegex,
            "Serveringstid t.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
          ),
        uteserveringFinns: Yup.boolean(),
        servtidNormaltidInne: Yup.boolean(),
        servtidAnnanInneFrom: Yup.string()
          .test(
            "servtidAnnanInneFrom",
            "Serveringstid måste anges",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              const { servtidNormaltidInne } = context.parent;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 2) {
                if (!servtidNormaltidInne) {
                  if (!val?.match(timeRegex)) {
                    return false;
                  }
                  return true;
                }
                return true;
              }
              return true;
            }
          )
          .nullable()
          .matches(
            timeRegex,
            "Annan serveringstid fr.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
          ),
        servtidAnnanInneTom: Yup.string()
          .test(
            "servtidAnnanInneTom",
            "Serveringstid måste anges",
            (val, context) => {
              const tillstandsKategori =
                context.from[1].value.tillstandsKategoriIds;
              const { servtidNormaltidInne } = context.parent;
              if (checkTillstandsKategoriGrupp(tillstandsKategori) === 2) {
                if (!servtidNormaltidInne) {
                  if (!val?.match(timeRegex)) {
                    return false;
                  }
                  return true;
                }
                return true;
              }
              return true;
            }
          )
          .nullable()
          .matches(
            timeRegex,
            "Annan serveringstid t.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
          ),
        servtidAnnanUteFrom: Yup.string()
          .nullable()
          .when("uteserveringFinns", {
            is: true,
            then: Yup.string()
              .nullable()
              .matches(
                timeRegex,
                "Serveringstid fr.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
              )
              .when("servtidNormaltidUte", {
                is: false,
                then: Yup.string()
                  .nullable()
                  .matches(
                    timeRegex,
                    "Serveringstid fr.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
                  )
                  .required("Serveringstid måste anges"),
              }),
          }),
        servtidAnnanUteTom: Yup.string()
          .nullable()
          .when("uteserveringFinns", {
            is: true,
            then: Yup.string()
              .nullable()
              .matches(
                timeRegex,
                "Serveringstid t.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
              )
              .when("servtidNormaltidUte", {
                is: false,
                then: Yup.string()
                  .nullable()
                  .matches(
                    timeRegex,
                    "Serveringstid t.o.m. måste vara 5 tecken långt och ha formatet TT:MM."
                  )
                  .required("Serveringstid måste anges"),
              }),
          }),
          provoTidTom: Yup.date()
          .typeError("Giltig t.o.m. har inte rätt datumformat (åååå-mm-dd)")
         /* .min(
            Yup.ref("aktiveringsDatum"),
            "Giltigt fr.o.m. kan inte vara tidigare än beslutsdatum."
          )*/
          .nullable(),      
        antalPersonerMax: Yup.number("Måste vara ett nummer").nullable(),
        antalSittplatserInne: Yup.number("Måste vara ett nummer").nullable(),
        antalSittplatserUte: Yup.number("Måste vara ett nummer").nullable(),
      }),
    })

};

export const newPermitSchema = Yup.object().shape({
  tillstandsTypId: Yup.number().required("En tillståndstyp måste vara vald").min(1, "Tillståndstyp måste väljas"),
  kommunId: kommunSchema(),
  tillstandsKategoriIds: KategoriSchema(),
  orgNr: orgNrSchema(),
  objektNr: objektNrSchema(),
  beslutsDatum: beslutsDatumSchema(),
  aktivieringsDatum: aktiveringsDatumSchema(),
  tillstandsHavare: tillstandsHavareObjectShape(),
  tillstandsObjekt: tillstandsObjektObjectShape(),
  versionDetails: versionDetailsObjectShape(), 
  avslutsDatum: avslutsdatumSchema(),
});

export const EditPermitSchema = Yup.object().shape({
  kommunId: kommunSchema(),
  tillstandsKategoriIds: KategoriSchema(),
  beslutsDatum: beslutsDatumSchema(),
  aktivieringsDatum: aktiveringsDatumSchema(),
  tillstandsHavare: tillstandsHavareObjectShape(),
  tillstandsObjekt: tillstandsObjektObjectShape(),
  versionDetails: versionDetailsObjectShape(),
  avslutsDatum: avslutsdatumSchema(),
});

export const FileManagementSKVValidation = Yup.object().shape({
  artal: Yup.number().required("Du måste ange ett år"),
  kvartal: Yup.number().required("Du måste ange ett kvartal"),
});

export const CorrectPermitValidation = Yup.object().shape({
  orgNr: orgNrSchema(),
  objektNr: objektNrSchema(),
  aktivieringsDatum: aktiveringsDatumSchema(),
  beslutsDatum: beslutsDatumSchema(),
  avslutsDatum: Yup.date()
  .typeError("Avslutsdatum har inte rätt datumformat (åååå-mm-dd)")
  .nullable(),
});
