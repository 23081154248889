import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IInfo } from "../../../Services/infoService";
import {
  IRestaurangRapportDetaljer,
  IUpdateRapportDetails,
} from "../../../Services/reportService";
import EgenkryddadSnaps from "./egenkryddadSnaps";
import { Godkann } from "./godkanna";
import { InkoptaVolymer } from "./inkoptaVolymer";
import Normalpriser from "./normalpriser";
import { Omsattning } from "./omsattning";
import BeraknadTillverkning from "./s_BerTillverkning";
import Export from "./s_Export";
import AnvandningsomradeTekniskSprit from "./s_AnvandningsomradeTekniskSprit";
import ForsaljningSverige from "./s_ForsaljningSverige";
import ForsaljningSverigeTekniskSprit from "./s_ForsaljningSverigeTekniskSprit";
import GodkannandeStat from "./s_Godkannande";
import Import from "./s_Import";
import Tillverkning from "./s_Tillverkning";


export enum Rapporttyper {
  Restaurangrapport = 1,
  StatistikrapportForsaljning = 2,
  StatistikrapportTillverkning = 3,
  VinODruvmust = 4,
  StatistikrapportForsaljningTillverkning = 5,
  LagerdeklarationAvVinOchDruvmust = 6,
  StatistikrapportTekniskSprit = 7, 
}

interface IMap extends IInfo {
  rapportTyp: Rapporttyper;
}

export const RapportTyper: IMap[] = [
  {
    id: 1,
    namn: "Restaurangrapport",
    rapportTyp: Rapporttyper.Restaurangrapport,
  },
  {
    id: 2,
    namn: "Statistikrapport Försäljning",
    rapportTyp: Rapporttyper.StatistikrapportForsaljning,
  },
  {
    id: 3,
    namn: "Statistikrapport Tillverkning",
    rapportTyp: Rapporttyper.StatistikrapportTillverkning,
  },
  {
    id: 4,
    namn: "Vin- och Druvmust",
    rapportTyp: Rapporttyper.VinODruvmust,
  },
  {
    id: 5,
    namn: "Statistikrapport försäljning/tillverkning",
    rapportTyp: Rapporttyper.StatistikrapportForsaljningTillverkning,
  },
  {
    id: 6,
    namn: "Lagerdeklaration av vin och druvmust",
    rapportTyp: Rapporttyper.LagerdeklarationAvVinOchDruvmust,
  },
  {
    id: 7,
    namn: "Statistikrapport teknisk sprit",
    rapportTyp: Rapporttyper.StatistikrapportTekniskSprit,
  }
];

enum FormTypes {
  Normalpriser = "Normalpriser",
  EgenkryddadSnaps = "EgenkryddadSnaps",
  Omsattning = "Omsattning",
  InkoptaVolymer = "InkoptaVolymer",
  Godkannande = "Godkannande",
  LagerdeklarationAvVinOchDruvmust = "LagerdeklarationAvVinOchDruvmust",
  ForsaljningInomSverige = "ForsaljningInomSverige",
  ForsaljningInomSverigeTekniskSprit = "ForsaljningInomSverigeTekniskSprit",
  Import = "Import",
  Export = "Export",
  Tillverkning = "Tillverkning",
  BeraknadTillverkning = "BeraknadTillverkning",
  GodkannandeStat = "GodkannandeStat",
  AnvandningsomradeTekniskSprit = "AnvandningsomradeTekniskSprit"
}

export enum Mode {
  Create = "Create",
  Edit = "Edit",
  Disabled = "Disabled",
}

interface FormtypeToFunction {
  type: FormTypes;
  function: (
    props: FormikProps<IRestaurangRapportDetaljer>,
    index: number,
    mode: Mode,
    prevYear: boolean,
    prevYearObj: IUpdateRapportDetails | null
  ) => React.ReactNode;
}

interface IFormStructure {
  RapportTyp: Rapporttyper;
  FormTypes: FormTypes[];
}

const FormStructure: IFormStructure[] = [
  {
    RapportTyp: Rapporttyper.Restaurangrapport,
    FormTypes: [
      FormTypes.Normalpriser,
      FormTypes.EgenkryddadSnaps,
      FormTypes.Omsattning,
      FormTypes.InkoptaVolymer,
      FormTypes.Godkannande,
    ],
  },
  {
    RapportTyp: Rapporttyper.StatistikrapportForsaljningTillverkning,
    FormTypes: [
      FormTypes.ForsaljningInomSverige,
      FormTypes.Import,
      FormTypes.Export,
      FormTypes.Tillverkning,
      FormTypes.BeraknadTillverkning,
      FormTypes.GodkannandeStat,
    ],
  },
  {
    RapportTyp: Rapporttyper.StatistikrapportForsaljning,
    FormTypes: [
      FormTypes.ForsaljningInomSverige,
      FormTypes.Import,
      FormTypes.Export,
      FormTypes.GodkannandeStat,
    ],
  },
  {
    RapportTyp: Rapporttyper.StatistikrapportTekniskSprit,
    FormTypes: [
      FormTypes.ForsaljningInomSverigeTekniskSprit,
      FormTypes.Import,
      FormTypes.Export,
      FormTypes.Tillverkning,
      FormTypes.AnvandningsomradeTekniskSprit,
      FormTypes.GodkannandeStat,
    ],
  },
];

const FormTypeToFunction: FormtypeToFunction[] = [
  {
    type: FormTypes.Normalpriser,
    function: Normalpriser,
  },
  {
    type: FormTypes.EgenkryddadSnaps,
    function: EgenkryddadSnaps,
  },
  {
    type: FormTypes.Omsattning,
    function: Omsattning,
  },
  {
    type: FormTypes.InkoptaVolymer,
    function: InkoptaVolymer,
  },
  {
    type: FormTypes.Godkannande,
    function: Godkann,
  },
  {
    type: FormTypes.AnvandningsomradeTekniskSprit,
    function: AnvandningsomradeTekniskSprit,
  },
  {
    type: FormTypes.ForsaljningInomSverige,
    function: ForsaljningSverige,
  },
  {
    type: FormTypes.ForsaljningInomSverigeTekniskSprit,
    function: ForsaljningSverigeTekniskSprit,
  },
  {
    type: FormTypes.Import,
    function: Import,
  },
  {
    type: FormTypes.Export,
    function: Export,
  },
  {
    type: FormTypes.Tillverkning,
    function: Tillverkning,
  },
  {
    type: FormTypes.BeraknadTillverkning,
    function: BeraknadTillverkning,
  },
  {
    type: FormTypes.GodkannandeStat,
    function: GodkannandeStat,
  },
];

export function RenderForm(
  rapportTyp: Rapporttyper,
  props: FormikProps<IRestaurangRapportDetaljer>,
  mode: Mode = Mode.Create,
  prevYear: boolean = false,
  prevYearObj: IRestaurangRapportDetaljer | null
) {
  const formStructure = FormStructure.find((p) => p.RapportTyp === rapportTyp);

  if (formStructure === undefined) throw Error("Cannot find form");

  const formarr = formStructure.FormTypes.map((p, i) => {
    return FormTypeToFunction.filter((x) => x.type === p).map((x) =>
      x.function(props, i + 1, mode, prevYear, prevYearObj)
    );
  });

  return formarr.map((x, i) => x);
}
