import { ICommonPropsAnvandare } from "../Services/UserService";

export const noNumbersRegX = /(^[\D ]*$)/;
export const onlyNumbers = /^[0-9]*$/;
export const noStaringWhitespaces = /^[^\s]+(\s+[^\s]+)*/;
export const swedishPhoneNumber =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
export const notWhiteSpace = "Inga inledande blanksteg";
export const required = "Obligatoriskt";
export const toShort = "Texten är för kort";
export const maxLength = "Texten är för lång";

export const GetFullName = (anvandareCommonProps: ICommonPropsAnvandare) => {
  return `${anvandareCommonProps.fornamn} ${anvandareCommonProps.efternamn}`;
};

export const checkIfPersonNumber = (input: string): boolean => {
  if (!input) { return false; }
  if (input.replace(/\D/g, "").length !== 10) { return false; } // remove everything but digits and check length

  let ssn = input
    .replace(/\D/g, "")     // strip out all but digits
    .split("")              // convert string to array
    .reverse()              // reverse order for Luhn
    .slice(0, 10);          // keep only 10 digits (i.e. 1977 becomes 77)

  // verify we got 10 digits, otherwise it is invalid
  if (ssn.length !== 10) {
    return false;
  }

  var sum = ssn
    // convert to number
    .map(function (n) {
      return Number(n);
    })
    // perform arithmetic and return sum
    .reduce(function (previous, current, index) {
      // multiply every other number with two
      if (index % 2) current *= 2;
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) current -= 9;
      // sum it up
      return previous + current;
    });

  // sum must be divisible by 10
  return 0 === sum % 10;
};

export const tryParseJson = (value: string) => {
  try {
    const o = JSON.parse(value);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e: any) {
    return false;
  }
}

export const checkIfEmptyObject = (obj: object): boolean => {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}
