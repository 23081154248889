import * as React from "react";
import {
  Typography,
} from "@mui/material";
import '../App.css';

export default function Footer() {

  return (
    <footer>
        <Typography variant="body2">Folkhälsomyndigheten - Alkohol- och tobaksregistret - {localStorage.getItem("deploymentInfo")}</Typography>
      </footer>
  );
}
