import { IHelperText } from "./baseHelperText";

export const användareText: IHelperText = {
  title: "Användare",
  text: [
    "Listan visar aktuella användarkonton och du kan söka fram konton genom att söka på namn eller någon av de andra sökmöjligheterna.",
    "Du väljer det användarkonto du vill se i listan och kan då ändra vissa uppgifter.",
    'För att se alla användarkonton samlade i en Excelfil väljer du "Exportera resultat".',
  ],
  subText: "",
};

export const användareSkapaText: IHelperText = {
  title: "Registrera användare",
  text: ["Här kan (i undantagsfall) nya användarkonton registreras."],
  subText: "",
};

export const användareAnsokningarText: IHelperText = {
  title: "Ansökningar",
  text: [
    "Listan visar inkomna ansökningar som ännu inte är godkända.",
    "Du väljer den ansökan du vill hantera i listan.",
  ],
  subText: "",
};

export const kontakterText: IHelperText = {
  title: "Kontakter",
  text: [
    "Listan visar kontaktpersoner i kommuner och län.",
    "Folkhälsomyndigheten behöver en till två kontaktpersoner i varje kommun som kontaktas gällande t.ex. nya användarkonton och restaurangrapportering. I registret finns också en till två kontaktpersoner för tillståndsfrågor på länsstyrelsen.",
    "Kontakta folkhälsomyndighetens support om någon uppgift behöver ändras.",
    'För att se alla uppgifter samlade i en Excelfil väljer du "Exportera resultat".',
  ],
  subText: "",
};

export const kontakterRegistreraText: IHelperText = {
  title: "Registrera kontakt",
  text: [
    "Här registreras nya kontaktpersoner. Alla kommuner ska ha minst en kontaktperson per typ. Det är möjligt att maximalt registrera två kontaktpersoner per typ.",
  ],
  subText: "",
};

export const kontakterEpostText: IHelperText = {
  title: "Skicka e-post",
  text: [
    'Här kan du skicka e-postmeddelanden till alla kommuners kontaktpersoner för användarkonton. För att infoga en lista över alla användarkonton som finns i kommunerna, klickar du på "Lägg till alla användarnamn för mottagarens kommun".',
  ],
  subText: "",
};

export const tillståndText: IHelperText = {
  title: "Tillstånd",
  text: [
    "Listan visar aktuella tillstånd och du kan söka fram tillstånd genom att söka på organisationsnummer eller någon av de andra sökmöjligheterna.",
    'För att se tillstånd som inte börjat gälla ännu markerar du "Inkludera framtida".',
    'För att se avslutade tillstånd markerar du "Inkludera avslutade". Om du även vill se tillstånd som avslutades före 2003 markerar du "Inkludera äldre avslutade".',
    "Du väljer det tillstånd du vill se i listan och kan då ändra eller avsluta tillståndet.",
    'För att se alla uppgifter samlade i en Excelfil väljer du "Exportera resultat".',
  ],
  subText: "",
};

export const registerutdragText: IHelperText = {
  title: "Registerutdrag",
  text: ["Här hämtas registerutdrag över stadigvarande serveringstillstånd."],
  subText: "",
};

export const filuppladdningText: IHelperText = {
  title: "Filuppladdning",
  text: ["Här laddas filer från Skatteverket in en gång per månad. Filerna innehåller uppgifter om registrerade varumottagare, skattebefriade förbrukare och upplagshavare."],
  subText: "",
};

export const filhanteringText: IHelperText = {
  title: "Filhantering",
  text: [
    "Här skapas en fil till Skatteverket en gång per kvartal. Filen innehåller uppgifter om tillståndshavare som har haft stadigvarande serveringstillstånd under aktuellt kvartal.",
    "Filen skapas tidigast 30 dagar efter aktuellt kvartals utgång. Filen levereras till Skatteverket med eTransport till Skatteverkets SHS.",
    "Filen tas in i deras system Informationslagret för att sedan levereras till system RUS för analys- och urvalshantering.",
  ],
  subText: "",
};

export const statistikText: IHelperText = {
  title: "Statistik",
  text: [
    "Här finns olika statistiksammanställningar.",
    "Statistiken baseras på uppgifter om tillstånd och företagens inlämnade rapporter. För pekaren över frågetecknet för att läsa mer om statistiken.",
  ],
  subText: "",
};

export const statistikAlkoholText: IHelperText = {
  title: "Statistiksammanställningar alkohol",
  text: [
    "Här finns statistiksammanställningar utifrån uppgifter om tillstånd och företagens inlämnade rapporter. Klicka på frågetecknet för att läsa mer om statistiken."
  ],
  subText: "Vissa sammanställningar ligger också till grund för den nationella statistik som publiceras i Indikatorlabbet och på myndighetens webbplats.",
};

export const statistikTobakText: IHelperText = {
  title: "Statistiksammanställningar tobak",
  text: [
    "Här finns statistiksammanställningar utifrån uppgifter om tillstånd. Klicka på frågetecknet för att läsa mer om statistiken.",
  ],
  subText: "Vissa sammanställningar ligger också till grund för den nationella statistik som publiceras i Indikatorlabbet och på myndighetens webbplats.",
};

export const statistikBefolkningText: IHelperText = {
  title: "Befolkningsstatistik",
  text: [
    "Här laddas en fil över befolkningsstatistik in. Filen hämtas från SCB en gång per år.",
  ],
  subText: "",
};

export const sökRapportText: IHelperText = {
  title: "Sök rapport",
  text: [
    "Listan visar företagens inlämnade rapporter och det finns möjlighet att söka fram rapporter på rapportens status.",
    'En rapports status ändras från "Inkommen" till "Klar" eller "Granskas".',
    "Klar = rapporten räknas med i statistiksammanställningar.",
    'Granskas = rapporten granskas och kan innehålla felregistrerade/orimliga uppgifter och tas inte med i statistiksammanställningar förrän den fått status "Klar".',
    "Makulerad = rapporten har makulerats av Folkhälsomyndigheten.",
    'Markera "Jämför omsättning och inköpt volym" för att kvalitetsgranska lämnade uppgifter.',
    "Rimlighetskontroll av restaurangrapporter - nya kolumner skapas där resultat av jämförelsen visas.",
    "Beräkning sker enligt följande:",
    "- Omsättning spritdryck/(inköpt volym spritdryck*(normalpris spritdryck*100))",
    "- Omsättning vin/(inköpt volym vin*((normalpris vin/75)*100))",
    "- Omsättning andra jästa alkoholdrycker/(inköpt volym andra jästa alkoholdrycker*((normalpris andra jästa alkoholdrycker/avser volym)*100))",
    "- Omsättning starköl/(inköpt volym starköl*((normalpris starköl/avser volym)*100))",
    "- Omsättning alkoholdrycksliknande preparat/(Inköpt volym alkoholdrycksliknande preparat*((normalpris alkoholdrycksliknande preparat/avser volym)*100))",
    'För att se alla uppgifter samlade i en Excelfil väljer du "Exportera resultat".',
    'För att se hur många rapporter som totalt kommit in under vald rapportperiod väljer du "Hämta statusrapport".',
  ],
  subText: "",
};

export const nyRapportText: IHelperText = {
  title: "Registrera rapport",
  text: [
    'Restaurangrapport',
    'Listan visar alla rapporter som ännu inte har kommit in. Välj den rapport du vill registrera i listan.',
    'Förtryckta rapporter för utskick till tillståndshavare kan skrivas ut så snart ett tillstånd har avslutats. Välj ikonen för utskrift längst till höger på raden för rapporten om du endast behöver en blankett.',
    'För att skriva ut alla förtryckta rapporter att skicka till tillståndshavare väljer du "Skriv ut samtliga". Observera att det tar ca. 1 minut att skapa 100 blanketter som PDF-dokument.',
    'För att underlätta uppgiftslämningen är det viktigt att du alltid skickar med en aktuell anvisning till tillståndshavaren. Du hittar aktuell anvisning på startsidan.',
    'OBS! Rapporter för årets utskick till tillståndshavare med aktuella tillstånd kan skrivas ut tidigast i slutet av november.',
  ],
  subText: "",
};

export const skapaRapportText: IHelperText = {
  title: "Skapa rapporter",
  text: [
    "Här skapas rapporter för vald rapportperiod och vald rapporttyp. Sammanfattningen visar hur många tillstånd som fortfarande saknar rapport.",
    "Rapporter skapas enligt följande urval:",
    "Restaurangrapport - rapport skapas för alla aktuella stadigvarande serveringstillstånd förutom tillstånd för provsmakning av egen tillverkning (kategorikod 91, 92).  Rapporter skapas också i samband med avslut av stadigvarande serveringstillstånd.",
    "Statistikrapport försäljning/tillverkning -  rapport skapas för alla aktuella och avslutade upplagshavare och registrerade varumottagare under rapportperioden. För tillståndshavare som både är upplagshavare och registrerad varumottagare skapas endast en rapport.",
    "Statistikrapport teknisk sprit - rapport skapas för alla aktuella och avslutade upplagshavare av teknisk sprit (kategorikod U6) under rapportperioden.",
  ],
  subText: "",
};

export const skapaPeriodText: IHelperText = {
  title: "Skapa period",
  text: ["Här skapas inför varje nytt år en ny rapportperiod. När ny rapportperiod skapas sätts föregående rapportperiod som förvald i alla listboxar med rapportperiod."],
  subText: "",
};

export const kommunerText: IHelperText = {
  title: "Sök kommun",
  text: [
    "Listan visar adressuppgifter till kommunerna. Adressuppgifterna används för att trycka mottagaradress på restaurangrapporterna.",
    "Du väljer den uppgift du vill se i listan och kan då ändra adressuppgiften.",
    'För att se alla uppgifter samlade i en Excelfil väljer du "Exportera resultat".',
  ],
  subText: "",
};

export const kommunerRegistreraText: IHelperText = {
  title: "Registrera kommun",
  text: ["Här registreras nya kommuner eller helt ny tillståndsgivare."],
  subText: "",
};

export const anvandarkontoText: IHelperText = {
  title: "Användarkonto",
  text: [
    "Här visas information om ditt användarkonto.",
    "Du kan ändra ditt lösenord här och du kan se hur länge ditt lösenord är giltigt.",
  ],
  subText: "",
};

export const installningarText: IHelperText = {
  title: "Inställningar",
  text: []
}

export const systeminstallningarText: IHelperText = {
  title: "Systeminställningar",
  text: [
    'Standardvärde normalpriser restaurangrapport',
    'Här registreras de standardvärden som ska användas om normalpris saknas på restaurangrapporten. Normalpris används vid jämförelser av omsättning och inköpt volym som lämnats på restaurangrapporter.',
    'Gränsvärden vid jämförelser försäljning/tillverkning',
    'Här registreras de gränsvärden som ska användas vid jämförelser med föregående års försäljning/tillverkning som lämnats i statistikrapport försäljning /tillverkning.',
    'Gränsvärden rimlighetskontroll restaurangrapporter',
    'Här registreras de gränsvärden som ska användas vid automatiska rimlighetskontroller som genomförs när en restaurangrapport har registrerats.',
  ]
}
