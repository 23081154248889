import { Delete, ExportFile, Get, Post, Put } from "../Utilities/axiosBase";
import { ISearchBase, ISearchResultBase } from "./Searchbase";
import { IMainBaseResponse } from "./base";
import { KommunKontaktTyp } from "../Utilities/contactUtils";

export interface ICommonPropsKontakt {
  fornamn: string;
  efternamn: string;
  epost: string;
  telefon: string;
  kommunId: number;
  kommunKontaktTypId: number;
}

export interface IKontaktGet extends ICommonPropsKontakt {
  id: number;
}
export interface IKontaktSearchResponse extends ICommonPropsKontakt {
  id: number;
  kommunNamn:string;
  kommunKontaktTypNamn:string
  lanNamn:string
}

export interface IKontaktPut extends ICommonPropsKontakt {}

export interface IKontaktPost extends ICommonPropsKontakt {}

export interface IKontaktSearchFilter extends ICommonPropsKontakt {
  lanId:number
}

export interface IKontaktSendEmail {
  amne: string,
  meddelande: string,
  namn: string,
  epost: string
}

export interface IKontaktData {
  epostadress: string,
  namn: string
}

export interface IKontaktCanBeCreatedResponse {
  availableTypes: KommunKontaktTyp[];
}

const kontaktBase: string = "/kommunkontakt";

export const searchKontakter = async (
  filters: ISearchBase<IKontaktSearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<IKontaktSearchResponse>>> => {
  return await Post<
    ISearchBase<IKontaktSearchFilter>,
    ISearchResultBase<IKontaktSearchResponse>
  >(`${kontaktBase}/search`, filters);
};

export const getKontakt = async (
  id: number
): Promise<IMainBaseResponse<IKontaktGet>> => {
  return await Get<IKontaktGet>(`${kontaktBase}/${id}`);
};

export const checkIfKontaktCandBeCreated = async (
  kommunId: number
): Promise<IMainBaseResponse<IKontaktCanBeCreatedResponse>> => {
  return await Get<IKontaktCanBeCreatedResponse>(
    `${kontaktBase}/checkifkommunkontaktcanbecreated/${kommunId}`
  );
};

export const putKontakt = async (
  kontakt: IKontaktPut,
  id: number
): Promise<IMainBaseResponse<IKontaktGet>> => {
  return await Put<IKontaktPut, IKontaktGet>(`${kontaktBase}/${id}`, kontakt);
};

export const postKontakt = async (
  kontakt: IKontaktPost
): Promise<IMainBaseResponse<IKontaktGet>> => {
  return await Post<IKontaktPost, IKontaktGet>(`${kontaktBase}`, kontakt);
};

export const deleteKontakt = async (
  id: number
): Promise<IMainBaseResponse<IKontaktGet>> => {
  return await Delete<IKontaktGet>(`${kontaktBase}/${id}`, id);
};

export const exportKontakt = async (filters: IKontaktSearchFilter) => {
  return await ExportFile<IKontaktSearchFilter>(
    `${kontaktBase}/exportexcel`,
    filters
  );
};

export const emailListKontakt = async (kontaktTypId: number):Promise<IMainBaseResponse<IKontaktData[]>> => {  
  return await Get(`${kontaktBase}/emaillist?kontaktTypId=${kontaktTypId}`)
};


export const sendEmailKontakt = async (kontakt: IKontaktSendEmail, kontaktTypId: number) => {
  return await Post(`${kontaktBase}/sendemail?kontaktTypId=${kontaktTypId}`, kontakt)
}

export const getEmailsContactsSearched = async (filter: ISearchBase<IKontaktSearchFilter>) : Promise<IMainBaseResponse<string[]>> => {
  return await Post(`${kontaktBase}/getemailsearchlist`,filter);
}