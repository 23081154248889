import * as React from "react";
import { useNavigate } from "react-router-dom";
import {Roles} from "./roles";
import { Get, Post } from "./axiosBase";
import { IMainBaseResponse } from "../Services/base";
import { Buffer } from "buffer";

export interface ITokenBase {
    tokenString: string,
    validTo: string
  }

 export interface ITokenStructure {
    accessToken: ITokenBase,
    refreshToken: ITokenBase,
    username: string,
    roles: Roles[],
    changePassword:boolean
  }

interface IUser {
    username:string,
    roles:Roles[]
}
interface ISignedIn {
    signedIn: boolean
}

export interface ICaptchaRequest {
    captchaToken: string,
    data: string
}

export interface IUpdateForgottenPasswordRequest {
    passwordBase64: string,
    forgottenPasswordToken: string
}

interface IAuthContext {
    user: IUser;
    getActiveUser: () => IUser;
    checkIfUserIsSignedIn: () => boolean | null;
    signInUser: (username:string, password:string) => Promise<IMainBaseResponse<ITokenStructure>>;
    signOutUser: () => void;
    validateUserRoleAccess: (roles:Roles[]) => boolean
    forgotPasswordEmail: (epost: string, token: string) => Promise<IMainBaseResponse<any>>;
    validatePasswordToken: (token: string) => Promise<IMainBaseResponse<boolean>>;
    updateForgottenPassword: (passwordBase64: string, token: string) => Promise<IMainBaseResponse<any>>;
    signInReportUser:(organisationsnr:string,password:string) => Promise<IMainBaseResponse<ITokenStructure>>;
}

function ValidateToken(token:ITokenStructure){

    if(new Date(token.refreshToken.validTo) < new Date()){
        return false;
    }
    return true;
}

export function ClearToken(){
    localStorage.removeItem("token");
}

export function GetToken (){
    return loadLocalStorage();
}

export function SetToken(token:ITokenStructure){
    localStorage.setItem("token",JSON.stringify(token));
}

export function ShouldUpdateToken(){
    let token = loadLocalStorage();
    if(token === null) return null;

    if(new Date(token.accessToken.validTo) < new Date()){
        return false;
    }
    if(new Date(new Date(token.refreshToken.validTo).setMinutes(-1)) < new Date()){
        return true;
    }
    return null;

}

const loadLocalStorage = () => {
    if((localStorage.getItem("token")) === null)
    {
        return null;
    }
    let accessToken : ITokenStructure = JSON.parse(localStorage.getItem("token") || '{}');
    return accessToken;
}



export let AuthContext = React.createContext<IAuthContext>(null!)

export function AuthProvider({children}: {children: React.ReactNode}){
    const navigate = useNavigate();
    const [user, setUser] = React.useState<IUser>({
        roles: [],
        username:""
    })
    const [signedIn, setSignedIn] = React.useState<ISignedIn>({
        signedIn:false,
    })

    const checkTokenValues = () => {
        let accessToken = loadLocalStorage();
        if(accessToken === null) return;

        if(ValidateToken(accessToken)){
            setUser({username:accessToken.username, roles:accessToken.roles})
            setSignedIn({signedIn:true})

        }
        else{
            localStorage.removeItem("accessToken");
            setUser(null!);
            setSignedIn({signedIn:false})
        }
    }

    React.useEffect(() => {
        checkTokenValues()
    },[])

    const signInUser = async (username: string, password: string) : Promise<IMainBaseResponse<ITokenStructure>> => {
        try {
            let response = await Post<any,ITokenStructure>("/auth/signin", {
                passwordBase64: Buffer.from(password).toString("base64"),
                username: username
            })
            setSignedIn({ signedIn: true });
            setUser({username:response.object.username, roles:response.object.roles});
            localStorage.setItem("token",JSON.stringify(response.object));
            return { success: true, message: response.message, object: response.object, status: response.status };
        } catch (error:any) {
            return { success: false, message: error.statusText, object: error.data, status: error.status }
        }
    }

    const signInReportUser = async (organisationsnr:string, password:string) : Promise<IMainBaseResponse<ITokenStructure>> => {
        try{
            let response = await Post<any, ITokenStructure>("/auth/signinrapportinlamnare",{passwordBase64: Buffer.from(password).toString("base64"),orgNr:organisationsnr})
            setSignedIn({signedIn:true})
            setUser({username:response.object.username,roles:response.object.roles})
            localStorage.setItem("token",JSON.stringify(response.object))
            return{success:true,message:response.message, object:response.object,status:response.status}
        }catch(error:any){
            return{success:false,message: error.statusText, object:error.data, status:error.status}
        }

    }
    const signOutUser = () => {
        setUser(null!);
        setSignedIn({signedIn:false})
        localStorage.clear();
        if(user.roles.findIndex(x => x === Roles.RapportInlamnare) > -1){
            navigate("/report/login")
        }
        else{
            navigate("/");
        }
    }

    const getActiveUser = () => {
        return user;
    }

    const validateUserRoleAccess = (roles:Roles[]) => {
        let userRoles : Roles[] = user.roles;
        if(user.roles.length===0){
            userRoles = loadLocalStorage()?.roles ?? [Roles.NoRole];
        }
        return userRoles.some(x => roles.indexOf(x) >= 0);
    }
    const checkIfUserIsSignedIn = () => {
        let accessToken = loadLocalStorage();
        if(accessToken === null) return null;

        return ValidateToken(accessToken);
    }

    const forgotPasswordEmail = async (email: string, token: string) : Promise<IMainBaseResponse<any>> => {
        try {
            let response = await Post<ICaptchaRequest,any>("/auth/forgotpassword-email", {
                captchaToken: token,
                data: email
            })
            return { success: true, message: response.message, object: response.object, status: response.status };
        } catch (error:any) {
            return { success: false, message: error.statusText, object: error.data, status: error.status }
        }
    }

    const validatePasswordToken = async (token: string) : Promise<IMainBaseResponse<boolean>> => {
        try {
            let response = await Get<boolean>(`/auth/isforgottenpasswordtokenvalid?token=${token}`)
            return { success: true, message: response.message, object: response.object, status: response.status };
        } catch (error:any) {
            return { success: false, message: error.statusText, object: error.data, status: error.status }
        }
    }

    const updateForgottenPassword = async (password: string, token: string) : Promise<IMainBaseResponse<any>> => {
        try {
            let response = await Post<IUpdateForgottenPasswordRequest,any>("/auth/updateforgottenpassword", {
                passwordBase64: password,
                forgottenPasswordToken: token
            })
            return { success: true, message: response.message, object: response.object, status: response.status };
        } catch (error:any) {
            return { success: false, message: error.statusText, object: error.data, status: error.status }
        }
    }

    let value = {
        getActiveUser,
        user,
        signInUser,
        checkIfUserIsSignedIn,
        signOutUser,
        validateUserRoleAccess,
        forgotPasswordEmail,
        validatePasswordToken,
        updateForgottenPassword,
        signInReportUser
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
    return React.useContext(AuthContext);
}