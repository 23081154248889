import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export function Omsattning(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.omsMat,
    props.values.omsSprit,
    props.values.omsVin,
    props.values.omsAndraJastaAlkoholdrycker,
    props.values.omsStarkOl,
    props.values.omsOl,
    props.values.omsAlkoPrep,
    props.values.omsOvrigt,
  ];

  
  useEffect(() => {
    sumAllValues();
  }, []);

  const sumAllValues = (): void => {
    CalcSum(props, "omsTotalt", values);
  };

  return (
    <Grid item xs={12} key={nr} marginBottom={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {nr}. Omsättning (hela kronor inklusive moms)
          </Typography>
          <Typography variant="caption">
            Omsättning redovisas inklusive moms och avrundas till hela kronor.
            Om företaget har flera verksamhetsgrenar redovisas endast den
            verksamhet som serveringstillståndet omfattar. Om serveringsstället
            även bedriver stadigvarande cateringsverksamhet ska omsättning för
            den verksamheten ingå.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Mat",
                  "omsMat",
                  props.values.omsMat,
                  props,
                  sumAllValues,
                  { omsMat: true },
                  mode,
                  "Kronor",
                  "number",
                  ["omsSprit", "omsVin"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Spritdrycker inklusive spritdrinkar",
                  "omsSprit",
                  props.values.omsSprit,
                  props,
                  sumAllValues,
                  {
                    inkopSpritLag: true,
                    inkopSpritHog: true,
                    normalPrisSprit: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsMat", "omsVin"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Vin inklusive vindrinkar",
                  "omsVin",
                  props.values.omsVin,
                  props,
                  sumAllValues,
                  {
                    inkopVinLag: true,
                    inkopVinMellan: true,
                    inkopVinHog: true,

                    normalPrisVin: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsMat", "omsSprit"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("Mat", prevYearObj?.omsMat)}
                    {InputComparePrevYear(
                      "Spritdrycker inklusive spritdrinkar",
                      prevYearObj?.omsSprit
                    )}
                    {InputComparePrevYear(
                      "Vin inklusive vindrinkar",
                      prevYearObj?.omsVin
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker",
                  "omsAndraJastaAlkoholdrycker",
                  props.values.omsAndraJastaAlkoholdrycker,
                  props,
                  sumAllValues,
                  {
                    omsAndraJastaAlkoholdrycker: true,
                    inkopAndraJastaAlkoholdryckerLag: true,
                    inkopAndraJastaAlkoholdryckerMellan: true,
                    inkopAndraJastaAlkoholdryckerHog: true,

                    normalPrisAndraJastaAlkoholdrycker: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsStarkOl", "omsOl"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Starköl",
                  "omsStarkOl",
                  props.values.omsStarkOl,
                  props,
                  sumAllValues,
                  {
                    inkopOlLag: true,
                    inkopOlHog: true,

                    normalPrisStarkOl: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsAndraJastaAlkoholdrycker", "omsOl"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Folköl",
                  "omsOl",
                  props.values.omsOl,
                  props,
                  sumAllValues,
                  {
                    omsOl: true,
                    normalPrisOl: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsStarkOl", "omsAndraJastaAlkoholdrycker"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker",
                      prevYearObj?.omsAndraJastaAlkoholdrycker
                    )}
                    {InputComparePrevYear("Starköl", prevYearObj?.omsStarkOl)}
                    {InputComparePrevYear("Folköl", prevYearObj?.omsOl)}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Alkoholdrycksliknande preparat",
                  "omsAlkoPrep",
                  props.values.omsAlkoPrep,
                  props,
                  sumAllValues,
                  {
                    inkopAlkoPrepLag: true,
                    inkopAlkoPrepHog: true,
                    omsAlkoPrep: true,

                    normalPrisAlkoPrep: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["omsOvrigt"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Lättdrycker",
                  "omsOvrigt",
                  props.values.omsOvrigt,
                  props,
                  sumAllValues,
                  { omsOvrigt: true },
                  mode,
                  "Kronor",
                  "number",
                  ["omsAlkoPrep"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Alkoholdrycksliknande preparat",
                      prevYearObj?.omsAlkoPrep
                    )}
                    {InputComparePrevYear(
                      "Lättdrycker",
                      prevYearObj?.omsOvrigt
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Summa omsättning",
                  "omsTotalt",
                  props.values.omsTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled,
                  "Kronor"
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Summa omsättning",
                      prevYearObj?.omsTotalt
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
