import * as Yup from "yup"

export const noNumbers = /(^[\D ]*$)/;  
const onlyNumbers = /^[\d -]*$/;

const min = "Lägsta tillåtna värde är 0";
const heltal = "Endast heltal är tillåtna";

const nrValidation = () => {
    return Yup.number().nullable().min(0,min).integer(heltal)
}


export const commonSchema: any = {

    //Tillverkning
    spritTillverkningLag:nrValidation(),
    spritTillverkningHog:nrValidation(),
    vinTillverkningLag:nrValidation(),
    vinTillverkningMellan:nrValidation(),
    vinTillverkningHog:nrValidation(),
    andraJastaTillverkningLag:nrValidation(),
    andraJastaTillverkningMellan:nrValidation(),
    andraJastaTillverkningHog:nrValidation(),
    starkOlTillverkningLag:nrValidation(),
    starkOlTillverkningHog:nrValidation(),
    folkOlTillverkning:nrValidation(),
    tillverkningTotalt:nrValidation(),
    tillverkning:nrValidation(),
 

    //Import
    spritImportLag:nrValidation(),
    spritImportHog:nrValidation(),
    vinImportLag:nrValidation(),
    vinImportMellan:nrValidation(),
    vinImportHog:nrValidation(),
    andraJastaImportLag:nrValidation(),
    andraJastaImportMellan:nrValidation(),
    andraJastaImportHog:nrValidation(),
    starkOlImportLag:nrValidation(),
    starkOlImportHog:nrValidation(),
    alkoPrepImport:nrValidation(),
    importTotalt:nrValidation(),
    importInforsel:nrValidation(),

    //Forsaljning Sverige
    spritSaljSystembolagetLag:nrValidation(),
    spritSaljSystembolagetHog:nrValidation(),
    spritSaljPartihandlareLag:nrValidation(),
    spritSaljPartihandlareHog:nrValidation(),
    spritSaljInnehavareLag:nrValidation(),
    spritSaljInnehavareHog:nrValidation(),
    vinSaljSystembolagetLag:nrValidation(),
    vinSaljSystembolagetMellan:nrValidation(),
    vinSaljSystembolagetHog:nrValidation(),
    vinSaljPartihandlareLag:nrValidation(),
    vinSaljPartihandlareMellan:nrValidation(),
    vinSaljPartihandlareHog:nrValidation(),
    vinSaljInnehavareLag:nrValidation(),
    vinSaljInnehavareMellan:nrValidation(),
    vinSaljInnehavareHog:nrValidation(),
    andraJastaSaljSystembolagetLag:nrValidation(),
    andraJastaSaljSystembolagetMellan:nrValidation(),
    andraJastaSaljSystembolagetHog:nrValidation(),
    andraJastaSaljPartihandlareLag:nrValidation(),
    andraJastaSaljPartihandlareMellan:nrValidation(),
    andraJastaSaljPartihandlareHog:nrValidation(),
    andraJastaSaljInnehavareLag:nrValidation(),
    andraJastaSaljInnehavareMellan:nrValidation(),
    andraJastaSaljInnehavareHog:nrValidation(),
    starkOlSaljSystembolagetLag:nrValidation(),
    starkOlSaljSystembolagetHog:nrValidation(),
    starkOlSaljPartihandlareLag:nrValidation(),
    starkOlSaljPartihandlareHog:nrValidation(),
    starkOlSaljInnehavareLag:nrValidation(),
    starkOlSaljInnehavareHog:nrValidation(),
    alkoPrepSaljSystembolaget:nrValidation(),
    alkoPrepSaljPartihandlare:nrValidation(),
    alkoPrepSaljInnehavare:nrValidation(),
    saljTotalt:nrValidation(),

    forsaljningAndraUpplagshavare:nrValidation(),
    forsaljningApotek:nrValidation(),
    forsaljningSjukhus:nrValidation(),
    forsaljnSkattebefriade:nrValidation(),
    forsaljningUniversitetHogskolor:nrValidation(),
    forsaljnTillverkare:nrValidation(),
    forsaljningOvrigt:nrValidation(),


   
      //Export
    spritExportLag:nrValidation(),
    spritExportHog:nrValidation(),
    vinExportLag:nrValidation(),
    vinExportMellan:nrValidation(),
    vinExportHog:nrValidation(),
    andraJastaExportLag:nrValidation(),
    andraJastaExportMellan:nrValidation(),
    andraJastaExportHog:nrValidation(),
    starkOlExportLag:nrValidation(),
    starkOlExportHog:nrValidation(),
    alkoPrepExport:nrValidation(),
    exportTotalt:nrValidation(),
    exportUtforsel:nrValidation(),

    //Beräknad
    beraknadSpritLag:nrValidation(),
    beraknadSpritHog:nrValidation(),
    beraknadTotalt:nrValidation()

}


export const StatistikrapportForsaljningTillverkningSchemaHandlaggare = Yup.object().shape(
    {
        uppgiftslamnare: Yup.string().nullable().required("Du måste ange uppgiftslämnare").matches(noNumbers,"Inga siffror är tillåtna"),
        kontaktPersonNamn: Yup.string().nullable().required("Du måste ange kontaktperson").matches(noNumbers,"Inga siffror är tillåtna"),
        kontaktPersonTelefon: Yup.string()
        .nullable()
        .matches(onlyNumbers, "Endast siffror är tillåtet")
        .when(["kontaktPersonEpost"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Du måste ange ett telefonnr och/eller en e-postadress")
          .matches(onlyNumbers, "Endast siffror är tillåtet"),
      }),
      kontaktPersonEpost: Yup.string()
      .nullable()
      .email("Ange en korrekt e-postadress")
      .when(["kontaktPersonTelefon"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null ) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Du måste ange ett telefonnr och/eller en e-postadress")
          .email("Ange en korrekt e-postadress"),
      }),
        
    }
    ,[
        ["kontaktPersonTelefon", "kontaktPersonEpost"]]
).shape({...commonSchema})

export const StatistikrapportForsaljningTillverkningSchema = Yup.object().shape(
    {
        uppgiftslamnare: Yup.string().nullable().required("Du måste ange uppgiftslämnare").matches(noNumbers,"Inga siffror är tillåtna"),
        kontaktPersonNamn: Yup.string().nullable().required("Du måste ange kontaktperson").matches(noNumbers,"Inga siffror är tillåtna"),
        kontaktPersonTelefon: Yup.string()
      .nullable()
      .when(["kontaktPersonEpost"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Du måste ange ett telefonnr och/eller en e-postadress")
          .matches(onlyNumbers, "Endast siffror är tillåtet"),
      }),
    kontaktPersonEpost: Yup.string()
      .nullable()
      .when(["kontaktPersonTelefon"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Du måste ange ett telefonnr och/eller en e-postadress")
          .email("Ange en korrekt e-postadress"),
      }),
        
    },[
        ["kontaktPersonTelefon", "kontaktPersonEpost"]]
).shape({...commonSchema})
