import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";

export default function Normalpriser(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  return (
    <Grid key={nr} item xs={12} marginBottom={4}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2">
            {nr}. Normalpriser (hela kronor inklusive moms)
          </Typography>
          <Typography variant="caption">
            Normalpris är priset för de olika alkoholdrycker som
            serveringsstället säljer mest av. Om ni säljer mest vid särskilda
            prissättningar som vid happy hour, after work eller liknande anger
            du det priset som normalpris. Priserna ska vara de priser som gäller
            vid årets slut. Priserna anges inklusive moms och avrundas till hela
            kronor.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Spritdryck 1 cl",
                  "normalPrisSprit",
                  props.values.normalPrisSprit,
                  props,
                  undefined,
                  {
                    omsTotalt: true,
                    normalPrisSprit: true,
                    omsSprit: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalPrisVin"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Vin 75 cl",
                  "normalPrisVin",
                  props.values.normalPrisVin,
                  props,
                  undefined,
                  {
                    omsTotalt: true,
                    normalPrisVin: true,
                    omsVin: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalPrisSprit"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Spritdryck 1 cl",
                      prevYearObj?.normalPrisSprit
                    )}
                    {InputComparePrevYear(
                      "Vin 75 cl",
                      prevYearObj?.normalPrisVin
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker",
                  "normalPrisAndraJastaAlkoholdrycker",
                  props.values.normalPrisAndraJastaAlkoholdrycker,
                  props,
                  undefined,
                  {
                    normalVolymAndraJastaAlkoholdrycker: true,
                    normalPrisAndraJastaAlkoholdrycker: true,
                    omsTotalt: true,
                    omsAndraJastaAlkoholdrycker: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalVolymAndraJastaAlkoholdrycker"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker avser volym i cl",
                  "normalVolymAndraJastaAlkoholdrycker",
                  props.values.normalVolymAndraJastaAlkoholdrycker,
                  props,
                  undefined,
                  {
                    normalPrisAndraJastaAlkoholdrycker: true,
                    normalVolymAndraJastaAlkoholdrycker: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["normalPrisAndraJastaAlkoholdrycker"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker",
                      prevYearObj?.normalPrisAndraJastaAlkoholdrycker
                    )}
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker avser volym i cl",
                      prevYearObj?.normalVolymAndraJastaAlkoholdrycker
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Starköl",
                  "normalPrisStarkOl",
                  props.values.normalPrisStarkOl,
                  props,
                  undefined,
                  {
                    normalVolymStarkOl: true,
                    normalPrisStarkOl: true,
                    omsTotalt: true,
                    omsStarkOl: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalVolymStarkOl"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Starköl avser volym i cl",
                  "normalVolymStarkOl",
                  props.values.normalVolymStarkOl,
                  props,
                  undefined,
                  {
                    normalPrisStarkOl: true,
                    normalVolymStarkOl: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["normalPrisStarkOl"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Starköl",
                      prevYearObj?.normalPrisStarkOl
                    )}
                    {InputComparePrevYear(
                      "Starköl avser volym i cl",
                      prevYearObj?.normalVolymStarkOl
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Folköl",
                  "normalPrisOl",
                  props.values.normalPrisOl,
                  props,
                  undefined,
                  {
                    normalVolymOl: true,
                    normalPrisOl: true,
                    omsTotalt: true,
                    omsOl: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalVolymOl"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Folköl avser volym i cl",
                  "normalVolymOl",
                  props.values.normalVolymOl,
                  props,
                  undefined,
                  {
                    normalPrisOl: true,
                    normalVolymOl: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["normalPrisOl"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("Folköl", prevYearObj?.normalPrisOl)}
                    {InputComparePrevYear(
                      "Folköl avser volym i cl",
                      prevYearObj?.normalVolymOl
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Alkoholdrycksliknande preparat",
                  "normalPrisAlkoPrep",
                  props.values.normalPrisAlkoPrep,
                  props,
                  undefined,
                  {
                    normalVolymAlkoPrep: true,
                    normalPrisAlkoPrep: true,
                    omsTotalt: true,
                    omsAlkoPrep: true,
                  },
                  mode,
                  "Kronor",
                  "number",
                  ["normalVolymAlkoPrep"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Alkoholdrycksliknande preparat avser volym i cl",
                  "normalVolymAlkoPrep",
                  props.values.normalVolymAlkoPrep,
                  props,
                  undefined,
                  {
                    normalPrisAlkoPrep: true,
                    normalVolymAlkoPrep: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["normalPrisAlkoPrep"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Alkoholdrycksliknande preparat",
                      prevYearObj?.normalPrisAlkoPrep
                    )}
                    {InputComparePrevYear(
                      "Alkoholdrycksliknande preparat avser volym i cl",
                      prevYearObj?.normalVolymAlkoPrep
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
