export enum Roles {
  FohmAdmin = "FohmAdmin",
  FohmLasAlkohol = "FohmLasAlkohol",
  FohmLasTobak = "FohmLasTobak",
  KommunAdminAlkohol = "KommunAdminAlkohol",
  KommunAdminTobak = "KommunAdminTobak",
  KommunLasAlkohol = "KommunLasAlkohol",
  KommunLasTobak = "KommunLasTobak",
  LansstyrelseLasAlkohol = "LansstyrelseLasAlkohol",
  Registerutdrag = "Registerutdrag",
  WebbtjanstRestaurangRapport = "WebbtjanstRestaurangRapport",
  WebbtjanstRegisterutdrag = "WebbtjanstRegisterutdrag",
  WebbtjanstTillstandAlkohol = "WebbtjanstTillstandAlkohol",
  NoRole = "NoRoles",
  KommunLanstyrelse = "kommunlanstyrelse",
  Foretag = "foretag",
  RapportInlamnare = "RapportInlamnare"
}

export enum Cat {
  kommun = "kommun",
  lan = "lan",
  all = "all",
  ext = "ext",
}

interface IRolesReadMap {
  role: Roles;
  label: string;
}

interface IRolesReadMultiMap {
  role: Roles[];
  key: number;
  label: string;
  cat: Cat;
}

export const RolesReadable: IRolesReadMap[] = [
  {
    role: Roles.FohmAdmin,
    label: "FOHM administratör",
  },
  {
    role: Roles.FohmLasAlkohol,
    label: "FOHM alkohol läs",
  },
  {
    role: Roles.FohmLasTobak,
    label: "FOHM tobak läs",
  },
  {
    role: Roles.KommunAdminAlkohol,
    label: "Kommunadmin alkohol",
  },
  {
    role: Roles.KommunAdminTobak,
    label: "Kommunadmin tobak",
  },
  {
    role: Roles.KommunLasAlkohol,
    label: "Kommuna alkohol läs",
  },
  {
    role: Roles.KommunLasTobak,
    label: "Kommun tobak läs",
  },
  {
    role: Roles.LansstyrelseLasAlkohol,
    label: "Länsstyrelsen läs",
  },
  {
    role: Roles.Registerutdrag,
    label: "Registerutdrag",
  }
];

export const MapToCorrectKey = (roles: Roles[]) => {
  if (roles === null || roles === undefined) return 0;
  return (
    RolesMapReadAble.find(
      (x) =>
        roles.every((p) => x.role.includes(p)) && x.role.length === roles.length
    )?.key ?? 0
  );
};

export const MapToCorrectlabel = (roles: Roles[]) => {
  if (roles === null || roles === undefined) return "--";

  return (
    RolesMapReadAble.find(
      (x) =>
        roles.every((p) => x.role.includes(p)) && x.role.length === roles.length
    )?.label ?? "--"
  );
};

export const MapToCorrectCat = (roles: Roles[]) => {
  if (roles === null || roles === undefined) return Cat.all;
  return (
    RolesMapReadAble.find(
      (x) =>
        roles.every((p) => x.role.includes(p)) && x.role.length === roles.length
    )?.cat ?? Cat.all
  );
};

export const ShowDropdown = (roles: Roles[]) => {
  if (roles === null || roles === undefined) return false;
  return (roles.includes(Roles.WebbtjanstRestaurangRapport) || roles.includes( Roles.WebbtjanstTillstandAlkohol ) ) 
};

export const GetCorrectRolesBasedOnKey = (key: any) => {
  return RolesMapReadAble.find((x) => x.key === key)?.role ?? [];
};

export const VerifyRolesExistsInRoles = (
  userRoles: Roles[],
  rolesAllowed: Roles[]
) => {
  if (rolesAllowed.some((r) => userRoles.includes(r))) {
    return true;
  }
  return false;
};

export const RolesMapReadAble: IRolesReadMultiMap[] = [
  {
    key: 1,
    label: "FOHM administratör",
    role: [Roles.FohmAdmin],
    cat: Cat.all,
  },
  // {
  //   key: 2,
  //   label: "FOHM läsbehörighet",
  //   role: [Roles.FohmLasAlkohol, Roles.FohmLasTobak],
  //   cat: Cat.all,
  // },
  {
    key: 3,
    label: "FOHM läsbehörighet alkohol",
    role: [Roles.FohmLasAlkohol],
    cat: Cat.all,
  },
  {
    key: 4,
    label: "FOHM läsbehörighet tobak",
    role: [Roles.FohmLasTobak],
    cat: Cat.all,
  },
  {
    key: 5,
    label: "Kommun administratör alkohol och tobak",
    role: [Roles.KommunAdminAlkohol, Roles.KommunAdminTobak],
    cat: Cat.kommun,
  },
  {
    key: 9,
    label: "Kommun administratör alkohol",
    role: [Roles.KommunAdminAlkohol],
    cat: Cat.kommun,
  },
  {
    key: 10,
    label: "Kommun administratör tobak",
    role: [Roles.KommunAdminTobak],
    cat: Cat.kommun,
  },
  {
    key: 6,
    label: "Kommun läsbehörighet alkohol och tobak",
    role: [Roles.KommunLasAlkohol, Roles.KommunLasTobak],
    cat: Cat.kommun,
  },
  {
    key: 7,
    label: "Kommun läsbehörighet alkohol",
    role: [Roles.KommunLasAlkohol],
    cat: Cat.kommun,
  },
  {
    key: 8,
    label: "Kommun läsbehörighet tobak",
    role: [Roles.KommunLasTobak],
    cat: Cat.kommun,
  },

  {
    key: 11,
    label: "Länsstyrelse läsbehörighet alkohol och tobak",
    role: [Roles.LansstyrelseLasAlkohol],
    cat: Cat.lan,
  },
  {
    key: 12,
    label: "Registerutdrag serveringstillstånd",
    role: [Roles.Registerutdrag],
    cat: Cat.ext,
  },
  {
    key: 13,
    label: "Webbtjänst restaurangrapport",
    role: [Roles.WebbtjanstRestaurangRapport],
    cat: Cat.kommun,
  },
  {
    key: 14,
    label: "Webbtjänst registerutdrag serveringstillstånd",
    role: [Roles.WebbtjanstRegisterutdrag],
    cat: Cat.ext,
  },
  {
    key: 15,
    label: "Webbtjänst stadigvarande serveringstillstånd",
    role: [Roles.WebbtjanstTillstandAlkohol],
    cat: Cat.kommun,
  },
];
