import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

import React, { useEffect } from "react";
import {
  getKommunListInfo,
  getLanListInfo,
  getOkandAvslutData,
  getOrsakTillAvslutData,
  getPartiAvslutData,
  getRapportPeriod,
  getRapportStatus,
  getRapportTyp,
  getRapporttypListaInfo,
  getServeringsAvslutData,
  getServeringsUtrymmeTyperData,
  getTillstandsKategorierData,
  getTobakAvslutData,
  IInfo,
  ITillstandsKategorier,
  kommunList,
  lanList,
} from "../Services/infoService";
import { PermitTypes, SearchPermitTypes, SearchPermitTypesAlkohol, SearchPermitTypesKommunAdmin, SearchPermitTypesKommunAlkohol, SearchPermitTypesKommunTobak, SearchPermitTypesTobak, SearchPermitTypesLansstyrelseAlkoholTobak } from "../Views/Permit/PermitsFormsUtils/permitsUtils";
import { Roles, RolesMapReadAble, Cat } from "../Utilities/roles";
import {
  KommunKontaktTyp,
  KommunKontaktTypes,
} from "../Utilities/contactUtils";
import {
  SystemTypes,
} from "../Utilities/systemUtils";
import { Rapporttyper } from "../Views/Report/ReportForm/formlogic";
import { RapportStatus } from "../Services/reportService";
export enum System {
  AlkT = 1,
  Castor = 2,
  EDP = 3,
  LEX = 4,
}
export interface IData {
  key: number;
  label: string;
}

export interface IDataGrouped {
  label: string;
  group: IData[];
}

export interface IGroupData extends IData {
  groupId: number;
  groupName: string;
}

export enum InfoType {
  kommuner = "kommuner",
  system = "system",
  kommunKontaktTyper = "kommunKontaktTyper",
  kommunKontaktTyperFiltered = "kommunKontaktTyperFiltered",
  lan = "lan",
  lanAnvandare = "lanAnvandare",
  kommunerAnvandare = "kommunerAnvandare",
  roller = "rollerReadable",
  rollerGrouped = "rollerGrouped",
  rollerApply = "rollerApply",
  rollerApplyForAccount = "rollerApplyForAccount",
  tillstandsTyper = "tillstandsTyper",
  sokTillstandsTyper = "sokTillstandsTyper",
  sokTillstandsTyperAlkohol = "sokTillstandsTyperAlkohol",
  sokTillstandsTyperTobak = "sokTillstandsTyperTobak",
  sokTillstandsTyperKommunAlkohol = "sokTillstandsTyperKommunAlkohol",
  sokTillstandsTyperKommunTobak = "sokTillstandsTyperKommunTobak",
  sokTillstandsTyperKommunAdmin = "sokTillstandsTyperKommunAdmin",
  sokTillstandsTyperLanstyrelseAlkoholTobak = "sokTillstandsTyperLanstyrelseAlkoholTobak",
  rollerKommunLanstyrelse = "rollerKommunLanstyrelse",
  rollerForetag = "rollerForetag",
  rapportTyper = "rapportTyper",
  tillstandsKategoriAlla = "tillstandsKategoriAlla",
  tillstandsKategoriStadigvarande = "tillstandsKategoriStadigvarande",
  tillstandsKategoriTillfalligt = "tillstandsKategoriTillfalligt",
  tillstandsKategoriUpplagshavare = "tillstandsKategoriUpplagshavare",
  tillstandsKategoriSkattebefriadForbrukare = "tillstandsKategoriSkattebefriadForbrukare",
  tillstandsKategoriRegistreradVarumottagare = "tillstandsKategoriRegistreradVarumottagare",
  servUtrymmeTypId = "servUtrymmeTypId",
  orsakTillAvslutId = "orsakTillAvslutId",
  serveringsAvslut = "serveringsAvslut",
  tobakAvslut = "tobakAvslut",
  tobakPartiAvslut = "tobakPartiAvslut",
  partiAvslut = "partiAvslut",
  okandAvslut = "okandAvslut",
  rapportTypId = "rapportTypId",
  rapportPeriodId = "rapportPeriodId",
  rapportStatusId = "rapportStatusId",
  rapportTyperRegisterReport = "rapportTyperRegisterReport",
  rapportStatusEjSkapad = "rapportStatusEjSkapad",
  kvartal = "kvartal",
  artal = "artal"
}

interface ISelectSingle extends SelectProps {
  data: IData[];
  selected: number;
}

interface ISelectSingleGrouped extends SelectProps {
  data: IDataGrouped[];
  selected: number[] | null;
}

interface ISelectSingleWithoutData extends SelectProps {
  selected: number;
  infoType: InfoType;
  defaultlabel?: string;
  //Should only be used as a combination with Infotype Roles Grouped
  rolesToFilter?: Roles[];
  kontaktTypesToFilter?: KommunKontaktTyp[];
  defaultVal?: boolean;
}


export function transferDataToIData(
  data: IInfo[],
  defaultlabel: string | undefined,
  defaultval: boolean
): IData[] {
  let d: IData[] = [];
  if (data !== null) {
    data.map((x) => d.push({ key: x.id, label: x.namn }));
    if (defaultval)
      d.unshift({
        key: 0,
        label: defaultlabel !== undefined ? defaultlabel : "Alla",
      });
  } else {
    if (defaultval)
      d.push({
        key: 0,
        label: defaultlabel !== undefined ? defaultlabel : "Alla",
      });
  }
  return d;
}

export function transferDataToIDataGrouped(
  data: ITillstandsKategorier[],
  defaultlabel: string | undefined,
  defaultval: boolean
): IGroupData[] {
  let d: IGroupData[] = [];
  if (data !== null) {
    data.map((x) => d.push({ key: x.id, label: x.namn, groupId: x.groupId, groupName: x.groupName }));
    if (defaultval)
      d.unshift({
        key: 0,
        label: defaultlabel !== undefined ? defaultlabel : "Alla",
        groupId: 0,
        groupName: "",
      });
  } else {
    if (defaultval)
      d.push({
        key: 0,
        label: defaultlabel !== undefined ? defaultlabel : "Alla",
        groupId: 0,
        groupName: "",
      });
  }
  return d;
}

export async function GetDataBasedOnType(
  infotype: InfoType,
  defaultlabel: string | undefined,
  defautval: boolean,
  group?: Roles[]
): Promise<IData[]> {
  switch (infotype) {
    case InfoType.kommuner: {
      return transferDataToIData(
        await getKommunListInfo(kommunList.all),
        defaultlabel,
        defautval
      );
    }
    case InfoType.kommunerAnvandare: {
      return transferDataToIData(
        await getKommunListInfo(kommunList.userSpecific),
        defaultlabel,
        defautval
      );
    }
    case InfoType.kommunKontaktTyper: {
      return transferDataToIData(
        KommunKontaktTypes.map<IInfo>((p) => ({ id: p.id, namn: p.namn })),
        defaultlabel,
        defautval
      );
    }
    case InfoType.system: {
      return transferDataToIData(
        SystemTypes.map<IInfo>((p) => ({ id: p.id, namn: p.namn })),
        defaultlabel,
        defautval
      );
    }
    // case InfoType.kommunKontaktTyperFiltered: {
    //   return transferDataToIData(
    //     KommunKontaktTypes.filter((p) =>
    //       filterKontaktTypes.includes(p.id)
    //     ).map<IInfo>((p) => ({ id: p.id, namn: p.namn })),
    //     defaultlabel,
    //     defautval
    //   );
    // }
    case InfoType.lan: {
      return transferDataToIData(
        await getLanListInfo(lanList.all),
        defaultlabel,
        defautval
      );
    }
    case InfoType.lanAnvandare: {
      return transferDataToIData(
        await getLanListInfo(lanList.userSpecific),
        defaultlabel,
        defautval
      );
    }
    case InfoType.rapportTyper: {
      return transferDataToIData(
        await getRapporttypListaInfo(),
        defaultlabel,
        defautval
      );
    }
    case InfoType.rapportTyperRegisterReport: {
      return transferDataToIData(
        await (await getRapporttypListaInfo()).filter(p => p.id === Rapporttyper.StatistikrapportForsaljningTillverkning || p.id === Rapporttyper.Restaurangrapport ||  p.id === Rapporttyper.StatistikrapportTekniskSprit),
        defaultlabel,
        defautval
      );
    }
    case InfoType.tillstandsTyper: {
      return transferDataToIData(PermitTypes, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyper: {
      return transferDataToIData(SearchPermitTypes, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperAlkohol: {
      return transferDataToIData(SearchPermitTypesAlkohol, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperTobak: {
      return transferDataToIData(SearchPermitTypesTobak, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperKommunAlkohol: {
      return transferDataToIData(SearchPermitTypesKommunAlkohol, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperKommunTobak: {
      return transferDataToIData(SearchPermitTypesKommunTobak, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperKommunAdmin: {
      return transferDataToIData(SearchPermitTypesKommunAdmin, defaultlabel, defautval);
    }
    case InfoType.sokTillstandsTyperLanstyrelseAlkoholTobak: {
      return transferDataToIData(SearchPermitTypesLansstyrelseAlkoholTobak, defaultlabel, defautval);
    }
    case InfoType.roller: {
      return transferDataToIData(
        RolesMapReadAble.map<IInfo>((p) => ({ id: p.key, namn: p.label })),
        defaultlabel,
        defautval
      );
    }
    case InfoType.rollerApply: {
      return transferDataToIData(
        RolesMapReadAble.filter(
          (x) =>
            x.role.findIndex(
              (p) =>
                p === Roles.FohmAdmin ||
                p === Roles.FohmLasAlkohol ||
                p === Roles.FohmLasTobak
            ) < 0
        ).map<IInfo>((p) => ({ id: p.key, namn: p.label })),
        defaultlabel,
        defautval
      );
    }

    case InfoType.rollerApplyForAccount: {
      return transferDataToIData(
        RolesMapReadAble.filter(
          (x) => (x.cat === Cat.kommun || x.cat === Cat.lan)
           && 
           (!x.label.toLocaleLowerCase().includes("stadigvarande serveringstillstånd"))
        ).map<IInfo>((p) => ({ id: p.key, namn: p.label })),
        defaultlabel,
        defautval
      );
    }

    case InfoType.rollerKommunLanstyrelse: {
      return transferDataToIData(
        RolesMapReadAble.filter(
          (x) => (x.cat === Cat.kommun || x.cat === Cat.lan) && 
          (!x.label.toLocaleLowerCase().includes("tobak")) && // Används bara vid ansökning av användarkonto. Döljer alla roller som innehåller tobak innan driftsättning.
           (!x.label.toLocaleLowerCase().includes("stadigvarande serveringstillstånd"))
        ).map<IInfo>((p) => ({ id: p.key, namn: p.label })),
        defaultlabel,
        defautval
      );
    }

    case InfoType.rollerForetag: {
      return transferDataToIData(
        RolesMapReadAble.filter((x) => x.cat === Cat.ext).map<IInfo>((p) => ({
          id: p.key,
          namn: p.label,
        })),
        defaultlabel,
        defautval
      );
    }

    case InfoType.rollerGrouped: {
      if (group !== undefined) {
        return transferDataToIData(
          RolesMapReadAble.filter((p) =>
            group.find((x) => p.role.includes(x))
          ).map<IInfo>((z) => ({ id: z.key, namn: z.label })),
          defaultlabel,
          defautval
        );
      } else {
        throw Error("Group Roles not provieded");
      }
    }

    case InfoType.tillstandsKategoriAlla: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData(null),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tillstandsKategoriStadigvarande: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData("StadigvarandeServeringstillstand"),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tillstandsKategoriTillfalligt: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData("TillfalligtServeringstillstand"),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tillstandsKategoriUpplagshavare: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData("Upplagshavare"),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tillstandsKategoriRegistreradVarumottagare: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData("RegistreradVarumottagare"),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tillstandsKategoriSkattebefriadForbrukare: {
      return transferDataToIDataGrouped(
        await getTillstandsKategorierData("SkattebefriadForbrukare"),
        defaultlabel,
        defautval
      )
    }

    case InfoType.servUtrymmeTypId: {
      return transferDataToIData(
        await getServeringsUtrymmeTyperData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.orsakTillAvslutId: {
      return transferDataToIData(
        await getOrsakTillAvslutData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.serveringsAvslut: {
      return transferDataToIData(
        await getServeringsAvslutData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.tobakAvslut: {
      return transferDataToIData(
        await getTobakAvslutData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.partiAvslut: {
      return transferDataToIData(
        await getPartiAvslutData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.okandAvslut: {
      return transferDataToIData(
        await getOkandAvslutData(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.rapportTypId: {
      return transferDataToIData(
        await (await getRapporttypListaInfo()).filter(p => p.id === Rapporttyper.StatistikrapportForsaljningTillverkning || p.id === Rapporttyper.Restaurangrapport || p.id === Rapporttyper.StatistikrapportTekniskSprit),
        defaultlabel,
        defautval
      )
    }

    case InfoType.rapportStatusEjSkapad: {
      return transferDataToIData(
        await (await getRapportStatus()).filter(p => p.namn !== RapportStatus.Skapad),defaultlabel,defautval
      )
    }

    case InfoType.rapportPeriodId: {
      return transferDataToIData(
        await getRapportPeriod(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.rapportStatusId: {
      return transferDataToIData(
        await getRapportStatus(),
        defaultlabel,
        defautval
      )
    }

    case InfoType.kvartal: {
      const kvartal = [
        {
          id: 1,
          namn: "1"
        },
        {
          id: 2,
          namn: "2"
        },
        {
          id: 3,
          namn: "3"
        },
        {
          id: 4,
          namn: "4"
        }
      ]
      return transferDataToIData(
        kvartal,
        defaultlabel,
        false
      );
    }

    case InfoType.artal: {

      const year = new Date().getFullYear(); 
      
      const artal = [
        {
          id: year - 1,
          namn: String(year - 1)
        },
        {
          id: year,
          namn: String(year)
        },
        {
          id: year + 1,
          namn: String(year + 1) 
        },
        {
          id: year + 2,
          namn: String(year + 2)
        },
        {
          id: year + 3,
          namn: String(year + 3)
        }
      ]

      return transferDataToIData(
        artal,
        defaultlabel,
        false
      );
    }

    default:
      null!;
  }
  return null!;
}

export const SelectSingleWihtoutData = (props: ISelectSingleWithoutData) => {
  const [data, setData] = React.useState<IData[]>([
    {
      key: props.selected,
      label: "Loading...",
    },
  ]);
  useEffect(() => {
    GetDataBasedOnType(
      props.infoType,
      props.defaultlabel,
      props.defaultVal ?? true,
      props.rolesToFilter
    ).then((p) => {
      setData(p);
    });
  }, []);

  const { selected, infoType, defaultVal, rolesToFilter, ...children } = props;

  return (
    <Select {...children} value={props.selected}>
      {data.map((d,i) => (
        <MenuItem key={i} value={d.key}>
          {d.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const SelectSingle = (props: ISelectSingle) => {
  const { selected, data, ...children } = props;

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...children} value={selected}>
        {props.data.map((p, index) => (
          <MenuItem key={index} value={p.key}>
            {p.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectSingleGrouped = (props: ISelectSingleGrouped) => {
  const { selected, data, ...children } = props;

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...children} value={selected}>
        {props.data.map((group, i) => (
          <div key={group.label}>
            <ListSubheader key={i}>{group.label}</ListSubheader>
            {group.group.map((item, j) => (
              <MenuItem key={j} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </div>
        ))}
      </Select>
    </FormControl>
  );
};
