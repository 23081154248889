import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { sendEmailValidation } from "./validationContact";
import {
  Grid,
  FormControl,
  FormLabel,
  Typography,
  Stack,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Link,
  Button,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import InputTextField from "../../Components/textfield";
import CustomModal from "../../Components/customModal";
import { IAlert, ShowAlert, ShowAlertEval } from "../../Components/alerts";
import {
  emailListKontakt,
  sendEmailKontakt,
  IKontaktSendEmail,
  IKontaktData,
} from "../../Services/ContactService";

export interface IEmailForm extends IKontaktSendEmail {}

export default function SendEmail() {
  const [avsluta, setAvsluta] = useState<boolean>(false);
  const [kontaktList, setKontaktList] = useState<IKontaktData[]>([]);
  const [status, setStatus] = useState<IAlert>();
  const initialValues: IEmailForm = {
    amne: "",
    meddelande: "",
    namn: "",
    epost: "",
  };
  const navigate = useNavigate();

  const handleCloseAvslutaModal = (): void => {
    setAvsluta(false);
  };

  const getKontaktList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const id = event.currentTarget.id;
    const kontaktId = parseInt("2", 10);
    emailListKontakt(kontaktId)
      .then((response) => {
        if (response.status === "200") {
          setKontaktList([...response.object]);
          if (id === "kopiera-adresser") {
            copyToClipboard(response.object);
          } else {
            setAvsluta(true);
          }
        }
      })
      .catch((error) => {
        setStatus({
          show: true,
          alertText: `${error.data}`,
          severity: "error",
        });
      });
  };

  const copyToClipboard = (content: IKontaktData[]) => {
    if (content.length > 0) {
      navigator.clipboard.writeText(
        content
          .map((x) => {
            return x.epostadress;
          })
          .join(";")
      );

      setStatus({
        show: true,
        alertText: 'Starta ditt mejlprogram och klistra in dem (CTRL+V) i "Hemlig kopia" och ange supportlådans adress som mottagare.',
        title: "Mejladresserna har kopierats",
        severity: "success",
      });
    } else {
      setStatus({
        show: true,
        alertText: "Det finns inga e-postadresser för vald mottagargrupp",
        title: "OBS!",
        severity: "warning",
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Skicka e-post till kontaktpersoner
        </Typography>
      </Grid>

      <Grid item xs={12} md={10}>
        <FormControl>
          <FormLabel component="p" id="demo-radio-buttons-group-label">
            Mottagare
          </FormLabel>
          <Typography>Kontaktperson - användarkonton</Typography>
          <Stack direction="row" alignItems="center">
            <Button
              onClick={getKontaktList}
              id="visa-mottagare"
              startIcon={<VisibilityIcon color="inherit" />}
              color="primary"
              variant="contained"
              sx={{ marginTop: "10px" }}
            >
              Visa mottagare
            </Button>
          </Stack>
        </FormControl>
        {ShowAlertEval(status) && (
          <Grid item xl={6} mt={1}>
            <ShowAlert
              onClose={() => {
                setStatus(undefined);
              }}
              severity={status?.severity}
              alertText={status && status.alertText ? status?.alertText : ""}
            />
          </Grid>
        )}
        {/* start modal */}
        <CustomModal
          title="Mottagare"
          openModal={avsluta}
          handleCloseModal={handleCloseAvslutaModal}
        >
          <table aria-label="tabell över mottagare">
            <thead className="visuallyHidden">
              <tr>
                <th>Namn</th>
                <th>E-postadress</th>
              </tr>
            </thead>
            {kontaktList.map((kontakt, i) => {
              return (
                <tbody key={i}>
                  <tr className="tableRow-grid">
                    <td className="tableCell-flex">
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <Typography>{kontakt.namn}</Typography>
                    </td>
                    <td className="tableCell-flex">
                      <ListItemAvatar>
                        <Avatar>
                          <EmailIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <Typography>{kontakt.epostadress}</Typography>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </CustomModal>
        {/* end modal */}

        <Formik
          enableReinitialize
          validationSchema={sendEmailValidation}
          initialValues={{
            ...initialValues,
            epost: "servering@folkhalsomyndigheten.se",
            namn: "Folkhälsomyndigheten",
          }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            setSubmitting(true);
            sendEmailKontakt(values, parseInt("2", 10))
              .then((response) => {
                setSubmitting(false);
                resetForm();
                if (response.status === "200") {
                  setStatus({
                    sent: true,
                    msg: "E-postmeddelandet har skickats",
                    title: "",
                    severity: "success",
                  });
                }
              })
              .catch((error) => {
                setStatus({
                  sent: true,
                  msg: `${error.data}`,
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            isSubmitting,
            setStatus,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} mt={2} mb={4}>
                    <InputTextField
                      fullWidth
                      required
                      name="amne"
                      label="Ämne"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amne}
                      error={evalErrors(errors, touched, "amne")}
                      helperText={getErrorText(errors, touched, "amne")}
                      aria-required="true"
                    />
                    <InputTextField
                      required
                      name="meddelande"
                      label="Meddelande"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.meddelande}
                      multiline
                      minRows={5}
                      error={evalErrors(errors, touched, "meddelande")}
                      helperText={getErrorText(errors, touched, "meddelande")}
                      aria-required="true"
                    />
                    <Link
                      sx={{ alignSelf: "flex-start" }}
                      type="button"
                      component="button"
                      underline="always"
                      variant="body1"
                      onClick={() => {
                        setFieldValue(
                          "meddelande",
                          values.meddelande.concat("%ANVÄNDARNAMN FÖR KOMMUN%")
                        );
                      }}
                    >
                      Lägg till alla användarnamn för mottagarens kommun
                    </Link>
                    <InputTextField
                      fullWidth
                      required
                      name="namn"
                      label="Avsändarens namn"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.namn}
                      error={evalErrors(errors, touched, "namn")}
                      helperText={getErrorText(errors, touched, "namn")}
                      aria-required="true"
                    />
                    <InputTextField
                      fullWidth
                      required
                      name="epost"
                      label="Avsändarens e-postadress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.epost}
                      error={evalErrors(errors, touched, "epost")}
                      helperText={getErrorText(errors, touched, "epost")}
                      aria-required="true"
                    />
                  </Stack>
                  <Grid item xs={12}>
                    {ShowAlertEval(status) && (
                      <Grid item xl={12}>
                        <ShowAlert
                          onClose={() => {
                            setStatus(null);
                          }}
                          severity={status.severity}
                          alertText={status.msg}
                        />
                      </Grid>
                    )}
                    <Stack direction="row" spacing={2}>
                      <BtnDefault disabled={isSubmitting} type="submit">
                        Skicka
                      </BtnDefault>
                      <BtnSecond
                        type="reset"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Rensa
                      </BtnSecond>
                      <BtnSecond onClick={() => navigate("/contacts")}>
                        Avbryt
                      </BtnSecond>
                      {isSubmitting && <CircularProgress />}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
