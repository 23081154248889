import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import "../../App.css";
import DisplayAlert from "../../Components/alert";
import { useAuth } from "../../Utilities/authProvider";
import zxcvbn from "zxcvbn";
import { Buffer } from "buffer";
import UnauthLayout from "../SignIn/unauthLayout";
import { UpdateForgotPasswordSchema } from "../../Utilities/validationSchema";
import InputTextField from "../../Components/textfield";
import PasswordStrength from "../../Components/passwordStrength";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { Box } from "@mui/system";
import { BtnSecond } from "../../Components/buttons";

export default function UpdateForgottenPassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [score, setScore] = React.useState(0);
  const { token } = useParams();

  function pwHandleChange(password: string) {
    if (password === "") {
      setScore(0);
    } else {
      var pw = zxcvbn(password);
      setScore(pw.score);
    }
  }

  useEffect(() => {
    setLoading(false);
  }, [tokenValid]);

  useEffect(() => {
    auth.validatePasswordToken(token!).then((res) => {
      setTokenValid(res.object);
    });
  }, []);

  const renderForm = () => {
    return (
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={UpdateForgotPasswordSchema}
        onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
          setLoading(true);
          auth
            .updateForgottenPassword(
              Buffer.from(values.newPassword).toString("base64"),
              token!
            )
            .then((resp) => {
              if (resp.success) {
                resetForm();
                setLoading(false);
                setSubmitting(false);
                setScore(0);
                setStatus({
                  sent: true,
                  msg: `Lösenordet har ändrats. Vänligen klicka på knappen "Gå vidare" nedan för att komma till inloggningssidan.`,
                });
              } else {
                setLoading(false);
                setSubmitting(false);
                setStatus({
                  sent: false,
                  msg: resp.object,
                });
              }
            })
            .catch((err) => {
              setLoading(false);
              setSubmitting(false);
              setStatus({
                sent: false,
                msg: err.message,
              });
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) =>
          !status || !status.sent || status.sent === false ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ margin: "10px 0px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption" component="p">
                    Fält markerade med <abbr title="Obligatoriskt fält">*</abbr>{" "}
                    är obligatoriska
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputTextField
                    type="password"
                    name="newPassword"
                    label="Nytt lösenord"
                    onChange={(e: any) => {
                      pwHandleChange(e.target.value);
                      setFieldValue("newPassword", e.target.value);
                    }}
                    onBlur={(e: any) => {
                      pwHandleChange(e.target.value);
                      setFieldValue("newPassword", e.target.value);
                    }}
                    value={values.newPassword}
                    error={evalErrors(errors, touched, "newPassword")}
                    helperText={getErrorText(errors, touched, "newPassword")}
                    aria-required="true"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputTextField
                    type="password"
                    name="confirmPassword"
                    label="Bekräfta lösenord"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={evalErrors(errors, touched, "confirmPassword")}
                    helperText={getErrorText(
                      errors,
                      touched,
                      "confirmPassword"
                    )}
                    aria-required="true"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  {values.newPassword.length > 0 && (
                    <PasswordStrength score={score} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={
                      loading ? (
                        <CircularProgress color="primary" size={"1em"} />
                      ) : (
                        <div></div>
                      )
                    }
                    type="submit"
                    title={"Skicka"}
                    disabled={
                      isSubmitting ||
                      Boolean(errors.newPassword) ||
                      Boolean(errors.confirmPassword) ||
                      score <= 2
                    }
                    color="primary"
                    variant="contained"
                    sx={{ minWidth: "125px" }}
                  >
                    Skicka
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {status && status.msg && (
                    <DisplayAlert type="error" msg={status.msg} />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Stack direction="column" alignItems="center">
              {status && status.sent && status.msg && (
                <DisplayAlert type="success" msg={status.msg} />
              )}
              <Button
                type="button"
                title="Gå vidare"
                variant="contained"
                onClick={(e) => {
                  navigate("/");
                }}
              >
                Gå vidare
              </Button>
            </Stack>
          )
        }
      </Formik>
    );
  };

  return (
    <UnauthLayout>
      <Grid container spacing={2}>
        {tokenValid ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h1" component="h1" align="center" paragraph>
                Ändra lösenord
              </Typography>
              <Typography align="center" variant="body1" gutterBottom>
                Ange ett nytt lösenord och bekräfta det.
                Lösenordet ska minst klara lösenordsstyrka Godkänt.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {renderForm()}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  component="h1"
                  align="center"
                  paragraph
                >
                  Länken är inte giltig längre
                </Typography>
                <Typography align="center" variant="body1" gutterBottom>
                  Länken är endast giltig i 10 minuter så du behöver skapa en ny
                  begäran. Gå tillbaka och klicka "Glömt lösenord" igen.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center">
                  <BtnSecond onClick={() => navigate("/")}>
                    Tillbaka
                  </BtnSecond>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </UnauthLayout>
  );
}
