import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { AutoCompleteAtr } from "../../../Components/autoComplete";
import { InfoType } from "../../../Components/selects";
import InputTextField from "../../../Components/textfield";
import { RadioCustom } from "../../../Components/radioCustom";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { disabledEnabled, Mode, Permits } from "./permitsUtils";
import { AutoCompleteGroupedAtr } from "../../../Components/autoCompleteGrouped";

export function FormTypeTillfalligt(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode
) {
  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Serveringens omfattning</Typography>
      </Grid>
      <Grid key="tillstandsKategoriTillfalligt" item xs={6}>
        <FormControl fullWidth>
          <AutoCompleteGroupedAtr
            onBlur={props.handleBlur}
            infoType={InfoType.tillstandsKategoriTillfalligt}
            label="Tillståndskategori"
            defaultVal={false}
            onChange={(data) => {
              props.setFieldValue("tillstandsKategoriIds", data);
            }}
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "tillstandsKategoriIds"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "tillstandsKategoriIds"
            )}
            id="tillstandsKategoriTillfalligt"
            name="tillstandsKategoriTillfalligt"
            selectedData={props.values.tillstandsKategoriIds ?? 0}
            disabled={mode === Mode.View}
            multiple={false}
            required={disabledEnabled(
              [
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
          />
        </FormControl>
      </Grid>
      <Grid key="versionDetails.alkodrycksliknPreparat" item xs={12}>
        <Stack mb={1}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name="versionDetails.alkodrycksliknPreparat"
                onChange={(e) => props.setFieldValue("versionDetails.alkodrycksliknPreparat", e.target.checked ? true : false)}
                disabled={mode === Mode.View}
                checked={props.values.versionDetails.alkodrycksliknPreparat}
              />
            }
            label={
              <Typography variant="body2">
                Alkoholdrycksliknande preparat
              </Typography>
            }
          />
        </Stack>
      </Grid>
      <Grid key="versionDetails.servtidTillfFrom" item xs={3}>
        <InputTextField
          fullWidth
          name="versionDetails.servtidTillfFrom"
          label="Serveringstid fr.o.m. klockan (TT:MM)"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.servtidTillfFrom ?? ""}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.servtidTillfTom" item xs={3}>
        <InputTextField
          fullWidth
          name="versionDetails.servtidTillfTom"
          label="Serveringstid t.o.m. klockan (TT:MM)"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.servtidTillfTom ?? ""}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">Serveringsutrymme</Typography>
      </Grid>
      <Grid key="versionDetails.servUtrymmeTypId" item xs={4}>
        <FormControl fullWidth>
          <AutoCompleteAtr
            onBlur={props.handleBlur}
            infoType={InfoType.servUtrymmeTypId}
            label="Typ av serveringsutrymme"
            defaultVal={false}
            onChange={(data) => {
              props.setFieldValue("versionDetails.servUtrymmeTypId", data);
            }}
            error={evalErrors(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "servUtrymmeTypId"
            )}
            helperText={getErrorText(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "servUtrymmeTypId"
            )}
            name="servUtrymmeTypId"
            selectedData={props.values.versionDetails.servUtrymmeTypId ?? 0}
            disabled={mode === Mode.Edit || mode === Mode.View}
            multiple={false}
          />
        </FormControl>
      </Grid>
      <Grid key="versionDetails.villkor" item xs={12}>
        <Typography variant="h2">Villkor</Typography>
        <InputTextField
          name="versionDetails.villkor"
          label="Villkor"
          type="text"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.villkor ? props.values.versionDetails.villkor : ""}
          fullWidth
          multiline
          rows={4}
          disabled={mode === Mode.View}
        />
      </Grid>
    </Grid>
  );
}
