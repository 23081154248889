import * as Yup from "yup";
import { maxLength, noNumbersRegX, noStaringWhitespaces, notWhiteSpace, toShort } from "../../Utilities/common";
import { Cat, MapToCorrectCat } from "../../Utilities/roles";



export const newAnvandareSchema = Yup.object().shape({
    anvandarnamn: Yup.string()
        .matches(noStaringWhitespaces, notWhiteSpace)
        .min(2, toShort)
        .max(50, maxLength)
        .required("Du måste ange ett användarnamn"),
    fornamn: Yup.string()
        .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .min(2, toShort)
        .max(50, maxLength)
        .required("Du måste ange ett förnamn"),
    efternamn: Yup.string()
        .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .min(2, toShort)
        .max(50, maxLength)
        .required("Du måste ange ett efternamn"),
    epost: Yup.string()
        .lowercase()
        .email("Du måste ange en giltig e-postadress")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .required("Du måste ange en e-postadress"),
    epost2: Yup.string()
        .lowercase()
        .email("Du måste ange en giltig e-postadress")
        .required("Du måste bekräfta e-postadress")
        .oneOf([Yup.ref('epost'), null], "E-postadresserna matchar inte"),
    kommunIdn: Yup.array().when("roller", {
        is: (val: any) => {
            let x = MapToCorrectCat([...val])
            if (x === Cat.kommun) {
                return true;
            }
            else return false;
        },
        then: (schema) => schema.min(1, "Du måste välja en kommun")
    }),
    lanId: Yup.number().when("roller", {
        is: (val: any) => {
            let x = MapToCorrectCat([...val])
            if (x === Cat.lan) {
                return true;
            }
            else return false;
        },
        then: (schema) => schema.required("Du måste välja ett län").min(1, "Du måste välja ett län")
    }),
    roller: Yup.array().min(1, "Du måste välja en roll")

})


export const editAnvandareSchema = Yup.object().shape({
    fornamn: Yup.string()
        .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .min(2, toShort)
        .max(50, maxLength)
        .required("Du måste ange ett förnamn"),
    efternamn: Yup.string()
        .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .min(2, toShort)
        .max(50, maxLength)
        .required("Du måste ange ett efternamn"),
    epost: Yup.string()
        .lowercase()
        .email("Du måste ange en giltig e-postadress")
        .matches(noStaringWhitespaces, notWhiteSpace)
        .required("Du måste ange en e-postadress"),
    kommunIdn: Yup.array().when("roller", {
        is: (val: any) => {
            let x = MapToCorrectCat([...val])
            if (x === Cat.kommun) {
                return true;
            }
            else return false;
        },
        then: (schema) => schema.min(1, "Minst en kommun måste vara vald när en kommunroll är vald")
    }),
    lanId: Yup.number().when("roller", {
        is: (val: any) => {
            let x = MapToCorrectCat([...val])
            if (x === Cat.lan) {
                return true;
            }
            else return false;
        },
        then: (schema) => schema.required("Du måste välja ett län").min(1, "Du måste välja ett län")
    }),
    roller: Yup.array().min(1, "Du måste välja en roll")
})