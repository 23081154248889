
import React from "react";
import { ISearchBase } from "../Services/Searchbase";


interface ISearchFilterProvider<T> {
    addFilter: (filter:ISearchBase<T>,key:string) => void
    getFilter: (key:string) => ISearchBase<T> | null
    
    //Use this to verify that it is the correct type of object key stored in the previous search filter, if switching 
    //search within the same scope
    //Example is Users / Applies
    getKey:() => string
}


export let SearchFilterContext = React.createContext<ISearchFilterProvider<any>>(null!)

export function SearchFilterProvider<T>({children}:{children:React.ReactNode}) {

    const [filter,setFilter] = React.useState<ISearchBase<T>>(null!);
    const [key, setKey] = React.useState<string>("default")

    const addFilter = (filter: ISearchBase<T>, key:string = "default") => {
        setFilter(filter);
        setKey(key);
    }
    
    function getFilter (inckey:string) : ISearchBase<T> | null {
        
        if(inckey === undefined || inckey !== key) return null;
        return filter;
    }

    function getKey():string{return key;}

    const value= {addFilter,getFilter,getKey};
    return <SearchFilterContext.Provider value={value}>{children}</SearchFilterContext.Provider>

}

export function useFilter() {
    return React.useContext(SearchFilterContext);
}