import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Link, Divider, Typography, Stack } from "@mui/material";
import { BtnSecond } from "../../Components/buttons";

const ApplyForOthers: React.FC = () => {
  let navigate = useNavigate();
  return (
    <Grid container spacing={2} mt={0}>
      <Grid item xs={12}>
        <Divider />
        <Stack direction="row" alignItems={"center"}>
          <Typography variant="body1">Länk till: &nbsp; </Typography>
          <Typography variant="body1">
            <Link href="https://www.folkhalsomyndigheten.se/folkhalsorapportering-statistik/statistikdatabaser-och-visualisering/alkoholdrycksstatistik-i-siffror/">
              Ta del av statistik relaterat till alkohol- och tobaksregistret.
            </Link>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} mt={4}>
        <BtnSecond onClick={() => navigate("/")}>
          Tillbaka
        </BtnSecond>
      </Grid>
    </Grid>
  );
};
export default ApplyForOthers;
