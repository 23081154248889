import React from "react"
import { Formik, FormikProps } from "formik";
import { Box, Grid } from "@mui/material";
import { GenerateFormStructure, MapToSearchPermitType, Mode } from "../PermitsFormsUtils/permitsUtils";
import { ITillstandGet } from "../../../Services/PermitService";

import { IForm } from "../new";

export function GenerateViewStructure(tillstand: ITillstandGet) {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...tillstand }}
      onSubmit={(values) => console.log("values: ", values)}
    >
      {(formikProps:FormikProps<IForm>) => (
        <Box component="form" >
          <Grid container spacing={2}>
            {GenerateFormStructure(
              MapToSearchPermitType(tillstand?.tillstandsTypId ?? 0),
              formikProps,
              Mode.View
            )}
          </Grid>
        </Box>
      )}
    </Formik>
  )
};
