import { Container, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Footer from "../../Components/footer";
import Header from "../../Components/header";
import { MainForm } from "./ReportForm/mainForm";

export default function RegistreraRapportExtern() {
  return (
    <Fragment>
      <Header />
      <Container maxWidth="xl"
        sx={{
          display: { minHeight: `calc(100vh - 140px)` },
          paddingTop: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {MainForm()}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  );
}
