import React, { ReactNode } from "react";
import {
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  MoreHoriz,
  UnfoldMore,
  Print,
  Edit,
  CheckBox,
} from "@mui/icons-material/";
import { ISearchBase, SortOrder } from "../Services/Searchbase";
import { BtnIcon } from "./buttons";
import { Data, MiniSelect } from "./selectTable";
import { MapToCorrectlabel } from "../Utilities/roles";
import moment from "moment";

//User this to map your data from a search call
export const mapToCellData = (head: ITableHead<any>[], data: any) => {
  let keys = head.map((x) => x.id);
  let tableBodyRows: ITableBodyRow[] = data.map((z: any) => {
    let cells: ITableCell[] = keys.map((k) => {
      //Special kollar ifall det är roller isf ska vi mappa mot mappningstabellen i roller
      if (k === "roller") {
        let x = MapToCorrectlabel(z[k]);
        return {
          label: x,
          value: x,
        };
      } else {
        return {
          label: k,
          value: z[k],
        };
      }
    });
    return {
      cells: cells,
      id: z["id"],
    };
  });
  return tableBodyRows;
};

//This is related to the pagesizes
//They should be added in size order
export const getMenuItems: Data[] = [
  {
    value: 10,
    name: "10",
    id: 10,
  },
  {
    value: 20,
    name: "20",
    id: 20,
  },
  {
    value: 50,
    name: "50",
    id: 50,
  },
  {
    value: 100,
    name: "100",
    id: 100,
  },
  
];

//Use this to get a MenuItem that needs to be passed in the ITableDefult
export const checkMenuItem = (num: number) => {
  return getMenuItems.find((p) => p.value === num) ?? getMenuItems[0];
};

export enum pageChange {
  end = "end",
  start = "start",
  next = "next",
  prev = "prev",
}
export enum ActionIcons {
  view = "view",
  print = "print",
  edit = "edit",
  arrowForward = "arrowForward",
}

export interface ITableHead<T> {
  value: string;
  id: keyof T | string;
  sortable: boolean;
}

export interface ISortOptions {
  direction: SortOrder;
  id: string | number | symbol;
}

export interface ITableBodyRow {
  cells: ITableCell[];
  id: number;
}
export interface ITableCell {
  label: string | number | symbol;
  value: string | number | symbol;
}

export interface IActions {
  action: (id: number) => void;
  icon: ActionIcons;
  title?: string;
}

//Use this to easily get the pagenumber after an action.
export const getPageNum = (
  action: pageChange,
  totalHits: number,
  rowsPerPage: number,
  currentPage: number
) => {
  switch (action) {
    case pageChange.end:
      return Math.ceil(totalHits / rowsPerPage);
    case pageChange.next:
      return (currentPage += 1);
    case pageChange.prev:
      return (currentPage -= 1);
    case pageChange.start:
      return 1;
  }
};

//Interface to use in order to use the table
export interface ITableDefault {
  header: ITableHead<any>[];
  data: ITableBodyRow[];
  activePage: number;
  totalHits: number;
  rowsPerPage: Data;

  children?: ReactNode;
  sortOptions: ISortOptions;
  actions: IActions[] | null;
  setFilterAction:
    | React.Dispatch<React.SetStateAction<ISearchBase<any>>>
    | React.Dispatch<React.SetStateAction<ISearchBase<any> | null>>;
  filter: ISearchBase<any>;
  setSortOptions: React.Dispatch<React.SetStateAction<ISortOptions>>;
  tableAria: string;
  checkbox?: boolean;
  setMarked?: React.Dispatch<React.SetStateAction<number[]>>;
  marked?: number[];
}

export function changeSortOrder<T>(
  head: ITableHead<any>,
  setFilter:
    | React.Dispatch<React.SetStateAction<ISearchBase<T>>>
    | React.Dispatch<React.SetStateAction<ISearchBase<any> | null>>,
  filter: ISearchBase<T>,
  setSortOptions: React.Dispatch<React.SetStateAction<ISortOptions>>,
  sortOptions: ISortOptions
) {
  if (head.id === sortOptions.id) {
    const order =
      sortOptions.direction === SortOrder.Stigande
        ? SortOrder.Fallande
        : SortOrder.Stigande;
    setFilter({ ...filter, sorteringsOrdning: order });
    setSortOptions({ direction: order, id: head.id });
  } else {
    setFilter({
      ...filter,
      sorteringsOrdning: SortOrder.Stigande,
      sorteringsNamn: head.id,
    });
    setSortOptions({ direction: SortOrder.Stigande, id: head.id });
  }
}

const changePage = (
  action: pageChange,
  totalHits: number,
  filter: ISearchBase<any>,
  setFilter:
    | React.Dispatch<React.SetStateAction<ISearchBase<any>>>
    | React.Dispatch<React.SetStateAction<ISearchBase<any> | null>>
) => {
  let page = getPageNum(
    action,
    totalHits,
    filter.sidoStorlek,
    filter.sidoNummer
  );
  setFilter({ ...filter, sidoNummer: page });
};

const changeRowsPerPage = (
  num: number,
  filter: ISearchBase<any>,
  setFilter:
    | React.Dispatch<React.SetStateAction<ISearchBase<any>>>
    | React.Dispatch<React.SetStateAction<ISearchBase<any> | null>>
) => {
  setFilter({ ...filter, sidoStorlek: num });
};

export const DefaultTable = (table: ITableDefault) => {
  //Gets the icon based on the iconAcion
  const returnIconBasedOnAction = (actionIcon: ActionIcons) => {
    switch (actionIcon) {
      case ActionIcons.view: {
        return <MoreHoriz />;
      }
      case ActionIcons.print: {
        return <Print />;
      }
      case ActionIcons.edit: {
        return <Edit />;
      }
      case ActionIcons.arrowForward: {
        return <ArrowForward />;
      }
    }
  };

  //Creates an aria-label for the icon based on the iconAction
  const returnIconAriaBasedOnAction = (
    actionIcon: ActionIcons,
    tableAria: string,
    data: ITableCell[]
  ) => {
    switch (actionIcon) {
      case ActionIcons.view: {
        if (tableAria === "Kontakter") {
          return `Visa mer om resultatet för ${String(data[2].value)} ${String(
            data[3].value
          )}`;
        } else if (tableAria === "Användare") {
          return `Visa mer om resultatet för ${String(data[1].value)} ${String(
            data[0].value
          )}`;
        } else if (tableAria === "Rapporter") {
          return `Visa mer om rapporten för organisationsnummer ${String(
            data[2].value
          )}`;
        } else if (tableAria === "Tillstånd") {
          return `Visa mer om tilståndet för organisationsnummer ${String(
            data[0].value
          )}`;
        }
      }
      case ActionIcons.print: {
        if (tableAria === "Rapporter") {
          return `Skriv ut rapporten för organisationsnummer ${String(
            data[0].value
          )}`;
        }
      }
      case ActionIcons.edit: {
        if (tableAria === "Rapporter") {
          return `Ändra rapporten för organisationsnummer ${String(
            data[0].value
          )}`;
        } else if (tableAria === "Kommuner") {
          return `Ändra Kommunen ${String(data[0].value)}`;
        }
      }
      case ActionIcons.arrowForward: {
        if (tableAria === "10 senaste tillstånd") {
          return `Visa mer om tilståndet för ${String(data[1].value)} ${String(
            data[0].value
          )}`;
        }
      }
    }
  };

  //Returns and render correct Icon in the head.
  const returnIcon = (data: ITableHead<any>) => {
    if (data.id === table.sortOptions.id) {
      if (table.sortOptions.direction === SortOrder.Fallande) {
        return <ArrowDownward fontSize="small" />;
      } else if (table.sortOptions.direction === SortOrder.Stigande) {
        return <ArrowUpward fontSize="small" />;
      }
    } else {
      return <UnfoldMore fontSize="small" />;
    }
    return;
  };

  //Calculates the amount of total pages to be displayed
  const calcAmountOfTotalPages = () => {
    return Math.ceil(table.totalHits / table.rowsPerPage.value);
  };

  //Renders the table header
  const renderTableHead = () => {
    //If any actions are passed in we make space for it
    const renderActionSpace = () => {
      if (table.actions !== null) {
        return (
          <TableCell
            key="action"
            className="tableHeaderCellNoHover"
          ></TableCell>
        );
      }
    };

    const renderCheckBoxCell = () => {
      if (table.checkbox !== undefined && table.checkbox) {
        return (
          <TableCell
            key="markera"
            className="tableHeaderCellNoHover"
          ></TableCell>
        );
      }
    };

    const orderBy = table.sortOptions;

    return (
      <TableRow>
        {table.header.map((data, index) => {
          if (data.sortable) {
            return (
              <TableCell
                component="th"
                role="columnheader"
                className="tableHeaderCell"
                key={index}
                onClick={() =>
                  changeSortOrder(
                    data,
                    table.setFilterAction,
                    table.filter,
                    table.setSortOptions,
                    table.sortOptions
                  )
                }
                aria-label={data.value}
                aria-sort={
                  orderBy.id === data.id
                    ? orderBy.direction === SortOrder.Stigande
                      ? "ascending"
                      : orderBy.direction === SortOrder.Fallande
                      ? "descending"
                      : "none"
                    : "none"
                }
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  aria-label={
                    orderBy.id === data.id &&
                    orderBy.direction === SortOrder.Fallande
                      ? data.value + " sortererad fallande"
                      : orderBy.id === data.id &&
                        orderBy.direction === SortOrder.Stigande
                      ? data.value + " sortererad stigande"
                      : data.value
                  }
                >
                  {data.value} {returnIcon(data)}
                  {orderBy.id === data.id ? (
                    <span
                      className="visuallyHidden"
                      aria-hidden={orderBy.id !== data.id}
                    >
                      {orderBy.direction === SortOrder.Fallande
                        ? "sorterad fallande"
                        : "sorterad stigande"}
                    </span>
                  ) : null}
                </Stack>
              </TableCell>
            );
          } else {
            return (
              <TableCell key={index} className="tableHeaderCellNoHover">
                <Stack direction="row" alignItems="center">
                  {data.value}
                </Stack>
              </TableCell>
            );
          }
        })}
        {renderActionSpace()}
        {renderCheckBoxCell()}
      </TableRow>
    );
  };

  //Renders all the table cells that are passed. The id is used for passing back to the provided actions if any.
  const renderTableCell = (
    data: ITableCell[],
    id: number,
    tableAria: string
  ) => {
    //If actions exists we render those here
    const renderActionSpace = () => {
      if (table.actions !== null) {
        return (
          <TableCell key={id}>
            <Stack direction="row" spacing={1}>
              {table.actions?.map((p, i) => {
                return (
                  <BtnIcon
                    key={i}
                    size="small"
                    onClick={() => p.action(id)}
                    aria-label={returnIconAriaBasedOnAction(
                      p.icon,
                      tableAria,
                      data
                    )}
                  >
                    {returnIconBasedOnAction(p.icon)}
                  </BtnIcon>
                );
              })}
            </Stack>
          </TableCell>
        );
      }
    };

    const renderCheckBoxCell = () => {
      if (
        table.checkbox !== undefined &&
        table.checkbox &&
        table.marked !== undefined
      ) {
        return (
          <TableCell key={id + "checked"}>
            <Checkbox
              onChange={(e, b) => {
                if (b === false && table.setMarked !== undefined) {
                  const arr = table.marked?.filter((p) => p !== id);
                  if (arr !== undefined) table.setMarked([...arr]);
                }
                if (
                  b === true &&
                  table.setMarked !== undefined &&
                  table.marked !== undefined
                ) {
                  table.setMarked([...table.marked, id]);
                }
              }}
              checked={table.marked?.findIndex((p) => p === id) > -1}
            />
          </TableCell>
        );
      }
    };

    //Render all cells with data
    return (
      <React.Fragment>
        {data.map((data, index) => {
          return <TableCell key={index}>{data.value}</TableCell>;
        })}
        {renderActionSpace()}
        {renderCheckBoxCell()}
      </React.Fragment>
    );
  };

  //Renders the table body
  const renderTableBody = (tableAria: string) => {
    return table.data.map((data, index) => {
      let foundStatusEnded = false;
      let foundStatusFuture = false;
      for (let i = 0, j = data.cells.length; i < j; i++) {
          let cur = data.cells[i];
          if (cur.label === "statusNamn" && cur.value === "Avslutat") {
            foundStatusEnded = true;
          } else if (cur.label === "statusNamn" && cur.value === "Annu inte giltigt") {
            foundStatusFuture = true;
          }
      }
      return (
        <TableRow key={index} className={foundStatusEnded === true ? "tableRow-highlight-ended" : foundStatusFuture === true ? "tableRow-highlight-future" : ""}>
          {renderTableCell(data.cells, data.id, tableAria)}
        </TableRow>
      );
    });
  };

  //Calls the change rowsperpage
  const onChangeRowsPerPage = (event: SelectChangeEvent, child: ReactNode) => {
    changeRowsPerPage(
      Number(event.target.value),
      table.filter,
      table.setFilterAction
    );
  };

  //Checks if a btn should be disabled in the table footer
  const checkIfBtnDisabled = (action: pageChange) => {
    switch (action) {
      case pageChange.end: {
        return !(
          table.activePage >= 1 && table.activePage !== calcAmountOfTotalPages()
        );
      }
      case pageChange.next: {
        return !(
          table.activePage >= 1 && table.activePage < calcAmountOfTotalPages()
        );
      }
      case pageChange.prev: {
        return !(
          table.activePage <= calcAmountOfTotalPages() && table.activePage > 1
        );
      }
      case pageChange.start: {
        return !(
          table.activePage <= calcAmountOfTotalPages() && table.activePage > 1
        );
      }
      default:
        return false;
    }
  };

  //Default return
  return (
    <TableContainer aria-describedby={table.tableAria}>
      <Table aria-label={table.tableAria}>
        <TableHead
          className="tableHeader"
          aria-label={`tabell över ${table.tableAria}`}
        >
          {renderTableHead()}
        </TableHead>
        <TableBody>
          {renderTableBody(table.tableAria)}

          <TableRow>
            <TableCell
              colSpan={
                table.header.length +
                (table.actions?.length ? 1 : 0) +
                (table.checkbox ? 1 : 0)
              }
              className="tableFooter"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent="flex-end"
              >
                <Box>
                  <Typography variant="body2">
                    Totalt antal sökträffar {table.totalHits} -- Sida{" "}
                    {table.activePage} av {calcAmountOfTotalPages()}
                  </Typography>
                </Box>
                <Box>
                  <MiniSelect
                    disabled={table.totalHits < getMenuItems[0].value}
                    data={getMenuItems}
                    onChange={onChangeRowsPerPage}
                    selected={table.rowsPerPage}
                    ariaLabel="Antal rader per sida"
                    ariaLive="polite"
                  />
                </Box>
                <Box>
                  <BtnIcon
                    key={1}
                    disabled={checkIfBtnDisabled(pageChange.start)}
                    onClick={() =>
                      changePage(
                        pageChange.start,
                        table.totalHits,
                        table.filter,
                        table.setFilterAction
                      )
                    }
                    aria-label="Första sidan"
                  >
                    <KeyboardDoubleArrowLeftRounded fontSize="small" />
                  </BtnIcon>
                  <BtnIcon
                    key={2}
                    disabled={checkIfBtnDisabled(pageChange.prev)}
                    onClick={() =>
                      changePage(
                        pageChange.prev,
                        table.totalHits,
                        table.filter,
                        table.setFilterAction
                      )
                    }
                    aria-label="Föregående sida"
                  >
                    <KeyboardArrowLeftRounded fontSize="small" />
                  </BtnIcon>
                  <BtnIcon
                    key={3}
                    disabled={checkIfBtnDisabled(pageChange.next)}
                    onClick={() =>
                      changePage(
                        pageChange.next,
                        table.totalHits,
                        table.filter,
                        table.setFilterAction
                      )
                    }
                    aria-label="Nästa sida"
                  >
                    <KeyboardArrowRightRounded fontSize="small" />
                  </BtnIcon>
                  <BtnIcon
                    key={4}
                    disabled={checkIfBtnDisabled(pageChange.end)}
                    onClick={() =>
                      changePage(
                        pageChange.end,
                        table.totalHits,
                        table.filter,
                        table.setFilterAction
                      )
                    }
                    aria-label="Sista sidan"
                  >
                    <KeyboardDoubleArrowRightRounded fontSize="small" />
                  </BtnIcon>
                </Box>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
