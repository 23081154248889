import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from 'react';
import InputTextField from "../../../Components/textfield";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { disabledEnabled, Mode, Permits, showHideField } from "./permitsUtils";
import { ITillstandshavare, GetTillstandhavareByOrgnr,  } from "../../../Services/PermitService";

export function FormTypeTillstandshavare(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode

  ) {
      const getAutofillObject = async (e: any, props: any) => {
        const orgNr = e.target.value;
        if(props.values.tillstandsHavare.adress == ""
        || props.values.tillstandsHavare.namn == ""
        || props.values.tillstandsHavare.postOrt == ""
        || props.values.tillstandsHavare.postnr == "")
        {
        await GetTillstandhavareByOrgnr(orgNr)
          .then((data) => {
            props.setFieldValue("tillstandsHavare.adress", data.object.adress);
            props.setFieldValue("tillstandsHavare.namn", data.object.namn);
            props.setFieldValue("tillstandsHavare.postOrt", data.object.postOrt);
            props.setFieldValue("tillstandsHavare.postnr", data.object.postnr);
           props.handleBlur(e);
          })
          .catch((error) => {
            console.error(error);
          });
        }
      };
  
  return (
    <Grid
      container
      key={index}
      spacing={2}
      direction="row"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Tillståndshavare</Typography>
      </Grid>
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={6} key="orgNr">
          <InputTextField
            fullWidth
            name="orgNr"
            label="Organisationsnummer"
            onChange={props.handleChange}
            value={props.values.orgNr ?? ""}     
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "orgNr"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "orgNr"
            )}
            onBlur={(e) => {         
                const value = e.target.value;
                if (value) {
                  getAutofillObject(e, props);                
                }
                props.setTouched({orgNr: true});                
              }
            }
            required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit)}
            aria-required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit) ? "true" : "false"}
            disabled={mode === Mode.Edit || mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={6} key="tillstandsHavare.namn">
          <InputTextField
            fullWidth
            name="tillstandsHavare.namn"
            label="Namn"
            onChange={props.handleChange}
            value={props.values.tillstandsHavare?.namn ?? ""}
            error={evalErrors(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "namn"
            )}
            helperText={getErrorText(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "namn"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit)}
            aria-required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit) ? "true" : "false"}
              disabled={mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsHavare.adress">
          <InputTextField
            fullWidth
            name="tillstandsHavare.adress"
            label="Adress"
            onChange={props.handleChange}
            value={props.values.tillstandsHavare?.adress ?? ""}
            error={evalErrors(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "adress"
            )}
            helperText={getErrorText(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "adress"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit)}
            aria-required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit) ? "true" : "false"}
              disabled={mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsHavare.postnr">
          <InputTextField
            fullWidth
            name="tillstandsHavare.postnr"
            label="Postnummer"
            onChange={props.handleChange}
            value={props.values.tillstandsHavare?.postnr ?? ""}
            error={evalErrors(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "postnr"
            )}
            helperText={getErrorText(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "postnr"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit)}
            aria-required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit) ? "true" : "false"}
              disabled={mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsHavare.postOrt">
          <InputTextField
            fullWidth
            name="tillstandsHavare.postOrt"
            label="Postort"
            onChange={props.handleChange}
            value={props.values.tillstandsHavare?.postOrt ?? ""}
            error={evalErrors(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "postOrt"
            )}
            helperText={getErrorText(
              props.errors.tillstandsHavare,
              props.touched.tillstandsHavare ?? {},
              "postOrt"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit)}
            aria-required={disabledEnabled([Permits.TobakstillstandDetaljhandelTillsvidare,
              Permits.TobakstillstandDetaljhandelVissTid,
              Permits.TobakstillstandPartihandel,
              Permits.StadigvarandeServeringstillstand,
              Permits.TillfalligtServeringstillstand],permit) ? "true" : "false"}
              disabled={mode === Mode.View}
          />
        </Grid>
      )}
    </Grid>
  );
}
