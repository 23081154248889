import { Delete, Get, Post, Put } from "../Utilities/axiosBase";
import { Roles } from "../Utilities/roles";
import { SystemTyp } from "../Utilities/systemUtils";
import { IMainBaseResponse } from "./base";
import { ISearchBase, ISearchResultBase } from "./Searchbase";

const anvandarbase: string = "/anvandare";

export interface IApplySearch {
  created: string,
  fornamn: string;
  efternamn: string;
  epost: string;
  foretagsnamn: string;
  adress: string;
  orgNr: string;
  postNr: string;
  postAdress: string;
  telefon: string;
  syfte: string;
  roller: Roles[];
  kommunIdn: number[];
  samtligaAktuellaServTillstand: boolean;
  samtligaServtillstandAvslutadeForeVisstDatum: boolean;
  registerutdragTillsvidare: boolean;
  registerutdragEnstakaTillfalle: boolean;
  kreditupplysningsForetag: boolean;
  id: number;
  anvandarnamnForslag: string;
  epostAlreadyExisting: string;
  hanteringStartad: boolean;
}

export interface IApplyGet {
  created: string,
  fornamn: string;
  efternamn: string;
  epost: string;
  foretagsnamn: string;
  adress: string;
  orgNr: string;
  postNr: string;
  postOrt: string;
  telefon: string;
  syfte: string;
  roller: Roles[];
  system: SystemTyp[];
  kommunIdn: number[];
  samtligaAktuellaServTillstand: boolean;
  samtligaServtillstandAvslutadeForeVisstDatum: boolean;
  kreditupplysningsForetag: boolean;
  id: number;
  anvandarnamnForslag: string;
  epostAlreadyExisting: string;
  lanId: number;
  systemId: number;
  hanteringStartad: boolean;
}

export interface IApproveApplicationResp {
  anvandarnamn: string;
  fornamn: string;
  efternamn: string;
  epost: string;
}

export interface IApplySearchFilter {
  efternamn: string;
  fornamn: string;
  epost: string;
  roller: Roles[];
  kommunId: number;
}

export interface IUpdateApply {
  created: string, 
  efternamn: string;
  fornamn: string;
  epost: string;
  roller: Roles[];
  system: SystemTyp[];
  kommunIdn: number[];
  syfte: string;
  foretagsnamn: string;
  adress: string;
  orgNr: string;
  postNr: string;
  postOrt: string;
  telefon: string;
  samtligaAktuellaServTillstand: boolean;
  samtligaServtillstandAvslutadeForeVisstDatum: boolean;
  kreditupplysningsForetag: boolean;
  lanId:number;
  systemId: number;
  hanteringStartad: boolean;
}

export const searchUserApplies = async (
  filter: ISearchBase<IApplySearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<IApplySearch>>> => {
  return await Post<
    ISearchBase<IApplySearchFilter>,
    ISearchResultBase<IApplySearch>
  >(`${anvandarbase}/searchuserapplication`, filter);
};

export const getAnsokan = async (
  id: number
): Promise<IMainBaseResponse<IApplyGet>> => {
  return await Get<IApplyGet>(`${anvandarbase}/getuserapplication/${id}`);
};

export const rejectuserapplication = async (
  id: number
): Promise<IMainBaseResponse<any>> => {
  return await Delete(`${anvandarbase}/rejectuserapplication/${id}`, id);
};

export const approveuserapplication = async (
  id: number,
  username: string
): Promise<IMainBaseResponse<IApproveApplicationResp>> => {
  return await Post<any, IApproveApplicationResp>(
    `${anvandarbase}/approveuserapplication/${id}`,
    { anvandarnamn: username }
  );
};

export const updateuserapplication = async (
  obj: IUpdateApply,
  id: number
): Promise<IMainBaseResponse<IApplyGet>> => {
  return await Put<IUpdateApply, IApplyGet>(
    `${anvandarbase}/updateuserapplication/${id}`,
    obj
  );
};
