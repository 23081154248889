import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  FormControl,
} from "@mui/material";
import { Box, grid } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import {
  InfoType,
  SelectSingleWihtoutData,
  SelectSingle,
} from "../../Components/selects";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import InputTextField from "../../Components/textfield";
import { IMainBaseResponse } from "../../Services/base";
import { getRapportPeriod, IInfo } from "../../Services/infoService";
import {
  exportRapporterRegistreraExcel,
  getRapportPDF,
  IReportSearchFilter,
  IReportSearchResult,
  printAllPdf,
  searchReports,
} from "../../Services/reportService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { useAuth } from "../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { Roles } from "../../Utilities/roles";
import { useFilter } from "../../Utilities/searchFilterProvider";
import { Rapporttyper } from "./ReportForm/formlogic";

export interface ICommonPropsRecord {
  typ: number;
  period: number;
  kommun: number;
  nummer: string;
}

export default function ReportNew() {
  //Usings
  const filterProvider = useFilter();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState<any>(null);
  const [results, setResults] = React.useState<
    IMainBaseResponse<ISearchResultBase<IReportSearchResult>>
  >(null!);
  const [perioder, setPerioder] = React.useState<IInfo[]>(null!);
  const [loadFileDownload, setShowLoadFileDownload] = React.useState(false);
  const [loadFilePrint, setShowLoadFilePrint] = React.useState(false);

  const auth = useAuth();

  useEffect(() => {
    const filter = filterProvider.getFilter("registrerarapport");
    if (filter !== null && filter !== undefined) {
      setFilter(filter);
    }

    getRapportPeriod().then((res) => {
      setPerioder([...res]);
      if (filter === null || filter === undefined) {
        setFilter({
          filter: {
            rapportId: 0,
            rapportTypId: Rapporttyper.Restaurangrapport,
            rapportPeriodId: res[1].id,
            lanId: 0,
            objektNr: "",
            orgNr: "",
            rapportStatusId: 1,
            tillstandsGivareId: 0,
            kommunId: 0,
            jamforForegaendeAr: false,
            jamforOmsattningVolym: false,
            kommentarFinns: false,
          },
          sidoNummer: 1,
          sidoStorlek: getMenuItems[0].value,
          sorteringsNamn: "orgNr",
          sorteringsOrdning: SortOrder.Stigande,
        });
      }
    });
  }, []);

  const [filter, setFilter] =
    React.useState<ISearchBase<IReportSearchFilter> | null>(null);
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "id",
    direction: SortOrder.Fallande,
  });

  useEffect(() => {
    if (
      perioder !== null &&
      perioder !== undefined &&
      filter !== null &&
      filter !== undefined
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [perioder, filter]);

  useEffect(() => {
    if (filter !== null) {
      searchReports(filter)
        .then((data) => {
          setResults({ ...data });
        })
        .catch((error) => {
          setErrors(error);
          setResults(null!);
        });
    }
  }, [filter]);

  const SubmitSearch = (values: IReportSearchFilter) => {
    setFilter({ ...filter, filter: { ...values } });
  };

  const headers: ITableHead<IReportSearchResult>[] = [
    {
      id: "orgNr",
      sortable: true,
      value: "Organisationsnummer",
    },
    {
      id: "tillstandshavare",
      sortable: true,
      value: "Tillståndshavare namn",
    },
    {
      id: "objektNr",
      sortable: true,
      value: "ObjektId/Restaurangnummer",
    },
    {
      id: "serveringsstalle",
      sortable: true,
      value: "Objektets namn",
    },
    {
      id: "skapad",
      sortable: false,
      value: "Skapad",
    },
  ];

  const navigateToUrl = (id: number) => {
    if (filter !== null) {
      filterProvider.addFilter(filter, "registrerarapport");
    }
    navigate(`${id}`);
  };

  const print = (id: number) => {
    getRapportPDF(id).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rapport.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const printAll = (setStatus: (status?: any) => void) => {
    if (filter === null) return;
    if (
      (filter.filter.tillstandsGivareId === 0 ||
      filter.filter.tillstandsGivareId === null) && filter.filter.rapportTypId === Rapporttyper.Restaurangrapport
    ) {
      setStatus({
        sent: true,
        msg: "Av prestandaskäl måste kommun anges!",
        title: "OBS!",
        severity: "warning",
      });
      return;
    }
    if(results.object.totaltAntalTraffar === 0) {
      setStatus({
        sent: true,
        msg: "Hittade inga rapporter att skriva ut enligt angiven sökning.",
        title: "Ett fel uppstod",
        severity: "error",
      });
      return;
    }
    setShowLoadFilePrint(true);
    printAllPdf(filter)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.object], {
            type: "application/octet-stream",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "rapporter.zip");
        document.body.appendChild(link);
        link.click();
        setStatus({
          sent: true,
          msg: "filen har laddats ner. Öppna filen för att skriva ut.",
          title: "Skriv ut fil",
          severity: "success",
        });
        setShowLoadFilePrint(false);
      })
      .catch((error) => {
        setStatus({
          sent: true,
          msg: "Ett oväntat fel inträffade.",
          title: "Ett fel inträffade",
          severity: "error",
        });
        setShowLoadFilePrint(false);
      });
  };

  const exportSearch = (setStatus: (status?: any) => void) => {
    if (filter === null) throw Error("Filter is null");
    setShowLoadFileDownload(true);
    exportRapporterRegistreraExcel(filter?.filter).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "RapporterRegistrera.xlsx");
      document.body.appendChild(link);
      link.click();
      setShowLoadFileDownload(false);
      // setStatus({
      //   sent: true,
      //   msg: "filen har laddats ner",
      //   title: "Exportera resultat",
      //   severity: "success",
      // });
    });
  };

  const filterIdsBasedOnRole = () => {

    if(auth.validateUserRoleAccess([Roles.KommunAdminAlkohol])){
      return ([Rapporttyper.Restaurangrapport])
    }
    else{
      return ([Rapporttyper.Restaurangrapport, Rapporttyper.StatistikrapportForsaljningTillverkning, Rapporttyper.StatistikrapportTekniskSprit])
    }


  }

  return (
    <Grid container>
      <Grid item xs={12}>
      {!auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && 
        <Typography variant="h1" component="h1" paragraph>
          Registrera rapport
        </Typography>
      }
      {auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && 
        <Typography variant="h1" component="h1" paragraph>
          Registrera restaurangrapport 
        </Typography>
      }
      </Grid>
      {!loading && filter !== null && (
        <Grid item xs={12} md={10} mb={2}>
          <Formik
            enableReinitialize
            initialValues={{
              ...filter.filter,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              SubmitSearch(values);
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              setFieldValue,
              errors,
              touched,
              values,
              handleChange,
              setStatus,
              status,
            }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <AutoCompleteAtr
                            onBlur={handleBlur}
                            infoType={InfoType.rapportTyperRegisterReport}
                            disableClearable={true}
                            label="Rapporttyp"
                            multiple={false}
                            defaultVal={false}
                            onChange={(data) => {
                              setFieldValue("rapportTypId", data);
                            }}
                            error={evalErrors(
                              errors,
                              touched ?? {},
                              "rapportTypId"
                            )}
                            helperText={getErrorText(
                              errors,
                              touched ?? {},
                              "rapportTypId"
                            )}
                            name="rapportTypId"
                            selectedData={
                              values.rapportTypId === 0
                                ? Rapporttyper.Restaurangrapport
                                : values.rapportTypId
                            }
                            idsToKeep={filterIdsBasedOnRole()}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <SelectSingle
                          label="Rapportperiod"
                          fullWidth
                          data={perioder.map((p) => {
                            return { key: p.id, label: p.namn };
                          })}
                          defaultValue={true}
                          selected={values.rapportPeriodId}
                          onChange={handleChange}
                          name="rapportPeriodId"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {((values.rapportTypId !== 5) && values.rapportTypId !== 7) && (
                        <Grid item xs={4}>
                          <AutoCompleteAtr
                            infoType={InfoType.kommunerAnvandare}
                            label="Kommun"
                            defaultVal={false}
                            error={evalErrors(
                              errors,
                              touched,
                              "tillstandsGivareId"
                            )}
                            helperText={getErrorText(
                              errors,
                              touched,
                              "tillstandsGivareId"
                            )}
                            multiple={false}
                            onChange={(data) => {
                              setFieldValue("tillstandsGivareId", data);
                            }}
                            name="tillstandsGivareId"
                            onBlur={handleBlur}
                            selectedData={values.tillstandsGivareId}
                          />
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <InputTextField
                          fullWidth
                          name="objektNr"
                          onChange={handleChange}
                          label={values.rapportTypId !== 5 && values.rapportTypId !== 7 ? "Restaurangnummer" : "Objektnummer"}
                          value={values.objektNr}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack spacing={2} direction="row">
                          <BtnDefault type="submit">Sök</BtnDefault>
                          <BtnSecond 
                            startIcon={
                              loadFileDownload ? (
                                <CircularProgress color="primary" size={"1em"} />
                              ) : (
                                <div></div>
                              )
                            }
                            disabled={loadFileDownload}
                            onClick={() => exportSearch(setStatus)}>
                            Exportera resultat
                          </BtnSecond>
                          <BtnSecond 
                            startIcon={
                              loadFilePrint ? (
                                <CircularProgress color="primary" size={"1em"} />
                              ) : (
                                <div></div>
                              )
                            }
                            disabled={loadFilePrint}
                            onClick={() => printAll(setStatus)}>
                            Skriv ut samtliga
                          </BtnSecond>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {ShowAlertEval(status) && (
                  <Grid item xs={12} md={10} mt={1}>
                    <ShowAlert
                      onClose={() => {
                        setStatus(null);
                      }}
                      severity={status.severity}
                      alertTitle={status.title}
                      alertText={status.msg}
                    />
                  </Grid>
                )}
              </Box>
            )}
          </Formik>
        </Grid>
      )}

      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <>
          {results !== null && (
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Sökresultat
              </Typography>
            </Grid>
          )}

          {results !== null && filter !== null && (
            <DefaultTable
              header={headers}
              activePage={filter.sidoNummer}
              data={mapToCellData(headers, results.object.sokResultat)}
              rowsPerPage={checkMenuItem(filter.sidoStorlek)}
              totalHits={results.object.totaltAntalTraffar}
              filter={filter}
              setFilterAction={setFilter}
              setSortOptions={setSortOptions}
              sortOptions={sortOptions}
              actions={[
                {
                  action: navigateToUrl,
                  icon: ActionIcons.edit,
                  title: "Ändra",
                },
                {
                  action: print,
                  icon: ActionIcons.print,
                  title: "Skriv ut",
                },
              ]}
              tableAria="Rapporter"
              checkbox={false}
            />
          )}
        </>
      </Grid>
    </Grid>
  );
}
