import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { GetCheckBox } from "../../../Components/checkbox";
import InputTextField from "../../../Components/textfield";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Mode } from "./formlogic";
import { GridWithCaption, GridWithStyle, ifNullOrUndefined, InputWithDefaults, Section, TextOverInput } from "./mainForm";

export function Godkann(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode
) {
  return (
    <Grid item xs={12} key={nr}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Kontaktperson</Typography>
          <Typography variant="caption">
            Ange telefonnummer och/eller e-postadress till den vi kan kontakta
            vid frågor om lämnade uppgifter. Uppgiftslämnare och kontaktperson
            ska alltid anges (även om det är samma person).
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Namn uppgiftslämnare",
                  "uppgiftslamnare",
                  props.values.uppgiftslamnare,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  ["kontaktPersonNamn","kontaktPersonTelefon","kontaktPersonEpost"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Kontaktperson",
                  "kontaktPersonNamn",
                  props.values.kontaktPersonNamn,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  ["uppgiftslamnare","kontaktPersonTelefon","kontaktPersonEpost"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Telefonnummer",
                  "kontaktPersonTelefon",
                  props.values.kontaktPersonTelefon,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  ["kontaktPersonNamn","uppgiftslamnare","kontaktPersonEpost"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "E-postadress för mottagningsbekräftelse",
                  "kontaktPersonEpost",
                  props.values.kontaktPersonEpost,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  ["kontaktPersonNamn","uppgiftslamnare","kontaktPersonTelefon"]
                )}
              </GridWithCaption>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
