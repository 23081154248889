import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import InputTextField from "../../Components/textfield";
import { getKommunList, getKommunListInfo, kommunList } from "../../Services/infoService";
import {
  getLicensor,
  ILicensorGet,
  putLicensor,
} from "../../Services/LicensorService";
import { licensorsUrl } from "../../Utilities/constants";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { LicensorCreateSchema } from "./validationLicensor";

export default function EditLicensor() {
  const navigate = useNavigate();
  const [licensor, setLicensor] = React.useState<ILicensorGet>(null!);
  const [loading, setLoading] = React.useState(true);
  const [licensorId, setLicensorId] = React.useState<number>(0);

  let { id } = useParams();

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      setLicensorId(parseInt(id));
      getLicensor(parseInt(id))
        .then((resp) => {
          setLicensor({ ...resp.object });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (licensor !== null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [licensor]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Ändra kommun/tillståndsgivare
        </Typography>
      </Grid>
      {!loading ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Typography variant="caption" component="p" mb={1}>
                Fält markerade med * är obligatoriska
              </Typography>
              <Formik
                enableReinitialize
                validationSchema={LicensorCreateSchema}
                initialValues={{ ...licensor }}
                onSubmit={(values, { setSubmitting, setStatus, setValues }) => {
                  setSubmitting(true);
                  console.log("values in submit: ", values);
                  setSubmitting(false);
                  putLicensor(values, licensorId)
                    .then((response) => {
                      setSubmitting(false);
                      setValues({
                        ...response.object,
                      });
                      setStatus({
                        sent: true,
                        msg: `Kommun/Tillståndsgivare ${values.kommunNamn} uppdaterades.`,
                        severity: "success",
                        title: "Kommun/Tillståndsgivare uppdaterad",
                      });
                    })
                    .catch((error) => {
                      setStatus({
                        sent: true,
                        msg: error.data,
                        title: "Ett fel uppstod",
                        severity: "error",
                      });
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setStatus,
                  isSubmitting,
                  setSubmitting,
                }) => (
                  <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {!status?.borttagen && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems={"baseline"}
                              >
                                <FormControl
                                  fullWidth
                                  error={evalErrors(
                                    errors,
                                    touched,
                                    "kommunId"
                                  )}
                                >
                                  <InputLabel id="kommuner-label">
                                    Kommun
                                  </InputLabel>
                                  <SelectSingleWihtoutData
                                    label="kommuner"
                                    labelId="kommuner-label"
                                    infoType={InfoType.kommuner}
                                    selected={values.kommunId}
                                    name="kommunId"
                                    disabled={true}
                                  />
                                </FormControl>
                                <InputTextField
                                  fullWidth
                                  name="namn"
                                  label="Namn"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.namn === null ? "" : values.namn
                                  }
                                  error={evalErrors(errors, touched, "namn")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "namn"
                                  )}
                                  required
                                  aria-required="true"
                                />
                                
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems={"baseline"}
                              >
                                <InputTextField
                                  fullWidth
                                  name="forvaltning"
                                  label="Förvaltning*"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.forvaltning === null
                                      ? ""
                                      : values.forvaltning
                                  }
                                  error={evalErrors(
                                    errors,
                                    touched,
                                    "forvaltning"
                                  )}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "forvaltning"
                                  )}
                                  aria-required="true"
                                />
                                <InputTextField
                                  fullWidth
                                  name="adress"
                                  label="Adress"
                                  onChange={handleChange}
                                  value={
                                    values.adress === null ? "" : values.adress
                                  }
                                  onBlur={handleBlur}
                                  error={evalErrors(errors, touched, "adress")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "adress"
                                  )}
                                  aria-required="true"
                                />
                                <InputTextField
                                  fullWidth
                                  name="postnr"
                                  label="Postnummer"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.postnr === null ? "" : values.postnr
                                  }
                                  error={evalErrors(errors, touched, "postnr")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "postnr"
                                  )}
                                  required
                                  aria-required="true"
                                />
                                <InputTextField
                                  fullWidth
                                  name="postOrt"
                                  label="Postort"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.postOrt === null
                                      ? ""
                                      : values.postOrt
                                  }
                                  error={evalErrors(errors, touched, "postOrt")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "postOrt"
                                  )}
                                  required
                                  aria-required="true"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {ShowAlertEval(status) && (
                              <Grid item xs={12}>
                                <ShowAlert
                                  onClose={() => {
                                    setStatus(null);
                                  }}
                                  severity={status.severity}
                                  alertTitle={status.title}
                                  alertText={status.msg}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={2}>
                                <BtnDefault
                                  disabled={
                                    isSubmitting ||
                                    values.kommunId === 0
                                  }
                                  type="submit"
                                >
                                  Spara
                                </BtnDefault>
                                <BtnSecond
                                  onClick={() => navigate(licensorsUrl)}
                                >
                                  Tillbaka
                                </BtnSecond>
                                {isSubmitting && <CircularProgress />}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
