import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import InputTextField from "../../../Components/textfield";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { disabledEnabled, Mode, Permits } from "./permitsUtils";

export function FormTypeVerksamhetsomraden(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode,
) {
  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Verksamhetsområden enligt skatteverket</Typography>
      </Grid>
      <Grid item xs={6} key="versionDetails.verksamhetsOmrade1">
        <InputTextField
          fullWidth
          name="versionDetails.verksamhetsOmrade1"
          label="Verksamhetsområde 1"
          onBlur={props.handleBlur}
          value={props.values.versionDetails.verksamhetsOmrade1 && props.values.versionDetails.verksamhetsOmrade1 }
          disabled={mode === Mode.View || disabledEnabled(
            [
              Permits.Upplagshavare,
              Permits.RegistreradVarumottagare,
              Permits.SkattebefriadForbrukare,
            ],
            permit
          )}
        />
      </Grid>
      <Grid item xs={6} key="versionDetails.verksamhetsOmrade2">
        <InputTextField
          fullWidth
          name="versionDetails.verksamhetsOmrade2"
          label="Verksamhetsområde 2"
          onBlur={props.handleBlur}
          value={props.values.versionDetails.verksamhetsOmrade2 && props.values.versionDetails.verksamhetsOmrade2}
          disabled={mode === Mode.View || disabledEnabled(
            [
              Permits.Upplagshavare,
              Permits.RegistreradVarumottagare,
              Permits.SkattebefriadForbrukare,
            ],
            permit
          )}
        />
      </Grid>
      <Grid item xs={6} key="versionDetails.verksamhetsOmrade3">
        <InputTextField
          fullWidth
          name="versionDetails.verksamhetsOmrade3"
          label="Verksamhetsområde 3"
          onBlur={props.handleBlur}
          value={props.values.versionDetails.verksamhetsOmrade3 && props.values.versionDetails.verksamhetsOmrade3}
          disabled={mode === Mode.View || disabledEnabled(
            [
              Permits.Upplagshavare,
              Permits.RegistreradVarumottagare,
              Permits.SkattebefriadForbrukare,
            ],
            permit
          )}
        />
      </Grid>
      <Grid item xs={6} key="versionDetails.verksamhetsOmrade4">
        <InputTextField
          fullWidth
          name="versionDetails.verksamhetsOmrade4"
          label="Verksamhetsområde 4"
          onBlur={props.handleBlur}
          value={props.values.versionDetails.verksamhetsOmrade4 && props.values.versionDetails.verksamhetsOmrade4}
          disabled={mode === Mode.View || disabledEnabled(
            [
              Permits.Upplagshavare,
              Permits.RegistreradVarumottagare,
              Permits.SkattebefriadForbrukare,
            ],
            permit
          )}
        />
      </Grid>
      <Grid item xs={6} key="versionDetails.verksamhetsOmrade5">
        <InputTextField
          fullWidth
          name="versionDetails.verksamhetsOmrade5"
          label="Verksamhetsområde 5"
          onBlur={props.handleBlur}
          value={props.values.versionDetails.verksamhetsOmrade5 && props.values.versionDetails.verksamhetsOmrade5}
          disabled={mode === Mode.View || disabledEnabled(
            [
              Permits.Upplagshavare,
              Permits.RegistreradVarumottagare,
              Permits.SkattebefriadForbrukare,
            ],
            permit
          )}
        />
      </Grid>
    </Grid>
  );
}
