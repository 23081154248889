import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { MainForm } from "./ReportForm/mainForm";

export default function ReportRecord() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Grid key="formik" item xs={12}>
        {MainForm(true, true, true)}
      </Grid>
    </Grid>
  );
}
