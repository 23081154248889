import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import { IAnvandarePost, postAnvandare } from "../../Services/UserService";
import {
  Cat,
  GetCorrectRolesBasedOnKey,
  MapToCorrectCat,
  MapToCorrectKey,
  MapToCorrectlabel,
  ShowDropdown
} from "../../Utilities/roles";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { newAnvandareSchema } from "./validation";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import InputTextField from "../../Components/textfield";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Components/customModal";
import { GetCheckBox } from "../../Components/checkbox";
import { boolean } from "yup";

export interface IForm extends IAnvandarePost {}

export default function New() {
  const initialValues: IForm = {
    anvandarnamn: "",
    efternamn: "",
    epost: "",
    fornamn: "",
    kommunIdn: [],
    lanId: 0,
    roller: [],
    system: [],
    systemId: 0,
    foretag: "",
    kreditupplysningsForetag: false,
    id: 0,
  };

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Registrera användare
        </Typography>
      </Grid>

      <Grid item xs={12} md={10} mb={2}>
        <Typography variant="caption" component="p" mb={1}>
          Fält markerade med * är obligatoriska
        </Typography>
        <Formik
          validationSchema={newAnvandareSchema}
          initialValues={{ ...initialValues, epost2: "", skickaEpost: true }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            setSubmitting(true);
            postAnvandare(values, values.skickaEpost)
              .then((resp) => {
                setSubmitting(false);
                resetForm();
                setStatus({
                  sent: true,
                  msg: values.skickaEpost
                    ? `Användare ${values.fornamn} ${values.efternamn} skapades. Ett e-postmeddelande skickades till användaren.`
                    : `Användare ${values.fornamn} ${values.efternamn} skapades.`,
                  title: "Användare skapad",
                  severity: "success",
                });
              })
              .catch((error) => {
                setStatus({
                  sent: true,
                  msg: JSON.stringify(error.data),
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            isSubmitting,
            setStatus,
            setFieldValue,
            handleSubmit,
            resetForm,
            submitForm,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <FormControl
                      fullWidth
                      error={evalErrors(errors, touched, "roller")}
                    >
                      <InputLabel id="roller-label">Roller</InputLabel>
                      <SelectSingleWihtoutData
                        label="Roller"
                        labelId="roller-label"
                        infoType={InfoType.roller}
                        selected={MapToCorrectKey(values.roller)}
                        onChange={(evt) => {
                          console.log(evt.target.value);
                          resetForm();
                          setFieldValue(
                            "roller",
                            GetCorrectRolesBasedOnKey(evt.target.value)
                          );
                        }}
                        name="roller"
                        defaultlabel="Välj"
                        required
                        aria-required="true"
                        onBlur={handleBlur}
                      />
                      <FormHelperText>
                        {getErrorText(errors, touched, "roller")}
                      </FormHelperText>
                    </FormControl>

                    <InputTextField
                      fullWidth
                      name="anvandarnamn"
                      label="Användarnamn"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.anvandarnamn}
                      error={evalErrors(errors, touched, "anvandarnamn")}
                      helperText={getErrorText(errors, touched, "anvandarnamn")}
                      required
                      aria-required="true"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <InputTextField
                      fullWidth
                      name="fornamn"
                      label="Förnamn"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fornamn}
                      error={evalErrors(errors, touched, "fornamn")}
                      helperText={getErrorText(errors, touched, "fornamn")}
                      required
                      aria-required="true"
                    />
                    <InputTextField
                      fullWidth
                      name="efternamn"
                      label="Efternamn"
                      onChange={handleChange}
                      value={values.efternamn}
                      onBlur={handleBlur}
                      error={evalErrors(errors, touched, "efternamn")}
                      helperText={getErrorText(errors, touched, "efternamn")}
                      required
                      aria-required="true"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <InputTextField
                      fullWidth
                      name="epost"
                      label="E-postadress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.epost}
                      error={evalErrors(errors, touched, "epost")}
                      helperText={getErrorText(errors, touched, "epost")}
                      required
                      aria-required="true"
                    />
                    <InputTextField
                      fullWidth
                      name="epost2"
                      label="Bekräfta e-postadress"
                      onChange={handleChange}
                      value={values.epost2}
                      onBlur={handleBlur}
                      error={evalErrors(errors, touched, "epost2")}
                      helperText={getErrorText(errors, touched, "epost2")}
                      required
                      aria-required="true"
                    />
                  </Stack>
                </Grid>
                {MapToCorrectCat(values.roller) === Cat.ext && (
                  <>
                    <Grid item xs={6}>
                      <InputTextField
                        fullWidth
                        name="foretag"
                        label="Företag"
                        onChange={handleChange}
                        value={values.foretag}
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "foretag")}
                        helperText={getErrorText(errors, touched, "foretag")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                name="kreditupplysningsForetag"
                                value={
                                  values.kreditupplysningsForetag &&
                                  values.kreditupplysningsForetag.toString()
                                }
                                onChange={(e, checked) => {
                                  setFieldValue(
                                    "kreditupplysningsForetag",
                                    checked
                                  );
                                }}
                              />
                            }
                            label={
                              "Kreditupplysningsföretag"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {MapToCorrectCat(values.roller) === Cat.kommun && (
                  <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={InfoType.kommuner}
                          label="Kommuner"
                          multiple={true}
                          defaultVal={false}
                          onChange={(data) => {
                            setFieldValue("kommunIdn", data);
                          }}
                          error={evalErrors(errors, touched, "kommunIdn")}
                          helperText={getErrorText(
                            errors,
                            touched,
                            "kommunIdn"
                          )}
                          name="kommunIdn"
                          selectedData={values.kommunIdn}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
                {MapToCorrectCat(values.roller) === Cat.lan && (
                  <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          error={evalErrors(errors, touched, "lanId")}
                          onBlur={handleBlur}
                          infoType={InfoType.lan}
                          multiple={false}
                          defaultVal={false}
                          label="Län"
                          onChange={(data) => {
                            setFieldValue("lanId", data);
                          }}
                          helperText={getErrorText(errors, touched, "lanId")}
                          name="lanId"
                          selectedData={values.lanId}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                         {ShowDropdown(values.roller) && (
                           <FormControl
                              fullWidth
                              error={evalErrors(errors, touched, "system")}
                            >
                              <AutoCompleteAtr
                                      defaultVal={false}
                                      infoType={InfoType.system}
                                      multiple={false}
                                      label="System"
                                      name="system"
                                      required
                                      onChange={(data) => {
                                        setFieldValue(
                                          "systemId", data                                         
                                        );                                      
                                      }}
                                      onBlur={handleBlur}
                                      selectedData={values.systemId}
                                      error={evalErrors(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                      helperText={getErrorText(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                    />
                              <FormHelperText>
                                {getErrorText(errors, touched, "system")}
                              </FormHelperText>
                            </FormControl>)}
                          </Grid>
              <Grid container spacing={2}>
                {ShowAlertEval(status) && (
                  <Grid item xs={12}>
                    <ShowAlert
                      onClose={() => {
                        setStatus(null);
                      }}
                      severity={status.severity}
                      alertTitle={status.title}
                      alertText={status.msg}
                    />
                  </Grid>
                )}
                <Grid item xs={4} mt={2}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault
                      onClick={() => errors !== undefined && Object.keys(errors).length > 0 ? setStatus({
                        sent: true,
                        msg: "Vänligen kontrollera att samtliga fält är ifyllda.",
                        title: "Ett fel uppstod",
                        severity: "error",
                      }): setOpen(true) }
                      disabled={isSubmitting}
                      type="button"
                    >
                      Skapa
                    </BtnDefault>
                    <BtnSecond onClick={() => navigate("/users")}>
                      Tillbaka
                    </BtnSecond>
                    {isSubmitting && <CircularProgress />}
                  </Stack>
                </Grid>
                <CustomModal
                  handleCloseModal={() => setOpen(false)}
                  openModal={open}
                  title="Bekräfta användare"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Divider sx={{ margin: "0px" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            <b>Förnamn: </b>
                            {values.fornamn}
                          </Typography>
                          <Typography>
                            <b>Efternamn: </b>
                            {values.efternamn}
                          </Typography>
                          <Typography>
                            <b>E-postadress: </b>
                            {values.epost}
                          </Typography>
                          <Typography>
                            <b>Roll: </b>
                            {MapToCorrectlabel(values.roller)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <GetCheckBox
                            checked={values.skickaEpost}
                            label="Skicka bekräftelse till användare"
                            name="skickaEpost"
                            onChange={(e, c) => setFieldValue("skickaEpost", c)}
                            mainLabel={""}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <BtnDefault
                          onClick={() => {
                            submitForm();
                            setOpen(false);
                          }}
                          type="submit"
                        >
                          Skapa
                        </BtnDefault>
                        <BtnSecond type="button" onClick={() => setOpen(false)}>
                          Tillbaka
                        </BtnSecond>
                      </Stack>
                    </Grid>
                  </Grid>
                </CustomModal>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
