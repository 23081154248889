import React from "react";
import { Typography } from "@mui/material";


function PasswordStrength(props: any) {

  let strColor;
  let strWidth;
  let styrka;

  switch (props.score) {
    case 0:
      strColor = "#a8002f";
      strWidth = "10%";
      styrka = "Mycket svagt";
      break;
    case 1:
      strColor = "#a8002f";
      strWidth = "20%";
      styrka = "Mycket svagt";
      break;
    case 2:
      strColor = "#a8002f";
      strWidth = "40%";
      styrka = "Svagt";
      break;
    case 3:
      strColor = "#ffcb31";
      strWidth = "60%";
      styrka = "Godkänt";
      break;
    case 4:
      strColor = "#71c05c";
      strWidth = "80%";
      styrka = "Starkt";
      break;
    case 5:
      strColor = "#71c05c";
      strWidth = "100%";
      styrka = "Mycket starkt";
      break;
    default:
  }

  var style = {
    backgroundColor: strColor,
    height: "5px",
    width: strWidth,
    transition: "all 300ms ease-in-out",
  };

  return (
    <div>
      <Typography gutterBottom className="pwStrWeak">Lösenordsstyrka: {styrka}</Typography>
      <div style={style} />
    </div>
  );
}

export default PasswordStrength;
