import React, { useEffect } from "react";
import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  GenerateFormStructure,
  MapToPermitType,
  MapToPermitTypeName,
  Mode,
} from "./PermitsFormsUtils/permitsUtils";
import { newPermitSchema } from "./validation";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { InfoType } from "../../Components/selects";
import { ITillstandPost, ITillstandsObjekt, postPermit, checkRestaurantNumber } from "../../Services/PermitService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { checkIfEmptyObject } from "../../Utilities/common";
import { getKommunListInfo, kommunList } from "../../Services/infoService";
import { Roles } from "../../Utilities/roles";
import { useAuth } from "../../Utilities/authProvider";
import CustomModal from "../../Components/customModal";

export interface IForm extends ITillstandPost { }

export const defaultVals: IForm = {
  tillstandsHavare: {
    adress: "",
    namn: "",
    postOrt: "",
    postnr: "",
  },
  tillstandsObjekt: {
    adress: "",
    namn: "",
    postOrt: "",
    postnr: "",
    webbutikAdress: "",
    webbutikNamn: "",
  },
  aktivieringsDatum: "",
  beslutsDatum: "",
  kommunId: 0,
  kommunNamn: "",
  objektNr: "",
  orgNr: "",
  tillstandsTypId: 0,
  andrad: "",
  andradAv: "",
  avslutsDatum: null,
  registreradViaWebbtjanst: false,
  id: 0,
  orsakTillAvslutId: 0,
  lopNr: "",
  versionId: 0,
  versionDetails: {
    alkodrycksliknPreparat: false,
    antalPersonerMax: null,
    antalPersonerMaxBeslutRaddningstjanst: false,
    antalSittplatserInne: null,
    antalSittplatserUte: null,
    erbjudaProvsmakning: false,
    framstallaSnaps: false,
    provoTidTom: null,
    servFleraTillfUnderPeriod: false,
    servUtrymmeStadigvarande: "",
    servUtrymmeTypId: null,
    servUtrymmeString: "",
    servtidAnnanInneFrom: null,
    servtidAnnanInneTom: null,
    servtidAnnanUteFrom: null,
    servtidAnnanUteTom: null,
    servtidNormaltidInne: false,
    servtidNormaltidUte: false,
    servtidSlutnaFrom: null,
    servtidSlutnaTom: null,
    servtidTillfFrom: null,
    servtidTillfTom: null,
    stadigvTillstandFinns: false,
    uteserveringFinns: false,
    versionsNr: 0,
    versionsStart: "",
    villkor: "",
    verksamhetsOmrade1: null,
    verksamhetsOmrade2: null,
    verksamhetsOmrade3: null,
    verksamhetsOmrade4: null,
    verksamhetsOmrade5: null,
  },
  tillstandsKategoriIds: [],
};

interface ITillstandsRubriker {
  tillstandsHavare: string,
  tillstandsObjekt: string,
  tillstandsKategoriIds: string,
  versionDetails: string
}

export const tillstandsRubriker: ITillstandsRubriker = {
  tillstandsHavare: "Tillståndshavare",
  tillstandsObjekt: "Tillståndsobjekt",
  tillstandsKategoriIds: "Tillståndskategori",
  versionDetails: "Versionsdetaljer"
}

export function NewPermit() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultData, setDefaultdata] = React.useState<IForm>(defaultVals);
  const [parsedData, setParsedData] = React.useState<IForm>(defaultVals);
  const [tillstandsTypId, setTillstandsTypId] = React.useState<number>(defaultVals.tillstandsTypId);
  const [showPermitFromLocalStorage, setShowPermitFromLocalStorage] = React.useState<boolean>(false);
  const [loadedPermitFromLocalStoreage, setLoadedPermitFromLocalStoreage] = React.useState<boolean>(false);
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [avsluta, setAvsluta] = React.useState<boolean>(false);

  const [searchPermitTypes, setSearchPermitTypes] = React.useState<InfoType>(
    InfoType.tillstandsTyper
  );
  const [kommuner, setKommuner] = React.useState<InfoType>(
    InfoType.kommuner
  );
  let newCreatedPermit = 0;
  const auth = useAuth();

  const setDefaultDataFromPermit = () => {
    if (parsedData !== undefined) {
      setDefaultdata(parsedData);
      setLoadedPermitFromLocalStoreage(true);
    }
  }

  const deletePermitFromLocalStorage = () => {
    localStorage.removeItem("permitChangeOwner");
    setShowPermitFromLocalStorage(false);
  }

  const getFormikError = (key: string, error: any) => {
    if (error !== null) {
      if (typeof error === "object") {
        for (const [key2, err] of Object.entries(error)) {
          return (
            <ListItem key={`${key}-${key2}`}>
              <Typography>
                {tillstandsRubriker[key as keyof ITillstandsRubriker]}: {err}
              </Typography>
            </ListItem>
          )
        }
      } else {
        return (
          <ListItem key={key}>
            <Typography>
              {error}
            </Typography>
          </ListItem>
        )
      }
    }
  }

  const printFormikError = (errors: any, key: any) => {
    if (errors && key) {
      return getFormikError(key, errors[key])
    }
  }

  const handleCloseAvslutaModal = (): void => {
    setAvsluta(false);
  };

  useEffect(() => {
    if (parsedData && parsedData.tillstandsTypId !== 0) {
      if (searchParams.get("load") === "true") {
        setDefaultDataFromPermit();
      } else {
        setShowPermitFromLocalStorage(true);
      }
    }
  }, [tillstandsTypId])

  useEffect(() => {
    let data: string = localStorage.getItem("permitChangeOwner") ?? null!;
    if (data) {
      let parsedVals: IForm = { ...defaultVals };
      for (const [key, value] of Object.entries(JSON.parse(data))) {
        if (key === "tillstandsTypId") {
          parsedVals.tillstandsTypId = value as number;
          setTillstandsTypId(parsedVals.tillstandsTypId);
        }
        if (key === "kommunId") {
          parsedVals.kommunId = value as number;
        }
        if (key === "objektNr") {
          parsedVals.objektNr = value as string;
        }
        if (key === "tillstandsObjekt") {
          parsedVals.tillstandsObjekt = value as ITillstandsObjekt;
        }
      }
      setParsedData(parsedVals);
    }
    if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && (auth.validateUserRoleAccess([Roles.KommunAdminTobak]))) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAdmin);
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAlkohol);
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunTobak);
    } else {
      setSearchPermitTypes(InfoType.tillstandsTyper);
    }
    if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol, Roles.KommunAdminTobak])) {
      setKommuner(InfoType.kommunerAnvandare);
    }
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography paragraph variant="h1" component="h1">
          Registrera tillstånd
        </Typography>
      </Grid>
      {showPermitFromLocalStorage && (
        <>
          <Grid item xs={12}>
            <Typography paragraph variant="h2">
              Du har ett tillstånd för ägarbyte
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              mb={4}
            >
              <Button
                type="button"
                title="Ladda in tillstånd för ägarbyte"
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setDefaultDataFromPermit()
                }}
              >
                Ladda in tillstånd för ägarbyte
              </Button>
              <Button
                type="button"
                title="Ta bort tillstånd för ägarbyte"
                variant="contained"
                color="error"
                onClick={(e) => {
                  deletePermitFromLocalStorage()
                }}
              >
                Ta bort tillstånd för ägarbyte
              </Button>
            </Stack>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Formik
          validationSchema={newPermitSchema}
          initialValues={{ ...defaultData }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            values = { ...values, versionDetails: { ...values.versionDetails, versionsStart: values.aktivieringsDatum } }
            if (values.versionDetails.antalPersonerMax !== null) {
              values.versionDetails.antalPersonerMax = values.versionDetails.antalPersonerMax?.toString() === "" ? null : Number(values.versionDetails.antalPersonerMax);
            }
            if (values.versionDetails.antalSittplatserInne !== null) {
              values.versionDetails.antalSittplatserInne = values.versionDetails.antalSittplatserInne?.toString() === "" ? null : Number(values.versionDetails.antalSittplatserInne);
            }
            if (values.versionDetails.antalSittplatserUte !== null) {
              values.versionDetails.antalSittplatserUte = values.versionDetails.antalSittplatserUte?.toString() === "" ? null : Number(values.versionDetails.antalSittplatserUte);
            }
            if (values.versionDetails.servUtrymmeTypId === 0) {
              values.versionDetails.servUtrymmeTypId = null;
            }
            setSubmitting(true);
            postPermit(values)
              .then((res) => {
                newCreatedPermit = res.object.id
                let successMsg = `Ett nytt ${MapToPermitTypeName(values.tillstandsTypId).toLowerCase()} har registrerats för ${values.tillstandsHavare.namn} ${new Date().toLocaleString()}.`;
                if (loadedPermitFromLocalStoreage === true) {
                  successMsg = `Tillståndet för ${defaultData.objektNr} ${defaultData.tillstandsHavare.namn} har avslutats och ett nytt tillstånd av typen ${MapToPermitTypeName(values.tillstandsTypId)} har registrerats för ${values.tillstandsHavare.namn} ${new Date().toLocaleString()}.`;
                }
                setSubmitting(false);
                resetForm();
                setStatus({
                  sent: true,
                  msg: successMsg,
                  severity: "success",
                });
                if (loadedPermitFromLocalStoreage === true) {
                  deletePermitFromLocalStorage();
                }
                navigate(`/permits/${newCreatedPermit}`);
              })
              .catch((error) => {
                setStatus({
                  sent: true,
                  msg: JSON.stringify(error.data),
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {(formikprops) => (
            <Box component="form" onSubmit={formikprops.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <AutoCompleteAtr
                      onBlur={formikprops.handleBlur}
                      infoType={searchPermitTypes}
                      disableClearable={true}
                      label="Tillståndstyp"
                      multiple={false}
                      defaultVal={false}
                      onChange={(data) => {
                        formikprops.resetForm();
                        formikprops.setFieldValue("tillstandsTypId", data);
                      }}
                      error={evalErrors(
                        formikprops.errors,
                        formikprops.touched ?? {},
                        "tillstandsTypId"
                      )}
                      helperText={getErrorText(
                        formikprops.errors,
                        formikprops.touched ?? {},
                        "tillstandsTypId"
                      )}
                      name="tillstandsTypId"
                      selectedData={formikprops.values.tillstandsTypId}
                      disabled={loadedPermitFromLocalStoreage}
                    />
                  </FormControl>
                </Grid>
                {formikprops.values.tillstandsTypId !== 0 && (
                  <Grid item xs={12}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={formikprops.handleBlur}
                          infoType={kommuner}
                          label="Kommun"
                          multiple={false}
                          defaultVal={false}
                          onChange={(data) => {
                            formikprops.setFieldValue("kommunId", data);
                          }}
                          error={evalErrors(
                            formikprops.errors,
                            formikprops.touched ?? {},
                            "kommunId"
                          )}
                          helperText={getErrorText(
                            formikprops.errors,
                            formikprops.touched ?? {},
                            "kommunId"
                          )}
                          name="kommunId"
                          selectedData={formikprops.values.kommunId ?? 0}
                          required={true}
                          aria-required="true"
                          disabled={loadedPermitFromLocalStoreage}
                        />
                      </FormControl>
                    </Grid>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {formikprops.values.tillstandsTypId !== 0 && (
                  <Grid item xs={12}>
                    <Typography variant="caption" component="p" mb={1}>
                      Fält markerade med * är obligatoriska
                    </Typography>
                  </Grid>
                )}
                {/* If user submits and there are errors, those should be shown here */
                checkIfEmptyObject(formikprops.errors) === false && showErrors && formikprops.status && formikprops.status.sent !== true && (
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Följande fält innehåller fel:
                    </Typography>
                    <List role="alert" aria-atomic="true">
                      {Object.keys(formikprops.errors).map((error) => {
                        return printFormikError(formikprops.errors, error)
                      })}
                    </List>
                  </Grid>
                )}
                {GenerateFormStructure(
                  MapToPermitType(formikprops.values.tillstandsTypId),
                  formikprops,
                  loadedPermitFromLocalStoreage === true ? Mode.Transfer : Mode.Create
                )}
                {ShowAlertEval(formikprops.status) && (
                  <Grid item xs={6}>
                    <ShowAlert
                      onClose={() => {
                        formikprops.setStatus(null);
                      }}
                      severity={formikprops.status.severity}
                      alertTitle={formikprops.status.title}
                      alertText={formikprops.status.msg}
                    />
                  </Grid>
                )}
                <Grid container>
                  <CustomModal
                    title="Bekräfta"
                    openModal={avsluta}
                    handleCloseModal={handleCloseAvslutaModal}
                    >
                          <Grid item xs={12}>
                          <Typography variant="body1" style={{ marginBottom: '16px' }}>
                            Restaurangnumret har använts tidigare. Restaurangnumret får endast användas igen om tillståndet avser samma serveringsställe/lokal. Klicka på Spara igen för att spara tillståndet.
                            </Typography>
                            <Stack spacing={2} direction="row">
                              <Grid item>
                              <BtnDefault
                                onClick={() => {
                                  formikprops.handleSubmit();
                                  setAvsluta(false);
                                  setShowErrors(true);
                                }}
                                type="submit"
                                >
                                Spara
                              </BtnDefault>
                              </Grid>
                              <Grid item>
                              <BtnSecond
                                onClick={() => {setAvsluta(false);}}
                              >
                                Avbryt
                              </BtnSecond>
                              </Grid>
                            </Stack>
                          </Grid>
                  </CustomModal>
                </Grid>
                <Grid item xs={12} mb={2}>
                  {formikprops.errors && Object.keys(formikprops.errors).length > 0 && (Object.getPrototypeOf(formikprops.errors) === Object.prototype) && (
                    <Typography variant="caption" component="p" mb={1}>
                      Alla obligatoriska fält är ej ifyllda
                    </Typography>
                  )}

                  <Stack direction="row" spacing={2}>
                  <BtnDefault
                    disabled={formikprops.isSubmitting}
                    onClick={async () => {
                      if(formikprops.values.tillstandsTypId === 1){
                        const isValidForm = !(formikprops.errors && Object.keys(formikprops.errors).length > 0 && (Object.getPrototypeOf(formikprops.errors) === Object.prototype));
                        const restaurangNumberHaveExist = await checkRestaurantNumber(formikprops.values.objektNr);
                        if ((isValidForm && restaurangNumberHaveExist)) {
                            setAvsluta(true);            
                        } else {
                          formikprops.handleSubmit(); 
                          setShowErrors(true);
                        }
                      }
                      else{
                        formikprops.handleSubmit(); 
                        setShowErrors(true);
                      }
                    }}
                  >
                    Registrera
                  </BtnDefault>
                    <BtnSecond
                      disabled={formikprops.isSubmitting}
                      onClick={() => navigate("/permits")}
                    >
                      {formikprops.status && formikprops.status.sent === true ? "Tillbaka" : "Avbryt"}
                    </BtnSecond>
                    {formikprops.status && formikprops.status.sent === true && newCreatedPermit !== 0 && (
                      <BtnSecond
                        onClick={() => navigate(`/permits/${newCreatedPermit}`)}
                      >
                        Tillbaka till tillståndet
                      </BtnSecond>
                    )}
                    {formikprops.isSubmitting && <CircularProgress />}
                  </Stack>
                </Grid>
                <Grid item xs={12} mb={2}>
                  {formikprops.values.tillstandsTypId === 10 && (
                    <Typography paragraph variant="body1">
                      Tillstånd av typen Tobakstillstånd detaljhandel viss tid sätts automatiskt som avslutade på dagen angiven i fältet "Giltigt t.o.m." när de sparas. 
                    </Typography>
                  )}
                  {formikprops.values.tillstandsTypId === 12 && (
                    <Typography paragraph variant="body1">
                      Tillstånd av typen Tillfälligt serveringstillstånd sätts automatiskt som avslutade på dagen angiven i fältet "Giltigt t.o.m." när de sparas.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
