import { IData } from "../Components/selects";
import { ExportFile, Get, PostFile } from "../Utilities/axiosBase";
import { IMainBaseResponse } from "./base";

export interface IStatisticsReportTypeOptions {
  key: number;
  label: string;
  tooltip: string;
  sortOrder: number
}
export interface IAlkoholStatistics {
  reportType: number;
  datum: string;
  fromDatum: string;
  tomDatum: string;
  area: number;
  area2: number;
  lanId: number[];
  kommunId: number[];
  grouping: number;
  grouping2: number;
  displayOptions: number;
  reportPeriodId: number;
}

export interface ITobakStatistics {
  reportType: number;
  fromDatum: string;
  tomDatum: string;
  datum: string;
  area: number;
  lanId: number[];
  kommunId: number[];
  grouping: number;
  reportPeriodId: number;
}

export interface IStatisticsFilter {
  datum: string;
  gruppering: string;
  lanIdn: number[] | null;
  kommunIdn: number[] | null;
  rapportPeriodId: number | null;
}

export interface IStatisticsFilter {
  datum: string;
  gruppering: string;
  lanIdn: number[] | null;
  kommunIdn: number[] | null;
  rapportPeriodId: number | null;
}
export interface IStatisticsFilterRapportPeriodId {
  gruppering: string;
  lanIdn: number[] | null;
  kommunIdn: number[] | null;
  rapportPeriodId: number | null;
}

export interface IStatisticsToFrom {
  fromDatum: string;
  tomDatum: string;
  gruppering: string;
  lanIdn: number[] | null;
  kommunIdn: number[] | null;
}

export const StatisticsAreas: IData[] = [
  {
    key: 1,
    label: "Hela riket",
  },
  {
    key: 2,
    label: "Län",
  },
  {
    key: 3,
    label: "Kommun",
  },
];

export const StatisticsGrouping: IData[] = [
  {
    key: 1,
    label: "Kommun",
  },
  {
    key: 2,
    label: "Län",
  },
];

export const AlcoholStatisticsReportTypeOptions: IStatisticsReportTypeOptions[] = [
  {
    key: 1,
    label: "Antal stadigvarande serveringstillstånd per 10 000 invånare 15 år eller äldre",
    tooltip: "Rapporten visar antal stadigvarande serveringstillstånd till allmänheten och slutet sällskap. Antal tillstånd visas även omräknat till antal tillstånd per 10 000 invånare 15 år eller äldre.",
    sortOrder: 9
  },
  {
    key: 2,
    label: "Antal stadigvarande serveringstillstånd till allmänheten",
    tooltip: "Rapporten visar antal stadigvarande serveringstillstånd till allmänheten och vilken typ av tillstånd som avses (kategorikod).",
    sortOrder: 3
  },
  {
    key: 3,
    label: "Antal stadigvarande serveringstillstånd till slutet sällskap",
    tooltip: "Rapporten visar antal stadigvarande serveringstillstånd till slutet sällskap och vilken typ av tillstånd som avses (kategorikod).",
    sortOrder: 3
  },
  {
    key: 4,
    label: "Antal tillfälliga serveringstillstånd per 10 000 invånare 15 år eller äldre",
    tooltip: "Rapporten visar antal tillfälliga serveringstillstånd till allmänheten och slutet sällskap. Antal tillstånd visas även omräknat till antal tillstånd per 10 000 invånare 15 år eller äldre.",
    sortOrder: 4
  },
  {
    key: 5,
    label: "Antal tillfälliga serveringstillstånd till allmänheten",
    tooltip: "Rapporten visar antal tillfälliga serveringstillstånd till allmänheten och vilken typ av tillstånd som avses (kategorikod).",
    sortOrder: 5
  },
  {
    key: 6,
    label: "Antal tillfälliga serveringstillstånd till slutet sällskap",
    tooltip: "Rapporten visar antal tillfälliga serveringstillstånd till slutet sällskap och vilken typ av tillstånd som avses (kategorikod).",
    sortOrder: 6
  },
  {
    key: 7,
    label: "Serveringstider - senaste stängningstid för serveringsställe",
    tooltip: "Rapporten visar senaste serveringstid för serveringsställen med tillstånd till allmänheten och slutet sällskap.",
    sortOrder: 1
  },
  // {
  //   key: 8,
  //   label: "Bortfallsbeskrivning restaurangrapporter(ej klar)",
  //   tooltip: "Rapporten visar hur stort bortfall det är för restaurangrapporten. Bortfallet inkluderar även rapporter som kommit in men är orimliga/felregistrerade och kräver rättning.",
  // },
  {
    key: 9,
    label: "Inköpta volymer i liter per invånare 15 år eller äldre",
    tooltip: 'Sammanställning av inlämnade restaurangrapporters angivna inköpta volym i liter per invånare. Baseras endast på rapporter med status "Klar". Vid visning på kommunnivå krävs att kommunen har minst 12 stadigvarande serveringstillstånd av kategori 21. 01, 03, 05 sammanslaget och att minst 70% av dessa har lämnat in restaurangrapport.',
    sortOrder: 2
  },
  {
    key: 10,
    label: "Omsättning i kronor",
    tooltip: 'Sammanställning av inlämnade restaurangrapporters angivna omsättning i kronor. Baseras endast på rapporter med status "Klar". Vid visning på kommunnivå krävs att kommunen har minst 12 stadigvarande serveringstillstånd av kategori 21, 01, 03, 05 sammanslaget och att minst 70% av dessa har lämnat in restaurangrapport.',
    sortOrder: 3
  },
  {
    key: 11,
    label: "Normalpris per cl alkoholdryck, medelvärde per län",
    tooltip: 'Sammanställning av inlämnade restaurangrapporters angivna normalpris i kronor. Normalpris anges som ett medelvärde per län samt även som ett viktat normalpris. Baseras på rapporter som lämnats för serveringstillstånd till allmänheten. Baseras endast på rapporter med status "Klar".',
    sortOrder: 4
  },
  // {
  //   key: 12,
  //   label: "Bortfallsbeskrivning normalpris (ej klar)",
  //   tooltip: "Rapporten visar hur stort bortfall det är för normalpris på restaurangrapporten. Bortfallet inkluderar även rapporter som kommit in men är orimliga/felregistrerade och kräver rättning.",
  // },
  // {
  //   key: 13,
  //   label: "Bortfallsbeskrivning statistikrapport försäljning/tillverkning (ej klar)",
  //   tooltip: "Rapporten visar senaste serveringstid för serveringsställen med tillstånd till allmänheten och slutet sällskap.",
  // },
   {
     key: 14,
     label: "Försäljning, import, export, tillverkning av alkoholdrycker",
     tooltip: 'Sammanställning av inlämnade statistikrapporters försäljning/tillverkning. Baseras endast på rapporter med status "Klar".',
     sortOrder: 14
   },
  {
    key: 15,
    label: "Antal registrerade varumottagare, skattebefriade förbrukare och upplagshavare",
    tooltip: "Rapporten visar antal registrerade varumottagare, skattebefriade förbrukare och upplagshavare.",
    sortOrder: 10
  },
  {
    key: 16,
    label: "Försäljning, import, export, tillverkning av teknisk sprit",
    tooltip:'Sammanställning av inlämnade statistikrapporters teknisk sprit. Baseras endast på rapporter med status "Klar".',
    sortOrder: 10
  },
  {
    key: 17,
    label: "Bortfallsbeskrivning restaurangrapport",
    tooltip:'Rapporten visar hur stort bortfall det är för restaurangrapporten uppdelat på stadigvarande serveringstillstånd allmänheten och stadigvarande serveringstillstånd slutna sällskap. Bortfallet inkluderar rapporter som kommit in men är orimliga/felregistrerade och kräver rättning dvs. har status=inkommen eller status=granskas. Det är endast rapporter i status=klar som tas med i de olika statistiksammanställningar som finns.',
    sortOrder: 11
  },

];

export const TobaccoStatisticsReportTypeOptions: IStatisticsReportTypeOptions[] = [
  {
    key: 1,
    label: "Antal tobakstillstånd detaljhandel tillsvidare per 10 000 invånare över 15 år",
    tooltip: "Rapporten visar antal tobakstillstånd detaljhandel tillsvidare. Antal tillstånd visas även omräknat till antal tillstånd per 10 000 invånare 15 år eller äldre.",
    sortOrder: 1
  },
  {
    key: 2,
    label: "Antal tobakstillstånd detaljhandel viss tid",
    tooltip: "Rapporten visar antal tobakstillstånd detaljhandel viss tid som har varit aktuella någon gång under vald period.",
    sortOrder: 2
  },
  {
    key: 3,
    label: "Antal tobakstillstånd partihandel",
    tooltip: "Rapporten visar antal tobakstillstånd partihandel.",
    sortOrder: 3
  },
];

export const uploadKommunbefolkningFile = async (
  formData: FormData
): Promise<IMainBaseResponse<number>> => {
  return await PostFile('/kommunbefolkning', formData);
};

export const getmostrecentyearofimport = async (): Promise<IMainBaseResponse<string>> => {
  return await Get('/kommunbefolkning/getmostrecentyearofimport');
};

export const exportStatistikServeringstider = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/serveringstiderexcel`,
    filters
  );
};

export const exportStatistikOmsattningIKr = async (filters: IStatisticsFilterRapportPeriodId) => {
  return await ExportFile<IStatisticsFilterRapportPeriodId>(
    `/statistik/statistikOmsattningIKr`, filters
  );
};

export const exportStatistikForsaljningImportExportTillvAvAlkoholdrycker = async (filters: IStatisticsFilterRapportPeriodId) => {
  return await ExportFile<IStatisticsFilterRapportPeriodId>(
    `/statistik/statistikForsaljningImportExportTillvAvAlkoholdrycker`, filters
  );
};

export const exportStatistikInkoptaVolymer = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/inkoptavolymerexcel`,
    filters
  );
};

export const exportStatistikNormalpris = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/normalpris`,
    filters
  );
};

export const exportStatistikStadigvarandeBefolkning = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/stadigvarandeserveringstillstand/befolkning`,
    filters
  );
};

export const exportStatistikStadigvarandeAllman = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/stadigvarandeserveringstillstand/allman`,
    filters
  );
};

export const exportStatistikStadigvarandeSlutna = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/stadigvarandeserveringstillstand/slutna`,
    filters
  );
};

export const exportStatistikTillfalligaBefolkning = async (filters: IStatisticsToFrom) => {
  return await ExportFile<IStatisticsToFrom>(
    `/statistik/tillfalligaserveringstillstand/befolkning`,
    filters
  );
};

export const exportStatistikTillfalligaAllman = async (filters: IStatisticsToFrom) => {
  return await ExportFile<IStatisticsToFrom>(
    `/statistik/tillfalligaserveringstillstand/allman`,
    filters
  );
};

export const exportStatistikTillfalligaSlutna = async (filters: IStatisticsToFrom) => {
  return await ExportFile<IStatisticsToFrom>(
    `/statistik/tillfalligaserveringstillstand/slutna`,
    filters
  );
};

export const exportStatistikPartihandelsTillstand = async (datum: string) => {
  return await ExportFile<string>(
    `/statistik/statistikpartihandelstillstand?datum=${datum}`,
    ""
  );
};

export const exportTekniskSprit = async (filters: IStatisticsFilterRapportPeriodId) => {
  return await ExportFile<IStatisticsFilterRapportPeriodId>(
    `/statistik/tekniskSprit`, filters
  );
};

export const exportBortfallsbeskrivning = async (filters: IStatisticsFilterRapportPeriodId) => {
  return await ExportFile<IStatisticsFilterRapportPeriodId>(
    `/statistik/bortfallsbeskrivning`, filters
  );
};

export const exportStatistikTobakTillsvidare = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/tobakstillstanddetaljhandel/tillsvidare/befolkning`,
    filters
  );
};

export const exportStatistikTobakvisstid = async (filters: IStatisticsToFrom) => {
  return await ExportFile<IStatisticsToFrom>(
    `/statistik/tobakstillstandDetaljhandel/visstid`,
    filters
  );
};

export const exportStatistikTobakPartihandel = async (filters: IStatisticsFilter) => {
  return await ExportFile<IStatisticsFilter>(
    `/statistik/tobakstillstandpartihandel`,
    filters
  );
};