import React from "react";
import { TextFieldProps, TextField,styled } from "@mui/material";

function InputTextField(props: TextFieldProps) {
  return (
    <TextField
      {...props} />
  )
}

export default InputTextField;


export const TextFieldStyledForReports = styled(TextField)({
  '& input:disabled':{
    borderRadius:"inherit",
    background:"#ebebeb",
    "-webkit-text-fill-color":"rgb(0 0 0)"
  }
})

export const TextFieldStyledForPrevReports = styled(TextField)({
  '& input:disabled':{
    borderRadius:"inherit",
    background:"#f5e8c2",
    "-webkit-text-fill-color":"rgb(0 0 0)"
  }
})