export interface ISearchBase<T> {
  sidoNummer?: number | any;
  sidoStorlek?: number | any;
  sorteringsNamn?: string | number | symbol;
  sorteringsOrdning?: SortOrder;
  filter: T;
}

export enum SortOrder {
  Stigande = "Stigande",
  Fallande = "Fallande",
}

export interface ISearchResultBase<T> {
  sokResultat: T[];
  totaltAntalTraffar: 0;
}
