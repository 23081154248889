import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import React, { ReactNode } from "react";

export interface Data {
  value: any;
  name: string;
  id?: number;
}

interface IMiniSelect {
  select?: SelectProps;
  data: Data[];
  selected: Data;
  label?: string;
  disabled: boolean;
  onChange: (event: SelectChangeEvent, child: ReactNode) => void;
  ariaLabel?: string;
  ariaLive?: string;
}

export const MiniSelect = (select: IMiniSelect) => {
  return (
    <FormControl variant="standard" disabled={select.disabled}>
      <Select
        onChange={select.onChange}
        value={select.selected.value}
        label={select.label ?? "emptyLabel"}
        size="small"
        name="tableSelect"
        aria-label={select.ariaLabel}
        aria-live="polite"
      >
        {select.data.map((data, index) => {
          return (
            <MenuItem key={data.id ?? index} value={data.value}>
              {data.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
