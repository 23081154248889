import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";

import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export default function BeraknadTillverkning(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.beraknadSpritHog,
    props.values.beraknadSpritLag,
  ];

  
  useEffect(() => {
    sumAllValues();
  }, []);

  const sumAllValues = () => {
    CalcSum(props, "beraknadTotalt", values);
  };

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Beräknad tillverkning nästkommande period (hela liter)
          </Typography>
          <Typography variant="caption">
            Beräknad tillverkning ska avse året efter redovisningsperioden (se
            även anvisningen som finns längst upp på sidan).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
            <Grid item>
                <Typography variant="h6" component="h3">Spritdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "beraknadSpritLag",
                  props.values.beraknadSpritLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["beraknadSpritHog", "beraknadTotalt"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "beraknadSpritHog",
                  props.values.beraknadSpritHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["beraknadTotalt", "beraknadSpritLag"]
                )}
                </GridWithCaption>
                </Section>
                <Section>
                  <Grid item xs={12}>
                    <Grid item sx={{mt: "10px"}}>
                      <Typography variant="h6" component="h3">Summa beräknad tillverkning</Typography>
                    </Grid>
                    <Typography variant="caption">Ange 0 i summa om du inte har någonting att redovisa.</Typography>
                    </Grid>
                    <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Summa",
                  "beraknadTotalt",
                  props.values.beraknadTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled,
                  "Liter",
                  "number",
                  ["beraknadSpritHog", "beraknadSpritLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.beraknadSpritLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.beraknadSpritHog
                    )}
                    {InputComparePrevYear(
                      "Summa beräknad tillverkning",
                      prevYearObj?.beraknadTotalt
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
