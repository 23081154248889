//@ts-nocheck

import { Autocomplete, TextField } from "@mui/material";
import React, { FocusEventHandler, useEffect } from "react";
import { ITillstandsKategoriObjekt } from "../Services/PermitService";
import { GetDataBasedOnType, IData, IGroupData, InfoType } from "./selects";

interface IAutoCompleteAtr {
  infoType: InfoType;
  label: string;
  multiple: boolean;
  defaultVal: boolean;
  defaultTitle?: string | null;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (data: number | number[] | null) => void;
  error: boolean;
  helperText: string;
  id?: string;
  name: string;
  selectedData: ITillstandsKategoriObjekt[] | number[] | number;
  disabled?: boolean;
  disableClearable?: boolean;
  idsToKeep?: number[];
  required?: boolean;
}

export const AutoCompleteGroupedAtr = (props: IAutoCompleteAtr) => {
  const [data, setData] = React.useState<IGroupData[]>([]);
  //TODO: Map keys to data when editing multiple
  function mapKeysToData(keys: number | number[] | undefined): IGroupData[] {
    if (!Array.isArray(keys)) throw Error("Not expected");

    // let temp = data
    //   .filter((d) => keys.includes(d.key))
    //   .map((d) => {
    //     return {
    //       key: d.key,
    //       label: d.label,
    //       groupId: d.groupId,
    //       groupName: d.groupName,
    //     }
    //   });

    // console.log("temp", temp)
    // return temp

    let datas: IGroupData[] | null = keys.map((x) => {
      let k = data.find((p) => p.key === x);
      if (k !== undefined) {
        return {
          key: k.key,
          label: k.label,
          groupId: k.groupId,
          groupName: k.groupName,
        };
      } else return null!;
    });
    if (datas !== null) {
      return datas;
    }

    throw Error("No matching targets");
  }

  function mapKeyToData(key: number | number[]): IGroupData | null {
    // if (Array.isArray(key)) throw Error("Not expected");
    // if (!Array.isArray(key)) throw Error("Not expected");

    if (key?.length > 0 && data?.length > 0) {
      return data.find((p) => {
        if (Array.isArray(key) && typeof key === "object" && isNaN(key[0])) {
          return p.key === key[0].id
        }
        return p.key === key[0]
      });
    }

    return null;
  }

  useEffect(() => {
    GetDataBasedOnType(
      props.infoType,
      props.defaultTitle ?? undefined,
      props.defaultVal
    ).then((p) => {
      if (props.idsToKeep === undefined) {
        setData(p as IGroupData[]);
      } else {
        let k = p.filter((item) => props.idsToKeep?.includes(item.key));
        setData(k as IGroupData[]);
      }
    });
  }, [props.idsToKeep]);

  const returnValues = (data: IGroupData[] | IGroupData | null) => {
    if (props.multiple && Array.isArray(data)) {
      return data.map((x) => x.key);
    } else if (!props.multiple && !Array.isArray(data) && data !== null) {
      return [data.key];
    } else if (!props.multiple && !Array.isArray(data) && data === null) {
      return 0;
    } else return null;
  };
  if (props.multiple) {
    return (
      <Autocomplete
        value={data.length !== 0 ? mapKeysToData(props.selectedData) : []}
        id={props.id}
        multiple={true}
        options={data}
        groupBy={(option) => option.groupName}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(opt, val) => {
          return opt.key === val.key;
        }}
        onChange={(ev, data) => {
          props.onChange(returnValues(data));
        }}
        disabled={props.disabled}
        disableClearable={props.disableClearable}
        renderInput={(params) => (
          <TextField
            onBlur={props.onBlur}
            name={props.name}
            helperText={props.helperText}
            error={props.error}
            label={props.label}
            required={props.required}
            {...params}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        value={mapKeyToData(props.selectedData)}
        id={props.id}
        multiple={false}
        options={data}
        groupBy={(option) => option.groupName}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(opt, val) => {
          if (opt !== undefined && val !== undefined) {
            return opt.key === val.key;
          }
        }}
        onChange={(ev, data) => {
          props.onChange(returnValues(data));
        }}
        disabled={props.disabled}
        disableClearable={props.disableClearable}
        renderInput={(params) => (
          <TextField
            onBlur={props.onBlur}
            name={props.name}
            helperText={props.helperText}
            error={props.error}
            label={props.label}
            required={props.required}
            {...params}
          />
        )}
      />
    );
  }
};
