import { IInfo } from "../Services/infoService";

export enum SystemTyp {
  AlkT = "AlkT",
  Castor = "Castor",
  EDP = "EDP",
  LEX = "LEX",
}

interface IMap extends IInfo {
  system: SystemTyp;
}

export const SystemTypes: IMap[] = [
  {
    id: 1,
    namn: "AlkT",
    system: SystemTyp.AlkT
  },
  {
    id: 2,
    namn: "Castor",
    system: SystemTyp.Castor
  },
  {
    id: 3,
    namn: "EDP",
    system: SystemTyp.EDP
  },

  {
    id: 4,
    namn: "LEX",
    system: SystemTyp.LEX
  },
];

export const SystemTypesForAll: IMap[] = [
  {
    id: 1,
    namn: "AlkT",
    system: SystemTyp.AlkT
  },
  {
    id: 2,
    namn: "Castor",
    system: SystemTyp.Castor
  },
  {
    id: 3,
    namn: "EDP",
    system: SystemTyp.EDP
  },

  {
    id: 4,
    namn: "LEX",
    system: SystemTyp.LEX
  },
];

export const SystemList = (systemTyps: SystemTyp[]) => {
  if (systemTyps === null || systemTyps === undefined) return 0;
  
  return (
    SystemTypesForAll.find(
      (x) =>
      systemTyps.every((p) => x.system.includes(p)) && x.system.length === systemTyps.length
    )?.id ?? 0
  );
};

export const GetCorrectSystemBasedOnKey = (key: any) => {
  console.log(key);
  return SystemTypesForAll.find((x) => x.id === key)?.system ?? [];
};



