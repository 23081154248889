import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export default function Import(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.spritImportLag,
    props.values.spritImportHog,
    props.values.vinImportLag,
    props.values.vinImportMellan,
    props.values.vinImportHog,
    props.values.andraJastaImportLag,
    props.values.andraJastaImportMellan,
    props.values.andraJastaImportHog,
    props.values.starkOlImportLag,
    props.values.starkOlImportHog,
    props.values.alkoPrepImport,
    props.values.importInforsel,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);
  
  const sumAllValues = (): void => {
    CalcSum(props, "importTotalt", values);
  };

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Import/införsel (hela liter)
          </Typography>
          <Typography variant="caption">
            Import/införsel gäller både införsel från annat EU-land som import från tredje land (se även anvisningen längst upp på sidan).
          </Typography>
        </Grid>
        <>
        {props.values.rapportTypId == 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
              {InputWithDefaults<IUpdateRapportDetails>(
              "TEKNISK SPRIT",
              "importInforsel",
              props.values.importInforsel,
              props,
              sumAllValues,
              undefined,
              mode,
              "Mängd uttryckt i liter ren alkohol",
              "number",
              ["importInforsel"]
              )}
              </GridWithCaption>
            </Section>
          </Grid>
        </Grid>
        )}
        </>
        <>
        {props.values.rapportTypId != 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Spritdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritImportLag",
                  props.values.spritImportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritImportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritImportHog",
                  props.values.spritImportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritImportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritImportLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritImportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Vin</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinImportLag",
                  props.values.vinImportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinImportMellan", "vinImportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinImportMellan",
                  props.values.vinImportMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinImportHog", "vinImportLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinImportHog",
                  props.values.vinImportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinImportMellan", "vinImportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinImportLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinImportMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinImportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Andra jästa alkoholdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaImportLag",
                  props.values.andraJastaImportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaImportMellan", "andraJastaImportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaImportMellan",
                  props.values.andraJastaImportMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaImportLag", "andraJastaImportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaImportHog",
                  props.values.andraJastaImportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaImportMellan", "andraJastaImportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaImportLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaImportMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaImportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Starköl</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlImportLag",
                  props.values.starkOlImportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlImportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlImportHog",
                  props.values.starkOlImportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlImportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlImportLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlImportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Alkoholdrycksliknande preparat</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisningen",
                  "alkoPrepImport",
                  props.values.alkoPrepImport,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("", prevYearObj?.alkoPrepImport)}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section>
              <Grid item xs={12}>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Summa import/införsel</Typography>
              </Grid>
                <Typography variant="caption">
                  Ange 0 i summa om du inte har någonting att redovisa.
                </Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisningen",
                  "importTotalt",
                  props.values.importTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("", prevYearObj?.importTotalt)}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
        )}
</>
      </Grid>
    </Grid>
  );
}
