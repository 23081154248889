import React from "react";

import Link from "@mui/material/Link";

function changeFocus() {
  var element = document.getElementById("mainContent");
  if (element) {
    element.focus();
  }
}

function SkipNav() {

  return (
    <div id="skipnav" className="skipNav">
      <Link
        className="skipNavLink"
        onClick={() => changeFocus()}
        onKeyPress={(e) => {
          if (e.key === "Enter") changeFocus();
        }}
        tabIndex={0}
      >
        Hoppa över navigation
      </Link>
    </div>
  );
}

export default SkipNav;
