import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Box,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { InfoType } from "../../Components/selects";
import { ICreateUser, postApply } from "../../Services/UserService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import {
  GetCorrectRolesBasedOnKey,
  MapToCorrectKey,
} from "../../Utilities/roles";
import { applyUserSchemaForetag } from "./validationForetag";
import ApplyForForetagModal from "./applyforForetagModal";
import InputTextField from "../../Components/textfield";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { GetCheckBox } from "../../Components/checkbox";
import { BtnSecond } from "../../Components/buttons";

export const initialValues: ICreateUser = {
  ref: "",
  id: 0,
  fornamn: "",
  efternamn: "",
  epost: "",
  foretagsnamn: "",
  adress: "",
  orgNr: "",
  postNr: "",
  postOrt: "",
  telefon: "",
  syfte: "",
  roller: [],
  kommunIdn: [],
  system: [],
  systemId: 0,
  lanId: 0,
  selectedOption: 0,
  gdpr: false,
  kreditupplysningsForetag: false,
};

const kreditForetag = "kreditupplysningsForetag";
const gdprCheck = "gdpr";

const ApplyForForetag: React.FC = () => {
  const captchaRef = useRef(null);
  const [token, setToken] = useState("");
  const [isOmfattning, setShowOmfattning] = useState(false);
  const [showForm, setShowForm] = useState(true);
  let navigate = useNavigate();

  return (
    <Grid container spacing={2} mt={0}>
      <Grid item xs={12}>
        
        <Formik
          validationSchema={applyUserSchemaForetag}
          initialValues={{ ...initialValues, epost2: "" }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            values = {
              ...values,
            };
            if (values.gdpr !== true) {
              setSubmitting(false);
              setStatus({
                sent: false,
                msg: "Du måste godkänna personuppgiftshanteringen",
                severity: "error",
              });
            } else if (!token) {
              setSubmitting(false);
              setStatus({
                sent: false,
                msg: "Du måste kryssa i att du är en människa",
                severity: "error",
              });
            } else {
              setSubmitting(true);
              postApply({ captchaToken: token, data: { ...values } })
                .then((res) => {
                  setSubmitting(false);
                  resetForm();
                  setStatus({
                    sent: true,
                    title: "Ansökan är registrerad",
                    msg: "Din ansökan om registerutdrag är nu skickad till Folkhälsomyndigheten. Vi återkommer med beslut i ärendet. Normal handläggningstid är två veckor. Längre handläggningstider kan förekomma.",
                    severity: "success",
                  });
                  setShowForm(false);
                })
                .catch((error) => {
                  console.log(error);
                  setStatus({
                    sent: true,
                    msg: JSON.stringify(error.data),
                    title: "Ett fel uppstod",
                    severity: "error",
                  });
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            handleBlur,
            touched,
            setFieldValue,
            isSubmitting,
            status,
            setStatus,
          }) => (
            <>
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <ApplyForForetagModal />
                  </Box>
                  <FormControl
                    fullWidth
                    error={evalErrors(errors, touched, "gdpr")}
                  >
                    <GetCheckBox
                      checked={values.gdpr ?? false}
                      label="Jag har läst om hur Folkhälsomyndigheten hanterar personuppgifter"
                      mainLabel=""
                      name="godkann"
                      onChange={(e, v) => setFieldValue("gdpr", v)}
                    />
                    <FormHelperText>
                      {getErrorText(errors, touched, "gdpr")}
                    </FormHelperText>
                  </FormControl>
                  <Divider />
                </Grid>



                  <Grid item xs={12}>
                    <Typography variant="h4" component="h2">
                      Ansökan om användarkonto för registerutdrag
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {showForm && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="caption" component="p">
                                Fält markerade med{" "}
                                <abbr title="Obligatoriskt fält">*</abbr> är
                                obligatoriska
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="orgNr"
                                    label="Organisationsnummer"
                                    onChange={handleChange}
                                    value={values.orgNr}
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="foretagsnamn"
                                    label="Företag"
                                    onChange={handleChange}
                                    value={values.foretagsnamn}
                                    onBlur={handleBlur}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "foretagsnamn"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "foretagsnamn"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="fornamn"
                                    label="Förnamn kontaktperson"
                                    onChange={handleChange}
                                    value={values.fornamn}
                                    onBlur={handleBlur}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "fornamn"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "fornamn"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="efternamn"
                                    label="Efternamn kontaktperson"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.efternamn}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "efternamn"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "efternamn"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="epost"
                                    label="E-postadress"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.epost}
                                    error={evalErrors(errors, touched, "epost")}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "epost"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="epost2"
                                    label="Bekräfta e-postadress"
                                    onChange={handleChange}
                                    value={values.epost2}
                                    onBlur={handleBlur}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "epost2"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "epost2"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="adress"
                                    label="Adress"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.adress}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "adress"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "adress"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="postNr"
                                    label="Postnummer"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.postNr}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "postNr"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "postNr"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="postOrt"
                                    label="Postort"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.postOrt}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "postOrt"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "postOrt"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputTextField
                                    fullWidth
                                    name="telefon"
                                    label="Telefon"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.telefon}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "telefon"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "telefon"
                                    )}
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <AutoCompleteAtr
                                      defaultVal={false}
                                      infoType={InfoType.rollerForetag}
                                      multiple={false}
                                      label="Roll"
                                      name="roller"
                                      onChange={(data) => {
                                        setFieldValue(
                                          "roller",
                                          GetCorrectRolesBasedOnKey(data)
                                        );
                                        setShowOmfattning(true);
                                      }}
                                      onBlur={handleBlur}
                                      selectedData={MapToCorrectKey(
                                        values.roller
                                      )}
                                      error={evalErrors(
                                        errors,
                                        touched,
                                        "roller"
                                      )}
                                      helperText={getErrorText(
                                        errors,
                                        touched,
                                        "roller"
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormControl
                                    component="fieldset"
                                    margin="normal"
                                  >
                                    <FormLabel focused={false}>
                                      <Typography variant="h4" component="h3">
                                        Kreditupplysningsföretag:
                                      </Typography>
                                    </FormLabel>

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          name="kreditupplysningsForetag"
                                          value={
                                            values.kreditupplysningsForetag &&
                                            values.kreditupplysningsForetag.toString()
                                          }
                                          onChange={(e, checked) => {
                                            setFieldValue(
                                              kreditForetag,
                                              checked
                                            );
                                          }}
                                        />
                                      }
                                      label={
                                        "Ja, mitt företag är ett kreditupplysningsföretag"
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <InputTextField
                                multiline={true}
                                minRows={5}
                                fullWidth
                                name="syfte"
                                label="Syfte med ansökan"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.syfte}
                                error={evalErrors(errors, touched, "syfte")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "syfte"
                                )}
                                required
                                aria-required="true"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="caption"
                                component="p"
                                mb={1}
                              >
                                Fält markerade med * är obligatoriska
                              </Typography>
                              <HCaptcha
                                onVerify={setToken}
                                sitekey={
                                  process.env
                                    .REACT_APP_HCAPTCHA_SITE_KEY as string
                                }
                                languageOverride="sv"
                                ref={captchaRef}
                              />
                            </Grid>
                            
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                              mt={1}
                            >
                              {showForm && (
                                <Button
                                  color="primary"
                                  title="skicka ansökan"
                                  type="submit"
                                  size="large"
                                  variant="contained"
                                  disabled={isSubmitting}
                                >
                                  Skicka in ansökan
                                </Button>
                              )}
                              <BtnSecond onClick={() => navigate("/")}>
                                Tillbaka
                              </BtnSecond>
                              {isSubmitting && <CircularProgress />}
                            </Stack>
                          </Grid>
                          {ShowAlertEval(status) && (
                            <Grid item xs={12}>
                              <ShowAlert
                                onClose={() => {
                                  setStatus(null);
                                }}
                                severity={status.severity}
                                alertTitle={status.title}
                                alertText={status.msg}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
export default ApplyForForetag;
