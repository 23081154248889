import React from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type CustomModalProps = {
  children: JSX.Element,
  handleCloseModal: any,
  openModal: boolean,
  title: string,
}

export default function CustomModal({ children, handleCloseModal, openModal, title }: CustomModalProps) {

  const StyledModal = styled(Modal)(({ theme }) => ({
    margin: theme.spacing(4),
    top: "10vh",
    maxHeight: "80vh",
    overflow: "auto",
  }));

  const ModalBox = styled(Box)(({ theme }) => ({
    position: "relative",
    height: "auto",
    margin: "0 auto",
    maxWidth: 680,
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(10),
  }));

  const CloseModalIcon = styled(CloseIcon)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    padding: theme.spacing(1),
    width: 24,
    height: 24,
    color: theme.palette.primary.contrastText,
    background: theme.palette.secondary.light,
    borderRadius: "50%",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  }));

  return (
    <StyledModal
      aria-labelledby="custom-modal-title"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableScrollLock={true}
    >
      <Fade in={openModal}>
        <ModalBox id="custom-modal-description">
          <CloseModalIcon onClick={handleCloseModal} />
          <Typography
            id="custom-modal-title"
            variant="h1"
            component="h1"
            mb={3}
          >
            { title }
          </Typography>
          { children }
        </ModalBox>
      </Fade>
    </StyledModal>
  )
};
