import { AxiosRequestConfig } from "axios";
import { TypeOf } from "yup";
import apiAtrClient, {
  ExportFile,
  Get,
  GetFile,
  GetFiles,
  Post,
  Put,
} from "../Utilities/axiosBase";
import { IRecordSearchFilter } from "../Views/Report/records";
import { RapportTyper, Rapporttyper } from "../Views/Report/ReportForm/formlogic";
import { IMainBaseResponse } from "./base";
import { ISearchBase, ISearchResultBase } from "./Searchbase";

export interface ILoginRapportInlamnare {
  passwordBase64: string;
  orgNr: string;
}

export enum RapportStatus {
  Skapad = "Skapad",
  Makulerad = "Makulerad",
  Inkommen = "Inkommen",
  Granskas = "Granskas",
  Klar = "Klar",
}

export enum RapportStatusId {
  Skapad=1,
  Makulerad=2,
  Inkomen=3,
  Granskas=4,
  Klar=5
}

export interface IRapportPeriodGet {
  id: number;
  namn: string;
  groupId: number | null;
  groupName: string | null;
}

export interface IMissingReports {
  periodId: number;
  period: string;
  rapportTyper: IRapportTyperSummary[];
}

export interface IRapportTyperSummary {
  rapportTypId: number;
  name: string;
  missingReports: number;
}

export interface IReportData {
  andradAv: string;
  godkandAv: string | null | undefined;
  godkandTid: string | null | undefined;
  foregaendeArsRapportId: number | null;
  id: number;
  losenord: string;
  objektOchOrgDetaljer: IObjektOchOrgDetaljer;
  kontaktPersonEpost: string;
  kontaktPersonNamn: string;
  kontaktPersonTelefon: string;
  rapportPeriodGiltigFrom: string;
  rapportPeriodNamn: string;
  rapportperiodId: number;
  rapportRegistreradTid: string | null;
  rapportStatusNamn: string;
  rapportTypId: number;
  rapportTypNamn: string;
  registreradAv: string | null;
  registreradTid: string | null;
  registreradViaWebb: string | null;
  tillstandsId: number;
  relateradeRapporter: IRelateradeRapporter[] | null;
  restaurangRapportDetaljer?: IRestaurangRapportDetaljer | null;
  tekniskSpritRapportDetaljer?: ITekniskSpritRapportDetaljer | null;
  forsaljningTillverkningRapportDetaljer?: IForsaljningTillverkningRapportDetaljer | null;

  uppgiftslamnare?: string | null;
  handlaggarkommentar?: string | null;
}

export interface IObjektOchOrgDetaljer {
  objektAdress: string;
  objektNamn: string;
  objektNr: string;
  objektPostNr: string;
  objektPostOrt: string;
  orgNr: string;
  havareNamn: string;
  havareAdress: string;
  havarePostOrt: string;
  havarePostNr: string;
  tillstandshavareNamn: string;
}

export interface IRelateradeRapporter {
  id: number;
  rapportPeriodNamn: string;
  rapportTypId: number;
}

export interface ITekniskSpritRapportDetaljer {
  forsaljnTillverkare?: number | null;
  forsaljningAndraUpplagshavare?: number | null;
  forsaljnSkattebefriade?: number | null;
  forsaljningUniversitetHogskolor?:	number | null;
  forsaljningSjukhus?: number | null;
  forsaljningApotek?: number | null;
  forsaljningOvrigt?: number | null;
  summaForsaljning?: number | null;
  importInforsel?: number | null;
  exportUtforsel?: number | null;
  tillverkning?: number | null;

  alkPrepEjAvseddaIntas?: boolean | null;
  alkPrepLivsmedel?: boolean | null;
  lakemedel?: boolean | null;
  ovrigtTekniskIndustriellt?: boolean | null;	
  vetenskapligt?: boolean | null;
}

export interface IRestaurangRapportDetaljer {
  normalPrisSprit?: number | null;
  normalPrisVin?: number | null;
  normalPrisStarkOl?: number | null;
  normalPrisOl?: number | null;
  normalPrisAndraJastaAlkoholdrycker?: number | null;
  normalPrisAlkoPrep?: number | null;
  normalPrisLattOl?: number | null;
  normalVolymStarkOl?: number | null;
  normalVolymOl?: number | null;
  normalVolymAndraJastaAlkoholdrycker?: number | null;
  normalVolymAlkoPrep?: number | null;
  antalLiterEgenKryddadSnaps?: number | null;
  omsMat?: number | null;
  omsSprit?: number | null;
  omsVin?: number | null;
  omsStarkOl?: number | null;
  omsOl?: number | null;
  omsAndraJastaAlkoholdrycker?: number | null;
  omsAlkoPrep?: number | null;
  omsOvrigt?: number | null;
  omsEntre?: number | null;
  omsTotalt?: number | null;
  inkopSpritLag?: number | null;
  inkopSpritHog?: number | null;
  inkopVinLag?: number | null;
  inkopVinMellan?: number | null;
  inkopVinHog?: number | null;
  inkopOlLag?: number | null;
  inkopOlHog?: number | null;
  inkopAndraJastaAlkoholdryckerLag?: number | null;
  inkopAndraJastaAlkoholdryckerMellan?: number | null;
  inkopAndraJastaAlkoholdryckerHog?: number | null;
  inkopAlkoPrepLag?: number | null;
  inkopAlkoPrepHog?: number | null;
  inkopTotalt?: number | null;
  datum?: string | null;
  uppgiftslamnare?: string | null;
  kontaktPersonNamn?: string | null;
  kontaktPersonTelefon?: string | null;
  kontaktPersonEpost?: string | null;
  footerText?: string | null;
}

export interface IForsaljningTillverkningRapportDetaljer {
  spritSaljSystembolagetLag?: number | null;
  spritSaljSystembolagetHog?: number | null;
  spritSaljPartihandlareLag?: number | null;
  spritSaljPartihandlareHog?: number | null;
  spritSaljInnehavareLag?: number | null;
  spritSaljInnehavareHog?: number | null;
  vinSaljSystembolagetLag?: number | null;
  vinSaljSystembolagetMellan?: number | null;
  vinSaljSystembolagetHog?: number | null;
  vinSaljPartihandlareLag?: number | null;
  vinSaljPartihandlareMellan?: number | null;
  vinSaljPartihandlareHog?: number | null;
  vinSaljInnehavareLag?: number | null;
  vinSaljInnehavareMellan?: number | null;
  vinSaljInnehavareHog?: number | null;
  andraJastaSaljSystembolagetLag?: number | null;
  andraJastaSaljSystembolagetMellan?: number | null;
  andraJastaSaljSystembolagetHog?: number | null;
  andraJastaSaljPartihandlareLag?: number | null;
  andraJastaSaljPartihandlareMellan?: number | null;
  andraJastaSaljPartihandlareHog?: number | null;
  andraJastaSaljInnehavareLag?: number | null;
  andraJastaSaljInnehavareMellan?: number | null;
  andraJastaSaljInnehavareHog?: number | null;
  starkOlSaljSystembolagetLag?: number | null;
  starkOlSaljSystembolagetHog?: number | null;
  starkOlSaljPartihandlareLag?: number | null;
  starkOlSaljPartihandlareHog?: number | null;
  starkOlSaljInnehavareLag?: number | null;
  starkOlSaljInnehavareHog?: number | null;
  alkoPrepSaljSystembolaget?: number | null;
  alkoPrepSaljPartihandlare?: number | null;
  alkoPrepSaljInnehavare?: number | null;
  saljTotalt?: number | null;
  spritImportLag?: number | null;
  spritImportHog?: number | null;
  vinImportLag?: number | null;
  vinImportMellan?: number | null;
  vinImportHog?: number | null;
  andraJastaImportLag?: number | null;
  andraJastaImportMellan?: number | null;
  andraJastaImportHog?: number | null;
  starkOlImportLag?: number | null;
  starkOlImportHog?: number | null;
  alkoPrepImport?: number | null;
  importTotalt?: number | null;
  spritExportLag?: number | null;
  spritExportHog?: number | null;
  vinExportLag?: number | null;
  vinExportMellan?: number | null;
  vinExportHog?: number | null;
  andraJastaExportLag?: number | null;
  andraJastaExportMellan?: number | null;
  andraJastaExportHog?: number | null;
  starkOlExportLag?: number | null;
  starkOlExportHog?: number | null;
  alkoPrepExport?: number | null;
  exportTotalt?: number | null;
  spritTillverkningLag?: number | null;
  spritTillverkningHog?: number | null;
  vinTillverkningLag?: number | null;
  vinTillverkningMellan?: number | null;
  vinTillverkningHog?: number | null;
  andraJastaTillverkningLag?: number | null;
  andraJastaTillverkningMellan?: number | null;
  andraJastaTillverkningHog?: number | null;
  starkOlTillverkningLag?: number | null;
  starkOlTillverkningHog?: number | null;
  folkOlTillverkning?: number | null;
  tillverkningTotalt?: number | null;
  beraknadSpritLag?: number | null;
  beraknadSpritHog?: number | null;
  beraknadAr?: number | null;
  beraknadTotalt?: number | null;
}

export interface IMissing {
  rapporttypId: number;
  rapporterSomInteHarGenererats: number;
}

export interface IUpdateRapportDetails
  extends IRestaurangRapportDetaljer, IForsaljningTillverkningRapportDetaljer, ITekniskSpritRapportDetaljer {
  rapportId?: number | null;
  rapportTypId?: number | null;
  godkann?: boolean | null;
  objektOchOrgDetaljer?: IObjektOchOrgDetaljer;
  handlaggarkommentar?:string|null
}

export interface IUpdateTekniskSpritRapportDetails
  extends IRestaurangRapportDetaljer, IForsaljningTillverkningRapportDetaljer, ITekniskSpritRapportDetaljer {
  rapportId?: number | null;
  rapportTypId?: number | null;
  godkann?: boolean | null;
  objektOchOrgDetaljer?: IObjektOchOrgDetaljer;
  handlaggarkommentar?:string|null
}

export interface IReportSearchFilter {
  rapportId: number;
  rapportTypId: number;
  rapportPeriodId: number;
  rapportStatusId: number;
  orgNr: string;
  objektNr: string;
  tillstandsGivareId: number;
  lanId: number;
  kommunId: number;
  jamforOmsattningVolym: boolean;
  jamforForegaendeAr: boolean;
  kommentarFinns: boolean;
}

export interface IReportSearchResult {
  id: number;
  rapportTypId: number;
  orgNr: string;
  tillstandshavare: string;
  objektNr: string;
  serveringsstalle: string;
  statusId: string;
  status: string;
  genereradDatum: string;
  inkommen: string;
  registreradViaWebb: boolean;
  rapportTypNamn: string;
  klar: string;
  skapad: string;
  //Extra
  avvikelseOmsSprit: number | null;
  avvikelseOmsVin: number | null;
  avvikelseOmsAndraJastaAlkoholdrycker: number | null;
  avvikelseOmsStarkol: number | null;
  avvikelseOmsAlkoPrep: number | null;
  avvikelseForsaljning: number | null;
  avvikelseImport: number | null;
  avvikelseExport: number | null;
  avvikelseTillverkning: number | null;
  avvikelseBeraknadTillverkning: number | null;
  orimligRestaurang: string | null;
  orimligForsaljningTillverkning: string | null;
  rapportPeriodNamn: string;
  rapportPeriodId: number;
}

export interface IKommentar {
  id:number
  andradAv:string;
  andrad:string;
  anvandareNamn:string;
  fullstandigtNamn:string;
  kommentar:string
}

export interface IKommentarCreate {
  kommentar: string
  rapportId: number
}

export interface IKommentarActionGet {
  [key: string]: any;
  rapportId:number | string;
  rapporttypString:string 
  kommentar:string;
  anvandareNamn:string;
  kommentardatum:string;
  kommunString:string;
  objektNr:string;
  organisationsNr:string;
  rapportPeriodString:string;
}

export interface IUpdateRestaurantReportStatus {
  id: number;
  status:
    | "Skapad"
    | "Makulerad"
    | "Inkommen"
    | "OrimligEllerFelRegistrerad"
    | "Godkand";
}

const rapportBase = "rapport";

export const getKommentarerAction = async():Promise<IMainBaseResponse<IKommentarActionGet[]>> => {
  return await Get<IKommentarActionGet[]>(`${rapportBase}/kommentareraction`);
}

export const postKommentar = async(kommentar:IKommentarCreate) : Promise<IMainBaseResponse<IKommentar>> => {
  return await Post<IKommentarCreate,IKommentar>(`${rapportBase}/kommentar`,kommentar);
}

export const getKommentarer = async(rapportId:number) : Promise<IMainBaseResponse<IKommentar[]>> => {
  return await Get<IKommentar[]>(`${rapportBase}/kommentarer?rapportId=${rapportId}`)
}

export const getRapportPeriod = async (): Promise<
  IMainBaseResponse<IRapportPeriodGet[]>
> => {
  return await Get<IRapportPeriodGet[]>(`${rapportBase}/rapportperiod`);
};

export const createPeriod = async (
  year: number
): Promise<IMainBaseResponse<IRapportPeriodGet>> => {
  return await Post(`${rapportBase}/rapportperiod/${year}`, null);
};

export const createReports = async (
  year: number,
  rapporttyp: number
): Promise<IMainBaseResponse<any>> => {
  return await Post(`${rapportBase}/${year}/${rapporttyp}`, null);
};

export const getMissingReports = async (
  periodId: number
): Promise<IMainBaseResponse<IMissingReports>> => {
  return await Get(`${rapportBase}/missingrapportinperiod/${periodId}`);
};

export const getReportId = async (
  password: string | undefined
): Promise<IMainBaseResponse<IReportData>> => {
  return await Get(`${rapportBase}/getreportid?passwordBase64=${password}`);
};

export function GetUpdateRequest(
  rapportTypId: number | null | undefined,
  data: IUpdateRapportDetails
): Promise<IMainBaseResponse<number>> {
  switch (rapportTypId) {
    case Rapporttyper.StatistikrapportForsaljningTillverkning:
      return updateReportStatistikforsaljningTillverkning(data);
    case Rapporttyper.Restaurangrapport:
      return updateReportRestaurangRapport(data);
    case Rapporttyper.StatistikrapportTekniskSprit:
      return updateReportTekniskSprit(data);
    default:
      throw Error("No funciton matches the rapporttyp Id");
  }
}

export const updateReportTekniskSprit = async (
  data: ITekniskSpritRapportDetaljer
): Promise<IMainBaseResponse<number>> => {
  console.log(data + "Anna" );
  return await Put(`${rapportBase}/Tekniskspritrapport`, data);
};

export const updateReportRestaurangRapport = async (
  data: IRestaurangRapportDetaljer
): Promise<IMainBaseResponse<number>> => {
  return await Put(`${rapportBase}/restaurangrapport`, data);
};

export const updateReportStatistikforsaljningTillverkning = async (
  data: IForsaljningTillverkningRapportDetaljer
): Promise<IMainBaseResponse<number>> => {
  return await Put(`${rapportBase}/forsaljningtillverkning`, data);
};

export const searchReports = async (
  filters: ISearchBase<IReportSearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<IReportSearchResult>>> => {
  return await Post<
    ISearchBase<IReportSearchFilter>,
    ISearchResultBase<IReportSearchResult>
  >(`${rapportBase}/search`, filters);
};

export const getReportById = async (
  id: string,
  prevYearId: boolean = false
): Promise<IMainBaseResponse<IReportData>> => {
  return await Get(`${rapportBase}/${id}/?prevYearId=${prevYearId}`);
};

export const getMissing = async (
  rapportperiodId: number
): Promise<IMainBaseResponse<IMissing[]>> => {
  return await Get(
    `${rapportBase}/getsummaryreportscreatedandnot?rapportPeriodId=${rapportperiodId}`
  );
};

export const getRapportPDF = async (id: number) => {
  return await GetFile(`${rapportBase}/rapportpdf/${id}`);
};

export const setRapportStatus = async (id: number, statusId: number) => {
  return await Put(`${rapportBase}/status?id=${id}&statusId=${statusId}`, null);
};

export const updateRestaurantReportStatus = async (
  data: IUpdateRestaurantReportStatus
): Promise<IMainBaseResponse<number>> => {
  return await Put(
    `${rapportBase}/status?id=${data.id}&statusId=${data.status}`,
    data
  );
};

export const resetRestaurantReportStatus = async (
  data: IUpdateRestaurantReportStatus
): Promise<IMainBaseResponse<number>> => {;
  return await Put(
    `${rapportBase}/id?id=${data.id}`,
    data
  );
};

export interface IStatusReportGet {
  reportType: number;
  reportPeriodId: number;
}

interface IStatusReportOptions {
  key: number;
  label: string;
}

export const StatusReportOptions: IStatusReportOptions[] = [
  {
    key: 1,
    label: "Status restaurangrapport",
  },
  {
    key: 2,
    label: "Status statistikrapporter",
  },
];

export async function GetStatusReportFile(
  url: string,
  reportData: { rapportPeriodId: number }
) {
  const extraConf: AxiosRequestConfig = {
    headers: {
      "Content-Disposition": "attachment; filename=rapport.pdf",
    },
    responseType: "arraybuffer",
  };

  try {
    const { data, status } = await apiAtrClient.post(url, reportData, {
      ...extraConf,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getStatistikRestaurangrapportering = async (
  reportData: number,
  rapportPeriodId: number
) => {
  ;
  if (reportData === Rapporttyper.Restaurangrapport) {
    return await GetStatusReportFile(
      "/statistik/restaurangrapportering/",
      {
        rapportPeriodId:rapportPeriodId
      }
    );
  } else if(reportData === Rapporttyper.StatistikrapportForsaljningTillverkning) {
    return await GetStatusReportFile(
      "/statistik/statistikrapportering/",
      {
        rapportPeriodId:rapportPeriodId
      });
  } else if(reportData === Rapporttyper.StatistikrapportTekniskSprit) {
    return await GetStatusReportFile(
    "/statistik/tekniskSpritrapportering/",
    {
      rapportPeriodId:rapportPeriodId
    });
}
};

export const printAllPdf = async (
  filters: ISearchBase<IReportSearchFilter>
) => {
  return await GetFiles(`${rapportBase}/rapporterpdf`, filters);
};

export const exportRapporterRegistreraExcel = async (
  filters: IReportSearchFilter
) => {
  return await ExportFile(`${rapportBase}/exportexcelregisrera/`, filters);
};

export const exportexcel = async (filter: ISearchBase<IReportSearchFilter> ) => {
  return await ExportFile(
    `${rapportBase}/searchexportexcel`,
    filter
  );
};

export const downloadOldDataExcel = async (
  ids:number[]
) => {
  return await ExportFile(`${rapportBase}/exportexcelids/`, ids);
};

export const approveSelected = async (
  ids: number[]
): Promise<IMainBaseResponse<number>> => {
  return await Put(`${rapportBase}/approveselected`, ids);
};
