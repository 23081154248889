import React, { useState } from "react";
import {
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
  Popper,
  IconButton,
  Stack,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { Box } from "@mui/system";
import {
  exportStatistikTobakPartihandel,
  exportStatistikTobakTillsvidare,
  exportStatistikTobakvisstid,
  IStatisticsFilter,
  IStatisticsToFrom,
  ITobakStatistics,
  StatisticsAreas,
  StatisticsGrouping,
  TobaccoStatisticsReportTypeOptions,
} from "../../Services/StatisticsService";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { DatePickerCustom } from "../../Components/datePicker";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { InfoType } from "../../Components/selects";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { StatisticsTobakSchema } from "./statisticsValidation";
import { IAlert, ShowAlert } from "../../Components/alerts";

const defaultVals: ITobakStatistics = {
  reportType: 1,
  fromDatum: "",
  tomDatum: "",
  datum: "",
  area: 1,
  lanId: [],
  kommunId: [],
  grouping: 1,
  reportPeriodId: 1
};

export default function TobaccoStatistics() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popperOpen, setPopperOpen] = React.useState<null | number>(null);
  const [status, setStatus] = useState<IAlert>({
    show: false,
    alertText: "",
    severity: "success",
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (index === popperOpen) {
      setPopperOpen(null);
      setAnchorEl(null);
    } else {
      setPopperOpen(index);
      setAnchorEl(event.currentTarget);
    }
  };

  let filter: IStatisticsFilter = {
    datum: "",
    gruppering: "",
    lanIdn: [],
    kommunIdn: [],
    rapportPeriodId: null
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
        Statistiksammanställningar tobak
        </Typography>
      </Grid>
      <Grid item xs={12}> 
        <Formik
          enableReinitialize
          validationSchema={StatisticsTobakSchema}
          initialValues={defaultVals}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            filter = {
              datum: values.datum,
              gruppering: values.grouping === 1 ? "Kommun" : "Lan",
              lanIdn: values.area === 2 ? values.lanId : [],
              kommunIdn: values.area === 3 ? values.kommunId : [],
              rapportPeriodId: values.reportPeriodId
            };
            switch (values.reportType) {
              case 1:
                return exportStatistikTobakTillsvidare(filter)
                  .then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tobakstillstand-tillsvidare.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);            
                  })
                  .catch((error) => {
                    setStatus({
                      alertText: "Något gick fel, kontrollera angivna datum eller testa igen om en stund.",
                      severity: "error",
                      show: true,
                    });
                    setSubmitting(false);
                  });
              case 2:
                let filterTobakVisstid: IStatisticsToFrom = {
                  fromDatum: values.fromDatum,
                  tomDatum: values.tomDatum,
                  gruppering: values.grouping === 1 ? "Kommun" : "Lan",
                  lanIdn: values.area === 2 ? values.lanId : [],
                  kommunIdn: values.area === 3 ? values.kommunId : [],
                };
                return exportStatistikTobakvisstid(filterTobakVisstid)
                  .then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tobakstillstand-visstid.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setStatus({
                      alertText: "Något gick fel, kontrollera angivna datum eller testa igen om en stund.",
                      severity: "error",
                      show: true,
                    });
                    setSubmitting(false);
                  });
              case 3:
                return exportStatistikTobakPartihandel(filter)
                  .then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tobakstillstand-partihandel.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setStatus({
                      alertText: "Något gick fel, kontrollera angivna datum eller testa igen om en stund.",
                      severity: "error",
                      show: true,
                    });
                    setSubmitting(false);
                  });
            }
          }}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" rowGap={2}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="alcohol-statistics-reporttype-label"
                    defaultValue={1}
                    name="alcohol-statistics-report-type-radio-group"
                    onChange={(e) =>
                      setFieldValue("reportType", +e.target.value)
                    }
                  >
                    {TobaccoStatisticsReportTypeOptions.map((data, index) => (
                      <div key={index}>
                        <div className="checkbox-container">
                          <FormControlLabel
                            value={data.key}
                            control={<Radio />}
                            label={data.label}
                            key={index}
                            checked={data.key === values.reportType}
                          />
                          <IconButton
                            aria-label={`Visa mer information om rapporttyp ${data.label}`}
                            aria-describedby="statistics-type-popper"
                            onClick={(e) => handleClick(e, index)}
                            aria-expanded={popperOpen === index}
                          >
                            <HelpIcon color="primary" />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                    <Popper
                      id="statistics-type-popper"
                      open={popperOpen !== null}
                      anchorEl={anchorEl}
                    >
                      <Box
                        sx={{
                          width: "400px",
                          border: 1,
                          p: 1,
                          bgcolor: "background.paper",
                          display: "flex",
                        }}
                      >
                        {popperOpen !== null && (
                          <Typography sx={{ mt: "0px" }}>
                            {
                              TobaccoStatisticsReportTypeOptions[popperOpen]
                                .tooltip
                            }
                          </Typography>
                        )}
                        <CloseIcon
                          aria-label="Stäng info"
                          onClick={() => (
                            setPopperOpen(null), setAnchorEl(null)
                          )}
                        />
                      </Box>
                    </Popper>
                  </RadioGroup>
                </FormControl>
                {values.reportType === 2 ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ width: "50%", mt: 3 }}
                  >
                    <DatePickerCustom
                      label="Från datum"
                      value={values.fromDatum}
                      onChange={(e) => setFieldValue("fromDatum", e)}
                      name="fromDatum"
                      onBlur={handleBlur}
                      error={evalErrors(errors, touched, "fromDatum")}
                      helperText={getErrorText(errors, touched, "fromDatum")}
                      required={true}
                    />
                    <DatePickerCustom
                      label="Till datum"
                      value={values.tomDatum}
                      onChange={(e) => setFieldValue("tomDatum", e)}
                      name="tomDatum"
                      onBlur={handleBlur}
                      error={evalErrors(errors, touched, "tomDatum")}
                      helperText={getErrorText(errors, touched, "tomDatum")}
                      required={true}
                    />
                  </Stack>
                ) : (
                  <Box sx={{ mt: 3, width: 300 }}>
                    <DatePickerCustom
                      label="Datum"
                      value={values.datum ?? null}
                      onChange={(e) => setFieldValue("datum", e)}
                      name="datum"
                      onBlur={handleBlur}
                      error={evalErrors(errors, touched, "datum")}
                      helperText={getErrorText(errors, touched, "datum")}
                      required={true}
                    />
                  </Box>
                )}
                <Stack direction="row" spacing={5}>
                  <FormControl>
                    <FormLabel id="alcohol-statistics-area-label">
                      Region
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="alcohol-statistics-area-label"
                      name="alcohol-statistics-area-radio-group"
                      defaultValue={1}
                      onChange={(e) => setFieldValue("area", +e.target.value)}
                    >
                      {StatisticsAreas.map((data, index) => (
                        <FormControlLabel
                          value={data.key}
                          control={<Radio />}
                          label={data.label}
                          key={index}
                          checked={data.key === values.area}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  {values.area === 2 && (
                    <Box sx={{ width: 300, pt: "28px" }}>
                      <AutoCompleteAtr
                        onBlur={handleBlur}
                        infoType={InfoType.lan}
                        label="Län"
                        multiple={true}
                        defaultVal={false}
                        onChange={(data) => setFieldValue("lanId", data)}
                        error={evalErrors(errors, touched, "lanId")}
                        helperText={getErrorText(errors, touched, "lanId")}
                        selectedData={values.lanId}
                        name="lanId"
                      />
                    </Box>
                  )}
                  {values.area === 3 && (
                    <Box sx={{ width: 300, pt: "28px" }}>
                      <AutoCompleteAtr
                        onBlur={handleBlur}
                        infoType={InfoType.kommuner}
                        label="Kommuner"
                        multiple={true}
                        defaultVal={false}
                        onChange={(data) => setFieldValue("kommunId", data)}
                        error={evalErrors(errors, touched, "kommunId")}
                        helperText={getErrorText(errors, touched, "kommunId")}
                        selectedData={values.kommunId}
                        name="kommunId"
                      />
                    </Box>
                  )}
                  {values.area !== 3 && (
                    <FormControl>
                      <FormLabel id="alcohol-statistics-grouping-label">
                        Gruppering
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="alcohol-statistics-grouping-label"
                        name="alcohol-statistics-grouping-radio-group"
                        defaultValue={1}
                        onChange={(e) =>
                          setFieldValue("grouping", +e.target.value)
                        }
                      >
                        {StatisticsGrouping.map((data, index) => (
                          <FormControlLabel
                            value={data.key}
                            control={<Radio />}
                            label={data.label}
                            key={index}
                            checked={data.key === values.grouping}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Stack>
                <Grid container spacing={2}>
                  <Grid item>
                    <BtnDefault disabled={isSubmitting} type="submit">
                      Ladda ner som Excel
                    </BtnDefault>
                  </Grid>
                  <Grid item>
                    <BtnSecond disabled={isSubmitting} onClick={() => resetForm()}>
                      Rensa
                    </BtnSecond>
                  </Grid>
                </Grid>
                {status.show === true && (
                  <Box sx={{ width: "40%" }} >
                  <ShowAlert
                    onClose={() => {
                      setStatus({
                        show: false,
                        alertText: "",
                        severity: "success",
                      });
                    }}
                    severity={status.severity}
                    alertTitle=""
                    alertText={status.alertText}
                  />
                  </Box>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
