import { Delete, ExportFile, Get, Post, Put } from "../Utilities/axiosBase";
import { ISearchBase, ISearchResultBase } from "./Searchbase";
import { IMainBaseResponse } from "./base";
import { KommunKontaktTyp } from "../Utilities/contactUtils";

export interface ICommonPropsLicensor {
  namn: string;
  forvaltning: string;
  postOrt: string;
}

export interface IPropsLicensorCreate {
  namn: string;
  forvaltning: string;
  postOrt: string;
  adress: string;
  postnr: string;
  kommunId: number;
}

export interface ILicensorGet extends IPropsLicensorCreate {
  id: number;
  kommunNamn:string
}
export interface ILicensorSearchResponse extends ICommonPropsLicensor {
  id: number;
  kommun: string;
}

export interface ILicensorPut extends IPropsLicensorCreate {}

export interface ILicensorPost extends IPropsLicensorCreate {}

export interface ILicensorSearchFilter {
  kommunId: number;
  namn: string;
}

export interface ILicensorCanBeCreatedResponse {
  availableTypes: KommunKontaktTyp[];
}

const licensorBase: string = "/tillstandsgivare";

export const searchLicensors = async (
  filters: ISearchBase<ILicensorSearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<ILicensorSearchResponse>>> => {
  return await Post<
    ISearchBase<ILicensorSearchFilter>,
    ISearchResultBase<ILicensorSearchResponse>
  >(`${licensorBase}/search`, filters);
};

export const getLicensor = async (
  id: number
): Promise<IMainBaseResponse<ILicensorGet>> => {
  return await Get<ILicensorGet>(`${licensorBase}/${id}`);
};

export const checkIfLicensorCandBeCreated = async (
  kommunId: number
): Promise<IMainBaseResponse<ILicensorCanBeCreatedResponse>> => {
  return await Get<ILicensorCanBeCreatedResponse>(
    `${licensorBase}/checkiflicensorcanbecreated/${kommunId}`
  );
};

export const putLicensor = async (
  licensor: ILicensorPut,
  id: number
): Promise<IMainBaseResponse<ILicensorGet>> => {
  return await Put<ILicensorPut, ILicensorGet>(
    `${licensorBase}/${id}`,
    licensor
  );
};

export const postTillstandshavare = async (
  licensor: ILicensorPost
): Promise<IMainBaseResponse<ILicensorGet>> => {
  return await Post<ILicensorPost, ILicensorGet>(`${licensorBase}`, licensor);
};

export const deleteLicensor = async (
  id: number
): Promise<IMainBaseResponse<ILicensorGet>> => {
  return await Delete<ILicensorGet>(`${licensorBase}/${id}`, id);
};

export const exportLicensor = async (filters: ISearchBase<ILicensorSearchFilter>) => {
  return await ExportFile<ISearchBase<ILicensorSearchFilter>>(
    `${licensorBase}/exportexcel`,
    filters
  );
};

export const getKommunerWithoutTillstandshavare = async (): Promise<
  IMainBaseResponse<number[]>
> => {
  return await Get(`${licensorBase}/getkommunidnwithouttillstandshavare`);
};
