import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IAlert, ShowAlert } from "../../Components/alerts";
import { BtnDefault } from "../../Components/buttons";
import { ISearchBase, SortOrder } from "../../Services/Searchbase";
import { theme } from "../../theme";
import { useFilter } from "../../Utilities/searchFilterProvider";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import {
  getLastImports,
  ILastImportsData,
  ISkvData,
  uploadSkvFile,
  uploadSkvFileDryrun,
} from "../../Services/PermitService";
import { IMainBaseResponse } from "../../Services/base";

export interface ICommonPropsRecord {
  typ: number;
  period: number;
  status: number;
  lan: number;
  kommun: number;
}

export interface IRecordSearchFilter extends ICommonPropsRecord {}

const UploadPermit: React.FC = () => {
  const [myCurrentFile, setMyCurrentFile] = useState<File | null>(null);
  const [myEndedFile, setMyEndedFile] = useState<File | null>(null);
  const [status, setStatus] = useState<IAlert>({
    show: false,
    alertText: "",
    severity: "success",
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [results, setResults] = React.useState<IMainBaseResponse<ILastImportsData>>(null!);
  const [report, setReport] = useState<ISkvData>();
  const filterProvider = useFilter();
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [uploadCompleted, setUploadCompleted] = React.useState(false);

  useEffect(() => {
    getLastImports()
    .then((response) => {
      setResults({ ...response });
      setLoading(false);
    })
    .catch((err) => {
      //TODO handle Error
      console.log("err: ", err);
      setLoading(false);
    });
  }, []);

  const checkRecords = () => {
    const filter = filterProvider.getFilter("uploadpermit");
    if (filter !== null && filter !== undefined) {
      return filter;
    } else
      return {
        filter: {
          typ: 1,
          period: 1,
          status: 1,
          lan: 1,
          kommun: 1,
        },
        sidoNummer: 1,
        sidoStorlek: getMenuItems[0].value,
        sorteringsNamn: "typ",
        sorteringsOrdning: SortOrder.Fallande,
      };
  };

  const [filter, setFilter] = React.useState<ISearchBase<IRecordSearchFilter>>(
    checkRecords()
  );
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "id",
    direction: SortOrder.Fallande,
  });

  const removeFiles = () => {
    setMyCurrentFile(null);
    setMyEndedFile(null);
    const file1 = document.querySelector('.file1') as HTMLInputElement;
    file1.value = '';
    const file2 = document.querySelector('.file2') as HTMLInputElement;
    file2.value = '';
    setUploadCompleted(false)
    setStatus({
      show: false,
      alertText: "",
      severity: "success",
    })
  }

  const uploadFileDryrun = () => {
    setIsUploading(true);
    const formData = new FormData();
    if (myCurrentFile && myEndedFile) {
      formData.append("files", myCurrentFile);
      formData.append("files", myEndedFile);
    } else {
      setStatus({
        alertText: "Du måste välja två filer att ladda upp.",
        severity: "error",
        show: true,
      });
      setIsUploading(false);
      return;
    }
    uploadSkvFileDryrun(formData)
      .then((response) => {
        setReport({ ...response.object });
        setIsUploading(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          setStatus({
            alertText:
              "Valideringen utav filen misslyckades, kontrollera filen och försök igen.",
            severity: "error",
            show: true,
          });
        } else {
          setStatus({
            alertText: `${error.data}`,
            severity: "error",
            show: true,
          });
        }
        setIsUploading(false);
      });
  };

  const uploadFile = () => {
    setIsUploading(true);
    const formData = new FormData();
    if (myCurrentFile && myEndedFile) {
      formData.append("files", myCurrentFile);
      formData.append("files", myEndedFile);
    } else {
      setStatus({
        alertText: "Du måste välja två filer att ladda upp.",
        severity: "error",
        show: true,
      });
      setIsUploading(false);
      return;
    }
    uploadSkvFile(formData)
      .then((response) => {
        setStatus({
          show: true,
          alertText: "Uppladdningen lyckades",
          severity: "success",
        });
        setReport({ ...response.object });
        getLastImports()
          .then((response) => {
            setResults({ ...response });
            setLoading(false);
            setUploadCompleted(true)
          })
          .catch((err) => {
            setLoading(false);
          });
          setIsUploading(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          setStatus({
            alertText:
              "Valideringen utav filen misslyckades, kontrollera filen och försök igen.",
            severity: "error",
            show: true,
          });
        } else {
          setStatus({
            alertText: `${error.data}`,
            severity: "error",
            show: true,
          });
        }
        setIsUploading(false);
      });
  };

  const handleCurrentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      setMyCurrentFile(newFile);
    }
  };

  const handleEndedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      setMyEndedFile(newFile);
    }
  };

  const navigateToUrl = (id: number) => {
    filterProvider.addFilter(filter, "uploadpermit");
    navigate(`${id}`);
  };

  const headers: ITableHead<any>[] = [
    {
      id: "timeOfImport",
      sortable: false,
      value: "Datum",
    },
    {
      id: "importedBy",
      sortable: true,
      value: "Användare",
    },
    {
      id: "existingUpplagshavareBeforeImport",
      sortable: false,
      value: "Upplagshavare, före",
    },
    {
      id: "existingUpplagshavareAfterImport",
      sortable: false,
      value: "Upplagshavare, efter",
    },
    {
      id: "existingRegistreradVarumottagareBeforeImport",
      sortable: false,
      value: "Registrerade varumottagare, före",
    },
    {
      id: "existingRegistreradVarumottagareAfterImport",
      sortable: false,
      value: "Registrerade varumottagare, efter",
    },
    {
      id: "existingSkattebefriadForbrukareBeforeImport",
      sortable: false,
      value: "Skattebefriade förbrukare, före",
    },
    {
      id: "existingSkattebefriadForbrukareAfterImport",
      sortable: false,
      value: "Skattebefriade förbrukare, efter",
    },
  ];

  return (
    <>
      <Backdrop
        open={isUploading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={2}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h3">
              {!report ? "Validerar filer" : "Importerar filer"}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" paragraph>
            Filimport från Skatteverket
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          {results !== null && loading === false && (
            <DefaultTable
              header={headers}
              activePage={filter.sidoNummer}
              data={mapToCellData(headers, results.object)}
              rowsPerPage={checkMenuItem(filter.sidoStorlek)}
              totalHits={Object.keys(results.object).length}
              filter={filter}
              setFilterAction={setFilter}
              setSortOptions={setSortOptions}
              sortOptions={sortOptions}
              actions={[
                {
                  action: navigateToUrl,
                  icon: ActionIcons.arrowForward,
                },
              ]}
              tableAria="10 senaste tillstånd"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Import av partihandelstillstånd för alkohol
          </Typography>
          <Box>
            <Typography variant="h3" component="p">Aktiva tillstånd</Typography>
            <label htmlFor="files">
              <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
                Välj en fil att importera
              </Typography>
              <input
                type="file"
                id="files"
                className="file1"
                name="files"
                onChange={handleCurrentFile}
              />
            </label>
          </Box>
          <Box sx={{ mt: "30px" }}>
            <Typography variant="h3" component="p">Avslutade tillstånd</Typography>
            <label htmlFor="files">
              <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
                Välj fil att importera
              </Typography>
              <input
                type="file"
                id="files"
                className="file2"
                name="files"
                onChange={handleEndedFile}
              />
            </label>
          </Box>

          <Grid item xs={12} sx={{ mt: "30px" }}>
            <Stack direction="row" spacing={2}>
              {!report && (
                <BtnDefault
                  disabled={isUploading}
                  onClick={() => {
                    uploadFileDryrun();
                  }}
                >
                  Importera
                </BtnDefault>
              )}

              {report && report?.numberOfErrors === 0 && uploadCompleted === false && (
                <BtnDefault disabled={isUploading} onClick={() => uploadFile()}>
                  Slutför importen
                </BtnDefault>
              )}

              {report && report?.numberOfErrors === 0 && uploadCompleted === false && (
                <BtnDefault
                  disabled={isUploading}
                  onClick={() => (setReport(undefined), removeFiles())}
                >
                  Avbryt
                </BtnDefault>
              )}

              {report && uploadCompleted === true && (
                <>
                <BtnDefault
                  onClick={() => (setReport(undefined), removeFiles())}
                >
                  Rensa
                </BtnDefault>
                {report?.numberOfErrors > 0 && (
                <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
                  Du behöver åtgärda felen nedan och ladda upp filerna på nytt
                  för att kunna slutföra importen.
                </Typography>
                )}
                </>
              )}

              {status.show === true && (
                <ShowAlert
                  onClose={() => {
                    setStatus({
                      show: false,
                      alertText: "",
                      severity: "success",
                    });
                  }}
                  severity={status.severity}
                  alertTitle=""
                  alertText={status.alertText}
                />
              )}
            </Stack>
          </Grid>
          {report && (
            <Grid item xs={12} sx={{ mt: "30px" }} className="recordList">
              <Typography variant="h2" component="h2" paragraph>
                Resultat - inläsning av fil
              </Typography>
              <Typography variant="h3" component="h3">Sammanfattning</Typography>
              <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Upplagshavare</Typography>
              <dl>
                <dt>
                  <Typography variant="body1">
                    Aktiva i ATR före import
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingUpplagshavareBeforeImport}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Aktiva i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingUpplagshavareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Avslutade i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledUpplagshavareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Nya</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.newUpplagshavare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Uppdaterade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.updatedUpplagshavare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Avslutade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledUpplagshavare}
                  </Typography>
                </dd>
              </dl>

              <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Registrerad varumottagare</Typography>
              <dl>
                <dt>
                
                  <Typography variant="body1">
                    Aktiva i ATR före import
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingRegistreradVarumottagareBeforeImport}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Aktiva i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingRegistreradVarumottagareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Avslutade i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledRegistreradVarumottagareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Nya</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.newRegistreradVarumottagare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Uppdaterade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.updatedRegistreradVarumottagare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Avslutade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledRegistreradVarumottagare}
                  </Typography>
                </dd>
              </dl>
              
              <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Skattebefriad förbrukare</Typography>
              <dl>  
                <dt>
                
                  <Typography variant="body1">
                    Aktiva i ATR före import
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingSkattebefriadForbrukareBeforeImport}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Aktiva i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.existingSkattebefriadForbrukareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Avslutade i SKV-fil</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledSkattebefriadForbrukareInSkvFile}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Nya</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.newSkattebefriadForbrukare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Uppdaterade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.updatedSkattebefriadForbrukare}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">
                    Avslutade
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.canceledSkattebefriadForbrukare}
                  </Typography>
                </dd>

                <dt style={{ marginTop: "18px" }}>
                  <Typography variant="body1">Antal fel</Typography>
                </dt>
                <dd style={{ marginTop: "18px" }}>
                  <Typography variant="body1">
                    {report.numberOfErrors}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="body1">Antal varningar</Typography>
                </dt>
                <dd>
                  <Typography variant="body1">
                    {report.numberOfWarnings}
                  </Typography>
                </dd>
              </dl>

              {report && report.numberOfErrors !== 0 && (
                <>
                  <Divider />
                  <Typography variant="h3">Importfel</Typography>
                  <ul className="skvImportWarningList">
                    {report.importErrors.map((error, index) => {
                      return (
                        <li key={index}>
                          <Typography variant="body1">
                            {error.error}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {report && report.numberOfWarnings !== 0 && (
                <>
                  <Divider />
                  <Typography variant="h3">Importvarningar</Typography>
                  <ul className="skvImportWarningList">
                    {report.importWarnings.map((warning, index) => {
                      return (
                        <li key={index}>
                          <Typography variant="body1">
                            {warning.warning}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UploadPermit;
