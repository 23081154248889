import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#2d5492",
      light: "#4b82db",
      dark: "#1c355c",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#4F4F4F",
      main: "#333333",
      dark: "#262626",
    },
    error: {
      main: "#E91B0C",
      light: "#E91B0C",
      dark: "#E91B0C",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Tahoma", "TimesNewRoman", "sansSerif"].join(","),
      margin: 0,
    },
    h1: {
      fontSize: "3rem",
      fontFamily: ["Klavika", "Tahoma", "TimesNewRoman"].join(","),
      margin: 0,
    },
    h2: {
      fontSize: "2rem",
      fontFamily: ["Klavika", "Tahoma", "TimesNewRoman"].join(","),
      margin: 0,
    },
    h3: {
      fontSize: "1.5rem",
      fontFamily: ["Klavika", "Tahoma", "TimesNewRoman"].join(","),
      margin: 0,
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: ["Klavika", "Tahoma", "TimesNewRoman"].join(","),
      margin: 0,
    },
    body1: {
      fontFamily: ["Tahoma", "TimesNewRoman", "sansSerif"].join(","),
      fontSize: "1rem",
      margin: 0,
    },
    body2: {
      fontFamily: ["Tahoma", "TimesNewRoman", "sansSerif"].join(","),
      fontSize: "0.8rem",
      margin: 0,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: "#2d5492",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "always",
        fontFamily: ["Tahoma", "TimesNewRoman", "sansSerif"].join(","),
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginTop: "1rem",
          marginBottom: "1rem",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "4px 14px",
          transition: "0.3s ease-in-out",
          boxShadow: "none",
          "&.active, &:hover": {
            boxShadow: "none",
            color: "#ffffff",
            backgroundColor: "#1c355c"
          },
          "&:focus": {
            outline: "1px solid #1c355c",
            outlineOffset: "2px",
          }
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "32px 0px",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
          "& $icon": {
            opacity: 1,
            color: "#fff",
          },

          "&:hover": {
            color: "#fff",
            outlineStyle: "solid",
            outlineColor: "#fff",
            outlineWidth: "thin",

            "&& $icon": {
              color: "#fff",
            },
          },
          "&$active": {
            color: "#fff",

            "&& $icon": {
              color: "#fff",
            },
          },
          "&:focus": {
            color: "#fff",
            outlineStyle: "solid",
            outlineColor: "#fff",
            outlineWidth: "thin",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#C4C4C4",
          },
          "&:focus": {
            backgroundColor: "#C4C4C4",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        focusVisibleClassName: "radioFocused"
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#808080"
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        focusVisibleClassName: "checkboxFocused"
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.active, &:hover, &:focus": {
            outline: "1px solid #1c355c",
          },
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input:disabled':{
            borderRadius:"inherit",
            WebkitTextFillColor:"rgb(0 0 0)",
            color: "black",
            backgroundColor: "#ebebeb",
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "backgroundColor": "#ebebeb",
            WebkitTextFillColor: "rgb(0 0 0)",
            color: "#000000",
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "rgb(0 0 0)"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#000000",
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: "1"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "rgb(0 0 0)"
          }
        },
      }
    }
  },
});
