import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BtnSecond } from "../../Components/buttons";
import { getSKVImport, ISkvData } from "../../Services/PermitService";

const UploadedPermit: React.FC = () => {
  let { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = useState<ISkvData>(null!);
  const navigate = useNavigate();

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      getSKVImport(+id)
    .then((response) => {
      setResults({ ...response.object });
      setLoading(false);
    })
    .catch((err) => {
      //TODO handle Error
      console.log("err: ", err);
      setLoading(false);
    });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" gutterBottom>
          Resultat - inläsning av fil
        </Typography>
        <BtnSecond
          onClick={() => {
            navigate(-1);
          }}
        >
          Tillbaka
        </BtnSecond>
      </Grid>
      {!loading ? (
        <Grid item xs={12} className="recordList">
          <dl>
            <dt>
              <Typography variant="body1">Datum</Typography>
            </dt>
            <dd>
              <Typography variant="body1">{results.timeOfImport}</Typography>
            </dd>
            <dt>
              <Typography variant="body1">Användare</Typography>
            </dt>
            <dd>
              <Typography variant="body1">{results.importedBy}</Typography>
            </dd>

          </dl>

          <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Upplagshavare</Typography>
          <dl>
            <dt>
              <Typography variant="body1">
                Aktiva i ATR före import
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingUpplagshavareBeforeImport}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Aktiva i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingUpplagshavareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Avslutade i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledUpplagshavareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Nya</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.newUpplagshavare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Uppdaterade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.updatedUpplagshavare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Avslutade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledUpplagshavare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Aktiva i ATR efter import 
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingUpplagshavareAfterImport}
              </Typography>
            </dd>
          </dl>

          <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Registrerad varumottagare</Typography>
          <dl>
            <dt>
              <Typography variant="body1">
                Aktiva i ATR före import
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingRegistreradVarumottagareBeforeImport}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Aktiva i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingRegistreradVarumottagareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Avslutade i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledRegistreradVarumottagareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Nya</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.newRegistreradVarumottagare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Uppdaterade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.updatedRegistreradVarumottagare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Avslutade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledRegistreradVarumottagare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Aktiva i ATR efter import 
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingRegistreradVarumottagareAfterImport}
              </Typography>
            </dd>
          </dl>
          
          <Typography variant="h4" component="h4" style={{ fontWeight: "bold", marginTop: "18px", marginBottom: "18px" }}>Skattebefriad förbrukare</Typography>
          <dl>  
            <dt>
            
              <Typography variant="body1">
                Aktiva i ATR före import
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingSkattebefriadForbrukareBeforeImport}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Aktiva i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingSkattebefriadForbrukareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Avslutade i SKV-fil</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledSkattebefriadForbrukareInSkvFile}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Nya</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.newSkattebefriadForbrukare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Uppdaterade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.updatedSkattebefriadForbrukare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Avslutade
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.canceledSkattebefriadForbrukare}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">
                Aktiva i ATR efter import 
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.existingSkattebefriadForbrukareAfterImport}
              </Typography>
            </dd>

            <dt style={{ marginTop: "18px" }}>
              <Typography variant="body1">Antal fel</Typography>
            </dt>
            <dd style={{ marginTop: "18px" }}>
              <Typography variant="body1">
                {results.numberOfErrors}
              </Typography>
            </dd>
            <dt>
              <Typography variant="body1">Antal varningar</Typography>
            </dt>
            <dd>
              <Typography variant="body1">
                {results.numberOfWarnings}
              </Typography>
            </dd>
          </dl>

          {results && results.numberOfErrors !== 0 && (
            <>
              <Divider />
              <Typography variant="h3">Importfel</Typography>
              <ul className="skvImportWarningList">
                {results.importErrors.map((error, index) => {
                  return (
                    <li key={index}>
                      <Typography variant="body1">
                        {error.error}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          {results && results.numberOfWarnings !== 0 && (
            <>
              <Divider />
              <Typography variant="h3">Importvarningar</Typography>
              <ul className="skvImportWarningList">
                {results.importWarnings.map((warning, index) => {
                  return (
                    <li key={index}>
                      <Typography variant="body1">
                        {warning.warning}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

export default UploadedPermit;
