import {
  CircularProgress,
  Divider,
  FormControl,
  Box,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import {
  deleteUser,
  getUser,
  IAnvandareGet,
  IAnvandarePut,
  putAnvandare,
} from "../../Services/UserService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import {
  formats,
  GetDateFormatted,
  GetDateFormattedUsingDate,
} from "../../Utilities/momentExt";
import {
  Cat,
  GetCorrectRolesBasedOnKey,
  MapToCorrectCat,
  MapToCorrectKey,
  ShowDropdown
} from "../../Utilities/roles";
import { editAnvandareSchema } from "./validation";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import { useAuth } from "../../Utilities/authProvider";
import InputTextField from "../../Components/textfield";
import { GetCheckBox } from "../../Components/checkbox";

export default function User() {
  interface IForm extends IAnvandarePut { }
  const navigate = useNavigate();
  const modal = useModal();
  const auth = useAuth();
  const [user, setUser] = React.useState<IAnvandareGet>(null!);
  const [loading, setLoading] = React.useState(true);
  const [userPut, setUserPut] = React.useState<IForm>(null!);
  const [userId, setUserId] = React.useState<number>(0);

  let { id } = useParams();

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      setUserId(parseInt(id));
      getUser(parseInt(id))
        .then((resp) => {
          setUser({ ...resp.object });
        })
        .catch((error) => { });
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      setLoading(false);
      setUserPut({ ...user });
    } else {
      setLoading(true);
    }
  }, [user]);

  const deleteUserF = (
    setStatus: (status?: any) => void,
    values: IForm,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    deleteUser(userId)
      .then((resp) => {
        modal.showDialog({ open: false });
        setStatus({
          sent: true,
          msg: `Användarkontot för ${values.fornamn} ${values.efternamn} har tagits bort.`,
          title: "Användare borttagen",
          severity: "success",
          borttagen: true
        });
        setSubmitting(false);
      })
      .catch((error) => {
        modal.showDialog({ open: false });
        setStatus({
          sent: true,
          msg: error.data,
          title: "Ett fel uppstod",
          severity: "error",
          borttagen: false
        });
        setSubmitting(false);
      });
  };


  const checkIfUserSignedInIsCurrent = () => {
    if (auth.getActiveUser().username === user.anvandarnamn) {
      return true;
    }
    return false;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Ändra användarkonto
        </Typography>
      </Grid>
      {!loading ? (
        <Grid item xs={12} mb={2}>
          <Grid container mb={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h3" component="p">
                Användarnamn: {user.anvandarnamn}
              </Typography>
              {user.arSparrad ? (
                <Typography>
                  Spärrad:{" "}{user?.sparradDatum ? GetDateFormattedUsingDate(user?.sparradDatum, formats.date) : ""}
                </Typography>
              ) : (
                <Typography>
                  Ej spärrad (lösenordet är giltigt till {GetDateFormattedUsingDate(user.utgangsdatum, formats.date)})
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} lg={8} md={10} xl={6}>
              <Typography variant="caption" component="p" mb={1}>
                Fält markerade med * är obligatoriska
              </Typography>
              <Formik
                validationSchema={editAnvandareSchema}
                //enableReinitialize
                //We need to check the initial value of lanId not to be null then the Yupvalidation that expexts a number behind the scenes will validate false
                initialValues={{
                  ...userPut,
                  lanId:
                    userPut.lanId === null || userPut.lanId === undefined
                      ? 0
                      : userPut.lanId,
                  remove: false,
                }}
                onSubmit={(values, { setSubmitting, setStatus, setValues }) => {
                  setSubmitting(true);                 
                  putAnvandare(values, userId)
                    .then((p) => {
                      setSubmitting(false);
                      setValues({
                        ...p.object,
                        lanId: p.object.lanId === null ? 0 : p.object.lanId,
                        systemId: p.object.systemId === null ? 0 : p.object.systemId,
                        remove: false,
                      });
                      setStatus({
                        sent: true,
                        msg: `Användarkontot för ${values.fornamn} ${values.efternamn} ändrades.`,
                        title: "Användare uppdaterad",
                        severity: "success",
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      setStatus({
                        sent: true,
                        msg: error.data,
                        title: "Ett fel uppstod",
                        severity: "error",
                      });
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setStatus,
                  isSubmitting,
                  setSubmitting
                }) => (
                  <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} mb={2}>
                      {!status?.borttagen && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl
                                fullWidth
                                error={evalErrors(errors, touched, "roller")}
                              >
                                <InputLabel id="roller-label">Roll</InputLabel>
                                <SelectSingleWihtoutData
                                  label="Roll"
                                  labelId="roller-label"
                                  infoType={InfoType.rollerGrouped}
                                  selected={MapToCorrectKey(values.roller)}
                                  onChange={(evt) => {
                                    setFieldValue(
                                      "roller",
                                      GetCorrectRolesBasedOnKey(evt.target.value)
                                    );
                                  }}
                                  name="roller"
                                  required
                                  rolesToFilter={values.roller}
                                  aria-required="true"
                                  onBlur={handleBlur}
                                  disabled={checkIfUserSignedInIsCurrent()}
                                  defaultVal={false}
                                />
                                <FormHelperText>
                                  {getErrorText(errors, touched, "roller")}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={1}>
                                <InputTextField
                                  fullWidth
                                  name="fornamn"
                                  label="Förnamn"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.fornamn}
                                  error={evalErrors(errors, touched, "fornamn")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "fornamn"
                                  )}
                                  required
                                  aria-required="true"
                                  disabled={checkIfUserSignedInIsCurrent()}
                                />
                                <InputTextField
                                  fullWidth
                                  name="efternamn"
                                  label="Efternamn"
                                  onChange={handleChange}
                                  value={values.efternamn}
                                  onBlur={handleBlur}
                                  error={evalErrors(errors, touched, "efternamn")}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "efternamn"
                                  )}
                                  required
                                  aria-required="true"
                                  disabled={checkIfUserSignedInIsCurrent()}
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={6}>
                              <InputTextField
                                fullWidth
                                name="epost"
                                label="E-postadress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.epost}
                                error={evalErrors(errors, touched, "epost")}
                                helperText={getErrorText(errors, touched, "epost")}
                                required
                                aria-required="true"
                                disabled={checkIfUserSignedInIsCurrent()}
                              />
                            </Grid>
                            {MapToCorrectCat(values.roller) === Cat.ext && (
                              <>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <InputTextField
                                        fullWidth
                                        name="foretag"
                                        label="Företag"
                                        onChange={handleChange}
                                        value={values.foretag}
                                        onBlur={handleBlur}
                                        error={evalErrors(errors, touched, "foretag")}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "foretag"
                                        )}
                                        disabled={checkIfUserSignedInIsCurrent()}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <GetCheckBox
                                        checked={
                                          values.kreditupplysningsForetag
                                        }
                                        label="Kreditupplysningsföretag"
                                        name="kreditupplysningsForetag"
                                        onChange={(data) =>
                                          setFieldValue("kreditupplysningsForetag", data.target.checked)
                                        }
                                        mainLabel={
                                          ""
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Stack direction="row" spacing={1}>
                                    {MapToCorrectCat(values.roller) === Cat.kommun && (
                                      <FormControl fullWidth>
                                        <AutoCompleteAtr
                                          onBlur={handleBlur}
                                          infoType={InfoType.kommuner}
                                          label="Kommuner"
                                          multiple={true}
                                          defaultVal={false}
                                          onChange={(data) => {
                                            setFieldValue("kommunIdn", data);
                                          }}
                                          error={evalErrors(errors, touched, "kommunIdn")}
                                          helperText={getErrorText(
                                            errors,
                                            touched,
                                            "kommunIdn"
                                          )}
                                          name="kommunIdn"
                                          selectedData={values.kommunIdn}
                                          disabled={checkIfUserSignedInIsCurrent()}
                                        />
                                      </FormControl>
                                    )}
                                    {MapToCorrectCat(values.roller) === Cat.lan && (
                                      <FormControl fullWidth>
                                        <AutoCompleteAtr
                                          error={evalErrors(errors, touched, "lanId")}
                                          onBlur={handleBlur}
                                          infoType={InfoType.lan}
                                          multiple={false}
                                          defaultVal={false}
                                          label="Län"
                                          onChange={(data) => {
                                            setFieldValue("lanId", data);
                                          }}
                                          helperText={getErrorText(
                                            errors,
                                            touched,
                                            "lanId"
                                          )}
                                          name="lanId"
                                          selectedData={values.lanId}
                                          disabled={checkIfUserSignedInIsCurrent()}
                                        />
                                      </FormControl>
                                    )}
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                         {ShowDropdown(values.roller) && (
                           <FormControl
                              fullWidth
                              error={evalErrors(errors, touched, "system")}
                            >
                              <AutoCompleteAtr
                                      defaultVal={false}
                                      infoType={InfoType.system}
                                      multiple={false}
                                      label="System"
                                      name="system"
                                      required
                                      onChange={(data) => {
                                        setFieldValue(
                                          "systemId", data                                         
                                        );                                      
                                      }}
                                      onBlur={handleBlur}
                                      selectedData={values.systemId}
                                      error={evalErrors(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                      helperText={getErrorText(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                    />
                              <FormHelperText>
                                {getErrorText(errors, touched, "system")}
                              </FormHelperText>
                            </FormControl>)}
                          </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid container spacing={2}>
                      {ShowAlertEval(status) && (
                        <Grid item xs={12}>
                          <ShowAlert
                            onClose={() => {
                              setStatus(null);
                            }}
                            severity={status.severity}
                            alertTitle={status.title}
                            alertText={status.msg}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>

                          <BtnDefault
                            disabled={
                              isSubmitting ||
                              (errors !== undefined &&
                                Object.keys(errors).length > 0) || checkIfUserSignedInIsCurrent() || status?.borttagen
                            }
                            type="submit"
                            onClick={() => console.log(errors)}
                          >
                            Spara
                          </BtnDefault>
                          <BtnDefault
                            disabled={isSubmitting || checkIfUserSignedInIsCurrent() || status?.borttagen}
                            onClick={() => {
                              modal.showDialog({
                                title: "Bekräfta",
                                open: true,
                                message:
                                  "Vill du verkligen ta bort användaren?",
                                actions: [
                                  {
                                    action: () => {
                                      deleteUserF(setStatus, values, setSubmitting)
                                    },
                                    btn: ActionType.default,
                                    text: "Ta bort",
                                  },
                                  {
                                    text: "Avbryt",
                                    btn: ActionType.second,
                                  },
                                ],
                              });
                            }}
                          >
                            Ta bort
                          </BtnDefault>


                          <BtnSecond onClick={() => navigate(-1)}>
                            Tillbaka
                          </BtnSecond>
                          {isSubmitting && <CircularProgress />}
                        </Stack>
                      </Grid>
                    </Grid>

                  </Box>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
