import {ExportFileViaGet} from "../Utilities/axiosBase";

const registerutdragBase: string = "/tillstand/registerutdrag";

export interface IGetRegisterutdrag {
  datum?: string;
}
export interface IGetRegisterutdragObject {
}

export const exportExcelRegisterutdragCurrent = async () => {
  return await ExportFileViaGet<IGetRegisterutdrag>(
    `${registerutdragBase}/aktuella`
  );
};

export const exportExcelRegisterutdragEnded = async (values: IGetRegisterutdrag ) => {
  return await ExportFileViaGet(
    `${registerutdragBase}/avslutadeefter?datum=${values.datum}`
  );
};
