import React, { useEffect } from "react";

import {
  Card,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  CardContent,
  Collapse,
  CardActions,
  Link,
  Button
} from "@mui/material";

import {
  UnfoldMore,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material/";
import {
  getKommentarerAction,
  IKommentarActionGet,
} from "../../Services/reportService";
import { BtnIcon } from "../../Components/buttons";
import { ArrowForward, SortByAlphaSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { StringLiteralType } from "typescript";

export default function RapporterKommentarer() {
  const [rapportKommentarer, setRapportKommentarer] = React.useState<IKommentarActionGet[]>();
  const [loading, setLoading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [sortBy, setSortBy] = React.useState('rapporttypString');
  const [sortOrder, setSortOrder] = React.useState('desc');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const nav = useNavigate();

  useEffect(() => {
    getKommentarerAction().then((res) => {
      setRapportKommentarer([...res.object]);
    });
  }, []);

  useEffect(() => {
    if (rapportKommentarer !== undefined && rapportKommentarer !== null) {
      setLoading(false);
    }
  }, [rapportKommentarer]);

  useEffect(() => {
    if(rapportKommentarer !== undefined){
      const sortedData = rapportKommentarer.slice().sort((a, b) => {
          const valueA =  a[sortBy];
          const valueB =  b[sortBy];
  
        if (sortOrder === 'desc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
      setRapportKommentarer(sortedData);
    }
  }, [sortBy, sortOrder]);


  const renderList = () => {
    if (loading) {
      return <CircularProgress />;
  }
  
 const handleSort = (column:string) => {
  if (column === sortBy) {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    setSortBy(column);
    setSortOrder('desc');
    console.log("setsort + setsortorder");
  }
}
    return (
      <TableContainer>
        <Table size="small">
          <TableHead className="tableHeader">
            <TableRow>
              <TableCell onClick={() => handleSort('rapporttypString')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover">
                Rapporttyp  <i style={{position: 'absolute'}}>{sortBy === 'rapporttypString' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell onClick={() => handleSort('rapportPeriodString')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover">
                Rapportperiod  <i style={{position: 'absolute'}}>{sortBy === 'rapportPeriodString' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell onClick={() => handleSort('kommunString')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover">
                Kommun   <i style={{position: 'absolute'}}>{sortBy === 'kommunString' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell  onClick={() => handleSort('organisationsNr')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover">
              Organisationsnummer  <i style={{position: 'absolute'}}>{sortBy === 'organisationsNr' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell  onClick={() => handleSort('objektNr')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover">
              Restaurangnummer  <i style={{position: 'absolute'}}>{sortBy === 'objektNr'&& (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell  onClick={() => handleSort('kommentardatum')} style={{ cursor: 'pointer' }}  className="tableHeaderCellNoHover">
                Kommentarsdatum  <i style={{position: 'absolute'}}>{sortBy === 'kommentardatum' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
              <TableCell  onClick={() => handleSort('anvandareNamn')} style={{ cursor: 'pointer' }} className="tableHeaderCellNoHover" colSpan={2}>
                Skriven av <i style={{position: 'absolute'}}>{sortBy === 'anvandareNamn' && (sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)  || <UnfoldMore fontSize="small" />}</i>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rapportKommentarer!.map((r) => (
              <TableRow key={r.rapportId}>
                <TableCell>{r.rapporttypString}</TableCell>
                <TableCell>{r.rapportPeriodString}</TableCell>
                <TableCell>{r.kommunString}</TableCell>
                <TableCell>{r.organisationsNr}</TableCell>
                <TableCell>{r.objektNr}</TableCell>
                <TableCell>
                  {moment(r.kommentardatum).format("yyyy-MM-DD")}
                </TableCell>
                <TableCell>{r.anvandareNamn}</TableCell>
                <TableCell>
                  <BtnIcon
                    key={r.rapportId}
                    size="small"
                    onClick={() => {
                      localStorage.setItem('selectedReportId', r.rapportId.toString());
                      nav(`/reports/`);
                    }}
                  >
                    <ArrowForward />
                  </BtnIcon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Card>
      <CardContent>
        <div className="cardHeading">
          <Typography
            gutterBottom
            component="h2"
            variant="h2"
            className="alignText"
          >
            Restaurangrapporter att hantera
          </Typography>
        </div>
        <Typography variant="body1">
          {rapportKommentarer?.length === 0 ? (
            <>
              Just nu finns det inga rapporter som kräver åtgärd
            </>
          ) :
          (
            <>
              Det finns rapporter som kräver åtgärd
            </>
          )}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body1">
          Rapporterna försvinner ur listan när kommentar har besvarats eller
          rapport är klarmarkerad.
          </Typography>
          {renderList()}
        </CardContent>
      </Collapse>
      <CardActions className="cardActions">
      {rapportKommentarer?.length !== 0 ? (
          <Link
            component={Button}
            className="button-lowercase"
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={expanded === true ? "Visa mindre" : "Läs mer"}
          >
            {expanded === true ? "Visa mindre" : "Läs mer"}
          </Link>
          ) :
          (
            <></>
          )}

      </CardActions>
    </Card>
  );
}
