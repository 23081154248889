import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault } from "../../Components/buttons";
import { InfoType, SelectSingle } from "../../Components/selects";
import { getRapportTyp } from "../../Services/infoService";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import {
  createReports,
  getRapportPeriod,
  IRapportPeriodGet,
  IMissing,
  getMissing,
} from "../../Services/reportService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { RapportTyper, Rapporttyper } from "./ReportForm/formlogic";

export default function CreateReportsForPeriod() {
  const modal = useModal();
  const [rapportPerioder, setRapportPerioder] = React.useState<
    IRapportPeriodGet[]
  >(null!);

  const [sammanfatting, setSammanfattning] = React.useState<IMissing[]>();

  const [loading, setLoading] = React.useState({
    rapporter: true,
    sammanfatting: true,
  });

  const [valdPeriod, setValdPeriod] = React.useState(0);

  const getSumFromSammanfattning = (rapportTypId: number) => {
    if (sammanfatting !== null && sammanfatting !== undefined) {
      if (
        sammanfatting.findIndex((p) => p.rapporttypId === rapportTypId) > -1
      ) {
        return sammanfatting.find((z) => z.rapporttypId === rapportTypId)
          ?.rapporterSomInteHarGenererats;
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    getRapportPeriod().then((res) => {
      setRapportPerioder([...res.object]);
      setValdPeriod(res.object[1].id);
      /*getMissing(res.object[0].id).then((res2) => {
        setSammanfattning([...res2.object]);
      });*/
    });
  }, []);

  useEffect(() => {
    setSammanfattning(undefined);
    if (valdPeriod === 0) return;
    getMissing(valdPeriod).then((res2) => {
      setSammanfattning([...res2.object]);
    });
  }, [valdPeriod]);

  useEffect(() => {
    if (rapportPerioder !== null) {
      setLoading({ ...loading, rapporter: false });
    } else {
      setLoading({ ...loading, rapporter: true });
    }
  }, [rapportPerioder]);

  useEffect(() => {
    if (sammanfatting !== null) {
      setLoading({ ...loading, sammanfatting: false });
    } else {
      setLoading({ ...loading, sammanfatting: true });
    }
  }, [sammanfatting]);

  const renderSammanfattning = () => {
    if (sammanfatting === null) {
      return (
        <Grid item xs={12}>
          <Typography>Ingen data hittades!</Typography>
        </Grid>
      );
    }

    
    return (
      <Grid item xs={12}>
        <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
        {sammanfatting !== null && sammanfatting !== undefined && (
          <>
            <Typography>
              <b>Restaurangrapport:</b>{" "}
              {getSumFromSammanfattning(Rapporttyper.Restaurangrapport)}{" "}
              rapporter saknas för vald period
            </Typography>
            <Typography>
              <b>Statistikrapport försäljning/tillverkning:</b>{" "}
              {getSumFromSammanfattning(
                Rapporttyper.StatistikrapportForsaljningTillverkning
              )}{" "}
              rapporter saknas för vald period
            </Typography>
            <Typography>
              <b>Statistikrapport teknisk sprit:</b>{" "}
              {getSumFromSammanfattning(
                Rapporttyper.StatistikrapportTekniskSprit
              )}{" "}
              rapporter saknas för vald period
            </Typography>
          </>
        )}
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Skapa rapporter för vald period
        </Typography>
      </Grid>
      {!loading.rapporter ? (
        <Grid item xs={12} md={6} mb={2}>
          <Formik
            initialValues={{
              tillstandstypId: 1,
              period: rapportPerioder[1].id,
            }}
            onSubmit={(values, { setSubmitting, setStatus }) => {
              setSubmitting(false);
              modal.showDialog({
                open: true,
                actions: [
                  {
                    action: () => {
                      setSubmitting(true);
                      createReports(values.period, values.tillstandstypId)
                        .then((res) => {
                          setStatus({
                            msg: "",
                            severity: "success",
                            title: "Rapporter har skapats",
                            sent: true,
                          });
                          setLoading({ ...loading, sammanfatting: true })
                          getMissing(valdPeriod).then((res2) => {
                            setSammanfattning([...res2.object]);
                            setLoading({ ...loading, sammanfatting: false })
                          })
                          setSubmitting(false);
                        })
                        .catch((error) => {
                          setStatus({
                            msg: `${error.data}`,
                            severity: "error",
                            title: "Ett fel inträffade",
                            sent: true,
                          });
                          setSubmitting(false);
                        });
                    },
                    btn: ActionType.default,
                    text: "Ok",
                  },
                  {
                    text: "Avbryt",
                    btn: ActionType.second,
                  },
                ],
                message: `Det kommer skapas ${getSumFromSammanfattning(values.tillstandstypId)} ${"rapporter"} av typen ${RapportTyper.find((x) => x.id === values.tillstandstypId)?.namn} för år ${rapportPerioder.find((x) => x.id === values.period)?.namn}. Är du säker på att rapporterna ska skapas?`,
                title: "Bekräfta",
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setStatus,
              setFieldValue,
            }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="p">
                      Nuvarande period: {moment().year()}{" "}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={5}>
                      <Grid item xs={6} md={8} lg={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <SelectSingle
                              fullWidth
                              data={
                                rapportPerioder.length === 0
                                  ? [{ key: 0, label: "Ingen period finns" }]
                                  : rapportPerioder.map((r) => ({
                                    key: r.id,
                                    label: r.namn,
                                  }))
                              }
                              selected={values.period}
                              onChange={(ev) => {
                                setFieldValue("period", ev.target.value);
                                setValdPeriod(ev.target.value as number);
                              }}
                              name="period"
                              label="Rapportperiod"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoCompleteAtr
                              disableClearable
                              infoType={InfoType.rapportTyper}
                              label="Rapporttyp"
                              onBlur={handleBlur}
                              onChange={(data) => {
                                setFieldValue("tillstandstypId", data);
                              }}
                              defaultVal={false}
                              name="tillstandstypId"
                              selectedData={values.tillstandstypId}
                              multiple={false}
                              error={evalErrors(
                                errors,
                                touched,
                                "tillstandstypId"
                              )}
                              helperText={getErrorText(
                                errors,
                                touched,
                                "tillstandstypId"
                              )}
                              idsToKeep={[
                                Rapporttyper.Restaurangrapport,
                                Rapporttyper.StatistikrapportForsaljningTillverkning,
                                Rapporttyper.StatistikrapportTekniskSprit,
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!loading.sammanfatting && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                            <BtnDefault
                              type="submit"
                              disabled={sammanfatting === null || sammanfatting === undefined || rapportPerioder.length === 0 || isSubmitting}
                              startIcon={
                                isSubmitting || sammanfatting === null || sammanfatting === undefined ? (
                                  <CircularProgress color="primary" size={"1em"} />
                                ) : (
                                  <div></div>
                                )
                              }
                            >
                              Skapa rapporter
                            </BtnDefault>


                          </Stack>
                        </Grid>
                        {ShowAlertEval(status) && (
                          <Grid item xs={12} md={8} lg={8}>
                            <ShowAlert
                              onClose={() => {
                                setStatus(null);
                              }}
                              severity={status.severity}
                              alertTitle={status.title}
                              alertText={status.msg}
                            />
                          </Grid>
                        )}
                      </Grid>

                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      ) : (
        <Grid item xs={6}>
          <CircularProgress />
        </Grid>
      )}
      {!loading.sammanfatting ? (
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">Sammanfattning</Typography>
              <Typography>
                Antal tillstånd för vald period som saknar rapport.
              </Typography>
            </Grid>
            {renderSammanfattning()}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={6}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
