import React, { useEffect } from "react";
import { Formik } from "formik";
import { Buffer } from "buffer";
import zxcvbn from "zxcvbn";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  getMyUser,
  IAnvandareGet,
  changepasswordAnvandare,
  getAvpulAvailable,
  avpulExecute,
} from "../Services/UserService";
import {
  getKommunListInfo,
  getLanListInfo,
  kommunList,
  lanList,
} from "../Services/infoService";
import PasswordStrength from "../Components/passwordStrength";
import { IAlert, ShowAlert, ShowAlertEval } from "../Components/alerts";
import OutlinedTextField from "../Components/textfield";
import { Roles } from "../Utilities/roles";
import { GetDateFormatted } from "../Utilities/momentExt";
import { evalErrors, getErrorText } from "../Utilities/formikErrorEval";
import { ChangePasswordSchema } from "../Utilities/validationSchema";
import { useAuth } from "../Utilities/authProvider";

export default function MyAccount() {
  const [user, setUserState] = React.useState<IAnvandareGet>();
  const [score, setScoreState] = React.useState<number>(0);
  const [kommuner, setKommuner] = React.useState<string>("");
  const [lan, setLan] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [avpulLoading, setAvpulLoading] = React.useState(false);
  const [avpul, setAvpul] = React.useState(false);
  const [avpulStatus, setAvpulStatus] = React.useState<IAlert>({
    show: false,
    alertText: "",
    severity: "success",
  });
  const auth = useAuth();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    GetMyUser();
    listKommuner();
    listLan();
  }, []);

  function GetMyUser() {
    getMyUser().then((response) => {
      if (response.success) {
        setUserState(response.object);
      }
    });
  }

  // Get all info about anvandare initially
  useEffect(() => {
      getAvpulAvailable().then((res) => {
        console.log("avpul res: ", res.object);
        if (res.object === true) {
          setAvpul(true);
        }
      });
  }, []);

  function pwHandleChange(password: string) {
    if (password === "") {
      setScoreState(0);
    } else {
      var pw = zxcvbn(password);
      setScoreState(pw.score);
    }
  }

  const listLan = () => {
    getLanListInfo(lanList.userSpecific).then((res) => {
      setLan(res.map((z) => z.namn).join("; "));
    });
  };

  const listKommuner = () => {
    getKommunListInfo(kommunList.userSpecific).then((res) => {
      setKommuner(res.map((z) => z.namn).join("; "));
    });
  };

  const triggerAvpul = () => {
    setAvpulLoading(false);
    avpulExecute()
      .then((res) => {
        if (res.success === true) {
          setAvpulStatus({
            alertText: "Jobbet har startats, du kommer få ett mail när allt är klart (det kan ta upp till några minuter).",
            severity: "success",
            show: true,
          });
        }
      })
      .catch((error) => {
        setAvpulStatus({
          alertText:
            "Kunde inte starta avidentifieringen, testa igen om en stund eller kontakta support.",
          severity: "error",
          show: true,
        });
      });
    setAvpulLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
          <Stack direction="column" alignItems="flex-start">
            <div>
              <Typography variant="h1" component="h1" paragraph>
                Användarkonto
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="p">
                Förnamn
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                {user?.fornamn}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="p">
                Efternamn
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                {user?.efternamn}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="p">
                Användarnamn
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                {user?.anvandarnamn}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" component="p">
                E-postadress
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                {user?.epost}
              </Typography>
            </div>
            {(user?.roller.includes(Roles.KommunAdminAlkohol) || 
              user?.roller.includes(Roles.KommunAdminTobak) || 
              user?.roller.includes(Roles.KommunLasAlkohol) || 
              user?.roller.includes(Roles.KommunLasTobak)) && (
                <div>
                  <Typography variant="caption" component="p">
                    Kommuner
                  </Typography>
                  <Typography variant="subtitle1" component="p" gutterBottom>
                    {kommuner}
                  </Typography>
                </div>
            )}
            {user?.roller.includes(Roles.LansstyrelseLasAlkohol) && (
              <div>
                <Typography variant="caption" component="p">
                  Län
                </Typography>
                <Typography variant="subtitle1" component="p" gutterBottom>
                  {lan}
                </Typography>
              </div>
            )}
            <div>
              <Typography variant="caption" component="p">
                Lösenord måste bytas senast
              </Typography>
              {user && (
                <Typography variant="subtitle1" component="p" paragraph>
                  {GetDateFormatted(user.utgangsdatum)}
                </Typography>
              )}
            </div>
            {avpul === true && (
            <>
              <Button
                startIcon={
                  avpulLoading ? (
                    <CircularProgress color="primary" size={"1em"} />
                  ) : (
                    <div></div>
                  )
                }
                color="primary"
                variant="contained"
                sx={{ marginTop: "20px" }}
                onClick={(e) => triggerAvpul()}
              >
                Starta avidentifieringsjobb (avpul)
              </Button>
                {avpulStatus.show === true && (
                <ShowAlert
                  onClose={() => {
                    setAvpulStatus({
                      show: false,
                      alertText: "",
                      severity: "success",
                    });
                  }}
                  severity={avpulStatus.severity}
                  alertTitle={""}
                  alertText={avpulStatus.alertText}
                />
              )}
              <Divider />
            </>
            )} 
            <FormControl component="fieldset">
              <Typography variant="h2" component="h2" gutterBottom>
                Byt lösenord
              </Typography>
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={ChangePasswordSchema}
                onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                  setLoading(true);
                  changepasswordAnvandare({
                    oldPasswordBase64: Buffer.from(values.oldPassword).toString(
                      "base64"
                    ),
                    newPasswordBase64: Buffer.from(values.newPassword).toString(
                      "base64"
                    ),
                  })
                    .then((resp) => {
                      console.log("adasd");
                      resetForm();
                      GetMyUser();
                      setLoading(false);
                      setSubmitting(false);
                      setScoreState(0);
                      setStatus({
                        sent: true,
                        msg: `Lösenordet har ändrats`,
                        title: "Uppdaterat",
                        severity: "success",
                      });
                    })
                    .catch((err) => {
                      setLoading(false);
                      setSubmitting(false);
                      setStatus({
                        sent: true,
                        msg: `${err.data}`,
                        title: "Ett fel uppstod",
                        severity: "error",
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setTouched,
                  setStatus,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography variant="caption" component="p" gutterBottom>
                      Fält markerade med{" "}
                      <abbr title="Obligatoriskt fält">*</abbr> är obligatoriska
                    </Typography>
                    <Grid item xs={6}>
                      <OutlinedTextField
                        name="oldPassword"
                        label="Nuvarande lösenord"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        error={evalErrors(errors, touched, "oldPassword")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "oldPassword"
                        )}
                        fullWidth
                        aria-required="true"
                        required
                      />
                      <OutlinedTextField
                        name="newPassword"
                        label="Nytt lösenord"
                        type="password"
                        onChange={(e: any) => {
                          pwHandleChange(e.target.value);
                          setFieldValue("newPassword", e.target.value);
                          setTouched({ ...touched, newPassword: true });
                        }}
                        onBlur={(e: any) => {
                          pwHandleChange(e.target.value);
                          setFieldValue("newPassword", e.target.value);
                          setTouched({ ...touched, newPassword: true });
                        }}
                        value={values.newPassword}
                        error={evalErrors(errors, touched, "newPassword")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "newPassword"
                        )}
                        fullWidth
                        aria-required="true"
                        required
                        style={{ marginTop: "20px" }}
                      />
                      <OutlinedTextField
                        name="confirmPassword"
                        label="Upprepa lösenordet"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        error={evalErrors(errors, touched, "confirmPassword")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "confirmPassword"
                        )}
                        fullWidth
                        aria-required="true"
                        required
                        style={{ marginTop: "20px" }}
                      />
                    </Grid>
                    <Grid item xs={4} alignSelf="center">
                      {values.newPassword.length > 0 && (
                        <PasswordStrength score={score} />
                      )}
                    </Grid>
                    <Button
                      startIcon={
                        loading ? (
                          <CircularProgress color="primary" size={"1em"} />
                        ) : (
                          <div></div>
                        )
                      }
                      color="primary"
                      type="submit"
                      variant="contained"
                      sx={{ marginTop: "20px" }}
                    >
                      Byt lösenord
                    </Button>
                    {ShowAlertEval(status) && (
                      <Grid item xs={12}>
                        <ShowAlert
                          onClose={() => {
                            setStatus(null);
                          }}
                          severity={status.severity}
                          alertTitle={status.title}
                          alertText={status.msg}
                        />
                      </Grid>
                    )}
                  </form>
                )}
              </Formik>
            </FormControl>
          </Stack>
      </Grid>
    </Grid>
  );
}
