import { Roles } from "../Utilities/roles";
import { SystemTyp } from "../Utilities/systemUtils";
import {
  Delete,
  ExportFile,
  Get,
  GetFile,
  Post,
  PostFile,
  Put,
} from "../Utilities/axiosBase";
import { ISearchBase, ISearchResultBase } from "./Searchbase";
import { IMainBaseResponse } from "./base";

export interface ICommonPropsAnvandare {
  id: number;
  fornamn: string;
  efternamn: string;
  roller: Roles[];
  system: SystemTyp[];
  systemId: number;
  epost: string;
  lanId: number;
}
export interface IKommun {
  kommunIdn: number[];
}
export interface IAnvandarnamn {
  anvandarnamn: string;
}

export interface IForetag {
  foretag: string;
  kreditupplysningsForetag: boolean;
}

export interface IApplyAnvandare
  extends ICommonPropsAnvandare,
    IKommun,
    IForetag {}

export interface IAnvandareGet
  extends ICommonPropsAnvandare,
    IKommun,
    IAnvandarnamn,
    IForetag {
  id: number;
  arSparrad: boolean;
  utgangsdatum: string;
  sparradDatum: string | null;
}

export interface IAnvandarePut
  extends ICommonPropsAnvandare,
    IKommun,
    IForetag {}

export interface IAnvandarePost
  extends ICommonPropsAnvandare,
    IKommun,
    IAnvandarnamn,
    IForetag {}

export interface IAnvandareSearchFilter {
  kommunId: number;
  anvandarnamn: string;
  efternamn: string;
  epost: string;
  fornamn: string;
  roller: Roles[];
}

export interface IAnvandareSearchedFilter extends IAnvandareSearchFilter {
  lanId?: number;
  foretag?: string;  
}
export interface IAnvandareChangePassword {
  oldPasswordBase64: string;
  newPasswordBase64: string;
}

export interface ICreateUser extends ICommonPropsAnvandare {
  ref: string;
  foretagsnamn: string;
  adress: string;
  orgNr: string;
  postNr: string;
  postOrt: string;
  telefon: string;
  syfte: string;
  kommunIdn: number[];
  samtligaAktuellaServTillstand?: boolean;
  samtligaServtillstandAvslutadeForeVisstDatum?: boolean;
  kreditupplysningsForetag: boolean;
  selectedOption?: number;
  gdpr?: boolean;
}
export interface KeyValuesGroup {
  key: string;
  value: string;
  group:string;
}

export interface CaptchaWrapper<T> {
  data: T;
  captchaToken: string;
}

export type Group =
  "Standardvarde" | 
  "Statistik" | 
  "Gransvarde";

const anvandarbase: string = "/anvandare";

const systeminstallningar: string = "/systeminstallning";

export const searchUsers = async (
  filters: ISearchBase<IAnvandareSearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<IAnvandareGet>>> => {
  return await Post<
    ISearchBase<IAnvandareSearchFilter>,
    ISearchResultBase<IAnvandareGet>
  >(`${anvandarbase}/search`, filters);
};

export const getUser = async (
  id: number
): Promise<IMainBaseResponse<IAnvandareGet>> => {
  return await Get<IAnvandareGet>(`${anvandarbase}/${id}`);
};

export const getMyUser = async (): Promise<
  IMainBaseResponse<IAnvandareGet>
> => {
  return await Get<IAnvandareGet>(`${anvandarbase}/me`);
};

export const putAnvandare = async (
  user: IAnvandarePut,
  id: number
): Promise<IMainBaseResponse<IAnvandareGet>> => {
  return await Put<IAnvandarePut, IAnvandareGet>(`${anvandarbase}/${id}`, user);
};

export const changepasswordAnvandare = async (
  request: IAnvandareChangePassword
): Promise<IMainBaseResponse<any>> => {
  return await Put<IAnvandareChangePassword, any>(
    `${anvandarbase}/changepassword/`,
    request
  );
};

export const deleteUser = async (
  id: number
): Promise<IMainBaseResponse<IAnvandareGet>> => {
  return await Delete<IAnvandareGet>(`${anvandarbase}/${id}`, id);
};

export const postAnvandare = async (
  user: IAnvandarePost,
  sendEmail: boolean
): Promise<IMainBaseResponse<IAnvandareGet>> => {
  return await Post<IAnvandarePost, IAnvandareGet>(
    `${anvandarbase}?sendEmail=${sendEmail}`,
    user
  );
};

export const exportAnvandare = async (filters: IAnvandareSearchFilter) => {
  return await ExportFile<IAnvandareSearchFilter>(
    `${anvandarbase}/exportexcel`,
    filters
  );
};

export const downloadPDF = async (isTobak : boolean): Promise<any> => {
  return await GetFile(`${anvandarbase}/manual/${isTobak}`);
};

export const postApply = async (
  createUser: CaptchaWrapper<ICreateUser>
): Promise<IMainBaseResponse<ICreateUser>> => {
  return await Post(`${anvandarbase}/createuserapplication`, createUser);
};

export const getEmailsUsersSearched = async (
  filters: IAnvandareSearchedFilter
): Promise<IMainBaseResponse<string[]>> => {
  return await Post(`${anvandarbase}/getemailsforsearchedusers`, filters);
};

export const getStandardvarde = async (group:Group) :Promise<IMainBaseResponse<KeyValuesGroup[]>> => {
    return await Get(`${systeminstallningar}?group=${group}`);
}
export const updateStandardvarden = async (
  keyvaluegroup: KeyValuesGroup[],
  group:Group
): Promise<IMainBaseResponse<KeyValuesGroup[]>> => {
  return await Put(`${systeminstallningar}?group=${group}`, keyvaluegroup);
}

export const getAvpulAvailable = async (): Promise<
  IMainBaseResponse<boolean>
> => {
  return await Get("avpul/isavailable");
};

export const avpulExecute = async () => {
  return await Post("avpul/execute", null);
};

export enum File {
  restaurang="restaurang",
  restaurangfragorsvar="restaurangfragorsvar",
  statistik="statistik",
  restaurangrapportering ="restaurangrapportering",
  tekniskSprit ="statistikteknisksprit"
}

export const downloadAnvisning = async (name: File) => {
  return await GetFile(`anvandare/anvisningsmanual?anvisning=${name}`);
};