import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { BtnDefault } from "../../Components/buttons";
import InputTextField from "../../Components/textfield";
import { useAuth } from "../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { loginExtReportUser } from "./validationLoginExt";
import { Buffer } from "buffer";
import { getDeploymentInfo } from "../../Services/infoService";
import UnauthLayout from "../SignIn/unauthLayout";
import { theme } from "../../theme";

export default function ExternalLogin() {
  const auth = useAuth();
  const navigate = useNavigate();
  const logo = require("../../Assets/FHM-Logotyp-Symbol.png");
  const [loading, setLoading] = React.useState(false);
  const [networkError, setNetworkError] = React.useState(true);

  useEffect(() => {
    let storage = localStorage.getItem("Network_Error");
    if (storage === null || storage === undefined) {
      setNetworkError(false);
    } else {
      setNetworkError(true);
    }
  }, [networkError]);

  const checkIfServerError = () => {
    const message = localStorage.getItem("Network_Error");
    if (message !== null) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <ShowAlert
              onClose={() => {
                localStorage.removeItem("Network_Error");
                setNetworkError(false);
              }}
              severity={"error"}
              alertTitle={"Ett oväntat fel inträffade"}
              alertText={message}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <UnauthLayout>
      <img className="login-fohm-logo" src={logo} alt="Logotyp ATR" />
      <Box sx={{ margin: "24px 0" }}>
        <Typography variant="h1" component="h1" align="center" gutterBottom>
          Rapportregistrering
        </Typography>
      </Box>
      <Typography align="center" variant="body2" paragraph>
        Vi rekommenderar att du använder webbläsare Edge eller Chrome
      </Typography>
      <Formik
        initialValues={{
          organisationsnr: "",
          password: "",
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setLoading(true);
          setSubmitting(true);
          auth
            .signInReportUser(values.organisationsnr, values.password)
            .then((response) => {
              if (response.success === true) {
                navigate(
                  `/report/registerreport/${
                    values.organisationsnr
                  }/${Buffer.from(values.password).toString("base64")}`
                );
                getDeploymentInfo();
              } else {
                setLoading(false);
                setSubmitting(false);
                setStatus({
                  sent: true,
                  title: "Kunde inte logga in",
                  msg: response.object,
                  severity: "error",
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              setSubmitting(false);
              setStatus({
                msg: error.data,
                sent: true,
                title: "Kunde inte logga in",
                severity: "error",
              });
            });
        }}
        validationSchema={loginExtReportUser}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          values,
          handleBlur,
          touched,
          isSubmitting,
          status,
          setStatus,
        }) => (
          <Box sx={{ margin: theme.spacing(2) }}>
            <Stack spacing={2} display="flex">
              <form onSubmit={handleSubmit}>
                <Typography
                  sx={{ mb: theme.spacing(1) }}
                  variant="caption"
                  component="p"
                >
                  Fält markerade med <abbr title="Obligatoriskt fält">*</abbr>{" "}
                  är obligatoriska
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputTextField
                      required
                      aria-label="required"
                      label="Organisationsnummer"
                      fullWidth
                      name="organisationsnr"
                      error={evalErrors(errors, touched, "organisationsnr")}
                      helperText={getErrorText(
                        errors,
                        touched,
                        "organisationsnr"
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputTextField
                      required
                      aria-label="required"
                      label="Lösenord"
                      fullWidth
                      name="password"
                      type="password"
                      error={evalErrors(errors, touched, "password")}
                      helperText={getErrorText(errors, touched, "password")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <BtnDefault disabled={isSubmitting} type="submit">
                        Logga in
                      </BtnDefault>
                      {loading && <CircularProgress color="primary" />}
                    </Stack>
                    {ShowAlertEval(status) && (
                      <Grid container>
                        <Grid item xs={12}>
                          <ShowAlert
                            onClose={() => {
                              setStatus(null);
                            }}
                            severity={status.severity}
                            alertTitle={status.title}
                            alertText={status.msg}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {networkError && checkIfServerError()}
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Box>
        )}
      </Formik>
    </UnauthLayout>
  );
}
