import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { changepasswordAnvandare } from "../../Services/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../Utilities/validationSchema";
import { theme } from "../../theme";
import OutlinedTextField from "../../Components/textfield";
import DisplayAlert from "../../Components/alert";
import { Buffer } from "buffer";
import zxcvbn from "zxcvbn";
import PasswordStrength from "../../Components/passwordStrength";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { BtnDefault } from "../../Components/buttons";

interface LocationState {
  oldpassword: string;
}

export default function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state as LocationState;
  const [loading, setLoading] = React.useState(false);
  const [scoreState, setScore] = React.useState(0);

  function pwHandleChange(password: string) {
    if (password === "") {
      setScore(0);
    } else {
      var pw = zxcvbn(password);
      setScore(pw.score);
    }
  }

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          oldPassword: locationState.oldpassword,
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
          setLoading(true);
          if (scoreState <= 2) {
            setLoading(false);
            setSubmitting(false);
            setStatus({
              sent: false,
              msg: "Det nya lösenordet är för svagt, vänligen ange ett annat önskat lösenord.",
            });
          } else {
            changepasswordAnvandare({
              oldPasswordBase64: Buffer.from(values.oldPassword).toString(
                "base64"
              ),
              newPasswordBase64: Buffer.from(values.newPassword).toString(
                "base64"
              ),
            })
              .then((resp) => {
                if (resp.success) {
                  resetForm();
                  setLoading(false);
                  setSubmitting(false);
                  setScore(0);
                  setStatus({
                    sent: true,
                    msg: `Lösenordet har ändrats. Vänligen klicka på knappen "Gå vidare" för att komma vidare.`,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                setSubmitting(false);
                setStatus({
                  sent: false,
                  msg: err.message,
                });
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) =>
          !status || !status.sent || status.sent === false ? (
            <Box onSubmit={handleSubmit} component="form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="p" gutterBottom>
                      Fält markerade med
                      <abbr title="Obligatoriskt fält">*</abbr> är obligatoriska
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <OutlinedTextField
                        fullWidth
                        type="password"
                        name="newPassword"
                        label="Nytt lösenord"
                        onChange={(e: any) => {
                          pwHandleChange(e.target.value);
                          setFieldValue("newPassword", e.target.value);
                        }}
                        onBlur={(e: any) => {
                          pwHandleChange(e.target.value);
                          setFieldValue("newPassword", e.target.value);
                        }}
                        value={values.newPassword}
                        error={evalErrors(errors, touched, "newPassword")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "newPassword"
                        )}
                        aria-required="true"
                        required
                      />
                      <div>
                        {values.newPassword.length > 0 && (
                          <PasswordStrength score={scoreState} />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedTextField
                        fullWidth
                        type="password"
                        name="confirmPassword"
                        label="Bekräfta lösenord"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        error={evalErrors(errors, touched, "confirmPassword")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "confirmPassword"
                        )}
                        aria-required="true"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <BtnDefault
                          type="submit"
                          title="Skicka"
                          disabled={isSubmitting}
                        >
                          Spara
                        </BtnDefault>
                        {loading && <CircularProgress />}
                      </Stack>
                      {status && status.msg && (
                        <DisplayAlert type="error" msg={status.msg} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="column" alignItems="center">
                  {status && status.sent && status.msg && (
                    <DisplayAlert type="success" msg={status.msg} />
                  )}
                  <Button
                    type="button"
                    title="Gå vidare"
                    variant="contained"
                    onClick={(e) => {
                      navigate("/home");
                    }}
                  >
                    Gå vidare
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )
        }
      </Formik>
    );
  };

  return (
    <div className="loginBackground">
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "20vh" }}
        >
          <Grid item xs={12}>
            <Paper sx={{ padding: theme.spacing(6) }} elevation={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1" component="h1" align="center">
                    Du måste byta lösenord!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="body2">
                    Ange ett nytt lösenord och bekräfta det.
                    Lösenordet ska minst klara lösenordsstyrka Godkänt.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {renderForm()}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
