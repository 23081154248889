import { Alert, AlertProps } from "@mui/material";
import React from "react";

export interface IAlert extends AlertProps {
  alertTitle?: string;
  alertText: string;
  show?: boolean;
}

export function ShowAlertEval(status: any): boolean {
  if (
    status !== undefined &&
    status !== null &&
    status.sent &&
    status.msg !== undefined
  ) {
    return true;
  } else {
    return false;
  }
}

export const ShowAlert = (alert: IAlert) => {
  const { alertTitle, alertText, ...children } = alert;
  const [showAlert, setShowAlert] = React.useState(true);

  if (alert.severity && alert.severity === "success") {
    return showAlert === true ? (
      <Alert {...children}>
        {alertTitle && <strong>{alertTitle}:</strong>} {alertText}
      </Alert>
    ) : (
      <></>
    );
  } else {
    return (
      <Alert {...children}>
        {alertTitle && <strong>{alertTitle}:</strong>} {alertText}
      </Alert>
    );
  }
};
