import React, { useEffect } from "react";
import {
  DoneAllOutlined,
  GradingOutlined,
  Info,
  InfoSharp,
  RateReviewOutlined,
  RouteOutlined,
  Call,
} from "@mui/icons-material";

import { Grid, Link, Typography} from "@mui/material";
import { getBaseFilter } from "./ApplyUserAccount/applies";
import { AccordionCard, Card, CardWithLinebreak, AccordionCardTextLinebreak } from "../Components/card";
import { searchUserApplies } from "../Services/AppliesService";
import { downloadAnvisning, File } from "../Services/UserService";
import RapporterKommentarer from "./Report/RapporterKommentarer";
import { getMyUser, IAnvandareGet } from "../Services/UserService";
import { useAuth } from "../Utilities/authProvider";
import { Roles } from "../Utilities/roles";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = React.useState<IAnvandareGet>(null!);
  const [amountOfAnsokningar, setAmount] = React.useState(0);

  useEffect(() => {
    getMyUser().then((res) => {
      setUser({ ...res.object });
    });
    if (auth.validateUserRoleAccess([Roles.Registerutdrag]) == true) {
      navigate("/permits/records");
    }
    if (auth.validateUserRoleAccess([Roles.FohmAdmin]) === true) {
      searchUserApplies(getBaseFilter()).then((res) => {
        setAmount(res.object.totaltAntalTraffar);
      });
    }
  }, []);

  const getAnvisningsfil = (file: File) => {
    downloadAnvisning(file).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/pdf",
        })
      );
      window.open(url);
    });
  };

  return (
    <Grid container spacing={6} sx={{ mb: "20px" }}>
      <h2 className="visuallyHidden">Genvägar</h2>
      <Typography variant="h1" component="h1" className="visuallyHidden">Översikt</Typography>
      <Grid item xs={12}>
        <Grid container spacing={2}>
        {auth.validateUserRoleAccess([
           Roles.FohmAdmin,
           Roles.FohmLasAlkohol,
           Roles.FohmLasTobak,
           Roles.KommunAdminAlkohol,
           Roles.KommunAdminTobak,
           Roles.KommunLasAlkohol,
           Roles.KommunLasTobak,
           Roles.LansstyrelseLasAlkohol]) === true && (
          // <Grid item xs={4}>
          //     <CardWithLinebreak
          //       heading="Aktuellt"
          //       icon={<Info sx={{ fontSize: 26 }} />}
          //       text={`Just nu pågår 2023 års restaurangrapportering och de rapporter som kräver hantering i din kommun finns längst ner under Restaurangrapporter att hantera. Missa inte heller stödet kring rapporteringen som finns under Restaurangrapportering.`}
          //       text1={`För dig som arbetar med tobakstillstånd finns det nu möjlighet att ansöka om användarkonto för behörighet till försäljningstillstånd för tobak i ATR. Ansök på inloggningssidan!`}
          //     />

          // </Grid>
          <Grid item xs={4}>
            <AccordionCardTextLinebreak
              heading={
                <Typography
                  gutterBottom
                  component="h3"
                  variant="h3"
                  className="alignText"
                >
                  Aktuellt
                </Typography>
              }
              icon={<GradingOutlined sx={{ fontSize: 26 }} />}
              text1={
                "Under juni kommer statistik baserat på uppgifter ur 2023 års restaurangrapporter publiceras på vår webbplats och i Indikatorlabbet. De rapporter för 2023 som inkommer från och med nu kommer inte med i statistiken, det är dock inget hinder för dig i kommunen att arbeta vidare med rapporterna som du önskar."
              }
              text2={
                "För dig som arbetar med tobakstillstånd finns det nu möjlighet att ansöka om användarkonto för behörighet till försäljningstillstånd för tobak i ATR. Ansök på inloggningssidan!"
              }
              expandedContent={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href="https://www.folkhalsomyndigheten.se/folkhalsorapportering-statistik/statistikdatabaser-och-visualisering/alkoholdrycksstatistik-i-siffror/"
                      style={{ display: 'block' }}
                    >
                      Alkoholdrycksstatistik
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href="http://www.andtuppfoljning.se/indikatorlabbet/"
                      style={{ display: 'block' }}
                    >
                      Indikatorlabbet
                    </Link>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          
          )}
          {auth.validateUserRoleAccess([
           Roles.FohmAdmin,
           Roles.FohmLasAlkohol,
           Roles.FohmLasTobak,
           Roles.KommunAdminAlkohol,
           Roles.KommunAdminTobak,
           Roles.KommunLasAlkohol,
           Roles.KommunLasTobak,
           Roles.LansstyrelseLasAlkohol]) === true && (
            <Grid item xs={4}>           
              <CardWithLinebreak
                icon={<GradingOutlined sx={{ fontSize: 26 }} />}
                heading="Driftinformation"
                text={`Tisdagar kl. 9:00 sker uppdatering av ATR som kan innebära störningar under 15-30 minuter. `} 
                text1={`Första onsdagen i varje månad kl. 18:00-21:00  genomför myndighetens IT-enhet planerat underhåll som kan innebära störningar i alla myndighetens system.`}   
              />
            </Grid>
          )}
          {auth.validateUserRoleAccess([Roles.FohmAdmin]) === true && (
            <Grid item xs={4}>
              <Card
                icon={<RateReviewOutlined sx={{ fontSize: 26 }} />}
                heading="Ansökan om användarkonton"
                text={`Det finns ${amountOfAnsokningar} ansökningar om användarkonto att hantera.`}
                link="/users/applies"
              />
            </Grid>
          )}
          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.FohmLasAlkohol,
            Roles.FohmLasTobak,
            Roles.KommunAdminAlkohol,
            Roles.KommunAdminTobak,
            Roles.KommunLasAlkohol,
            Roles.KommunLasTobak,
            Roles.LansstyrelseLasAlkohol]) === true && (
            <Grid item xs={4}>
              <AccordionCard
                heading={
                  <Typography
                    gutterBottom
                    component="h3"
                    variant="h3"
                    className="alignText"
                  >
                    Serveringstillstånd
                  </Typography>
                }
                icon={<DoneAllOutlined sx={{ fontSize: 26 }} />}
                text={
                  "Folkhälsomyndigheten är enligt 13 kap. 1 § alkohollagen (2010:1622) skyldig att föra ett centralt register. Här samlar vi till exempel alla serveringstillstånd och restaurangrapporter."
                }
                expandedContent={
                  <Typography paragraph>
                     Kommunen har underrättelseskyldighet och ska skicka Folkhälsomyndigheten kopia på tillståndsbevis och andra beslut som fattats enligt alkohollagen (9 kap. 7 §).
                     Tillståndsbevisen ska innehålla uppgifter enligt föreskrift FoHMFS 2014:6.
                     Alla tillståndsbevis ska registreras i Alkohol- och tobaksregistret, antingen av kommunen eller av Folkhälsomyndigheten.
                  </Typography>
                }
              />
            </Grid>
          )}

          {auth.validateUserRoleAccess([
           Roles.FohmAdmin,
           Roles.FohmLasAlkohol,
           Roles.FohmLasTobak,
           Roles.KommunAdminAlkohol,
           Roles.KommunAdminTobak,
           Roles.KommunLasAlkohol,
           Roles.KommunLasTobak,
           Roles.LansstyrelseLasAlkohol]) === true && (
          <Grid item xs={4}>
            <AccordionCard
              heading={
                <Typography
                  gutterBottom
                  component="h3"
                  variant="h3"
                  className="alignText"
                >
                  Föreskrifter
                </Typography>
              }
              icon={<GradingOutlined sx={{ fontSize: 26 }} />}
              text={
                "Följ länkarna för att läsa de föreskrifter som gäller innehåll som lagras i alkohol- och tobaksregistret, såsom restaurangrapporter och tillståndsbevis."
              }
              expandedContent={
                <Grid container spacing={2}>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href={
                        "https://www.folkhalsomyndigheten.se/publikationer-och-material/publikationsarkiv/k/konsoliderad-version-av-fohmfs-2014-5-folkhalsomyndighetens-foreskrifter/"
                      }
                    >
                      Konsoliderad version av FoHMFS 2014:5
                      Folkhälsomyndighetens föreskrifter om uppgifter för
                      statistik avseende servering av alkoholdrycker och
                      alkoholdrycksliknande preparat
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href={
                        "https://www.folkhalsomyndigheten.se/publikationer-och-material/publikationsarkiv/k/konsoliderad-version-av-fohmfs-2014-6-folkhalsomyndighetens-foreskrifter/"
                      }
                    >
                      Konsoliderad version av FoHMFS 2014:6
                      Folkhälsomyndighetens föreskrifter och allmänna råd om
                      tillståndsbevis
                    </Link>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          )}

          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.FohmLasAlkohol,
            Roles.FohmLasTobak,
            Roles.KommunAdminAlkohol,
            Roles.KommunAdminTobak,
            Roles.KommunLasAlkohol,
            Roles.KommunLasTobak,
            Roles.LansstyrelseLasAlkohol]) === true && (
          <Grid item xs={4}>
            <AccordionCard
              heading={
                <Typography
                  gutterBottom
                  component="h3"
                  variant="h3"
                  className="alignText"
                >
                  Restaurangrapportering
                </Typography>
              }
              icon={<RateReviewOutlined sx={{ fontSize: 26 }} />}
              text={
                "Som ett stöd, har vi här samlat information om restaurangrapporteringen, vad den innebär och hur man hanterar rapporterna i systemet."
              }
              expandedContent={
                <Grid container spacing={2}>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      onClick={ () => getAnvisningsfil(File.restaurangfragorsvar) }
                    >
                      Frågor och svar om restaurangrapport
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      onClick={ () => getAnvisningsfil(File.restaurang) }
                    >
                      Anvisning inför restaurangrapportering
                    </Link>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          )}

          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.FohmLasAlkohol,
            Roles.FohmLasTobak,
            Roles.KommunAdminAlkohol,
            Roles.KommunAdminTobak,
            Roles.KommunLasAlkohol,
            Roles.KommunLasTobak,
            Roles.LansstyrelseLasAlkohol]) === true && (
            <Grid item xs={4}>
              <AccordionCard
                heading={
                  <Typography
                    gutterBottom
                    component="h3"
                    variant="h3"
                    className="alignText"
                  >
                    Tobakstillstånd
                  </Typography>
                }
                icon={<DoneAllOutlined sx={{ fontSize: 26 }} />}
                text={
                  "Tobakstillstånd registreras i alkohol- och tobaksregistret. Enligt lagen (2018:2088) om tobak och liknande produkter ska kommunerna meddela beslut om tillstånd för att en handlare ska få sälja tobak.  Alla tillståndsbevis ska registreras i ATR, antingen av kommunen eller av Folkhälsomyndigheten."
                }
                expandedContent={
                  <Typography paragraph>
                    Kommunerna ska skicka in samtliga beslut som fattats med stöd av Lag (2018:2088) om tobak och liknande produkter (7 kap 20§) och tillståndsbesluten bör innehålla de uppgifter som finns angivna i Vägledning gällande tillståndsbeslut för tobaksvaror som kommunen ska skicka in till Folkhälsomyndigheten (se Vägledningar).
                  </Typography>
                }
              />
            </Grid>
          )}

          {auth.validateUserRoleAccess([
           Roles.FohmAdmin,
           Roles.FohmLasAlkohol,
           Roles.FohmLasTobak,
           Roles.KommunAdminAlkohol,
           Roles.KommunAdminTobak,
           Roles.KommunLasAlkohol,
           Roles.KommunLasTobak,
           Roles.LansstyrelseLasAlkohol]) === true && (
          <Grid item xs={4}>
            <AccordionCard
              heading={
                <Typography
                  gutterBottom
                  component="h3"
                  variant="h3"
                  className="alignText"
                >
                  Vägledningar
                </Typography>
              }
              icon={<RouteOutlined sx={{ fontSize: 26 }} />}
              text={
                "Folkhälsomyndigheten publicerar regelbundet vägledningar på områdena alkohol respektive tobak. Här länkar vi till några av dem som kopplar till innehållet i registret."
              }
              expandedContent={
                <Grid container spacing={2}>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href={
                        "https://www.folkhalsomyndigheten.se/publikationer-och-material/publikationsarkiv/v/vagledning-for-kommunernas-granskning-av-ansokan-om-tillstand-for-att-salja-tobaksvaror-/?pub=62413"
                      }
                    >
                      Vägledning för kommunernas granskning av ansökan om
                      tillstånd för att sälja tobaksvaror
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href={
                        "https://www.folkhalsomyndigheten.se/publikationer-och-material/publikationsarkiv/v/vagledning-gallande-tillstandsbeslut-for-tobaksvaror-som-kommunen-ska-skicka-in-till-folkhalsomyndigheten/?pub=67173"
                      }
                    >
                      Vägledning gällande tillståndsbeslut för tobaksvaror som
                      kommunen ska skicka in till Folkhälsomyndigheten
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      tabIndex={0}
                      target="_blank"
                      href={
                        "https://www.folkhalsomyndigheten.se/livsvillkor-levnadsvanor/andts/fragor-och-svar/fragor-och-svar-om-alkoholdrycksliknande-preparat/"
                      }
                    >
                      Frågor och svar om alkoholdrycksliknande preparat
                    </Link>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          )}
          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.FohmLasAlkohol,
            Roles.FohmLasTobak,
            Roles.KommunAdminAlkohol,
            Roles.KommunAdminTobak,
            Roles.KommunLasAlkohol,
            Roles.KommunLasTobak,
            Roles.LansstyrelseLasAlkohol]) === true && (
            <Grid item xs={4}>
              <AccordionCard
                heading={
                  <Typography
                    gutterBottom
                    component="h3"
                    variant="h3"
                    className="alignText"
                  >
                    Kontakta oss
                  </Typography>
                }
                icon={<Call sx={{ fontSize: 26 }} />}
                text={
"Behöver du hjälp med systemets funktioner eller har andra frågor som rör ATR, kontakta oss via"
                 }
                expandedContent={
                  <Typography paragraph>
                  <li>E-post: <a href="mailto:servering@folkhalsomyndigheten.se">servering@folkhalsomyndigheten.se</a></li>
                  <li>Telefon: 010-205 20 00</li>
                  <br/>
                  Dag innan röd dag och storhelg samt vissa högtidsdagar (Trettondagsafton, Skärtorsdag, Valborgsmässoafton och liknande) kan supporttiderna vara förkortade.<br/>
                  <br />
                  Användarguide till ATR hittar du i menyn under ditt användarnamn uppe till höger.
                  </Typography>      
                }
              />
            </Grid>
          )}

        </Grid>
      </Grid>
      {auth.validateUserRoleAccess([
        Roles.FohmAdmin,
        Roles.KommunAdminAlkohol,
      ]) && (
        <Grid item xs={12}>
          <RapporterKommentarer />
        </Grid>
      )}
    </Grid>
  );
}
