import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
//import React from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { ITekniskSpritRapportDetaljer } from "../../../Services/reportService";
import { disabledEnabled } from "../../Permit/PermitsFormsUtils/permitsUtils";
import { Mode } from "./formlogic";
import React, { useEffect } from "react";
import {
  GridWithCaption,
  InputWithDefaults,
  Section,
} from "./mainForm";

export const isNullOrUndefinedReturnZero = (
  val: number | null | undefined | string
): number => {
  if (val === null || val === undefined || val === "") {
    return 0;
  }
  if (typeof val === "string") {
    return 0;
  }
  return val;
};

export const sumValues = (values: (number | undefined | null)[]): number => {
  return values
    .map((p) => {
      return isNullOrUndefinedReturnZero(p);
    })
    .reduce((a, b) => a + b);
};

export const CalcSum = (
  props: FormikProps<IUpdateRapportDetails>,
  st: keyof IUpdateRapportDetails,
  values: (number | undefined | null)[]
) => {
  props.setFieldValue(st, sumValues(values));
};

export default function ForsaljningSverigeTekniskSprit(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {

  const values: (number | undefined | null)[] = [
    props.values.forsaljningAndraUpplagshavare,
    props.values.forsaljningApotek,
    props.values.forsaljningSjukhus,
    props.values.forsaljnSkattebefriade,
    props.values.forsaljningUniversitetHogskolor,
    props.values.forsaljnTillverkare,
    props.values.forsaljningOvrigt,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);
  

  const sumAllValues = (): void => {
    CalcSum(props, "summaForsaljning", values);
  };

  return (
    <Grid key={nr} item xs={12}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Försäljning inom Sverige (hela liter)
          </Typography>
          <Typography variant="caption">
            Försäljning ska delas upp enligt följande (se även anvisningen som finns längst upp på sidan).
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section head3="Teknisk sprit">
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till tillverkare av alkoholdrycker", 
                  "forsaljnTillverkare",
                  props.values.forsaljnTillverkare,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljningAndraUpplagshavare"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till andra upplagshavare än tillverkare av alkoholdrycker",
                  "forsaljningAndraUpplagshavare",
                  props.values.forsaljningAndraUpplagshavare,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljnTillverkare"]
                )}
              </GridWithCaption>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till skattebefriade förbrukare",
                  "forsaljnSkattebefriade",
                  props.values.forsaljnSkattebefriade,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljningUniversitetHogskolor"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till universitet och högskolor",
                  "forsaljningUniversitetHogskolor",
                  props.values.forsaljningUniversitetHogskolor,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljnSkattebefriade"]
                )}
              </GridWithCaption>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till sjukhus",
                  "forsaljningSjukhus",
                  props.values.forsaljningSjukhus,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljningApotek"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Försäljning till apotek",
                  "forsaljningApotek",
                  props.values.forsaljningApotek,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljningSjukhus"]
                )}
              </GridWithCaption>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Övrig försäljning (näringsidkare, kommuner, regioner, myndigheter,museer och skolverksamhet)",
                  "forsaljningOvrigt",
                  props.values.forsaljningOvrigt,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Mängd uttryckt i liter ren alkohol",
                  "number",
                  ["forsaljningOvrigt"]
                )}
              </GridWithCaption>      
              <Section>
              <Grid item xs={12}>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Summa försäljning inom Sverige</Typography>
              </Grid>
                <Typography variant="caption">
                  Ange 0 i summa om du inte har någonting att redovisa.
                </Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Summa",
                  "summaForsaljning",
                  props.values.summaForsaljning,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled,
                )}
              </GridWithCaption>
            </Section>


            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
