import { Container, Grid } from "@mui/material";
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/footer";
import Header from "../Components/header";
import SkipNav from "../Components/skipNav";
import { RequireAuth } from "../Utilities/checkAuth";

export default function Layout() {
  return (
    <>
      <RequireAuth>
        <Fragment>
          <SkipNav />
          <Header />
          <main id="mainContent" tabIndex={-1}>
            <Container
              maxWidth="xl"
              sx={{
                display: { minHeight: `calc(100vh - 140px)` },
                paddingTop: "10px",
              }}
            >
              <Grid>
                <Outlet />
              </Grid>
            </Container>
          </main>
        </Fragment>
      </RequireAuth>
      <Footer />
    </>
  );
}
