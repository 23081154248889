import React, { useEffect } from "react";
import {
  exportAnvandare,
  getEmailsUsersSearched,
  IAnvandareGet,
  IAnvandareSearchFilter,
  IAnvandareSearchedFilter,
  searchUsers,
} from "../../Services/UserService";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { IMainBaseResponse } from "../../Services/base";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ButtonBase,
  Stack,
  Typography,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import {
  GetCorrectRolesBasedOnKey,
  MapToCorrectKey,
} from "../../Utilities/roles";
import { getKommunListInfo, kommunList } from "../../Services/infoService";
import { InfoType } from "../../Components/selects";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { useNavigate } from "react-router";
import { useFilter } from "../../Utilities/searchFilterProvider";
import InputTextField from "../../Components/textfield";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";

// import apiAtrClient from "../Utilities/axiosBase";

export default function Users() {
  const filterProvider = useFilter();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<
    IMainBaseResponse<ISearchResultBase<IAnvandareGet>>
  >(null!);
  const [errors, setErrors] = React.useState<any>(null);

  /* custom styles */
  const BtnIcon = styled(ButtonBase)(({ theme }) => ({
    marginRight: "20px",
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        background: "white",
      },
    },
  }));

  const getBaseFilter = (): ISearchBase<IAnvandareSearchFilter> => {
    return {
      filter: {
        anvandarnamn: "",
        efternamn: "",
        epost: "",
        fornamn: "",
        kommunId: 0,
        roller: null!,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "anvandarnamn",
      sorteringsOrdning: SortOrder.Fallande,
    };
  };

  const checkLocation = () => {
    const filter = filterProvider.getFilter("users");
    if (filter !== null && filter !== undefined) {
      return filter;
    } else
      return {
        filter: {
          anvandarnamn: "",
          efternamn: "",
          epost: "",
          fornamn: "",
          kommunId: 0,
          roller: null!,
        },
        sidoNummer: 1,
        sidoStorlek: getMenuItems[0].value,
        sorteringsNamn: "anvandarnamn",
        sorteringsOrdning: SortOrder.Fallande,
      };
  };

  const [filter, setFilter] = React.useState<
    ISearchBase<IAnvandareSearchFilter>
  >(checkLocation());
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "anvandarnamn",
    direction: SortOrder.Fallande,
  });
  const [status, setStatus] = React.useState<any>();

  const headers: ITableHead<IAnvandareGet>[] = [
    {
      id: "fornamn",
      sortable: true,
      value: "Förnamn",
    },
    {
      id: "efternamn",
      sortable: true,
      value: "Efternamn",
    },
    {
      id: "anvandarnamn",
      sortable: true,
      value: "Användarnamn",
    },
    {
      id: "epost",
      sortable: true,
      value: "E-postadress",
    },
    {
      id: "roller",
      sortable: false,
      value: "Roll",
    },
    {
      id: "sparradDatum",
      sortable: true,
      value: "Spärrades",
    },
  ];

  useEffect(() => {
    searchUsers(filter)
      .then((data) => {
        setUsers({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setUsers(null!);
      });
  }, [filter]);

  useEffect(() => {
    getKommunListInfo(kommunList.all);
    searchUsers(filter)
      .then((data) => {
        setUsers({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setUsers(null!);
      });
  }, []);

  useEffect(() => {
    if (users === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [users]);

  const navigateToUser = (id: number) => {
    filterProvider.addFilter(filter, "users");
    navigate(`${id}`);
  };

  const copyEmailUsersSearched = () => {
    const usersFiltered: IAnvandareSearchedFilter = filter.filter;
    getEmailsUsersSearched(usersFiltered)
      .then((response) => {
        if (response.status === "200") {
          copyToClipboard(response.object);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const copyToClipboard = (content: string[]) => {
    navigator.clipboard.writeText(
      content
        .map((x) => {
          return x;
        })
        .join(";")
    );

    setStatus({
      sent: true,
      msg: 'Starta ditt mejlprogram och klistra in dem (CTRL+V i "Hemlig kopia") och ange supportlådans adress som mottagare.',
      title: "Mejladresserna har kopierats",
      severity: "success",
    });
  };

  const SubmitSearch = (values: IAnvandareSearchFilter) => {
    //Special we need to map the current rolesID added in the form to the correct match in the mapper table get the roles that needs to be passed.
    setFilter({ ...filter, filter: { ...values } });
  };

  const SubmitExport = (values: IAnvandareSearchFilter) => {
    exportAnvandare(values).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Anvandare.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Sök användare
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} mb={2}>
        <Formik
          enableReinitialize
          initialValues={{ ...filter.filter }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SubmitSearch(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            isSubmitting,
            setStatus,
            setFieldValue,
            handleSubmit,
            resetForm,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        defaultVal={false}
                        error={evalErrors(errors, touched, "roller")}
                        helperText={getErrorText(errors, touched, "roller")}
                        infoType={InfoType.roller}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue(
                            "roller",
                            GetCorrectRolesBasedOnKey(data)
                          );
                        }}
                        name="roller"
                        onBlur={handleBlur}
                        selectedData={MapToCorrectKey(values.roller)}
                        label="Roll"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        defaultVal={false}
                        error={evalErrors(errors, touched, "kommunId")}
                        helperText={getErrorText(errors, touched, "kommunId")}
                        infoType={InfoType.kommuner}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue("kommunId", data);
                        }}
                        name="kommunId"
                        onBlur={handleBlur}
                        selectedData={values.kommunId}
                        label="Kommun"
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <InputTextField
                      fullWidth
                      name="fornamn"
                      label="Förnamn"
                      onChange={handleChange}
                      value={values.fornamn}
                    />
                    <InputTextField
                      fullWidth
                      name="efternamn"
                      label="Efternamn"
                      onChange={handleChange}
                      value={values.efternamn}
                    />
                    <InputTextField
                      fullWidth
                      name="epost"
                      label="E-postadress"
                      onChange={handleChange}
                      value={values.epost}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault disabled={isSubmitting} type="submit">
                      Sök
                    </BtnDefault>
                    <BtnSecond
                      type="reset"
                      onClick={() => {
                        setFilter({ ...getBaseFilter() });
                        resetForm();
                      }}
                    >
                      Rensa
                    </BtnSecond>
                    <BtnSecond
                      disabled={isSubmitting}
                      onClick={() => {
                        SubmitExport(values);
                      }}
                      type="button"
                    >
                      Exportera resultat
                    </BtnSecond>
                    <BtnIcon
                      onClick={copyEmailUsersSearched}
                      id="kopiera-adresser"
                    >
                      <FileCopyIcon color="primary" />
                      <Typography
                        marginLeft={1}
                        color="primary"
                        variant="caption"
                        component="p"
                      >
                        Kopiera e-postadresser för framsökta användare
                      </Typography>
                    </BtnIcon>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      {ShowAlertEval(status) && (
        <Grid item xs={12} md={10} mt={1}>
          <ShowAlert
            onClose={() => {
              setStatus(null);
            }}
            severity={status.severity}
            alertTitle={status.title}
            alertText={status.msg}
          />
        </Grid>
      )}
      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}

      {users !== null && (
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Sökresultat
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {users !== null ? (
          <DefaultTable
            header={headers}
            activePage={filter.sidoNummer}
            data={mapToCellData(headers, users.object.sokResultat)}
            rowsPerPage={checkMenuItem(filter.sidoStorlek)}
            totalHits={users.object.totaltAntalTraffar}
            sortOptions={sortOptions}
            actions={[
              {
                action: navigateToUser,
                icon: ActionIcons.arrowForward,
                title: "Visa mer",
              },
            ]}
            filter={filter}
            setFilterAction={setFilter}
            setSortOptions={setSortOptions}
            tableAria="Användare"
          />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
