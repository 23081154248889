import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import { ShowAlert, ShowAlertEval } from "../../../Components/alerts";
import { BtnDefault } from "../../../Components/buttons";
import { DatePickerCustom } from "../../../Components/datePicker";
import {
  IGetRegisterutdrag,
  exportExcelRegisterutdragCurrent,
  exportExcelRegisterutdragEnded,
} from "../../../Services/registerExcerptService";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { GetDateFormattedCompact } from "../../../Utilities/momentExt";
import { RegisterutdragSchema } from "./validationRegisterutdrag";

export default function RegisterutdragExportera() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Ladda ner registerutdrag
        </Typography>
      </Grid>

      <Grid container direction="column">
        <Formik
          initialValues={{}}
          onSubmit={(
            values: IGetRegisterutdrag,
            { setSubmitting, setStatus }
          ) => {
            setSubmitting(true);
            exportExcelRegisterutdragCurrent()
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.object as BlobPart], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", moment(new Date()).format("yyyyMMDDkkmm") + "_serveringstillstand-aktuella.xlsx");
                document.body.appendChild(link);
                link.click();
                setSubmitting(false);
              })
              .catch((error) => {
                setStatus({
                  msg: `${error.data}`,
                  severity: "error",
                  title: "Ett fel inträffade",
                  sent: true,
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            status,
            handleSubmit,
            isSubmitting,
            setStatus,
          }) => (
            <Box component="form" onSubmit={handleSubmit} sx={{ width: "450px" }}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  paragraph
                  id="list-all-current-permits"
                >
                  Lista med samtliga aktuella stadigvarande serveringstillstånd
                </Typography>
                <BtnDefault
                  aria-labelledby="list-all-current-permits"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress color="primary" size={"1em"} />
                    ) : (
                      <div></div>
                    )
                  }
                >
                  Ladda ner
                </BtnDefault>
                {ShowAlertEval(status) && (
                  <ShowAlert
                    onClose={() => {
                      setStatus(null);
                    }}
                    severity={status.severity}
                    alertTitle={status.title}
                    alertText={status.msg}
                  />
                )}
              </Grid>
            </Box>
          )}
        </Formik>

        <Formik
          initialValues={{
            datum: "",
          }}
          validationSchema={RegisterutdragSchema}
          onSubmit={(
            values: IGetRegisterutdrag,
            { setSubmitting, setStatus }
          ) => {
            setSubmitting(true);
            exportExcelRegisterutdragEnded(values)
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.object as BlobPart], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", moment(new Date()).format("yyyyMMDDkkmm") + "_serveringstillstand-avslutade.xlsx");
                document.body.appendChild(link);
                link.click();
                setSubmitting(false);
              })
              .catch((error) => {
                setStatus({
                  msg: `${error.data}`,
                  severity: "error",
                  title: "Ett fel inträffade",
                  sent: true,
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setStatus,
            setFieldValue,
          }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: "450px", mt: "40px" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  paragraph
                  id="list-all-ended-permits"
                >
                  Lista med stadigvarande serveringstillstånd som avslutats efter visst datum
                </Typography>
                <Box sx={{ width: "300px" }}>
                  <DatePickerCustom
                    label="ÅÅÅÅ-MM-DD"
                    value={values.datum || null}
                    onChange={(ev) =>
                      setFieldValue("datum", GetDateFormattedCompact(ev))
                    }
                    error={evalErrors(errors, touched, "datum")}
                    helperText={getErrorText(errors, touched, "datum")}
                    name="datum"
                    onBlur={handleBlur}
                    required={true}
                    aria-required={true}
                  />
                </Box>
                <Box sx={{ mt: "16px" }}>
                  <BtnDefault
                    type="submit"
                    aria-labelledby="list-all-ended-permits"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress color="primary" size={"1em"} />
                      ) : (
                        <div></div>
                      )
                    }
                  >
                    Ladda ner
                  </BtnDefault>
                </Box>
                {ShowAlertEval(status) && (
                  <ShowAlert
                    onClose={() => {
                      setStatus(null);
                    }}
                    severity={status.severity}
                    alertTitle={status.title}
                    alertText={status.msg}
                  />
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
