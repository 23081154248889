
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import React from "react";

export interface ICheckbox {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string;
  checked: boolean;
  name: string;
  disabled?: boolean;
  mainLabel:string
  error?: boolean ,
  errorText?: string
}

export const GetCheckBox = (props: ICheckbox) => {
  return (
    <FormControl error={props.error ?? false}>
      <FormLabel focused={false}>
      {props.mainLabel}
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            disabled={props.disabled}
            onChange={props.onChange}
            checked={props.checked}
            name={props.name}
          />
        }
        label={props.label}
      ></FormControlLabel>
      {props.error && (<FormHelperText>{props.errorText}</FormHelperText>)}
    </FormControl>
  );
};
