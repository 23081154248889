import {
  Box,
  Grid,
  Stack,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShowAlert } from "../../Components/alerts";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { Input } from "../../Components/inputs";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import {
  IApplySearch,
  IApplySearchFilter,
  searchUserApplies,
} from "../../Services/AppliesService";
import { IMainBaseResponse } from "../../Services/base";
import { getKommunListInfo, kommunList } from "../../Services/infoService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import {
  GetCorrectRolesBasedOnKey,
  MapToCorrectKey,
} from "../../Utilities/roles";
import { useFilter } from "../../Utilities/searchFilterProvider";


export const getBaseFilter = (): ISearchBase<IApplySearchFilter> => {
  return {
    filter: {
      efternamn: "",
      epost: "",
      fornamn: "",
      kommunId: 0,
      roller: [],
    },
    sidoNummer: 1,
    sidoStorlek: getMenuItems[0].value,
    sorteringsNamn: "fornamn",
    sorteringsOrdning: SortOrder.Fallande,
  };
};

export default function Applies() {
  const filterProvider = useFilter();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState<any>(null);
  const [ansokningar, setAnsokningar] = React.useState<
    IMainBaseResponse<ISearchResultBase<IApplySearch>>
  >(null!);

  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "epost",
    direction: SortOrder.Fallande,
  });

  

  const checkLocation = (): ISearchBase<IApplySearchFilter> => {
    const filter = filterProvider.getFilter("applies");
    if (filter !== null && filter !== undefined) {
      return filter;
    } else {
      return getBaseFilter();
    }
  };

  const [filter, setFilter] = React.useState<ISearchBase<IApplySearchFilter>>(
    checkLocation()
  );

  const headers: ITableHead<IApplySearch>[] = [
    {
      id: "efternamn",
      sortable: true,
      value: "Efternamn",
    },
    {
      id: "fornamn",
      sortable: true,
      value: "Förnamn",
    },
    {
      id: "epost",
      sortable: true,
      value: "E-postadress",
    },
    {
      id: "roller",
      sortable: true,
      value: "Roll",
    },
    {
      id: "created",
      sortable: true,
      value: "Inkom",
    },
    {
      id: "hanteringStartadText",
      sortable: true,
      value: "Hantering påbörjad",
    },
  ];

  useEffect(() => {
    searchUserApplies(filter)
      .then((data) => {
        console.log(data);
        setAnsokningar({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setAnsokningar(null!);
      });
  }, [filter]);

  useEffect(() => {
    getKommunListInfo(kommunList.all);
    searchUserApplies(filter)
      .then((data) => {
        setAnsokningar({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setAnsokningar(null!);
      });
  }, []);

  useEffect(() => {
    if (ansokningar === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ansokningar]);

  const navigateToAnsokan = (id: number) => {
    filterProvider.addFilter(filter, "applies");
    navigate(`${id}`);
  };

  const submitSearch = (values: IApplySearchFilter) => {
    setFilter({ ...filter, filter: { ...values } });
  };

  const submitExport = (values: IApplySearchFilter) => {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">Ansökningar</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
        <Formik
          enableReinitialize
          initialValues={{ ...filter.filter }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            submitSearch(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            resetForm,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id="roller-label">Roller</InputLabel>
                      <SelectSingleWihtoutData
                        label="Roller"
                        labelId="roller-label"
                        infoType={InfoType.roller}
                        selected={MapToCorrectKey(values.roller)}
                        onChange={(evt) => {
                          setFieldValue(
                            "roller",
                            GetCorrectRolesBasedOnKey(evt.target.value)
                          );
                        }}
                        name="roller"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>Kommun</InputLabel>
                      <SelectSingleWihtoutData
                        selected={values.kommunId}
                        onChange={handleChange}
                        infoType={InfoType.kommuner}
                        label="Kommun"
                        labelId="kommer-label"
                        name="kommunId"
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault disabled={isSubmitting} type="submit">
                      Sök
                    </BtnDefault>
                    <BtnSecond
                      type="reset"
                      onClick={() => {
                        setFilter({ ...getBaseFilter() });
                        resetForm();
                      }}
                    >
                      Rensa
                    </BtnSecond>
                    {/* <BtnSecond
                      disabled={isSubmitting}
                      onClick={() => {
                        SubmitExport(values);
                      }}
                      type="button"
                    >
                      Exportera resultat
                    </BtnSecond> */}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">Sökresultat</Typography>
      </Grid>
      <Grid item xl={4}>
        {errors && (
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {ansokningar !== null ? (
          <DefaultTable
            header={headers}
            activePage={filter.sidoNummer}
            data={mapToCellData(headers, ansokningar.object.sokResultat)}
            rowsPerPage={checkMenuItem(filter.sidoStorlek)}
            totalHits={ansokningar.object.totaltAntalTraffar}
            sortOptions={sortOptions}
            actions={[
              {
                action: navigateToAnsokan,
                icon: ActionIcons.view,
              },
            ]}
            filter={filter}
            setFilterAction={setFilter}
            setSortOptions={setSortOptions}
            tableAria="Användare"
          />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
