import { Container, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Roles } from "../Utilities/roles";
import { useAuth } from "../Utilities/authProvider";
import "../App.css";

export default function Error500() {
  let navigate = useNavigate();
  const auth = useAuth();

  const navigateLogin = () => {
      navigate("/")
  };

  const navigateReportLogin = () => {   
      navigate("/report/login")    
  };

  const navigateCorrectLogin = () => {
    if (auth.user.roles.findIndex((x) => x === Roles.RapportInlamnare) > -1) {
      navigate("/report/login")
    } else {
      navigate("/")
    }
  };
  
  return (
    <Container className="contentContainer">
      <main id="mainContent" tabIndex="-1">
        <Typography variant="h1" component="h1" paragraph>
          Förlåt, något gick fel
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Du kan hjälpa oss lösa det genom att mejla oss en beskrivning av vad
          du gjorde när det hände till{" "}
          <Link component="a" href="mailto:servering@folkhalsomyndigheten.se">
            servering@folkhalsomyndigheten.se
          </Link>
        </Typography>
        <Typography>
          <Link component="a" onClick={() => navigateReportLogin()} tabIndex={0}>
            Tillbaka till inloggningssidan för rapportregistrering
          </Link>
        </Typography>
        <Typography>
          <Link component="a" onClick={() => navigateLogin()} tabIndex={0}>
            Tillbaka till inloggningssidan för alkohol- och tobaksregistret
          </Link>
        </Typography>
      </main>
    </Container>
  );
}