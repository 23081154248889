import React from "react";
import { Typography, Container } from "@mui/material";

function IEerrorpage() {
  return (
    <Container className="contentContainer">
        <Typography variant="h1" component="h1" paragraph>
          Webbläsaren som du använder stödjs inte av denna sidan.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Vänligen byt webbläsare för att kunna besöka sidan.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Vi rekommenderar att använda webbläsaren Chrome eller Edge.
        </Typography>
      </Container>
  );
}

export default IEerrorpage;
