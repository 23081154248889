import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IGroupData } from "../../../Components/selects";
import InputTextField from "../../../Components/textfield";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { Mode, Permits, SearchPermitTypes } from "./permitsUtils";

export function FormTypeLopnummer(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode,
  dataType?: IGroupData[]
) {

  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={6} key="lopNr">
        <InputTextField
          fullWidth
          name="lopNr"
          label="Folkhälsomyndighetens interna löpnummer"
          onBlur={props.handleBlur}
          value={props.values.lopNr && props.values.lopNr}
          disabled={mode === Mode.View || mode === Mode.Edit || mode === Mode.Create || mode == Mode.Transfer}
        />
      </Grid>
    </Grid>
  );
}
