import * as React from "react";
import {
  Grid,
  Typography,
  Divider,
  Link,
  CircularProgress,
} from "@mui/material";
import {
  downloadOldDataExcel,
  IRelateradeRapporter,
  IReportData,
} from "../../../Services/reportService";
import {
  downloadAnvisning,
  File,
} from "../../../Services/UserService";
import { RapportTyper, Rapporttyper } from "./formlogic";
import {Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import moment from "moment";
//TODO map tillstandsinfo to correct interface when BE is in place.

interface IReportInfo {
  data: IReportData;
  renderExtra: boolean;
  viewPrevYear: boolean;
}

export function MainInfoRapport(info: IReportInfo) {
  const ListLinks = styled("ul")`
    display: flex;
    margin: 0;
    padding: 0;

    li:not(:first-of-type) {
      padding-left: 1rem;
    }
  `;

  const [loadingRapportExcel, setLoadinRapportExcel] = React.useState(false);

  const getHeader = () => {
    switch (info.data.rapportTypId) {
      case Rapporttyper.Restaurangrapport:
        return "Registrera restaurangrapport";
      case Rapporttyper.StatistikrapportForsaljningTillverkning:
        return "Registrera statistikrapport försäljning/tillverkning";
        case Rapporttyper.StatistikrapportTekniskSprit:
          return "Registrera statistikrapport teknisk sprit";
      default:
        return "";
    }
  };

  const getHeaderWithExtra = () => {
    switch (info.data.rapportTypId) {
      case Rapporttyper.Restaurangrapport:
        return "Restaurangrapport";
      case Rapporttyper.StatistikrapportForsaljningTillverkning:
        return "Statistikrapport försäljning/tillverkning";
      case Rapporttyper.StatistikrapportTillverkning:
        return "Statistikrapport tillverkning";
      case Rapporttyper.StatistikrapportForsaljning:
        return "Statistikrapport försäljning";
      case Rapporttyper.LagerdeklarationAvVinOchDruvmust:
        return "Lagerdeklaration av vin och druvmust";
      case Rapporttyper.VinODruvmust:
        return "Vin och druvmust";
        case Rapporttyper.StatistikrapportTekniskSprit:
          return "Statistikrapport teknisk sprit";
      default:
        return "";
    }
  };

  const getObjOrRestNr = () => {
    switch (info.data.rapportTypId) {
      case Rapporttyper.Restaurangrapport:
        return "Restaurangnummer";
      case Rapporttyper.StatistikrapportForsaljningTillverkning:
        return "Objektnummer";
      case Rapporttyper.StatistikrapportTekniskSprit:
        return "Objektnummer";
      default:
        return "";
    }
  };

  const downloadOldData = () => {
    if (
      info.data.relateradeRapporter === null ||
      info.data.relateradeRapporter === undefined
    )
      return;

    setLoadinRapportExcel(true);
    downloadOldDataExcel(
      info.data.relateradeRapporter?.map((z) => {
        return z.id;
      })
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.object], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Rapporter.xlsx");
        document.body.appendChild(link);
        link.click();
        setLoadinRapportExcel(false);
      })
      .catch((err) => {
        setLoadinRapportExcel(false);
      });
  };

  const getAnvisningsfil = () => {
    let file = File.restaurang;

    if(info.data.rapportTypId === Rapporttyper.Restaurangrapport){
      file = File.restaurang
    }
    else if(info.data.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning){
      file = File.statistik
    }
    else if(info.data.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit){
      file = File.tekniskSprit
    }


    downloadAnvisning(file).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/pdf",
        })
      );
      window.open(url);
    });
  };

  const showHideKategori = () => {
    switch (info.data.rapportTypId) {
      case Rapporttyper.Restaurangrapport:
        return true;
      case Rapporttyper.StatistikrapportForsaljningTillverkning:
        return false;
      default:
        return false;
    }
  };

  return (
    <Grid container spacing={2}>
      {info.renderExtra && (
        <>
          <Grid item xs={12}>
            <Typography variant="h1">{getHeaderWithExtra()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Rapportperiod: {info.data.rapportPeriodNamn}
                </Typography>
                <Typography>
                  Registrerad:{" "}
                  {info.data.rapportRegistreradTid !== null
                    ? moment(info.data.rapportRegistreradTid).format(
                        "yyyy-MM-DD"
                      )
                    : ""}
                </Typography>
                <Typography>
                  Registrerad av: {info.data.registreradAv}
                </Typography>
                <Typography>Status: {info.data.rapportStatusNamn}</Typography>
              </Grid>
              
              <Grid item xs={12}>
                <Typography>
                  Rapporter på samma tillstånd:{" "}
                  <Link onClick={downloadOldData}>
                    Ladda ner excel med alla rapporter
                  </Link>
                  {loadingRapportExcel && <CircularProgress />}
                </Typography>
                  {(info.data.relateradeRapporter?.some(x => x.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)) && (
                  <Typography>Statistikrapport försäljning/tillverkning:</Typography>
                  )}   
                <ListLinks>
                  {info.data?.relateradeRapporter &&
                    info.data?.relateradeRapporter.map(
                      (relateradeRapporter: IRelateradeRapporter) => {
                        if (
                          moment(`${relateradeRapporter.rapportPeriodNamn}-01-01`) >=
                          moment("2020-01-01")  && relateradeRapporter.rapportTypId != Rapporttyper.StatistikrapportTekniskSprit
                        ) {
                          return (
                            <li key={relateradeRapporter.id}>
                              <Link component={RouterLink} 
                              to={`/reports/${relateradeRapporter.id}/${info.viewPrevYear}`}
                              onClick={() => {window.location.href=`/reports/${relateradeRapporter.id}/${info.viewPrevYear}`}}>
                                {relateradeRapporter.rapportPeriodNamn}
                              </Link>
                            </li>
                          );
                        } else {
                          if (
                            relateradeRapporter.rapportPeriodNamn === "2019"
                          ) {
                            return (
                              <li key={relateradeRapporter.id}>
                                <Typography variant="caption">
                                  {relateradeRapporter.rapportPeriodNamn} {info.data?.relateradeRapporter && info.data?.relateradeRapporter.findIndex(x => x.rapportPeriodNamn ===  "2019") < info.data.relateradeRapporter.length ? " ..." : "" }
                                </Typography>
                              </li>
                            );
                          } else if (
                            info.data?.relateradeRapporter && info.data.relateradeRapporter [info.data.relateradeRapporter.length - 1].rapportPeriodNamn ===
                            relateradeRapporter.rapportPeriodNamn && relateradeRapporter.rapportPeriodNamn !== "2019"
                          ) {
                            return (
                              <li key={relateradeRapporter.id}>
                                <Typography variant="caption">
                                  {relateradeRapporter.rapportPeriodNamn}
                                </Typography>
                              </li>
                            );
                          }
                        }
                      }
                    )}
                </ListLinks>
                {(info.data.relateradeRapporter?.some(x => x.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit)) && (
                  <Typography>Statistikrapport teknisk sprit:</Typography>
                  )}
                  <ListLinks>
                    {info.data?.relateradeRapporter &&
                      info.data?.relateradeRapporter.map(
                        (relateradeRapporter: IRelateradeRapporter) => {
                          if (
                            moment(`${relateradeRapporter.rapportPeriodNamn}-01-01`) >=
                            moment("2020-01-01") && relateradeRapporter.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit
                          ) {
                            return (
                              <li key={relateradeRapporter.id}>
                                <Link component={RouterLink} 
                                to={`/reports/${relateradeRapporter.id}/${info.viewPrevYear}`}   
                                onClick={() => {window.location.href=`/reports/${relateradeRapporter.id}/${info.viewPrevYear}`}}>                         
                                  {relateradeRapporter.rapportPeriodNamn}                        
                                </Link>
                              </li>
                            );
                          }                          
                        }
                      )}
                  </ListLinks>   
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!info.renderExtra && (
            <Grid item xs={12}>
              <Typography variant="h1" component="h1">
                {getHeader()}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Link component="button" onClick={getAnvisningsfil}>
              <Typography variant="h3" component="p">Ladda ner anvisning</Typography>
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Namn: {(info.data.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (info.data.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)
              ? info.data.objektOchOrgDetaljer.havareNamn : info.data.objektOchOrgDetaljer.objektNamn}
            </Typography>
            <Typography>
              Adress: {(info.data.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (info.data.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)
              ? info.data.objektOchOrgDetaljer.havareAdress : info.data.objektOchOrgDetaljer.objektAdress}
            </Typography>
            <Typography>
              Postnummer: {(info.data.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (info.data.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)
              ? info.data.objektOchOrgDetaljer.havarePostNr : info.data.objektOchOrgDetaljer.objektPostNr}
            </Typography>
            <Typography>
              Postort: {(info.data.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (info.data.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)
              ? info.data.objektOchOrgDetaljer.havarePostOrt : info.data.objektOchOrgDetaljer.objektPostOrt}
            </Typography>
          </Grid>
 
          <Grid item xs={12}>
            <Typography>
              {getObjOrRestNr()}: {info.data.objektOchOrgDetaljer.objektNr}
            </Typography>
            <Typography>
              Organisationsnummer: {info.data.objektOchOrgDetaljer.orgNr}
            </Typography>
            <Typography>
              Rapportperiod: {info.data.rapportPeriodNamn}
            </Typography>
          </Grid>
          {info.renderExtra && (
            <>
              <Grid item xs={6}>
                <Typography>
                  Tillstånd:{" "}
                  <Link
                    component={RouterLink}
                    to={`/permits/${info.data.tillstandsId}`}
                  >
                    {" "}
                    {info.data.tillstandsId}
                  </Link>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
}
