import {
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShowAlert } from "../../Components/alerts";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import {
  GetTillstand,
  updateTillstand,
} from "../../Services/PermitService";
import { IForm, defaultVals } from "./new";
import {
  GenerateFormStructure,
  MapToSearchPermitType,
  Mode,
  SearchPermitTypes,
} from "./PermitsFormsUtils/permitsUtils";
import { EditPermitSchema } from "./validation";

export default function EditPermit() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [tillstand, setTillstand] = React.useState<IForm>({ ...defaultVals });
  const [permitId, setPermitId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [framtidaVersion, setFramtidaVersion] = React.useState(false);
  const [error, setError] = React.useState("");

  const getSetTillstand = (id: number, setActivenr: boolean = false) => {
    GetTillstand(id)
      .then((resp) => {
        const data = { ...resp.object };
        data.versionDetails.versionsStart = "";
        setTillstand({ ...data });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      setPermitId(parseInt(id));
      getSetTillstand(parseInt(id), true);
    }
  }, []);

  if (tillstand.id === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {loading
            ? <CircularProgress />
            : <Typography variant="h2">Hittade inget tillstånd med id {id}</Typography>
          }
        </Grid>
      </Grid>
    );
  }

  async function shortenTillstandsKategoriIds(values: IForm) {
    const shortenTillstandsKategoriIds: number[] = [];
    values.tillstandsKategoriIds && values.tillstandsKategoriIds.map((i) => {
      typeof i !== "number" ? shortenTillstandsKategoriIds.push(i.id) : shortenTillstandsKategoriIds.push(i)
    })
    return shortenTillstandsKategoriIds
  }

  let mappedPermit = MapToSearchPermitType(tillstand?.tillstandsTypId)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Ändra:{" "}
          {SearchPermitTypes.find((x) => x.id === tillstand?.tillstandsTypId)?.namn}{" "}
        </Typography>
      </Grid>
      {mappedPermit !== 0 && (
      <Grid item xs={12}>
        <Formik
          validationSchema={EditPermitSchema}
          enableReinitialize
          initialValues={{ ...tillstand }}
          onSubmit={ async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            const result = await shortenTillstandsKategoriIds(values);
            values.tillstandsKategoriIds = result
            updateTillstand(tillstand.id, { ...values }).then((res) => {
              setSubmitting(false);
              setStatus({ updated: true, collapseOpen: true, statusText: "Ny version skapad. Gå tillbaka.", statusTitle:"Uppdaterat", severity:"success" });
              navigate(`/permits/${tillstand.id}`);
            }).catch(err => {
              if (err.status === 400) {
                setStatus({ updated: false, collapseOpen: true, statusText: `${err.data}`, statusTitle:"Valideringen misslyckades", severity:"error" });
                setSubmitting(false);
              } else {
                setStatus({ updated: false, collapseOpen: true, statusText: `${err.data}`, statusTitle:"Något gick fel", severity:"error" });
                setSubmitting(false);
              }
            });
          }}
        >
          {(formikProps) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              <Grid container spacing={2}>
                {GenerateFormStructure(
                  MapToSearchPermitType(mappedPermit),
                  formikProps,
                  Mode.Edit
                )}
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault
                      disabled={
                        formikProps.isSubmitting ||
                        (formikProps.status !== undefined &&
                          formikProps.status.updated)
                      }
                      type="submit"
                    >
                      Spara
                    </BtnDefault>
                    <BtnSecond
                      onClick={() => {
                        navigate("/permits");
                      }}
                    >
                      Tillbaka
                    </BtnSecond>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Collapse
                    in={
                      formikProps.status !== undefined &&
                      formikProps.status.collapseOpen
                    }
                  >
                    <ShowAlert
                      alertText={formikProps.status?.statusText}
                      alertTitle={formikProps.status?.statusTitle}
                      severity={formikProps.status?.severity}
                      onClose={() => {
                        formikProps.setStatus({
                          ...formikProps.status,
                          collapseOpen: false,
                        });
                      }}
                    />
                  </Collapse>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      )}
    </Grid>
  );
}
