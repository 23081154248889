import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Utilities/authProvider";
import { Roles } from "./roles";

function CheckAndRedirect(children: JSX.Element, redirect: string, checkRoles: boolean = false, negate: boolean = false, roles: Roles[] = null!) {
    let auth = useAuth();
    let location = useLocation();

    //Check so that we only should validate that a user is signed in
    if (!checkRoles && !negate && auth.checkIfUserIsSignedIn()) {
        return children;
    }
    //Check so that a user is not signed in
    else if (!checkRoles && negate && !auth.checkIfUserIsSignedIn()) {
        return children;
    }
    //Check so that a user is signed in and has the correct roles
    else if (checkRoles && auth.checkIfUserIsSignedIn() && auth.validateUserRoleAccess(roles)) {
        return children;
    }
    //Redirects if no condition is fullfiled
    console.log("redirect")
    return <Navigate to={redirect} state={{ from: location }} replace />

}

//This we call to wrap content and validate that we have signed in user otherwise we redirect
export function RequireAuth({ children }: { children: JSX.Element }) {
    return CheckAndRedirect(children, "/", false);
}

//This we call to wrap content that validate so if a user is signed in can't navigate to example the sign in page
export function RequireAuthNegeted({ children }: { children: JSX.Element }) {
    return CheckAndRedirect(children, "/home", false, true);
}

//This we call to wrap content that is roles based access and signed in access restricted. Pass the roles required
export function RequireAuthRole({ children, roles, redirectTo }: { children: JSX.Element, roles: Roles[], redirectTo: string }) {
    return CheckAndRedirect(children, redirectTo, true, false, roles);
}