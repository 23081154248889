import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import { GridWithCaption, InputWithDefaults, Section } from "./mainForm";

export default function GodkannandeStat(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode
) {
  //TODO check route if we are not externally signed in remove OBS! ....

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">Kontaktperson</Typography>
          <Typography variant="caption">
            Ange telefonnummer och/eller e-postadress till den vi kan kontakta
            vid frågor om lämnade uppgifter. Uppgiftslämnare och kontaktperson
            ska alltid anges (även om det är samma person).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Namn uppgiftslämnare",
                  "uppgiftslamnare",
                  props.values.uppgiftslamnare,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  [
                    "kontaktPersonNamn",
                    "kontaktPersonTelefon",
                    "kontaktPersonEpost",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Kontaktperson",
                  "kontaktPersonNamn",
                  props.values.kontaktPersonNamn,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  [
                    "uppgiftslamnare",
                    "kontaktPersonTelefon",
                    "kontaktPersonEpost",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Telefonnummer",
                  "kontaktPersonTelefon",
                  props.values.kontaktPersonTelefon,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  ["uppgiftslamnare", "kontaktPersonNamn", "kontaktPersonEpost"]
                )}

                {InputWithDefaults<IUpdateRapportDetails>(
                  "E-postadress för mottagningsbekräftelse",
                  "kontaktPersonEpost",
                  props.values.kontaktPersonEpost,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "",
                  "text",
                  [
                    "uppgiftslamnare",
                    "kontaktPersonNamn",
                    "kontaktPersonTelefon",
                  ]
                )}
              </GridWithCaption>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
