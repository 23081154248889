import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IHelperText } from "../HelperText/baseHelperText";

export interface HelpTextProps extends IHelperText {
  title: string;
  text: string[];
  subText?: string;
}

export const HelpText = (props: HelpTextProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={handleOpen}
        aria-label="visa mer information"
        variant="contained"
        startIcon={<InfoIcon />}
      >
        Information
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        role="dialog"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || "Information"}
        </DialogTitle>
        <DialogContent dividers>
          {props.text?.map((str: string, index: number) => {
            return (
              <DialogContentText
                key={index}
                id="alert-dialog-description"
                sx={{ mt: 2, mb: 2 }}
              >
                {str}
              </DialogContentText>
            );
          })}
          {props.subText && (
            <DialogContentText id="alert-dialog-description">
              {props.subText}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Stäng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
