import { FormControl, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { AutoCompleteGroupedAtr } from "../../../Components/autoCompleteGrouped";
import { InfoType } from "../../../Components/selects";
import InputTextField from "../../../Components/textfield";
import { RadioCustom } from "../../../Components/radioCustom";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { disabledEnabled, Mode, Permits } from "./permitsUtils";
import OutlinedTextField from "../../../Components/textfield";
import { ITillstandsKategorier } from "../../../Services/infoService";
import { ITillstandsKategoriObjekt } from "../../../Services/PermitService";

export function FormTypeServeringensOmfattning(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode
) {
  /**
   * @param data
   * @returns group as number
   *
   * 1 = Ej specificerat
   * 2 = Tillstånd till allmänheten
   * 3 = Tillstånd till slutna sällskap
   */
  const getGroupId = (data: ITillstandsKategoriObjekt[] | number[] | null) => {
    let list, group;
    let x: string = localStorage.getItem("tillstandsTypStadigvarande") ?? null!;
    if (x !== null) {
      list = JSON.parse(x);
    }
    if (list && data) {
      group = list.find((l: ITillstandsKategorier) => l.id === data[0]);
      if (typeof data[0] === "object") {
        const tillstandsKategoriObjekt = data[0] as ITillstandsKategoriObjekt;
        group = list.find((l: ITillstandsKategorier) => l.groupId === tillstandsKategoriObjekt.tillstandsKategoriGruppId);
      }
    }
    
    return group?.groupId;
  };

  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Serveringens omfattning</Typography>
      </Grid>
      <Grid key="tillstandsKategoriStadigvarande" item xs={6}>
        <FormControl fullWidth>
          <AutoCompleteGroupedAtr
            onBlur={props.handleBlur}
            infoType={InfoType.tillstandsKategoriStadigvarande}
            label="Kategorikod"
            defaultVal={false}
            onChange={(data) => {
              props.setFieldValue("tillstandsKategoriIds", data);
            }}
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "tillstandsKategoriIds"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "tillstandsKategoriIds"
            )}
            id="tillstandsKategoriIds"
            name="tillstandsKategoriIds"
            selectedData={props.values.tillstandsKategoriIds ?? 0}
            disabled={mode === Mode.View}
            multiple={false}
            required={disabledEnabled(
              [Permits.StadigvarandeServeringstillstand],
              permit
            )}
            aria-required={
              disabledEnabled(
                [Permits.StadigvarandeServeringstillstand],
                permit
              )
                ? "true"
                : "false"
            }
          />
        </FormControl>
      </Grid>
      {getGroupId(props.values.tillstandsKategoriIds) === 2 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">Serveringstid allmänheten</Typography>
          </Grid>
          <Grid key="versionDetails.servtidNormaltidInne" item xs={12}>
            <RadioCustom
              showLabel={true}
              defaultValue="Ja"
              label="Normaltid 11:00-01:00"
              name="versionDetails.servtidNormaltidInne"
              onChange={(e) =>
                props.setFieldValue(
                  "versionDetails.servtidNormaltidInne",
                  e === "Ja" ? true : false
                )
              }
              radios={[
                { label: "Ja", value: "Ja" },
                { label: "Nej", value: "Nej" },
              ]}
              row={true}
              value={
                props.values.versionDetails.servtidNormaltidInne === true
                  ? "Ja"
                  : "Nej"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
          <Grid key="versionDetails.servtidAnnanInneFrom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidAnnanInneFrom"
              label="Annan serveringstid fr.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidAnnanInneFrom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanInneFrom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanInneFrom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidInne === false
              }
              aria-required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidInne === false
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
          <Grid key="versionDetails.servtidAnnanInneTom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidAnnanInneTom"
              label="Annan serveringstid t.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidAnnanInneTom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanInneTom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanInneTom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidInne === false
              }
              aria-required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidInne === false
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="h3">Serveringstid uteservering</Typography>
      </Grid>
      <Grid key="versionDetails.uteserveringFinns" item xs={12}>
        <RadioCustom
          showLabel={true}
          defaultValue="Ja"
          label="Uteservering"
          name="versionDetails.uteserveringFinns"
          onChange={(e) =>
            props.setFieldValue(
              "versionDetails.uteserveringFinns",
              e === "Ja" ? true : false
            )
          }
          radios={[
            { label: "Ja", value: "Ja" },
            { label: "Nej", value: "Nej" },
          ]}
          row={true}
          value={
            props.values.versionDetails.uteserveringFinns === true
              ? "Ja"
              : "Nej"
          }
          disabled={mode === Mode.View}
        />
      </Grid>
      {props.values.versionDetails.uteserveringFinns === true && (
        <>
          <Grid key="versionDetails.servtidNormaltidUte" item xs={12}>
            <RadioCustom
              showLabel={true}
              defaultValue="Ja"
              label="Normaltid 11:00-01:00"
              name="versionDetails.servtidNormaltidUte"
              onChange={(e) =>
                props.setFieldValue(
                  "versionDetails.servtidNormaltidUte",
                  e === "Ja" ? true : false
                )
              }
              radios={[
                { label: "Ja", value: "Ja" },
                { label: "Nej", value: "Nej" },
              ]}
              row={true}
              value={
                props.values.versionDetails.servtidNormaltidUte === true
                  ? "Ja"
                  : "Nej"
              }
              disabled={mode === Mode.View}
            />
          </Grid>

          <Grid key="versionDetails.servtidAnnanUteFrom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidAnnanUteFrom"
              label="Annan serveringstid fr.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidAnnanUteFrom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanUteFrom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanUteFrom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidUte === false
              }
              aria-required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidUte === false
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
          <Grid key="versionDetails.servtidAnnanUteTom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidAnnanUteTom"
              label="Annan serveringstid t.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidAnnanUteTom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanUteTom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidAnnanUteTom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidUte === false
              }
              aria-required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && props.values.versionDetails.servtidNormaltidUte === false
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
        </>
      )}
      {(getGroupId(props.values.tillstandsKategoriIds) === 3 || getGroupId(props.values.tillstandsKategoriIds) === 2) && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">Serveringstid slutna sällskap</Typography>
          </Grid>
          <Grid key="versionDetails.servtidSlutnaFrom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidSlutnaFrom"
              label="Serveringstid fr.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidSlutnaFrom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidSlutnaFrom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidSlutnaFrom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && getGroupId(props.values.tillstandsKategoriIds) === 3
              }
              aria-required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && getGroupId(props.values.tillstandsKategoriIds) === 3
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
          <Grid key="versionDetails.servtidSlutnaTom" item xs={6}>
            <InputTextField
              fullWidth
              name="versionDetails.servtidSlutnaTom"
              label="Serveringstid t.o.m. klockan (TT:MM)"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.versionDetails.servtidSlutnaTom ?? ""}
              error={evalErrors(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidSlutnaTom"
              )}
              helperText={getErrorText(
                props.errors.versionDetails,
                props.touched.versionDetails ?? {},
                "servtidSlutnaTom"
              )}
              required={
                disabledEnabled(
                  [Permits.StadigvarandeServeringstillstand],
                  permit
                ) && getGroupId(props.values.tillstandsKategoriIds) === 3
              }
              aria-required={
                disabledEnabled([Permits.StadigvarandeServeringstillstand], permit)
                  ? "true"
                  : "false"
              }
              disabled={mode === Mode.View}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="h2">Serveringsutrymme</Typography>
      </Grid>
      <Grid key="versionDetails.antalPersonerMax" item xs={6}>
        <InputTextField
          fullWidth
          name="versionDetails.antalPersonerMax"
          label="Högsta antal personer i serveringslokaler"
          // onChange={props.handleChange}
          onChange={(e) => {
            const { value } = e.target;
            const regex = /^\d*$/;
            if (regex.test(value.toString()) || value === "") {
              props.setFieldValue("versionDetails.antalPersonerMax", value === "" ? null : parseInt(value, 10));
            }
 
          }}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.antalPersonerMax ?? ''}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid
        key="versionDetails.antalPersonerMaxBeslutRaddningstjanst"
        item
        xs={12}
      >
        <Typography variant="h3">Enligt räddningstjänstens beslut</Typography>
        <RadioCustom
          showLabel={false}
          defaultValue="Nej"
          label="Enligt räddningstjänstens beslut"
          name="versionDetails.antalPersonerMaxBeslutRaddningstjanst"
          onChange={(e) =>
            props.setFieldValue(
              "versionDetails.antalPersonerMaxBeslutRaddningstjanst",
              e === "Ja" ? true : false
            )
          }
          radios={[
            { label: "Ja", value: "Ja" },
            { label: "Nej", value: "Nej" },
          ]}
          row={true}
          value={
            props.values.versionDetails
              .antalPersonerMaxBeslutRaddningstjanst === true
              ? "Ja"
              : "Nej"
          }
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.antalSittplatserInne" item xs={6}>
        <InputTextField
          fullWidth
          name="versionDetails.antalSittplatserInne"
          label="Antal sittplatser i bordsavdelning/matsal"
          // onChange={props.handleChange}
          onChange={(e) => {
            const { value } = e.target;
            const regex = /^\d*$/;
            if (regex.test(value.toString()) || value === "") {
              props.setFieldValue("versionDetails.antalSittplatserInne", value === "" ? null : parseInt(value, 10));
            }
          }}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.antalSittplatserInne ?? ''}
          // required={disabledEnabled([Permits.StadigvarandeServeringstillstand], permit)}
          // aria-required={disabledEnabled([Permits.StadigvarandeServeringstillstand], permit) ? "true" : "false"}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.antalSittplatserUte" item xs={6}>
        <InputTextField
          fullWidth
          name="versionDetails.antalSittplatserUte"
          label="Antal sittplatser i uteservering"
          // onChange={props.handleChange}
          onChange={(e) => {
            const { value } = e.target;
            const regex = /^\d*$/;
            if (regex.test(value.toString()) || value === "") {
              props.setFieldValue("versionDetails.antalSittplatserUte", value === "" ? null : parseInt(value, 10));
            }
          }}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.antalSittplatserUte ?? ''}
          // required={disabledEnabled([Permits.StadigvarandeServeringstillstand], permit)}
          // aria-required={disabledEnabled([Permits.StadigvarandeServeringstillstand], permit) ? "true" : "false"}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.servUtrymmeStadigvarande" item xs={12}>
        <OutlinedTextField
          name="versionDetails.servUtrymmeStadigvarande"
          label="Utrymme inom vilka alkoholservering ska ske"
          type="text"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.servUtrymmeStadigvarande ? props.values.versionDetails.servUtrymmeStadigvarande : "" }
          fullWidth
          multiline
          rows={4}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.villkor" item xs={12}>
        <Typography variant="h2">Villkor</Typography>
        <OutlinedTextField
          name="versionDetails.villkor"
          label="Villkor"
          type="text"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.versionDetails.villkor ? props.values.versionDetails.villkor : ""}
          fullWidth
          multiline
          rows={4}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.erbjudaProvsmakning" item xs={12}>
        <Typography variant="h3">
          Rätt att erbjuda provsmakning av egentillverkade alkoholdrycker
        </Typography>
        <RadioCustom
          showLabel={false}
          defaultValue="Nej"
          label="Rätt att erbjuda provsmakning av egentillverkade alkoholdrycker"
          name="versionDetails.erbjudaProvsmakning"
          onChange={(e) =>
            props.setFieldValue(
              "versionDetails.erbjudaProvsmakning",
              e === "Ja" ? true : false
            )
          }
          radios={[
            { label: "Ja", value: "Ja" },
            { label: "Nej", value: "Nej" },
          ]}
          row={true}
          value={
            props.values.versionDetails.erbjudaProvsmakning === true
              ? "Ja"
              : "Nej"
          }
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid key="versionDetails.framstallaSnaps" item xs={12}>
        <Typography variant="h3">Rätt att framställa snaps</Typography>
        <RadioCustom
          showLabel={false}
          defaultValue="Nej"
          label="Rätt att framställa snaps"
          name="versionDetails.framstallaSnaps"
          onChange={(e) =>
            props.setFieldValue(
              "versionDetails.framstallaSnaps",
              e === "Ja" ? true : false
            )
          }
          radios={[
            { label: "Ja", value: "Ja" },
            { label: "Nej", value: "Nej" },
          ]}
          row={true}
          value={
            props.values.versionDetails.framstallaSnaps === true ? "Ja" : "Nej"
          }
          disabled={mode === Mode.View}
        />
      </Grid>
    </Grid>
  );
}
