import { Alert } from "@mui/material";
import * as React from "react";

function DisplayAlert(props: {msg: string, type: string /*, onClose: void*/} ) {
  switch (props.type) {
    case "error":
      return (
        <Alert severity="error">
          {props.msg}
        </Alert>
      );
    case "info":
      return (
        <Alert severity="info" closeText="Stäng meddelande">
          {props.msg}
        </Alert>
      );
    case "warning":
      return (
        <Alert severity="warning" role="alert" closeText="Stäng meddelande">
          {props.msg}
        </Alert>
      );
    case "success":
      return (
        <Alert severity="success" closeText="Stäng meddelande">
          {props.msg}
        </Alert>
      );
    default:
      return (
        <Alert severity="warning" role="alert" closeText="Stäng meddelande">
          {props.msg}
        </Alert>
      );
  }
}

export default DisplayAlert;
