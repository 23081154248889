import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Box, Button, Grid, TextField, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import "../../App.css";
import { ForgotPasswordSchema } from "../../Utilities/validationSchema";
import { useAuth } from "../../Utilities/authProvider";
import { Link as RouterLink } from "react-router-dom";
import UnauthLayout from "./unauthLayout";
import { ShowAlert } from "../../Components/alerts";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [token, setToken] = React.useState("");
  const captchaRef = React.useRef(null);

  return (
    <UnauthLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" align="center">
            Beställ nytt lösenord
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={{ epost: "" }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
              auth
                .forgotPasswordEmail(values.epost, token)
                .then((res) => {
                  console.log("res success: ", res.success);
                  if (res.success) {
                    resetForm();
                    setSubmitting(false);
                    setToken("");
                    captchaRef.current.resetCaptcha();
                    setStatus({
                      sent: true,
                      msg: "Instruktioner för att återställa lösenordet har skickats till e-postadressen.",
                      title: "Lösenordsåterställningen lyckades"
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                  setStatus({
                    sent: false,
                    title: "Återställning av lösenord misslyckates",
                    msg: `vänligen försök igen senare (felkod ${err.response.status}).`,
                  });
                  setToken(null);
                  captchaRef.current.resetCaptcha();
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setStatus,
            }) =>
              !status || !status.sent || status.sent === false ? (
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography variant="caption" component="p" gutterbottom>
                        Fält markerade med{" "}
                        <abbr title="Obligatoriskt fält">*</abbr> är obligatoriska
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ marginBottom: "1em" }}
                        type="email"
                        name="epost"
                        label="E-postadress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.epost}
                        error={
                          errors.epost && touched.epost && Boolean(errors.epost)
                        }
                        helperText={errors.epost && touched.epost && errors.epost}
                        fullWidth
                        aria-required="true"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <HCaptcha
                      sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                      onVerify={setToken}
                      languageOverride="sv"
                      ref={captchaRef}
                    />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                    <Box>
                      {status && !status.sent && status.msg && (
                        <ShowAlert
                          severity="error"
                          alertTitle={status.title}
                          alertText={status.msg}
                        />
                      )}
                      {status && status.sent && status.msg && (
                        <ShowAlert
                          alertText={status.msg}
                          alertTitle={status.title}
                          severity="success"
                        />
                      )}
                      <Stack direction="row" justifyContent="center" spacing={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          title="Skicka in"
                          disabled={isSubmitting || !token}
                        >
                          Skicka In
                        </Button>
                        <Button
                          variant="outlined"
                          component={RouterLink}
                          to="/"
                          title="Avbryt"
                        >
                          Avbryt
                        </Button>
                      </Stack>
                    </Box>
                    </Grid>
                  </Grid>
                </Box>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="column" alignItems="center">
                    {status && status.sent && status.msg && (
                      <ShowAlert
                      alertText={status.msg}
                      alertTitle={status.title}
                      severity="success"
                    />
                    )}
                    <Button
                      type="button"
                      title="Gå vidare"
                      variant="outlined"
                      onClick={(e) => {
                        navigate("/");
                      }}
                    >
                      Gå vidare
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>
      </Grid>
    </UnauthLayout>
  );
}
