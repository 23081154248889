import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldArray, Formik, FormikProps } from "formik";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  KeyValuesGroup,
  updateStandardvarden,
  getStandardvarde,
  Group,
} from "../../Services/UserService";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { SelectSingle } from "../../Components/selects";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { GetCheckBox } from "../../Components/checkbox";

export default function SystemSettings() {
  const [settings, setSettings] = React.useState<KeyValuesGroup[]>([]);
  const [group, setGroup] = React.useState("Standardvärde normalpriser restaurangrapport");
  const navigate = useNavigate();

  interface ISettings {
    settings: KeyValuesGroup[];
  }

  const listOfGroupedMapped = [
    {
      key: 1,
      label: "Standardvärde normalpriser restaurangrapport",
    },
    {
      key: 2,
      label: "Gränsvärden vid jämförelser försäljning/tillverkning",
    },
    {
      key: 3,
      label: "Gränsvärden rimlighetskontroll restaurangrapporter",
    },
  ];

  useEffect(() => {
    GetSetNormalPrices(group === "Standardvärde normalpriser restaurangrapport" ? "Standardvarde" : group === "Gränsvärden vid jämförelser försäljning/tillverkning" ? "Statistik" : "Gransvarde");
  }, [group]);

  const GetSetNormalPrices = (group: Group) => {
    getStandardvarde(group).then((response) => {
      if (response.success) {
        setSettings([...response.object]);
      }
    });
  };

  const getIndex = (settings: KeyValuesGroup[], name: string) => {
    var index = settings.findIndex(p => p.key === name);
    return index
  };

  const renderContent = (type: string, formikProps: FormikProps<ISettings>) => {
    if (type === "Statistik") {
      return (
        <>
          {/* <Grid item xs={12}>
            <GetCheckBox
              checked={
                formikProps.values.settings[0].value.toLocaleLowerCase() ===
                "true"
                  ? true
                  : false
              }
              label="Jämför endast om minst två tidigare rapporter finns"
              name={`settings[0].value`}
              onChange={(e, c) => {
                formikProps.setFieldValue(`settings[0].value`, c.toString());
              }}
              mainLabel=""
            />
          </Grid> */}

          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Gränsvärden för Summa försäljning inom Sverige i procent
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal ökning"
                name="SaleMaxIncrease"
                value={formikProps.values.settings[10].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[10].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal minskning"
                name="SaleMaxDecrease"
                value={formikProps.values.settings[9].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[9].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Gränsvärden för Summa import/införsel i procent
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal ökning"
                name="ImportMaxIncrease"
                value={formikProps.values.settings[6].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[6].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal minskning"
                name="ImportMaxDecrease"
                value={formikProps.values.settings[5].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[5].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Gränsvärden för Summa export/utförsel i procent
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal ökning"
                name="ExportMaxIncrease"
                value={formikProps.values.settings[4].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[4].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal minskning"
                name="ExportMaxDecrease"
                value={formikProps.values.settings[3].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[3].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Gränsvärden för Summa tillverkning i procent
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal ökning"
                name="ProductionMaxIncrease"
                value={formikProps.values.settings[8].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[8].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal minskning"
                name="ProductionMaxDecrease"
                value={formikProps.values.settings[7].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[7].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Gränsvärden för summa beräknad tillverkning i procent
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal ökning"
                name="ExpectedProductionMaxIncrease"
                value={formikProps.values.settings[2].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[2].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximal minskning"
                name="ExpectedProductionMaxDecrease"
                value={formikProps.values.settings[1].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[1].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
        </>
      );
    } else if (type === "Standardvarde") {
      return (
        <>
        <Grid container direction="column" xs={6} spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              type="number"
              label="Normalpris spritdryck 1 cl"
              name="NormalprisSpritdryck"
              value={formikProps.values.settings[4].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="number"
              label="Normalpris vin 75cl"
              name="NormalprisVin"
              value={formikProps.values.settings[7].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
            </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} sx={{mt: "6px"}}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris andra jästa alkoholdrycker"
              name="NormalprisAndraJastaDrycker"
              value={formikProps.values.settings[2].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris andra jästa alkoholdrycker avser volym (cl)"
              name="NormalprisAndraJastaDryckerAvserVolym"
              value={formikProps.values.settings[3].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} sx={{mt: "6px"}}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris starköl"
              name="NormalprisStarkol"
              value={formikProps.values.settings[5].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris starköl avser volym (cl)"
              name="NormalprisStarkolAvserVolym"
              value={formikProps.values.settings[6].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} sx={{mt: "6px"}}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris alkoholdrycksliknande preparat"
              name="NormalprisAlkoPrep"
              value={formikProps.values.settings[0].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Normalpris alkoholdrycksliknande preparat avser volym (cl)"
              name="NormalprisAlkoPrepAvserVolym"
              value={formikProps.values.settings[1].value}
              onChange={(e) => {
                formikProps.setFieldValue(
                  `settings[${getIndex(settings, e.target.name)}].value`,
                  e.target.value.toString()
                );
              }}
            />
          </Grid>
        </Grid>
        </>
      );
    } else if (type === "Gransvarde") {
      return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Spritdryck</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde min"
                name="AvvikelseOmsSpritdryckMin"
                value={formikProps.values.settings[5].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde max"
                name="AvvikelseOmsSpritdryckMax"
                value={formikProps.values.settings[4].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">Vin</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde min"
                name="AvvikelseOmsVinMin"
                value={formikProps.values.settings[9].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde max"
                name="AvvikelseOmsVinMax"
                value={formikProps.values.settings[8].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Andra jästa drycker
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde min"
                name="AvvikelseOmsAndraJastaDryckerMin"
                value={formikProps.values.settings[3].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde max"
                name="AvvikelseOmsAndraJastaDryckerMax"
                value={formikProps.values.settings[2].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">Starköl</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde min"
                name="AvvikelseOmsStarkolMin"
                value={formikProps.values.settings[7].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde max"
                name="AvvikelseOmsStarkolMax"
                value={formikProps.values.settings[6].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: "8px"}}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Alkoholdrycksliknande preparat
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde min"
                name="AvvikelseOmsAlkoPrepMin"
                value={formikProps.values.settings[1].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Gränsvärde max"
                name="AvvikelseOmsAlkoPrepMax"
                value={formikProps.values.settings[0].value}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `settings[${getIndex(settings, e.target.name)}].value`,
                    e.target.value.toString()
                  );
                }}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="h1" variant="h1" paragraph>
          Systeminställningar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectSingle
              label="Välj inställningsvärden"
              fullWidth
              data={listOfGroupedMapped}
              selected={
                listOfGroupedMapped.find((p) => p.label === group)?.key ?? 1
              }
              onChange={(e) => {
                let x =
                  listOfGroupedMapped.find((p) => p.key === e.target.value)
                    ?.label ?? "Gränsvärde";
                setGroup(x);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={8} md={10} xl={8}>
        <Formik
          enableReinitialize
          initialValues={{ settings: [...settings] }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            updateStandardvarden([...values.settings], group === "Standardvärde normalpriser restaurangrapport" ? "Standardvarde" : group === "Statistik" ? "Statistik" : "Gransvarde")
              .then((resp) => {
                setSubmitting(false);
                setStatus({
                  sent: true,
                  msg: "Systeminställningarna är sparade",
                  title: "Uppdaterat",
                  severity: "success",
                });
              })
              .catch((error) => {
                setStatus({
                  sent: true,
                  msg: error.data,
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {(formikProps) => (
            <Box component="form" onSubmit={formikProps.handleSubmit}>
              {formikProps.values.settings.length > 0 ? (
                <>
                  <FieldArray
                    name="settings"
                    render={(arrayhelpers) => (
                      <>
                        {renderContent(
                          formikProps.values.settings[0].group,
                          formikProps
                        )}
                        <Grid container spacing={2} sx={{mt: "8px"}}>
                        <Grid item xs={12}>
                          <Stack spacing={2} direction="row">
                            <BtnDefault
                              type="submit"
                              disabled={formikProps.isSubmitting}
                            >
                              Spara
                            </BtnDefault>
                            <BtnSecond
                              onClick={() => {
                                navigate("/settings");
                              }}
                            >
                              Avbryt
                            </BtnSecond>
                            {formikProps.isSubmitting && <CircularProgress />}
                          </Stack>
                        </Grid>
                        {ShowAlertEval(formikProps.status) && (
                          <Grid item xs={6}>
                            <ShowAlert
                              onClose={() => {
                                formikProps.setStatus(null);
                              }}
                              severity={formikProps.status.severity}
                              alertTitle={formikProps.status.title}
                              alertText={formikProps.status.msg}
                            />
                          </Grid>
                        )}
                      </Grid>
                      </>
                    )}
                  />
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography>Inga värden finns</Typography>
                </Grid>
              )}
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
