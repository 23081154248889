import * as Yup from "yup";

export const StatisticsSchema = Yup.object().shape({
  datum: Yup.date()
    .when("reportType", {
      is: 1,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 2,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 3,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 7,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 15,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    }),
  fromDatum: Yup.date()
    .when("reportType", {
      is: 4,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 5,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 6,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    }),
  tomDatum: Yup.date()
    .when("reportType", {
      is: 4,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 5,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 6,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    }),
});

export const StatisticsTobakSchema = Yup.object().shape({
  datum: Yup.date()
    .when("reportType", {
      is: 1,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    })
    .when("reportType", {
      is: 3,
      then: Yup.date()
        .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
        .required("Du måste ange ett datum"),
    }),
  fromDatum: Yup.date().when("reportType", {
    is: 2,
    then: Yup.date()
      .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
      .required("Du måste ange ett datum"),
  }),
  tomDatum: Yup.date().when("reportType", {
    is: 2,
    then: Yup.date()
      .typeError("Datum måste anges i korrekt format (åååå-mm-dd)")
      .required("Du måste ange ett datum"),
  }),
});
