import * as React from "react";
import { useState } from "react";
import {
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Backdrop,
  Paper,
  styled,
  List,
  ListItem,
  Link,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = styled(Modal)(({ theme }) => ({
  margin: theme.spacing(4),
  top: "10vh",
  maxHeight: "80vh",
  overflow: "auto",
}));

const ModalButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.light,
  border: `${`1px solid`} ${theme.palette.secondary.light}`,
  "&:hover": {
    border: `${`1px solid`} ${theme.palette.secondary.dark}`,
  },
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "auto",
  margin: "0 auto",
  maxWidth: 680,
  background: theme.palette.primary.contrastText,
  padding: theme.spacing(10),
}));

const CloseModalIcon = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(3),
  top: theme.spacing(3),
  padding: theme.spacing(1),
  width: 24,
  height: 24,
  color: theme.palette.primary.contrastText,
  background: theme.palette.secondary.light,
  borderRadius: "50%",
  cursor: "pointer",
  transition: "0.3s ease-in-out",
  "&:hover": {
    background: theme.palette.secondary.dark,
  },
}));

const SmallBackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: theme.palette.secondary.light,
  "&:hover": {
    background: theme.palette.secondary.dark,
  },
}));

const MyCircleIcon = styled(CircleIcon)(({ theme }) => ({
  fontSize: "0.75rem",
}));

const ApplyforKommunerModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleOpenForetagsModal = () => setOpen(true);
  const handleCloseForetagsModal = () => setOpen(false);

  return (
    <>
      <ModalButton
        size="small"
        variant="outlined"
        onClick={handleOpenForetagsModal}
      >
        Hur vi behandlar personuppgifter i din ansökan
      </ModalButton>
      <Paper>
        <CustomModal
          aria-labelledby="anvandarkonto-modal-title"
          aria-describedby="anvandarkonto-modal-description"
          open={open}
          onClose={handleCloseForetagsModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableScrollLock={true}
        >
          <Fade in={open}>
            <ModalBox id="anvandarkonto-modal-description">
              <CloseModalIcon onClick={handleCloseForetagsModal} />
              <Typography
                id="anvandarkonto-modal-title"
                variant="h1"
                component="h1"
                mb={3}
                sx={{ maxWidth: 600 }}
              >
                Användarkonto och hur vi behandlar personuppgifter
              </Typography>
              <Typography variant="body1">
                När du ansöker om användarkonto i alkohol- och tobaksregistret kommer
                Folkhälsomyndigheten att behandla dina personuppgifter i ett
                register:
              </Typography>
              <List>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Förnamn
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Efternamn
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    E-postadress
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" mt={3}>
                Vi behöver behandla dina personuppgifter för att göra det möjligt
                att lägga upp ett personligt användarkonto och kunna kommunicera
                med de personer som har ett användarkonto i alkohol- och tobaksregistret. 
              </Typography>
              <Typography>
                Vi tillämpar vid varje tid gällande integritetslagstiftning vid
                all behandling av personuppgifter. Den rättsliga grunden för att
                behandla dina personuppgifter för att lägga upp och hantera
                användarkonto är fullgörande av avtal och regleras i artikel
                6.1.b dataskyddsförordningen.
              </Typography>

              <Typography variant="body1" mt={2}>
                Personuppgiftsansvarig är: <br />
                Folkhälsomyndigheten <br />
                171 82 Solna <br />
                (organisationsnummer 202100-6545)
              </Typography>
              <Typography variant="body1" mt={4}>
                Du har rätt begära ut de uppgifter vi har om dig, för att:
              </Typography>
              <List>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Begära rättelse
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Begära överföring
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Begära att vi begränsar behandlingen
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    För att göra invändningar
                  </Typography>
                </ListItem>
                <ListItem>
                  <MyCircleIcon />
                  <Typography variant="body1" ml={1}>
                    Begära radering av dina uppgifter
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" mt={2} sx={{ maxWidth: 550 }}>
                {`Det gör du enklast genom att kontakta oss på `}
                <Link href="info@folkhalsomyndigheten.se">
                  info@folkhalsomyndigheten.se
                </Link>
                {` eller `}

                {`telefonnummer `}
                <Link href="tfn 010-205 20 00">010-205 20 00</Link>
                {` Du når vårt dataskyddsombud på `}
                <Link href="dataskyddsombud@folkhalsomyndigheten.se">
                  dataskyddsombud@folkhalsomyndigheten.se
                </Link>
                {` Om du är missnöjd med vår behandling av dina personuppgifter har
                du rätt att lämna klagomål till tillsynsmyndigheten
                Integritetsskyddsmyndigheten.`}
              </Typography>
              <SmallBackButton
                variant="contained"
                onClick={handleCloseForetagsModal}
                size="small"
              >
                Gå tillbaka till ansökan
              </SmallBackButton>
            </ModalBox>
          </Fade>
        </CustomModal>
      </Paper>
    </>
  );
};

export default ApplyforKommunerModal;
