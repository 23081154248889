import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export function InkoptaVolymer(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.inkopSpritLag,
    props.values.inkopSpritHog,
    props.values.inkopVinLag,
    props.values.inkopVinMellan,
    props.values.inkopVinHog,
    props.values.inkopAndraJastaAlkoholdryckerLag,
    props.values.inkopAndraJastaAlkoholdryckerMellan,
    props.values.inkopAndraJastaAlkoholdryckerHog,
    props.values.inkopOlLag,
    props.values.inkopOlHog,
    props.values.inkopAlkoPrepLag,
    props.values.inkopAlkoPrepHog,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);

  const sumAllValues = (): void => {
    CalcSum(props, "inkopTotalt", values);
  };

  return (
    <Grid item xs={12} key={nr} marginBottom={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {nr}. Inköpta volymer (hela liter)
          </Typography>
          <Typography variant="caption">
            Inköpt volym redovisas i liter och avrundas till antal hela liter.
            Samtliga alkoholdrycker som har levererats till serveringsstället
            under året minskat med eventuella returer ska redovisas.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Spritdrycker över 2,25, högst 22,0 volymprocent",
                  "inkopSpritLag",
                  props.values.inkopSpritLag,
                  props,
                  sumAllValues,
                  {
                    inkopSpritLag: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopSpritHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Spritdrycker över 22,0 volymprocent",
                  "inkopSpritHog",
                  props.values.inkopSpritHog,
                  props,
                  sumAllValues,
                  {
                    inkopSpritHog: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopSpritLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Spritdrycker över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.inkopSpritLag
                    )}
                    {InputComparePrevYear(
                      "Spritdrycker över 22,0 volymprocent",
                      prevYearObj?.inkopSpritHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Vin över 2,25, högst 7,0 volymprocent",
                  "inkopVinLag",
                  props.values.inkopVinLag,
                  props,
                  sumAllValues,
                  {
                    inkopVinLag: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopVinMellan", "inkopVinHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Vin över 7,0, högst 15,0 volymprocent",
                  "inkopVinMellan",
                  props.values.inkopVinMellan,
                  props,
                  sumAllValues,
                  {
                    inkopVinMellan: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopVinLag", "inkopVinHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Vin över 15,0, högst 22,0 volymprocent",
                  "inkopVinHog",
                  props.values.inkopVinHog,
                  props,
                  sumAllValues,
                  {
                    inkopVinHog: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopVinMellan", "inkopVinLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Vin över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.inkopVinLag
                    )}
                    {InputComparePrevYear(
                      "Vin över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.inkopVinMellan
                    )}
                    {InputComparePrevYear(
                      "Vin över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.inkopVinHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker över 2,25, högst 7,0 volymprocent",
                  "inkopAndraJastaAlkoholdryckerLag",
                  props.values.inkopAndraJastaAlkoholdryckerLag,
                  props,
                  sumAllValues,
                  {
                    inkopAndraJastaAlkoholdryckerLag: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  [
                    "inkopAndraJastaAlkoholdryckerMellan",
                    "inkopAndraJastaAlkoholdryckerHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker över 7,0, högst 15,0 volymprocent",
                  "inkopAndraJastaAlkoholdryckerMellan",
                  props.values.inkopAndraJastaAlkoholdryckerMellan,
                  props,
                  sumAllValues,
                  {
                    inkopAndraJastaAlkoholdryckerMellan: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  [
                    "inkopAndraJastaAlkoholdryckerLag",
                    "inkopAndraJastaAlkoholdryckerHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Andra jästa alkoholdrycker över 15,0, högst 22,0 volymprocent",
                  "inkopAndraJastaAlkoholdryckerHog",
                  props.values.inkopAndraJastaAlkoholdryckerHog,
                  props,
                  sumAllValues,
                  {
                    inkopAndraJastaAlkoholdryckerHog: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  [
                    "inkopAndraJastaAlkoholdryckerLag",
                    "inkopAndraJastaAlkoholdryckerMellan",
                  ]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.inkopAndraJastaAlkoholdryckerLag
                    )}
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.inkopAndraJastaAlkoholdryckerMellan
                    )}
                    {InputComparePrevYear(
                      "Andra jästa alkoholdrycker över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.inkopAndraJastaAlkoholdryckerHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Starköl över 3,5 högst 5,6 volymprocent",
                  "inkopOlLag",
                  props.values.inkopOlLag,
                  props,
                  sumAllValues,
                  {
                    inkopOlLag: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopOlHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Starköl över 5,6 volymprocent",
                  "inkopOlHog",
                  props.values.inkopOlHog,
                  props,
                  sumAllValues,
                  {
                    inkopOlHog: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopOlLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Starköl över 3,5 högst 5,6 volymprocent",
                      prevYearObj?.inkopOlLag
                    )}
                    {InputComparePrevYear(
                      "Starköl över 5,6 volymprocent",
                      prevYearObj?.inkopOlHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Alkoholdrycksliknande preparat över 2,25, högst 5,0 volymprocent",
                  "inkopAlkoPrepLag",
                  props.values.inkopAlkoPrepLag,
                  props,
                  sumAllValues,
                  {
                    inkopAlkoPrepLag: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopAlkoPrepHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Alkoholdrycksliknande preparat över 5,0 volymprocent",
                  "inkopAlkoPrepHog",
                  props.values.inkopAlkoPrepHog,
                  props,
                  sumAllValues,
                  {
                    inkopAlkoPrepHog: true,
                  },
                  mode,
                  "Volym",
                  "number",
                  ["inkopAlkoPrepLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Alkoholdrycksliknande preparat över 2,25, högst 5,0 volymprocent",
                      prevYearObj?.inkopAlkoPrepLag
                    )}
                    {InputComparePrevYear(
                      "Alkoholdrycksliknande preparat över 5,0 volymprocent",
                      prevYearObj?.inkopAlkoPrepHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Summa inköpta volymer",
                  "inkopTotalt",
                  props.values.inkopTotalt,
                  props,
                  undefined,
                  { inkopTotalt: true },
                  Mode.Disabled,
                  "Volym"
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Summa inköpta volymer",
                      prevYearObj?.inkopTotalt
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
