import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { IMainBaseResponse } from "../../Services/base";
import {
  exportLicensor,
  ILicensorSearchFilter,
  ILicensorSearchResponse,
  searchLicensors,
} from "../../Services/LicensorService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { useFilter } from "../../Utilities/searchFilterProvider";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import { ShowAlert } from "../../Components/alerts";
import { Box, FormControl, Grid, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import InputTextField from "../../Components/textfield";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { InfoType } from "../../Components/selects";
import { useAuth } from "../../Utilities/authProvider";
import { Roles } from "../../Utilities/roles";

export default function Licensors() {
  const filterProvider = useFilter();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [licensors, setLicensors] = React.useState<
    IMainBaseResponse<ISearchResultBase<ILicensorSearchResponse>>
  >(null!);
  const [errors, setErrors] = React.useState<any>(null);
  const [kommuner, setKommuner] = React.useState<InfoType>(
    InfoType.kommuner
  );
  const auth = useAuth();

  function getBaseFilter(): ISearchBase<ILicensorSearchFilter> {
    return {
      filter: {
        namn: "",
        kommunId: 0,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "kommun",
      sorteringsOrdning: SortOrder.Stigande,
    };
  }

  const checkLocation = () => {
    const filter = filterProvider.getFilter("licensors");
    if (filter !== null && filter !== undefined) {
      return filter;
    } else return getBaseFilter();
  };

  const [filter, setFilter] = React.useState<
    ISearchBase<ILicensorSearchFilter>
  >(checkLocation());
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "kommun",
    direction: SortOrder.Stigande,
  });

  const headers: ITableHead<ILicensorSearchResponse>[] = [
    {
      id: "kommun",
      sortable: true,
      value: "Kommun",
    },
    {
      id: "namn",
      sortable: true,
      value: "Namn",
    },
    {
      id: "forvaltning",
      sortable: true,
      value: "Förvaltning",
    },
    {
      id: "postOrt",
      sortable: true,
      value: "Postort",
    },
  ];

  const navigateToLicensor = (id: number) => {
    filterProvider.addFilter(filter, "licensors");
    navigate(`${id}`);
  };

  const SubmitSearch = (values: ILicensorSearchFilter) => {
    setFilter({ ...filter, filter: { ...values } });
  };

  const SubmitExport = (values: ILicensorSearchFilter) => {
    exportLicensor({ ...filter, filter: { ...values } }).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Tillstandsgivare.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    searchLicensors(filter)
      .then((data) => {
        setLicensors({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setLicensors(null!);
      });
  }, [filter]);

  useEffect(() => {
    if (licensors === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [licensors]);

  useEffect(() => {
    if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && auth.validateUserRoleAccess([Roles.KommunAdminTobak])) {
      setKommuner(InfoType.kommunerAnvandare);
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Sök kommuners adress
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} xl={8}>
        <Formik
          enableReinitialize
          initialValues={{ ...filter.filter }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SubmitSearch(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            handleSubmit,
            resetForm,
            errors,
            touched,
            setFieldValue,
            handleBlur,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        defaultVal={false}
                        error={evalErrors(errors, touched, "kommunId")}
                        helperText={getErrorText(errors, touched, "kommunId")}
                        infoType={kommuner}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue("kommunId", data);
                        }}
                        name="kommunId"
                        onBlur={handleBlur}
                        selectedData={values.kommunId}
                        label="Kommun"
                      />
                    </FormControl>
                    <InputTextField
                      fullWidth
                      name="namn"
                      label="Namn"
                      onChange={handleChange}
                      value={values.namn}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault disabled={isSubmitting} type="submit">
                      Sök
                    </BtnDefault>
                    <BtnSecond
                      type="reset"
                      onClick={() => {
                        setFilter({ ...getBaseFilter() });
                        resetForm();
                      }}
                    >
                      Rensa
                    </BtnSecond>
                    <BtnSecond
                      disabled={isSubmitting}
                      onClick={() => {
                        SubmitExport(values);
                      }}
                      type="button"
                    >
                      Exportera resultat
                    </BtnSecond>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}
      {licensors && (
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Sökresultat
          </Typography>
        </Grid>
      )}
      {licensors !== null && (
        <Grid item xs={12} mb={2}>
          <>
            <DefaultTable
              header={headers}
              activePage={filter.sidoNummer}
              data={mapToCellData(headers, licensors.object.sokResultat)}
              rowsPerPage={checkMenuItem(filter.sidoStorlek)}
              totalHits={licensors.object.totaltAntalTraffar}
              filter={filter}
              setFilterAction={setFilter}
              setSortOptions={setSortOptions}
              sortOptions={sortOptions}
              actions={[
                {
                  action: navigateToLicensor,
                  icon: ActionIcons.arrowForward,
                  title: "Visa mer",
                },
              ]}
              tableAria="Tillståndsgivare"
            />
          </>
        </Grid>
      )}
    </Grid>
  );
}
