import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import InputTextField from "../../Components/textfield";
import {
  getKontakt,
  IKontaktGet,
  putKontakt,
  deleteKontakt,
} from "../../Services/ContactService";
import { useAuth } from "../../Utilities/authProvider";
import {
  KommunKontaktTyp,
  KommunKontaktTypes,
} from "../../Utilities/contactUtils";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import { Roles } from "../../Utilities/roles";
import { newKontaktSchema } from "./validationContact";

export default function EditContact() {
  const navigate = useNavigate();
  const modal = useModal();
  const [contact, setContact] = React.useState<IKontaktGet>(null!);
  const [loading, setLoading] = React.useState(true);
  const [contactId, setContactId] = React.useState<number>(0);
  const [contactDeleted, setContactDeleted] = React.useState(false);

  let { id } = useParams();
  let auth = useAuth();

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      setLoading(true);
      setContactId(parseInt(id));
      getKontakt(parseInt(id))
        .then((resp) => {
          setContact({ ...resp.object });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const deleteContact = (
    setStatus: (status?: any) => void,
    values: IKontaktGet,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    deleteKontakt(contactId)
      .then((response) => {
        modal.showDialog({ open: false });
        setContactDeleted(true);
        setStatus({
          sent: true,
          msg: `Kontakt ${values.fornamn} ${values.efternamn} har tagits bort.`,
          title: "Kontakt borttagen",
          severity: "success",
          borttagen: true,
        });
        setSubmitting(false);
      })
      .catch((error) => {
        modal.showDialog({ open: false });
        setStatus({
          sent: true,
          msg: error.data,
          title: "Ett fel uppstod",
          severity: "error",
        });
        setSubmitting(false);
      });
  };

  const disableForRole = (): boolean => {
    if (
      auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) &&
      contact.kommunKontaktTypId ===
      KommunKontaktTypes.find(
        (p) => p.kommunKontakt === KommunKontaktTyp.UtskickAvRestaurangRapport
        )?.id
    ) {
      return false;
    }
    if (
      auth.validateUserRoleAccess([Roles.FohmAdmin])
    ) {
      return false;
    }
    return true;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Ändra kontakt
        </Typography>
      </Grid>
      {!loading ? (
        <Grid item xs={12}>
          <Grid item xs={12} md={10} mb={2}>
            <Typography variant="caption" component="p" mb={1}>
              Fält markerade med * är obligatoriska
            </Typography>
            <Formik
              enableReinitialize
              validationSchema={newKontaktSchema}
              initialValues={{ ...contact, confirmepost: contact.epost }}
              onSubmit={(values, { setSubmitting, setStatus, setValues }) => {
                setSubmitting(true);
                putKontakt(values, contactId)
                  .then((response) => {
                    setSubmitting(false);
                    setValues({
                      ...response.object,
                      confirmepost: response.object.epost,
                    });
                    setStatus({
                      sent: true,
                      msg: `Kontakt ${values.fornamn} ${values.efternamn} uppdaterades.`,
                      title: "Kontakt uppdaterad",
                      severity: "success",
                    });
                  })
                  .catch((error) => {
                    setStatus({
                      sent: true,
                      msg: error.data,
                      title: "Ett fel uppstod",
                      severity: "error",
                    });
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                setStatus,
                isSubmitting,
                setSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {!status?.borttagen && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="baseline"
                            >
                              <FormControl
                                fullWidth
                                error={evalErrors(errors, touched, "kommunId")}
                              >
                                <InputLabel id="kommuner-label">
                                  Kommun
                                </InputLabel>
                                <SelectSingleWihtoutData
                                  label="kommuner"
                                  labelId="kommuner-label"
                                  infoType={InfoType.kommuner}
                                  selected={values.kommunId}
                                  name="kommunId"
                                  disabled={true}
                                />
                              </FormControl>
                              <FormControl
                                fullWidth
                                error={evalErrors(
                                  errors,
                                  touched,
                                  "kommunKontaktTypId"
                                )}
                              >
                                <InputLabel id="kommuner-label">Typ</InputLabel>
                                <SelectSingleWihtoutData
                                  label="kommunKontaktTyper"
                                  labelId="kommunKontaktTyper-label"
                                  infoType={InfoType.kommunKontaktTyper}
                                  selected={values.kommunKontaktTypId}
                                  name="kommunKontaktTypId"
                                  disabled={true}
                                />
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems={"baseline"}
                            >
                              <InputTextField
                                disabled={disableForRole()}
                                fullWidth
                                name="fornamn"
                                label="Förnamn"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fornamn}
                                error={evalErrors(errors, touched, "fornamn")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "fornamn"
                                )}
                                required
                                aria-required="true"
                              />
                              <InputTextField
                                disabled={disableForRole()}
                                fullWidth
                                name="efternamn"
                                label="Efternamn"
                                onChange={handleChange}
                                value={values.efternamn}
                                onBlur={handleBlur}
                                error={evalErrors(errors, touched, "efternamn")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "efternamn"
                                )}
                                required
                                aria-required="true"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems={"baseline"}
                            >
                              <InputTextField
                                disabled={disableForRole()}
                                fullWidth
                                name="epost"
                                label="E-postadress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.epost}
                                error={evalErrors(errors, touched, "epost")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "epost"
                                )}
                                required
                                aria-required="true"
                              />
                              <InputTextField
                                disabled={disableForRole()}
                                fullWidth
                                name="confirmepost"
                                label="Bekräfta e-postadress"
                                onChange={handleChange}
                                value={values.confirmepost}
                                onBlur={handleBlur}
                                error={evalErrors(
                                  errors,
                                  touched,
                                  "confirmepost"
                                )}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "confirmepost"
                                )}
                                required
                                aria-required="true"
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {ShowAlertEval(status) && (
                            <Grid item xs={12}>
                              <ShowAlert
                                onClose={() => {
                                  setStatus(null);
                                }}
                                severity={status.severity}
                                alertTitle={status.title}
                                alertText={status.msg}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                              {!contactDeleted &&
                                !disableForRole() && (
                                  <>
                                    <BtnDefault
                                      disabled={isSubmitting}
                                      type="submit"
                                    >
                                      Spara
                                    </BtnDefault>
                                    <BtnDefault
                                      type="button"
                                      disabled={isSubmitting}
                                      onClick={() => {
                                        modal.showDialog({
                                          title: "Bekräfta",
                                          open: true,
                                          message: "Vill du ta bort kontakten?",
                                          actions: [
                                            {
                                              action: () => {
                                                deleteContact(
                                                  setStatus,
                                                  values,
                                                  setSubmitting
                                                );
                                              },
                                              btn: ActionType.default,
                                              text: "Ta bort",
                                            },
                                            {
                                              text: "Tillbaka",
                                              btn: ActionType.second,
                                            },
                                          ],
                                        });
                                      }}
                                    >
                                      Ta bort
                                    </BtnDefault>
                                  </>
                                )}
                              <BtnSecond onClick={() => navigate("/contacts")}>
                                Tillbaka
                              </BtnSecond>
                              {isSubmitting && <CircularProgress />}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
