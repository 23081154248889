import React, { FocusEventHandler } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import "moment/locale/sv";
import { FormControl, TextField } from "@mui/material";
import moment from "moment";
import { formats, GetDateFormatted } from "../Utilities/momentExt";


interface IDatePickerCustom {
  label: string;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (date: any, keyboardInputValue?: string) => void;
  error: boolean;
  helperText: string;
  name: string;
  value: string | null;
  disabled?: boolean;
  required: boolean;
  onAccept?: (date: any) => void; 

}

export function DatePickerCustom(props: IDatePickerCustom) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      locale={moment.locale("sv")}
    >
      <FormControl required={props.required} fullWidth>
        <DatePicker
          mask="____-__-__"
          onChange={(ev) => {
            props.onChange((ev === null || ev === "") ? null : GetDateFormatted(ev,formats.dateFormatBe));
          }}
          onAccept={(date) => {
            if (props.onAccept) {
              props.onAccept(date);
            }
          }}
          label={props.label}
          renderInput={(params) => (
            <TextField
              required={props.required}
              aria-label={props.required ? "true" : "false"}
              {...params}
              onBlur={props.onBlur}
              name={props.name}
              helperText={props.helperText}
              error={props.error}
              label={props.label}
            />
          )}
          value={props.value === "" ? null : props.value}
          disabled={props.disabled}
        ></DatePicker>
      </FormControl>
    </LocalizationProvider>
  );
}
