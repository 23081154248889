import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BtnDefault, BtnSecond } from "../../../Components/buttons";
import {
  getKommentarer,
  IKommentar,
  postKommentar,
} from "../../../Services/reportService";
import moment from "moment";
import { ShowAlert, ShowAlertEval } from "../../../Components/alerts";
import { Formik } from "formik";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { kommentarValidation } from "./kommentarValidation";

export interface IKommentarComponent {
  rapportId: number;
  disabled: boolean;
}

interface IExpanded {
  id: string;
  expanded: boolean;
}

interface ILoading {
  hamta: boolean;
  expanded: boolean;
}

export default function Kommentarer(props: IKommentarComponent) {
  const [kommentarer, setKommentarer] = React.useState<IKommentar[]>();
  const [loading, setLoading] = React.useState<ILoading>({
    hamta: true,
    expanded: true,
  });
  const [expanded, setExpanded] = React.useState<IExpanded[]>([
    { expanded: false, id: "comments" },
    { expanded: false, id: "comment" },
  ]);

  useEffect(() => {
    getKommentarer(props.rapportId).then((res) => {
      setKommentarer([...res.object]);
    });
  }, [props.rapportId]);

  useEffect(() => {
    if (kommentarer !== null && kommentarer !== undefined) {
      setLoading({ ...loading, hamta: false });
      setExpanded((prev) => {
        const nyaKommentarer = kommentarer.filter(
          (z) => prev.findIndex((x) => x.id === z.id.toString()) < 0
        );
        if (nyaKommentarer.length === 0) return prev;

        const newExpanded: IExpanded[] = nyaKommentarer.map((z) => {
          return { expanded: false, id: z.id.toString() };
        });

        return [...prev, ...newExpanded];
      });
    } else {
      setLoading({ ...loading, hamta: true });
    }
  }, [kommentarer]);

  const evalExpanded = (id: string) => {
    return expanded.findIndex((x) => x.id === id && x.expanded) > -1;
  };

  const handleChangeExpanded = (id: string) => {
    setExpanded((prev) => {
      const newState = prev.map((x) => {
        if (x.id === id) {
          return { ...x, expanded: !x.expanded };
        }
        return x;
      });
      return newState;
    });
  };

  const formatLatestComment = (kommentar: IKommentar, submitting: boolean) => {
    const calcLength = () => {
      if (kommentar.kommentar.length > 30) {
        return `${kommentar.kommentar.substring(0, 30)}...`;
      } else {
        return kommentar.kommentar;
      }
    };
    return (
      <Typography sx={!submitting ? { overflow: "auto" } : null}>
        {submitting ? (
          <CircularProgress />
        ) : (
          `
        ${kommentar.fullstandigtNamn} (${kommentar.anvandareNamn})${" "}
        ${moment(kommentar.andrad).format("yyyy-MM-DD")} -${" "}
        ${calcLength()}`
        )}
      </Typography>
    );
  };

  const renderKommentarBlock = (submitting: boolean) => {
    if (loading.hamta) {
      return <CircularProgress />;
    } else if (kommentarer !== undefined && kommentarer.length > 0) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <b>Kommentarer (totalt {kommentarer.length}):</b>{" "}
            </Typography>
          </Grid>
          <Grid item>{formatLatestComment(kommentarer[0], submitting)}</Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="body1">
            <b>Kommentarer:</b>{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Det finns inga kommentarer, lägg till genom att klicka på lägg till
            kommentar.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderKommentarerBlock = (submitting: boolean) => {
    if (loading.hamta) {
      return <CircularProgress />;
    } else if (kommentarer !== undefined && kommentarer.length > 1) {
      return kommentarer.map((z, i) => {
        if (i >= 1) {
          return (
            <Accordion
              key={z.id.toString()}
              expanded={evalExpanded(z.id.toString())}
              onChange={() => handleChangeExpanded(z.id.toString())}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={z.id.toString()}
                id={z.id.toString()}
              >
                {formatLatestComment(z, submitting)}
              </AccordionSummary>
              <AccordionDetails sx={{ overflow: "auto" }}>
                <Typography>{z.kommentar}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
      });
    }
  };

  return (
    <Formik
      validationSchema={kommentarValidation}
      initialValues={{ kommentar: "" }}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        setSubmitting(true);
        postKommentar({
          kommentar: values.kommentar,
          rapportId: props.rapportId,
        })
          .then((res) => {
            if (kommentarer !== undefined && kommentarer.length > 0) {
              setKommentarer([{ ...res.object }, ...kommentarer]);
            } else {
              setKommentarer([{ ...res.object }]);
            }
            setSubmitting(false);
            setStatus({
              sent: true,
              msg: `En ny kommentar har lagts till i listan över kommentarer`,
              title: "Kommentaren är skapad",
              severity: "success",
            });
            resetForm();
          })
          .catch((err) => {
            setSubmitting(false);
            setStatus({
              sent: true,
              msg: `Kommentaren kunde inte skapas`,
              title: "Ett fel uppstod",
              severity: "error",
            });
          });
      }}
    >
      {(formikprops) => (
        <Box
          component="form"
          sx={{ width: "100%" }}
          onSubmit={formikprops.handleSubmit}
        >
          <Accordion
            expanded={evalExpanded("comments")}
            onChange={() => handleChangeExpanded("comments")}
            key="comments"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="comments"
              id="comments"
              sx={{ margin: "0px" }}
            >
              {renderKommentarBlock(formikprops.isSubmitting)}
            </AccordionSummary>
            <AccordionDetails>
              {kommentarer !== undefined && kommentarer.length > 0 && (
                <Typography>{kommentarer[0].kommentar}</Typography>
              )}

              <Divider sx={{ marginTop: "5px", marginBottom: "10px" }} />
              {renderKommentarerBlock(false)}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={evalExpanded("comment")}
            onChange={() => handleChangeExpanded("comment")}
            key="comment"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="comment"
              id="comment"
            >
              <Typography>
                <b>Lägg till kommentar</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="caption">Handläggarkommentar</Typography>
                  <TextField
                    inputProps={{ "aria-label": `Handläggarkommentar` }}
                    fullWidth
                    rows={2}
                    multiline
                    name="kommentar"
                    value={formikprops.values.kommentar}
                    onChange={formikprops.handleChange}
                    onBlur={formikprops.handleBlur}
                    error={evalErrors(
                      formikprops.errors,
                      formikprops.touched,
                      "kommentar"
                    )}
                    helperText={getErrorText(
                      formikprops.errors,
                      formikprops.touched,
                      "kommentar"
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault
                      disabled={ formikprops.isSubmitting ||
                        (formikprops.errors !== undefined &&
                          Object.keys(formikprops.errors).length > 0)}
                      type="submit"
                    >
                      Lägg till kommentar
                    </BtnDefault>
                    {formikprops.isSubmitting && <CircularProgress />}
                  </Stack>
                </Grid>
                {ShowAlertEval(formikprops.status) && (
                  <Grid item xs={12}>
                    <ShowAlert
                      onClose={() => {
                        formikprops.setStatus(null);
                      }}
                      severity={formikprops.status.severity}
                      alertTitle={formikprops.status.title}
                      alertText={formikprops.status.msg}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Formik>
  );
}
