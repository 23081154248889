import {
  Box,
  FormControl,
  Grid,
  GridProps,
  TextField,
  Typography,
  TypographyProps,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Formik, FormikProps, FormikTouched } from "formik";
import React, { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getReportById,
  getReportId,
  GetUpdateRequest,
  IReportData,
  IUpdateRapportDetails,
  RapportStatus,
  setRapportStatus,
} from "../../../Services/reportService";
import { useAuth } from "../../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { RapportTyper, Rapporttyper, RenderForm, Mode } from "./formlogic";
import { MainInfoRapport } from "./mainInfo";
import { Skicka } from "./skicka";
import {
  StatistikrapportForsaljningTillverkningSchema,
  StatistikrapportForsaljningTillverkningSchemaHandlaggare,
} from "./s_validation";
import { permitRestaurangrapportSchema } from "./validation";
import moment from "moment";
import { ShowAlert, ShowAlertEval } from "../../../Components/alerts";
import Kommentarer from "./kommentarer";
import { TextFieldStyledForPrevReports, TextFieldStyledForReports } from "../../../Components/textfield";
import { initialValues } from "../../ApplyUserAccount/applyforForetag";


export function evalOnBlur(
  event: React.FocusEvent<any, Element>,
  props: FormikProps<IUpdateRapportDetails>,
  x: FormikTouched<IUpdateRapportDetails>
) {
  props.handleBlur(event);
  props.setTouched({ ...props.touched, ...x }, true);
}

export function TextOverInput(props: TypographyProps) {
  return (
    <Typography
      sx={{
        marginBottom: "6px",
        marginLeft: "4px",
        display: "block",
        height: "4rem",
      }}
      variant="caption"
    >
      {props?.children}
    </Typography>
  );
}

export function GridWithStyle(props: GridProps) {
  return (
    <Grid item xs={6} md={3} lg={2} sx={{ alignSelf: "self-start" }}>
      {props?.children}
    </Grid>
  );
}

export function GridWithCaption({
  children,
  caption,
}: {
  children: JSX.Element[] | JSX.Element;
  caption?: string;
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        {caption !== null && caption !== undefined && caption !== "" && (
          <Grid item xs={12}>
            <Typography variant="h4" component="h3">
              {caption}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
}

export function Section({
  children,
  head3,
}: {
  children:
    | JSX.Element[]
    | JSX.Element
    | ReactElement<any, any>
    | false
    | true
    | Element;
  head3?: string;
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {head3 !== null && head3 !== undefined && head3 !== "" && (
          <Grid item xs={12} sx={{mt: "18px"}}>
            <Typography variant="h3" component="h3"><b>{head3}</b></Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
}

export function InputComparePrevYear(
  label: string,
  value: number | string | null | undefined
) {
  return (
    <Grid item xs={6} sm={4} md={3} lg={3} sx={{ alignSelf: "self-end" }}>
      <Typography variant="caption">Föregående år {label}</Typography>
      <TextFieldStyledForPrevReports
        disabled
        fullWidth
        label={""}
        value={ifNullOrUndefined(value, "text")}
        inputProps={{ "aria-label": `${label}` }}
      />
    </Grid>
  );
}

export function InputWithDefaults<T>(
  label: string,
  name: keyof T,
  value: number | string | null | undefined,
  formikProps: FormikProps<T>,
  func?: () => void,
  propsToSetUpdate: FormikTouched<IUpdateRapportDetails> = formikProps.touched,
  mode: Mode = Mode.Disabled,
  unit: "Mängd uttryckt i liter ren alkohol" | "Liter" | "Kronor" | "Volym" | "" = "Liter",
  type: "number" | "text" = "number",
  neighbours?: Array<keyof T>
) {
  return (
    <Grid item xs={6} sm={4} md={3} lg={3} sx={{ alignSelf: "self-end" }}>
      <Typography variant="caption">{label}</Typography>
      <TextFieldStyledForReports
        disabled={
          mode === Mode.Disabled ? true : mode === Mode.Edit ? false : true
        }
        sx={{marginTop:"7px"}}
        inputProps={{ "aria-label": `${label}` }}
        type={type}
        fullWidth
        label={mode === Mode.Disabled ? "" : unit}
        name={name.toString()}
        value={ifNullOrUndefined(value, type)}
        onChange={(e) => {
          formikProps.handleChange(e)
          if (func !== undefined) func();
        }}
        error={evalErrors(formikProps.errors, formikProps.touched, name)}
        helperText={getErrorText(
          formikProps.errors,
          formikProps.touched,
          name,
          neighbours
        )}
        onBlur={(e) => {
          formikProps.handleBlur(e);
          if (e.target.type === "number") {
            formikProps.setFieldValue(
              name.toString(),
              e.target.value === "" ? null : Number(e.target.value)
            );
          } else {
            formikProps.setFieldValue(
              name.toString(),
              e.target.value === "" ? null : e.target.value
            );
          }

          formikProps.setTouched(
            { ...formikProps.touched, ...propsToSetUpdate, [name]: true },
            true
          );
          SaveLocal(formikProps);
          if (func !== undefined) func();
        }}
        onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
      />
    </Grid>
  );
}

export function ifNullOrUndefined(x: any, type: "text" | "number") {
  if (type === "text" && (x === undefined || x === null)) return "";
  else if (type === "number" && (x === undefined || x === null)) {
    return "";
  } else {
    return x;
  }
}

export const sparadRapport = "sparadRapport";

export function SaveLocal(formikProps: FormikProps<any>) {
  localStorage.setItem(sparadRapport, JSON.stringify(formikProps.values));
}

export function MainForm(
  renderExtraMainInfo: boolean = false,
  renderDesicionBtns: boolean = false,
  readonlyOverride: boolean = false
) {
  const [rapport, setRapport] = React.useState<IReportData>(null!);
  const [rapportDetails, setRapportDetails] =
    React.useState<IUpdateRapportDetails>(null!);
  const [loading, setLoading] = React.useState(true);
  const [mode, setMode] = React.useState<Mode>(Mode.Disabled);
  const [viewPrevYear, setPrevYear] = React.useState(false);
  const [prevYearObj, setPrevYearObj] = React.useState<IUpdateRapportDetails>(
    null!
  );

  const [rapportStatus, setRapportStatusLocal] = React.useState<string>(
    RapportStatus.Klar
  );
  const auth = useAuth();

  useEffect(() => {
    if (rapport === null || rapportDetails === null) {
      setLoading(true);
    } else if (viewPrevYear === true && prevYearObj === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [rapport, prevYearObj, rapportDetails]);

  useEffect(() => {
    if (rapportStatus === rapportStatus) console.log("Uppdaterar rapportState");
    setRapport({ ...rapport, rapportStatusNamn: rapportStatus });
  }, [rapportStatus]);

    const { password, id, prevYear } = useParams();

  const setRapportMode = (rapportStatus: string) => {
    //Om vi skickar med denna true ska vi alltid sätta disabled.
    if (readonlyOverride) {
      setMode(Mode.Disabled);
    } else if (
      auth.user.roles.findIndex((r) => r === Roles.RapportInlamnare) > -1 &&
      rapportStatus === RapportStatus.Skapad
    ) {
      setMode(Mode.Edit);
    } else if (

      auth.user.roles.findIndex((r) => r === Roles.RapportInlamnare) > -1 &&
      rapportStatus !== RapportStatus.Skapad
    ) {
      setMode(Mode.Disabled);
    } else if (
      (auth.user.roles.findIndex(
        (r) => r === Roles.FohmAdmin || r === Roles.KommunAdminAlkohol
      ) > -1 &&
        rapportStatus === RapportStatus.Skapad) ||
      rapportStatus === RapportStatus.Inkommen ||
      rapportStatus === RapportStatus.Granskas

    ) {
      setMode(Mode.Edit);
    } else {
      setMode(Mode.Disabled);
    }
  };

  const checkIfLocalSaveExistsAndLoad = (): null | IUpdateRapportDetails => {
    var data = localStorage.getItem(sparadRapport);
    if (data === null) return null;

    var parsedValue: IUpdateRapportDetails = JSON.parse(data);
    return parsedValue;
  };

  useEffect(() => {
    if (auth.user.username === "") return;

    if (id !== null && id !== undefined) {
      getReportById(id, prevYear === "true" ? true : false).then((res) => {
        setRapport({ ...res.object });
        setRapportStatusLocal(res.object.rapportStatusNamn);
        if (res.object.foregaendeArsRapportId !== null) {
          setPrevYear(true);
          getReportById(res.object.foregaendeArsRapportId.toString()).then(
            (preRes) => {
              setPrevYearObj({
                ...preRes.object.restaurangRapportDetaljer,
                ...preRes.object.forsaljningTillverkningRapportDetaljer,
                ...preRes.object.tekniskSpritRapportDetaljer,
                kontaktPersonEpost: preRes.object.kontaktPersonEpost,
                kontaktPersonNamn: preRes.object.kontaktPersonNamn,
                kontaktPersonTelefon: preRes.object.kontaktPersonTelefon,
                uppgiftslamnare: preRes.object.uppgiftslamnare,
                handlaggarkommentar: preRes.object.handlaggarkommentar,
                rapportId: preRes.object.id,
                rapportTypId: preRes.object.rapportTypId,
                godkann: false,
                objektOchOrgDetaljer: { ...preRes.object.objektOchOrgDetaljer },
              });
            }
          );
        }

        const localData = checkIfLocalSaveExistsAndLoad();
        if (
          localData !== null &&
          res.object.rapportStatusNamn === RapportStatus.Skapad &&
          localData.rapportId !== null &&
          localData.rapportId !== undefined &&
          localData.rapportId === res.object.id
        ) {
          setRapportDetails({ ...localData });
        } else {
          console.log("reportDetails");
          setRapportDetails({
            ...res.object.restaurangRapportDetaljer,
            ...res.object.forsaljningTillverkningRapportDetaljer,
            ...res.object.tekniskSpritRapportDetaljer,
            kontaktPersonEpost: res.object.kontaktPersonEpost,
            kontaktPersonNamn: res.object.kontaktPersonNamn,
            kontaktPersonTelefon: res.object.kontaktPersonTelefon,
            uppgiftslamnare: res.object.uppgiftslamnare,
            handlaggarkommentar: res.object.handlaggarkommentar,
            rapportId: res.object.id,
            rapportTypId: res.object.rapportTypId,
            godkann: false,
            objektOchOrgDetaljer: { ...res.object.objektOchOrgDetaljer },
          });
        }
        setRapportStatusLocal(res.object.rapportStatusNamn);
        setRapportMode(res.object.rapportStatusNamn);
      });
    } else {
      getReportId(password).then((res) => {
        setRapport({ ...res.object });
        const localData = checkIfLocalSaveExistsAndLoad();
        if (
          localData !== null &&
          res.object.rapportStatusNamn === RapportStatus.Skapad &&
          localData.rapportId !== null &&
          localData.rapportId !== undefined &&
          localData.rapportId === res.object.id
        ) {
          setRapportDetails({ ...localData });
        } else {
          setRapportDetails({
            ...res.object.restaurangRapportDetaljer,
            ...res.object.forsaljningTillverkningRapportDetaljer,
            ...res.object.tekniskSpritRapportDetaljer,
            kontaktPersonEpost: res.object.kontaktPersonEpost,
            kontaktPersonNamn: res.object.kontaktPersonNamn,
            kontaktPersonTelefon: res.object.kontaktPersonTelefon,
            uppgiftslamnare: res.object.uppgiftslamnare,
            handlaggarkommentar: res.object.handlaggarkommentar,
            rapportId: res.object.id,
            rapportTypId: res.object.rapportTypId,
            godkann: false,
            objektOchOrgDetaljer: { ...res.object.objektOchOrgDetaljer },
          });
        }
        setRapportMode(res.object.rapportStatusNamn);
        setRapportStatusLocal(res.object.rapportStatusNamn);
      });
    }
  }, [auth.user, id]);

  const getValidationSchema = () => {
    switch (rapport.rapportTypId) {
      case Rapporttyper.Restaurangrapport:
        return permitRestaurangrapportSchema;
      case Rapporttyper.StatistikrapportForsaljningTillverkning: 
          return StatistikrapportForsaljningTillverkningSchemaHandlaggare;
      case Rapporttyper.StatistikrapportTekniskSprit : 
          return StatistikrapportForsaljningTillverkningSchemaHandlaggare;
    }
  };

  const renderCommentSection = () => {
    if (auth.validateUserRoleAccess([Roles.RapportInlamnare])) {
      return null;
    } else if (
      auth.validateUserRoleAccess([Roles.FohmAdmin, Roles.KommunAdminAlkohol])
    ) {
      return (
        <>
          <Grid item xs={12}>
            <Divider sx={{ margin: "0px", marginBottom: "10px" }} />
            <Typography variant="h2">Kommentarer</Typography>
          </Grid>
          <Grid sx={{ marginBottom: "15px" }} item xs={12}>
            <Kommentarer rapportId={rapport.id} disabled={false} />
          </Grid>
        </>
      );
    } else if (auth.validateUserRoleAccess([Roles.FohmLasAlkohol])) {
      <>
        <Grid item xs={12}>
          <Divider sx={{ margin: "0px", marginBottom: "10px" }} />
          <Typography variant="h2">Kommentarer</Typography>
        </Grid>
        <Grid sx={{ marginBottom: "15px" }} item xs={12}>
          <Kommentarer rapportId={rapport.id} disabled />
        </Grid>
      </>;
    }
  };

  const getRapportTyp = () => {
    const rapportTyp = RapportTyper.find(
      (r) => r.id === rapport.rapportTypId
    )?.rapportTyp;
    if (rapportTyp === undefined || rapportTyp === null)
      throw Error("No reporttype found this should not happen.");

    return rapportTyp;
  };

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid item key="main" xs={12}>
          <CircularProgress color="primary" size={"1em"} />
        </Grid>
      ) : (
        <>
          <Grid item key="main" xs={12}>
            <MainInfoRapport viewPrevYear={viewPrevYear} data={rapport} renderExtra={renderExtraMainInfo}  />
          </Grid>
          <Grid key="formik" item xs={12}>
            <Formik
              enableReinitialize
              validationSchema={getValidationSchema()}
              initialValues={{ ...rapportDetails }}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                GetUpdateRequest(values?.rapportTypId, values)
                  .then((res) => {
                    setSubmitting(false);

                    let message = `Rapporten för ${ rapport.objektOchOrgDetaljer.objektNamn} har registrerats ${moment().format("yyyy-MM-DD")}. En mottagningsbekräftelse skickas till angiven e-postadress. Du kan spara en kopia (PDF-dokument) genom att klicka "Skriv ut".`;

                    if(rapport.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit || Rapporttyper.StatistikrapportForsaljningTillverkning){
                        message = `Rapporten för ${
                        rapport.objektOchOrgDetaljer.havareNamn
                      } har registrerats ${moment().format("yyyy-MM-DD")}. En mottagningsbekräftelse skickas till angiven e-postadress. Du kan spara en kopia (PDF-dokument) genom att klicka "Skriv ut".`;
                    }
                    if (rapport.rapportStatusNamn === "Inkommen") {
                        message = `Rapporten för ${
                        rapport.objektOchOrgDetaljer.objektNamn
                      } har ändrats ${moment().format("yyyy-MM-DD")}. Du kan spara en kopia (PDF-dokument) genom att klicka "Skriv ut".`;
                    }
                    setStatus({
                      sent: true,
                      msg: message,
                      title: "Sparat",
                      severity: "success",
                    });
                    setRapportStatusLocal(RapportStatus.Inkommen);
                    setMode(Mode.Disabled);
                  })
                  .catch((err) => {
                    setStatus({
                      sent: true,
                      msg: `${err.data}`,
                      title: "Ett fel har inträffat",
                      severity: "error",
                    });
                    setSubmitting(false);
                  });
              }}
            >
              {(formikprops) => (
                <Box component="form" onSubmit={formikprops.handleSubmit}>
                  <Grid container spacing={1} alignItems="flex-start">
                    {RenderForm(
                      getRapportTyp(),
                      formikprops,
                      mode,
                      viewPrevYear,
                      prevYearObj
                    )}
                    {Skicka(
                      formikprops,
                      mode,
                      renderDesicionBtns,
                      rapportStatus,
                      setRapportStatusLocal
                    )}
                  </Grid>
                  {ShowAlertEval(formikprops.status) && (
                    <Grid item xs={12}>
                      <ShowAlert
                        onClose={() => {
                          formikprops.setStatus(null);
                        }}
                        severity={formikprops.status.severity}
                        alertTitle={formikprops.status.title}
                        alertText={formikprops.status.msg}
                      />
                    </Grid>
                  )}
                </Box>
              )}
            </Formik>
          </Grid>
          {renderCommentSection()}
        </>
      )}
    </Grid>
  );
}
