import {
  Breadcrumbs,
  Grid,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation } from "react-router";
import { SearchFilterProvider } from "../Utilities/searchFilterProvider";
import { ModalProvider } from "../Utilities/modalProvider";
import { HelpText } from "../Components/helpText";
import {
  användareText,
  kontakterText,
  tillståndText,
  statistikText,
  sökRapportText,
  registerutdragText,
  filuppladdningText,
  filhanteringText,
  skapaRapportText,
  skapaPeriodText,
  statistikAlkoholText,
  statistikTobakText,
  statistikBefolkningText,
  kontakterRegistreraText,
  kontakterEpostText,
  kommunerText,
  kommunerRegistreraText,
  användareSkapaText,
  användareAnsokningarText,
  anvandarkontoText,
  nyRapportText,
  systeminstallningarText,
  installningarText,
} from "../HelperText/viewsHerlperText";
import { IHelperText } from "../HelperText/baseHelperText";

interface navmap {
  path: string;
  swe: string;
  informationText?: IHelperText;
}

export const navigationMap: navmap[] = [
  {
    path: "users",
    swe: "Användare",
    informationText: användareText,
  },
  {
    path: "users/new",
    swe: "Registrera användare",
    informationText: användareSkapaText,
  },
  {
    path: "users/applies",
    swe: "Ansökningar",
    informationText: användareAnsokningarText,
  },
  {
    path: "contacts",
    swe: "Kontakter",
    informationText: kontakterText,
  },
  {
    path: "contacts/new",
    swe: "Registrera kontakt",
    informationText: kontakterRegistreraText,
  },
  {
    path: "contacts/sendemail",
    swe: "Skicka e-post",
    informationText: kontakterEpostText,
  },
  {
    path: "permits/records",
    swe: "Registerutdrag",
    informationText: registerutdragText,
  },
  {
    path: "permits/upload",
    swe: "Filimport SKV",
    informationText: filuppladdningText,
  },
  {
    path: "permits/FileManagementSKV",
    swe: "Skapa fil SKV",
    informationText: filhanteringText,
  },
  {
    path: "permits",
    swe: "Tillstånd",
    informationText: tillståndText,
  },
  {
    path: "population",
    swe: "Befolkningsstatistik",
  },
  {
    path: "statistics",
    swe: "Statistik",
    informationText: statistikText,
  },
  {
    path: "statistics/alcohol",
    swe: "Statistik alkohol",
    informationText: statistikAlkoholText,
  },
  {
    path: "statistics/tobacco",
    swe: "Statistik tobak",
    informationText: statistikTobakText,
  },
  {
    path: "statistics/population",
    swe: "Befolkningsstatistik",
    informationText: statistikBefolkningText,
  },
  {
    path: "reports",
    swe: "Rapporter",
    informationText: sökRapportText,
  },
  // {
  //   path: "reports/new",
  //   swe: "Registrera rapport",
  //   informationText: nyRapportText,
  // },
  {
    path:"reports/generatereports",
    swe:"Skapa rapporter för period",
    informationText: skapaRapportText,
  },
  {
    path:"reports/createperiod",
    swe:"Skapa period",
    informationText: skapaPeriodText,
  },
  {
    path: "licensors",
    swe:"Kommuner",
    informationText: kommunerText,
  },
  {
    path: "licensors/new",
    swe:"registrera kommuner",
    informationText: kommunerRegistreraText,
  },
  {
    path: "myaccount",
    swe:"Användarkonto",
    informationText: anvandarkontoText,
  },
  {
    path: "settings",
    swe: "Inställningar",
    informationText: installningarText,
  },
  {
    path: "settings/systemsettings",
    swe: "Systeminställningar",
    informationText: systeminstallningarText
  }
];

export default function Main() {
  const style: SxProps<Theme> = {
    marginTop: "20px",
  };

  const location = useLocation();
  let paths = location.pathname
    .split("/")
    .filter((x) => x !== "" && x !== "home");
  const getFullPath = (index: number) => {
    let path: string = "";
    for (let i = 0; i <= index; i++) {
      path += `/${paths[i]}`;
    }
    return path;
  };

  const buildBreadCrumb = () => {
    const getOnePerPath = () => {
      return paths.map((p, index, { length }) => {
        if (index < length - 1) {
          return (
            <Link key={index} href={getFullPath(index)}>
              <Stack direction="row" alignItems="center">
                <Typography variant="body2">
                  {isNaN(Number(p))
                    ? navigationMap.find((k) => k.path === p)?.swe
                    : p}
                </Typography>
              </Stack>
            </Link>
          );
        } else {
          return (
            <Stack key={index} direction="row" alignItems="center">
              <Typography variant="body2">
                {isNaN(Number(p))
                  ? navigationMap.find((k) => k.path === p)?.swe
                  : p}
              </Typography>
            </Stack>
          );
        }
      });
    };
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link href="/home">
          <Stack direction="row" alignItems="center">
            <HomeIcon fontSize="inherit" />
            <Typography variant="body2">Hem</Typography>
          </Stack>
        </Link>
        {getOnePerPath()}
      </Breadcrumbs>
    );
  };

  const buildHelpText = () => {
    let helpText: IHelperText | undefined = {title: "", text: [""]};
    if (paths.length > 1 ) {
      helpText = navigationMap.find(
        (k) => k.path === `${paths[0]}/${paths[1]}`
      )?.informationText;
    } else {
      helpText = navigationMap.find(
        (k) => k.path === paths[0]
      )?.informationText;
    }
    if (helpText)
      return (
        <HelpText
          text={helpText.text}
          title={helpText.title}
          subText={helpText.subText}
        />
      );
  };

  return (
    <Grid container>
      <ModalProvider>
        <SearchFilterProvider>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={"space-between"}
          >
            {buildBreadCrumb()}
            {buildHelpText()}
          </Grid>
          <Grid container sx={style}>
            <Outlet />
          </Grid>
        </SearchFilterProvider>
      </ModalProvider>
    </Grid>
  );
}
