import { Autocomplete, TextField } from "@mui/material";
import React, { FocusEventHandler, useEffect } from "react";
import { GetDataBasedOnType, IData, InfoType } from "./selects";

interface IAutoCompleteAtr {
  infoType: InfoType;
  label: string;
  multiple: boolean;
  defaultVal: boolean;
  defaultTitle?: string | null;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (data: number | number[] | null) => void;
  error: boolean;
  helperText: string;
  name: string;
  selectedData: number[] | number;
  disabled?: boolean;
  disableClearable?: boolean;
  idsToKeep?: number[];
  required?: boolean;
}

export const AutoCompleteAtr = (props: IAutoCompleteAtr) => {
  const [data, setData] = React.useState<IData[]>([]);

  function mapKeysToData(keys: number | number[] | undefined): IData[] {
    if (!Array.isArray(keys)) throw Error("Not expected");

    let datas: IData[] | null = keys.map((x) => {
      let k = data.find((p) => p.key === x);
      if (k !== undefined) {
        return {
          key: k.key,
          label: k.label,
        };
      } else return null!;
    });
    if (datas !== null) return datas;

    throw Error("No matching targets");
  }

  function mapKeyToData(key: number | number[]): IData | null {
    if (Array.isArray(key)) throw Error("Not expected");

    let k = data.find((p) => p.key === key);
    if (k !== undefined) return { ...k };
    return null;
  }

  useEffect(() => {
    GetDataBasedOnType(
      props.infoType,
      props.defaultTitle ?? undefined,
      props.defaultVal
    ).then((p) => {
      if (props.idsToKeep === undefined) {
        setData(p);
      } else {
        let k = p.filter((item) => props.idsToKeep?.includes(item.key));
        setData(k);
      }
    });
  }, [props.idsToKeep]);

  useEffect(() => {
    GetDataBasedOnType(
      props.infoType,
      props.defaultTitle ?? undefined,
      props.defaultVal
    ).then((p) => {
      if (props.idsToKeep === undefined) {
        setData(p);
      } else {
        let k = p.filter((item) => props.idsToKeep?.includes(item.key));
        setData(k);
      }
    });
  }, [props.infoType])

  const returnValues = (data: IData[] | IData | null) => {
    if (props.multiple && Array.isArray(data)) {
      return data.map((x) => x.key);
    } else if (!props.multiple && !Array.isArray(data) && data !== null) {
      return data.key;
    } else if (!props.multiple && !Array.isArray(data) && data === null) {
      return 0;
    } else return null;
  };

  if (props.multiple) {
    return (
      <Autocomplete
        value={data.length !== 0 ? mapKeysToData(props.selectedData) : []}
        multiple={true}
        options={data}
        disableCloseOnSelect
        isOptionEqualToValue={(opt, val) => {
          return opt.key === val.key;
        }}
        getOptionLabel={(option) => option.label}
        onChange={(ev, data) => {
          props.onChange(returnValues(data));
        }}
        disabled={props.disabled}
        disableClearable={props.disableClearable}
        clearText="Rensa"
        closeText="Stäng"
        openText="Öppna"
        renderInput={(params) => (
          <TextField
            onBlur={props.onBlur}
            name={props.name}
            helperText={props.helperText}
            error={props.error}
            label={props.label}
            required={props.required}
            {...params}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        value={mapKeyToData(props.selectedData)}
        multiple={false}
        options={data}
        isOptionEqualToValue={(opt, val) => {
          return opt.key === val.key;
        }}
        getOptionLabel={(option) => option.label}
        onChange={(ev, data) => {
          props.onChange(returnValues(data));
        }}
        disabled={props.disabled}
        disableClearable={props.disableClearable}
        clearText="Rensa"
        closeText="Stäng"
        openText="Öppna"
        renderInput={(params) => (
          <TextField
            onBlur={props.onBlur}
            name={props.name}
            helperText={props.helperText}
            error={props.error}
            label={props.label}
            required={props.required}
            {...params}
          />
        )}
      />
    );
  }
};
