import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  FormControl,
  Box,
  Button,
} from "@mui/material";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { DatePickerCustom } from "../../Components/datePicker";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { InfoType } from "../../Components/selects";
import { IAlert, ShowAlert, ShowAlertEval } from "../../Components/alerts";
import InputTextField from "../../Components/textfield";
import {
  GetTillstand,
  ITillstandGet,
  correctTillstand,
  incorrectTillstand,
} from "../../Services/PermitService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { CorrectPermitValidation } from "./validation";
import { ActionType, useModal } from "../../Utilities/modalProvider";

export default function Correct() {
  const navigate = useNavigate();
  const modal = useModal();
  let { id } = useParams();
  const [tillstand, setTillstand] = React.useState<ITillstandGet>();
  const [loading, setLoading] = React.useState(true);
  const [avslutsInfotype, setAvslutsInfotype] = React.useState<InfoType>(
    InfoType.serveringsAvslut
  );
  const [incorrectLoading, setIncorrectLoading] = React.useState(false);
  const [incorrectStatus, setIncorrectStatus] = React.useState<IAlert>({
    show: false,
    alertText: "",
    severity: "success",
  });

  const getSetTillstand = (id: number, setActivenr: boolean = false) => {
    GetTillstand(id)
      .then((resp) => {
        setTillstand({ ...resp.object });
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      });
  };

  const triggerIncorrect = (id: number) => {
    setIncorrectLoading(false);
    incorrectTillstand(id)
      .then((res) => {
        if (res.success === true) {
          setIncorrectStatus({
            alertText: "Detta tillstånd är nu markerad som felregistrerad.",
            severity: "success",
            show: true,
          });
        }
      })
      .catch((error) => {
        setIncorrectStatus({
          alertText:
            "Kunde inte spara tillståndet som felregistrerat.",
          severity: "error",
          show: true,
        });
      });
    modal.showDialog({ open: false });
    setIncorrectLoading(false);
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      getSetTillstand(parseInt(id), true);
    }
  }, [id]);

  useEffect(() => {
    if (tillstand?.tillstandsTypId) {
      if ([1, 12].indexOf(tillstand.tillstandsTypId) > -1) {
        setAvslutsInfotype(InfoType.serveringsAvslut);
      } else if ([9, 10].indexOf(tillstand.tillstandsTypId) > -1) {
        setAvslutsInfotype(InfoType.tobakAvslut);
      } else if (tillstand.tillstandsTypId === 11) {
        setAvslutsInfotype(InfoType.partiAvslut);
      } else {
        setAvslutsInfotype(InfoType.okandAvslut);
      }
    }
  }, [tillstand]);

  const mainContent = () => {
    if (tillstand === undefined || tillstand === null) {
      return (
        <Typography variant="h2">
          Något gick fel vid inläsning av tillstånd
        </Typography>
      );
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" component="h1" paragraph>
              Rätta tillstånd
            </Typography>
            <Formik
              enableReinitialize
              validationSchema={CorrectPermitValidation}
              initialValues={{ ...tillstand }}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                const submitValues = {
                  orgNr: values.orgNr,
                  objektNr: values.objektNr,
                  aktiveringsdatum: values.aktivieringsDatum,
                  orsakTillAvslutId: values.orsakTillAvslutId,
                  avslutsDatum: values.avslutsDatum,
                  beslutsDatum: values.beslutsDatum,
                };
                correctTillstand(Number(id), submitValues)
                  .then(() => {
                    setStatus({
                      sent: true,
                      msg: `Tillståndet har uppdaterats.`,
                      title: "",
                      severity: "success",
                    });
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setStatus({
                      sent: true,
                      msg: "Kontrollera fälten och försök igen.",
                      title: "Ett fel uppstod",
                      severity: "error",
                    });
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                status,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                handleBlur,
                setStatus,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="orgNr"
                        label="Organisationsnummer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.orgNr}
                        error={evalErrors(errors, touched, "orgNr")}
                        helperText={getErrorText(errors, touched, "orgNr")}
                        required
                        aria-required="true"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="objektNr"
                        label="Objektnummer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.objektNr}
                        error={evalErrors(errors, touched, "objektNr")}
                        helperText={getErrorText(errors, touched, "objektNr")}
                        required
                        aria-required="true"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: "10px" }}>
                    <Grid item xs={3}>
                      <DatePickerCustom
                        label="Beslutsdatum"
                        value={values.beslutsDatum}
                        onChange={(e) => setFieldValue("beslutsDatum", e)}
                        name="beslutsDatum"
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "beslutsDatum")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "beslutsDatum"
                        )}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DatePickerCustom
                        label="Giltigt fr.o.m."
                        value={values.aktivieringsDatum}
                        onChange={(e) => setFieldValue("aktivieringsDatum", e)}
                        name="aktivieringsDatum"
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "aktivieringsDatum")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "aktivieringsDatum"
                        )}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DatePickerCustom
                        label="Giltigt t.o.m."
                        value={values.avslutsDatum}
                        onChange={(e) => setFieldValue("avslutsDatum", e)}
                        name="avslutsDatum"
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "avslutsDatum")}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "avslutsDatum"
                        )}
                        required={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: "10px" }}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={avslutsInfotype}
                          disableClearable={false}
                          label="Orsak till avslut"
                          multiple={false}
                          defaultVal={false}
                          onChange={(data) => {
                            setFieldValue("orsakTillAvslutId", data);
                          }}
                          error={evalErrors(
                            errors,
                            touched ?? {},
                            "orsakTillAvslutId"
                          )}
                          helperText={getErrorText(
                            errors,
                            touched ?? {},
                            "orsakTillAvslutId"
                          )}
                          name="orsakTillAvslutId"
                          selectedData={values.orsakTillAvslutId}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: "26px" }}>
                    <Stack direction="row" spacing={2}>
                      <BtnDefault type="submit" disabled={isSubmitting}>
                        Rätta
                      </BtnDefault>
                      <Button 
                        color="primary"
                        variant="contained"
                        startIcon={
                          incorrectLoading ? (
                            <CircularProgress color="primary" size={"1em"} />
                          ) : (
                            <div></div>
                          )
                        }
                        onClick={() => {
                            modal.showDialog({
                              title: "Bekräfta",
                              open: true,
                              message:
                                "Vill du felregistrera tillståndet?",
                              actions: [
                                {
                                  action: () => {
                                    triggerIncorrect(values.id);
                                  },
                                  btn: ActionType.default,
                                  text: "Spara",
                                },
                                {
                                  btn: ActionType.second,
                                  text: "Avbryt",
                                },
                              ],
                            });
                          }}
                        >
                        Felregistrera
                      </Button>
                      <BtnSecond onClick={() => navigate(-1)}>
                        Tillbaka
                      </BtnSecond>
                    </Stack>
                    {ShowAlertEval(status) && (
                      <Grid item xs={3}>
                        <ShowAlert
                          onClose={() => {
                            setStatus(null);
                          }}
                          severity={status.severity}
                          alertTitle={status.title}
                          alertText={status.msg}
                        />
                      </Grid>
                    )}
                    {incorrectStatus.show === true && (
                      <Grid item xs={3}>
                        <ShowAlert
                          onClose={() => {
                            setIncorrectStatus({
                              show: false,
                              alertText: "",
                              severity: "success",
                            });
                          }}
                          severity={incorrectStatus.severity}
                          alertTitle={""}
                          alertText={incorrectStatus.alertText}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  };

  const render = () => {
    if (loading && tillstand === undefined) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }
    return mainContent();
  };

  return render();
}
