import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import React from "react";

export const BtnDefault = (props: ButtonProps) => {
  return (
    <Button variant="contained" {...props}>
      {props?.children}
    </Button>
  );
};

export const BtnSecond = (props: ButtonProps) => {
  return (
    <Button
      sx={{
        backgroundColor: "#eee",
        color: "black",
        ":hover": { backgroundColor: "darkgray", color: "white" },
      }}
      variant="contained"
      {...props}
    >
      {props?.children}
    </Button>
  );
};

export const BtnIcon = (props: IconButtonProps) => {
  return (
    <IconButton {...props} sx={{ color: "black" }}>
      {props?.children}
    </IconButton>
  );
};
