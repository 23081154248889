import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

export interface IRadios {
  value: string;
  label: string;
}

export interface IRadioCustom {
  label: string;
  defaultValue: string;
  name: string;
  radios: IRadios[];
  row: boolean;
  value: string;
  onChange: (e: string) => void;
  showLabel: boolean;
  disabled?: boolean;
}

export function RadioCustom(props: IRadioCustom) {
  return (
    <FormControl>
      {props.showLabel && <FormLabel id="x">{props.label}</FormLabel>}
      <RadioGroup
        row={props.row}
        aria-labelledby="x"
        defaultValue={props.defaultValue}
        name={props.name}
        value={props.value}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      >
        {props.radios.map((r, i) => {
          return (
            <FormControlLabel
              key={i}
              value={r.value}
              control={<Radio />}
              label={r.label}
              disabled={props.disabled && props.disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
