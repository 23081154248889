import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import {
  deleteFutureVersionTillstand,
  GetTillstand,
  getTillstandVersion,
  getTillstandVersions,
  ITillstandGet,
  ITillstandRapport,
  getTillstandRapport,
  ICloseTillstand,
  closeTillstand,
} from "../../Services/PermitService";
import { GetDateFormattedUsingDate } from "../../Utilities/momentExt";
import {
  MapToSearchPermitType,
  Permits,
  SearchPermitTypes,
  showHideFieldBasic,
} from "./PermitsFormsUtils/permitsUtils";
import { GenerateViewStructure } from "./PermitViewUtils/permitView";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import { Formik } from "formik";
import { DatePickerCustom } from "../../Components/datePicker";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { IData, InfoType, SelectSingle } from "../../Components/selects";
import { ClosePermitSchema } from "../../Utilities/validationSchema";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import CustomModal from "../../Components/customModal";
import { styled } from "@mui/system";
import { downloadOldDataExcel } from "../../Services/reportService";
import { useAuth } from "../../Utilities/authProvider";
import { Roles } from "../../Utilities/roles";
import { getMyUser, IAnvandareGet } from "../../Services/UserService";
import { RapportTyper, Rapporttyper } from "../Report/ReportForm/formlogic";

export default function Permit() {
  const ListLinks = styled("ul")`
    display: flex;
    margin: 0;
    padding: 0;

    li:not(:first-of-type) {
      padding-left: 1rem;
    }
  `;
  const navigate = useNavigate();
  let { id } = useParams();
  const [tillstand, setTillstand] = React.useState<ITillstandGet>();
  const [permitId, setPermitId] = React.useState<number>(0);
  const [activeVnr, setActiveVnr] = React.useState<number>(0);
  const [loading, setLoading] = React.useState({
    tillstand: true,
    versions: true,
  });
  const [versions, setVersions] = React.useState<ITillstandGet[]>();
  const [repports, setRepports] = React.useState<ITillstandRapport[]>();
  const [avsluta, setAvsluta] = React.useState<boolean>(false);
  const [reloadPermit, setReloadPermit] = React.useState<boolean>(false);
  const [avslutsInfotype, setAvslutsInfotype] = React.useState<InfoType>(
    InfoType.serveringsAvslut
  );
  const [permitType, setPermitType] = React.useState<Permits>();
  const modalProvider = useModal();
  const auth = useAuth();
  const [user, setUserState] = React.useState<IAnvandareGet>();
  const [currentDate, setCurrentDate] = React.useState(new Date());

  const  getTodaysDate = () => {
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0); // Set the time to midnight
    setCurrentDate(todaysDate);
  };

  const getSetTillstand = (id: number, setActivenr: boolean = false) => {
    GetTillstand(id)
      .then((resp) => {
        setTillstand({ ...resp.object });
        if (setActivenr) {
          setActiveVnr(resp.object.versionDetails.versionsNr);
        }
      })
      .catch((err) => {
        // handle Error
        setLoading({ tillstand: false, versions: false });
      });
  };

  const getSetTillstandVersion = (id: number, version: number) => {
    getTillstandVersion(id, version)
      .then((resp) => {
        setTillstand({ ...resp.object });
      })
      .catch((err) => {
        // handle Error
        setLoading({ tillstand: false, versions: false });
      });
  };

  const getTillstandVersionsF = (id: number) => {
    getTillstandVersions(id)
      .then((res) => {
        setVersions([...res.object]);
      })
      .catch((err) => {
        // handle ERROR
        setLoading({ tillstand: false, versions: false });
      });
  };

  const getSetTillstandRepport = (id: number) => {
    getTillstandRapport(id).then((response) => {
      setRepports([...response.object]);
    });
  };

  const goToTillstandReport = (id: number) => {
    navigate(`/reports/${id}`);
  };

  const setPermitToLocalStorage = () => {
    localStorage.setItem("permitChangeOwner", JSON.stringify(tillstand));
  };

  const deletePermitFromLocalStorage = () => {
    localStorage.removeItem("permitChangeOwner");
  };

  function GetMyUser() {
    getMyUser().then((response) => {
      if (response.success) {
        setUserState(response.object);
      }
    });
  }

  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to view");
    } else {
      window.scrollTo(0, 0);
      setPermitId(parseInt(id));


      getSetTillstand(parseInt(id), true);
      getTillstandVersionsF(parseInt(id));
      getSetTillstandRepport(parseInt(id));
      GetMyUser();
      getTodaysDate();
    }
  }, []);

  useEffect(() => {
    if (tillstand !== null || tillstand !== undefined) {
      setLoading({ ...loading, tillstand: false });
    }
    if (tillstand?.tillstandsTypId) {
      if ([1, 12].indexOf(tillstand.tillstandsTypId) > -1) {
        setAvslutsInfotype(InfoType.serveringsAvslut);
      } else if ([5, 6, 8].indexOf(tillstand.tillstandsTypId) > -1) {
        setAvslutsInfotype(InfoType.partiAvslut);
      } else if ([9, 10].indexOf(tillstand.tillstandsTypId) > -1) {
        setAvslutsInfotype(InfoType.tobakAvslut);
      } else if (tillstand.tillstandsTypId === 11) {
        setAvslutsInfotype(InfoType.tobakAvslut);
      } else {
        setAvslutsInfotype(InfoType.okandAvslut);
      }
      setPermitType(MapToSearchPermitType(tillstand.tillstandsTypId));
    }
  }, [tillstand]);

  useEffect(() => {
    if (versions !== null || tillstand !== undefined) {
      setLoading({ ...loading, versions: false });
    }
  }, [versions]);

  const loadOtherVersionTillstand = (versionNr: number) => {
    if (versionNr === activeVnr) {
      getSetTillstand(permitId);
    } else {
      getSetTillstandVersion(permitId, versionNr);
    }
  };
  const [loadingRapportExcel, setLoadinRapportExcel] = React.useState(false);
  const downloadOldData = () => {
    if (repports === null || repports === undefined) return;

    setLoadinRapportExcel(true);
    downloadOldDataExcel(
      repports?.map((z) => {
        return z.id;
      })
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.object], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Rapporter.xlsx");
        document.body.appendChild(link);
        link.click();
        setLoadinRapportExcel(false);
      })
      .catch((err) => {
        setLoadinRapportExcel(false);
      });
  };

  const loadRapportList = () => {
    if (loading.versions || loading.tillstand) {
      return <CircularProgress></CircularProgress>;
    } else if (repports && repports?.length > 0) {
      return (
        <Grid item xs={12}>
          <Typography variant="h2">Inkomna rapporter</Typography>
          <Typography>
            Rapporter på samma tillstånd:{" "}
            <Link onClick={downloadOldData}>
              Ladda ner excel med alla rapporter
            </Link>
            {loadingRapportExcel && <CircularProgress />}
          </Typography>   
          
          {(repports.some(x => x.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)) && (
           <Typography>Statistikrapport försäljning/tillverkning:</Typography>
          )}   

          <ListLinks>
            {repports?.map((report) => {
              if (
                moment(`${report.rapportPeriodNamn}-01-01`) >=
                moment("2020-01-01") && (report.rapportTypId != Rapporttyper.StatistikrapportTekniskSprit)
              ) {
                return (
                  <li key={report.id}>
                    <Link component={RouterLink} to={`/reports/${report.id}`}>
                      {report.rapportPeriodNamn}
                    </Link>
                  </li>
                );
              } else {
                if (
                 report.rapportPeriodNamn === "2019"
                ) {
                  return (
                    <li key={report.id}>
                      <Typography variant="caption">
                        {report.rapportPeriodNamn} {repports.findIndex(x => x.rapportPeriodNamn ===  "2019") < repports.length ? " ..." : "" }
                      </Typography>
                    </li>
                  );
                } else if (
                  repports[repports.length - 1].rapportPeriodNamn ===
                    report.rapportPeriodNamn && report.rapportPeriodNamn !== "2019"
                ) {
                  return (
                    <li key={report.id}>
                      <Typography variant="caption">
                        {report.rapportPeriodNamn}
                      </Typography>
                    </li>
                  );
                }
              }
            })}
          </ListLinks>

          {(repports.some(x => x.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit)) && (
            <div>
             <Typography>Statistikrapport teknisk sprit:</Typography>
            <ListLinks>
              {repports?.map((report) => {
                if (
                  moment(`${report.rapportPeriodNamn}-01-01`) >=
                  moment("2020-01-01") && (report.rapportTypId == Rapporttyper.StatistikrapportTekniskSprit) 
                ) {
                  return (
                    <li key={report.id}>
                      <Link component={RouterLink} to={`/reports/${report.id}`}>
                        {report.rapportPeriodNamn}
                      </Link>
                    </li>
                  );
                }
              })}
            </ListLinks> 
            </div>
          )}

          
        </Grid>

      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="h2">Inkomna rapporter</Typography>
          <Typography variant="body2">Inga rapporter</Typography>
        </Grid>
      )
    }
  };
  const loadVersionList = () => {
    if (loading.versions || loading.tillstand) {
      return <CircularProgress></CircularProgress>;
    } else if (versions && versions.length > 0) {
      return (
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ paddingBottom: "16px" }}>
            Versioner av tillståndet
          </Typography>
          <SelectSingle
            label="Välj tillstånd"
            fullWidth
            data={versions.map((d) => ({
              key: d.versionDetails.versionsNr,
              label: `Versionsnummer: ${
                d.versionDetails.versionsNr
              } Gäller fr.o.m ${GetDateFormattedUsingDate(
                d.versionDetails.versionsStart
              )}`,
            }))}
            selected={tillstand?.versionDetails.versionsNr ?? activeVnr}
            onChange={(ev) => {
              loadOtherVersionTillstand(Number(ev.target.value));
            }}
            name="tillstandsversion"
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  const checkIfEditShouldBeDisabled = () => {
    if (versions === undefined) throw Error("Unexpected Error");
    //First we check if the selected tillstand is the same as the current avtive versionnr if not then disable
    if (activeVnr !== tillstand?.versionDetails.versionsNr) {
      return true;
    }
    const activeVersionDate = versions?.find(
      (v) => v.versionDetails.versionsNr === activeVnr
    )?.versionDetails.versionsStart;

    //If it is the same versionnr as the active one verify that
    if (
      activeVersionDate !== null &&
      moment(tillstand?.versionDetails.versionsStart).isAfter(activeVersionDate)
    ) {
      return true;
    }

    if (
      activeVersionDate !== null &&
      activeVnr === tillstand?.versionDetails.versionsNr &&
      versions?.findIndex((v) =>
        moment(v.versionDetails.versionsStart).isAfter(
          moment(tillstand.versionDetails.versionsStart)
        )
      ) > -1
    ) {
      return true;
    }
  };

  const sendDelete = (versionId: number, tillstandId: number) => {
    deleteFutureVersionTillstand(versionId).then((resp) => {
      getTillstandVersionsF(tillstandId);
      getSetTillstand(permitId, true);
      modalProvider.showDialog({ open: false });
    });
  };

  const showDeleteButton = () => {
    if (tillstand === undefined) {
      throw Error("Something went wrong");
    }
    const activeVersionDate = versions?.find(
      (v) => v.versionDetails.versionsNr === activeVnr
    )?.versionDetails.versionsStart;
    if (
      activeVersionDate !== null &&
      moment(tillstand?.versionDetails.versionsStart).isAfter(activeVersionDate)
    ) {
      return (
        <BtnSecond
          onClick={() => {
            modalProvider.showDialog({
              open: true,
              actions: [
                {
                  btn: ActionType.default,
                  text: "Ta bort",
                  action: () => sendDelete(tillstand.versionId, tillstand.id),
                },
                {
                  btn: ActionType.second,
                  text: "Avbryt",
                },
              ],
              message:
                "Är du säker på att du vill ta bort en framtida version av tillståndet?",
              title: "Ta bort version",
            });
          }}
        >
          Ta bort framtida version
        </BtnSecond>
      );
    }
  };

  const closePermit = () => {
    setPermitToLocalStorage();
    setAvsluta(true);
  };

  const handleCloseAvslutaModal = () => {
    deletePermitFromLocalStorage();
    setAvsluta(false);
    getSetTillstand(permitId, true);
    setReloadPermit(true);
  };

  const defaultVals: ICloseTillstand = {
    orsakTillAvslutId: 0,
    avslutsdatum: "",
  };

  const renderClosePermitModal = () => {
    return (
      <Paper>
        <CustomModal
          handleCloseModal={handleCloseAvslutaModal}
          openModal={avsluta}
          title="Avsluta tillstånd"
        >
          <Formik
        validationSchema={ClosePermitSchema(tillstand?.aktivieringsDatum)}
          initialValues={{ ...defaultVals, changeOwner: false }}
            onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
              values = { ...values };
              setSubmitting(true);
              if (tillstand !== undefined) {
                closeTillstand(tillstand.id, { ...values })
                  .then((res) => {
                    if (values.changeOwner === true) {
                      navigate("/permits/new?load=true");
                    }
                    setStatus({
                      sent: true,
                      msg: "Tillståndet har avslutats.",
                      title: "",
                      severity: "success",
                    });
                    setSubmitting(false);
                    handleCloseAvslutaModal();
                  })
                  .catch((err) => {
                    setStatus({
                      sent: true,
                      msg: err.data,
                      title: "Något gick fel!",
                      severity: "error",
                    });
                    setSubmitting(false);
                  });
              }
            }}
          >
            {(formikprops) => (
              <Grid
                item
                xs={12}
                component="form"
                onSubmit={formikprops.handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DatePickerCustom
                      label="Avslutsdatum"
                      value={formikprops.values.avslutsdatum ?? null}
                      onChange={(ev) =>
                        formikprops.setFieldValue("avslutsdatum", ev)
                      }
                      name="avslutsdatum"
                      onBlur={formikprops.handleBlur}
                      error={evalErrors(
                        formikprops.errors,
                        formikprops.touched,
                        "avslutsdatum"
                      )}
                      helperText={getErrorText(
                        formikprops.errors,
                        formikprops.touched,
                        "avslutsdatum"
                      )}
                      required={true}
                      disabled={
                        formikprops.status !== undefined &&
                        Object.keys(formikprops.status).length > 0
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        onBlur={formikprops.handleBlur}
                        infoType={avslutsInfotype}
                        disableClearable={true}
                        label="Orsak till avslut"
                        multiple={false}
                        defaultVal={false}
                        onChange={(data) => {
                          formikprops.setFieldValue("orsakTillAvslutId", data);
                        }}
                        error={evalErrors(
                          formikprops.errors,
                          formikprops.touched ?? {},
                          "orsakTillAvslutId"
                        )}
                        helperText={getErrorText(
                          formikprops.errors,
                          formikprops.touched ?? {},
                          "orsakTillAvslutId"
                        )}
                        name="orsakTillAvslutId"
                        selectedData={formikprops.values.orsakTillAvslutId}
                        required={true}
                        disabled={
                          formikprops.status !== undefined &&
                          Object.keys(formikprops.status).length > 0
                        }
                      />
                    </FormControl>
                  </Grid>
                  {permitType &&
                    showHideFieldBasic(
                      [
                        Permits.StadigvarandeServeringstillstand,
                        Permits.TobakstillstandDetaljhandelTillsvidare,
                        Permits.TobakstillstandPartihandel,
                      ],
                      permitType
                    ) && (
                      <Grid item xs={12}>
                        <Stack mb={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                inputProps={{ "aria-label": "controlled" }}
                                name="changeOwner"
                                onChange={formikprops.handleChange}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                Ägarbyte, avsluta tillståndet och flytta med
                                uppgifter
                              </Typography>
                            }
                          />
                        </Stack>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    {ShowAlertEval(formikprops.status) && (
                      <Grid item xs={12}>
                        <ShowAlert
                          severity={formikprops.status.severity}
                          alertTitle={formikprops.status.title}
                          alertText={formikprops.status.msg}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <BtnDefault
                        disabled={
                          (formikprops.status !== undefined &&
                            Object.keys(formikprops.status).length > 0) ||
                          (formikprops.errors !== undefined &&
                            Object.keys(formikprops.errors).length > 0) ||
                          formikprops.isSubmitting ||
                          !formikprops.isValid
                        }
                        type="submit"
                      >
                        Avsluta
                      </BtnDefault>
                      <BtnSecond
                        disabled={formikprops.isSubmitting}
                        onClick={() => handleCloseAvslutaModal()}
                      >
                        {formikprops.status && formikprops.status.sent
                          ? "Stäng"
                          : "Avbryt"}
                      </BtnSecond>
                      {formikprops.isSubmitting && <CircularProgress />}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </CustomModal>
      </Paper>
    );
  };

  const mainContent = () => {
    if (
      tillstand === undefined ||
      tillstand === null ||
      versions === undefined
    ) {
      return (
        <Typography variant="h2">
          Något gick fel vid inläsning av tillstånd
        </Typography>
      );
    }

    return (
      <>
        <Grid container spacing={2}>
          {renderClosePermitModal()}
          <Grid item xs={12} md={6}>
            <Typography variant="h2" sx={{ paddingBottom: "16px" }}>
              {
                SearchPermitTypes.find(
                  (x) => x.id === tillstand?.tillstandsTypId
                )?.namn
              }
            </Typography>
            {showHideFieldBasic(
              [
                Permits.SkattebefriadForbrukare,
                Permits.Upplagshavare,
                Permits.RegistreradVarumottagare,
              ],
              tillstand.tillstandsTypId
            ) ? (
              <Typography variant="h3" component="p">
                Tillståndsgivare: Skatteverket
              </Typography>
            ) : (
              <Typography variant="h3" component="p">
                Kommun: {tillstand.kommunNamn}
              </Typography>
            )}
            <Typography variant="h3" component="p">
              Ändrat:{" "}
              {GetDateFormattedUsingDate(tillstand.andradDagLatest ?? "")}
            </Typography>
            <Typography variant="h3" component="p">
              Ändrat av: {tillstand.andradAvLatest}  
              {tillstand.registreradViaWebbtjanst && " (webbtjänst)"}
            </Typography>
            <Typography variant="h3" component="p">
              Status: {tillstand.statusNamn}
            </Typography>     
            {tillstand.avslutsDatum !== null &&
              (moment(new Date(tillstand.avslutsDatum)).format("yyyyMMDD")) < (moment(new Date(currentDate)).format("yyyyMMDD")) && (
              <>
                <Typography variant="h3">
                  Orsak till avslut: {tillstand.orsakTillAvslutName}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loadVersionList()}
          </Grid>

          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.FohmLasAlkohol,
            Roles.KommunAdminAlkohol
          ]) && (
            <>
              {showHideFieldBasic(
                [
                  Permits.RegistreradVarumottagare,
                  Permits.Upplagshavare,
                  Permits.StadigvarandeServeringstillstand,
                ],
                tillstand.tillstandsTypId
              ) && loadRapportList()}
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            {GenerateViewStructure(tillstand)}
          </Grid>

          <Grid item xs={12} sx={{mb: "20px"}}>
            <Stack direction="row" spacing={2}>
              {!tillstand.registreradViaWebbtjanst && tillstand.avslutsDatum === null &&
              (user && tillstand && (auth.validateUserRoleAccess([Roles.FohmAdmin]) || (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol, Roles.KommunAdminTobak]) && user.kommunIdn.indexOf(tillstand.kommunId) > -1))) && (
                <BtnDefault onClick={() => closePermit()}>Avsluta</BtnDefault>
              )}
              {tillstand.aktivieringsDatum !== "null" &&
              (auth.validateUserRoleAccess([Roles.FohmAdmin, Roles.KommunAdminAlkohol, Roles.KommunAdminTobak])) &&
              (user && tillstand && (auth.validateUserRoleAccess([Roles.FohmAdmin]) || (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol, Roles.KommunAdminTobak]) && user.kommunIdn.indexOf(tillstand.kommunId) > -1))) &&
              ( !tillstand.registreradViaWebbtjanst && (tillstand.avslutsDatum === null ||
                (moment(new Date(tillstand.avslutsDatum)).format("yyyyMMDD")) >= (moment(new Date()).format("yyyyMMDD")))) ? (
                <BtnDefault
                  onClick={() => {
                    navigate("edit");
                  }}
                  disabled={checkIfEditShouldBeDisabled()}
                >
                  Ändra
                </BtnDefault>
              ) : null}
              {!tillstand.registreradViaWebbtjanst && auth.validateUserRoleAccess([Roles.FohmAdmin]) && !(tillstand.tillstandsTypId === 5 || tillstand.tillstandsTypId === 6 || tillstand.tillstandsTypId === 8) && (
                <BtnDefault
                  onClick={() => {
                    navigate("correct");
                  }}
                  disabled={checkIfEditShouldBeDisabled()}
                >
                  Rätta
                </BtnDefault>
              )}
              {(!tillstand.registreradViaWebbtjanst && showDeleteButton())}
              <BtnSecond  onClick={() => navigate("/permits")}>
                Tillbaka
              </BtnSecond>
            </Stack>
          </Grid>
          
        </Grid>
      </>
    );
  };

  const render = () => {
    if (loading.tillstand && tillstand === undefined) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    } else if (!loading.tillstand && tillstand === undefined) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">
              Hittade inget tillstånd med id {id}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return mainContent();
  };

  return render();
}
