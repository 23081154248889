import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export default function Export(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.spritExportLag,
    props.values.spritExportHog,
    props.values.vinExportLag,
    props.values.vinExportMellan,
    props.values.vinExportHog,
    props.values.andraJastaExportLag,
    props.values.andraJastaExportMellan,
    props.values.andraJastaExportHog,
    props.values.starkOlExportLag,
    props.values.starkOlExportHog,
    props.values.alkoPrepExport,
    props.values.exportUtforsel,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);

  const sumAllValues = () => {
    CalcSum(props, "exportTotalt", values);
  };

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Export/utförsel (hela liter)
          </Typography>
          <Typography variant="caption">
            Export/utförsel gäller både utförsel till annat EU-land som export
            till tredje land (se även anvisningen längst upp på
            sidan).
          </Typography>
        </Grid>
        <>
        {props.values.rapportTypId == 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
              {InputWithDefaults<IUpdateRapportDetails>(
              "TEKNISK SPRIT",
              "exportUtforsel",
              props.values.exportUtforsel,
              props,
              sumAllValues,
              undefined,
              mode,
              "Mängd uttryckt i liter ren alkohol",
              "number",
              ["exportUtforsel"]
              )}
              </GridWithCaption>
            </Section>
          </Grid>
        </Grid>
        )}
        </>
        <>
        {props.values.rapportTypId != 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
            <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Spritdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritExportLag",
                  props.values.spritExportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritExportHog",
                  props.values.spritExportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritExportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritExportLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritExportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Vin</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinExportLag",
                  props.values.vinExportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinExportMellan", "vinExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinExportMellan",
                  props.values.vinExportMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinExportLag", "vinExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinExportHog",
                  props.values.vinExportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinExportMellan", "vinExportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinExportLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinExportMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinExportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Andra jästa alkoholdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaExportLag",
                  props.values.andraJastaExportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaExportMellan", "andraJastaExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaExportMellan",
                  props.values.andraJastaExportMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaExportLag", "andraJastaExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaExportHog",
                  props.values.andraJastaExportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaExportMellan", "andraJastaExportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaExportLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaExportMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaExportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Starköl</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlExportLag",
                  props.values.starkOlExportLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlExportHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlExportHog",
                  props.values.starkOlExportHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlExportLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlExportLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlExportHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Alkoholdrycksliknande preparat</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisningen",
                  "alkoPrepExport",
                  props.values.alkoPrepExport,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("", prevYearObj?.alkoPrepExport)}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section>
              <Grid item xs={12}>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Summa export/utförsel</Typography>
              </Grid>
                <Typography variant="caption">
                  Ange 0 i summa om du inte har någonting att redovisa.
                </Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "",
                  "exportTotalt",
                  props.values.exportTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled,
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("", prevYearObj?.exportTotalt)}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
        )}
        </>
      </Grid>
    </Grid>
  );
}
