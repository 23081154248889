import * as React from "react";
import { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  styled,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Stack,
} from "@mui/material";
import ApplyForKommuner from "./applyforKommuner";
import ApplyForForetag from "./applyforForetag";
import ApplyForOthers from "./applyforOthers";

const ApplicationContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  minHeight: "100vh",
}));

const ApplicationPaper = styled(Paper)(({ theme }) => ({
  margin: "10vh 0",
  maxWidth: 980,
  padding: theme.spacing(10),
}));

const ApplyUserAccount: React.FC = () => {
  const [status, setStatus] = useState(0);

  const radioHandler = (status: number) => {
    setStatus(status);
  };

  return (
    <ApplicationContainer maxWidth={false}>
      <Container>
        <ApplicationPaper>
          <Grid item xs={12}>
            <Typography variant="h1" component="h1" paragraph>
              Ansökan om användarkonto
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                aria-labelledby="application-usertype"
                name="radio-buttons-group"
              >
                <Typography
                  variant="h3"
                  component="p"
                  mb={3}
                  sx={{ maxWidth: 750 }}
                  id="application-usertype"
                >
                  Innan du kan gå vidare behöver vi veta varför du behöver ett
                  användarkonto.
                </Typography>
                <Stack direction="row" mb={2}>
                  <FormControlLabel
                    value="kommun"
                    label={
                      <Typography variant="body1">
                        Jag representerar en kommun eller länsstyrelse. Jag
                        behöver ha tillgång till alkohol- och tobaksregistret
                        för att kunna utföra mina arbetsuppgifter eller
                        konfigurera en systemintegration.
                      </Typography>
                    }
                    control={
                      <Radio
                        name="kommun"
                        checked={status === 1}
                        onClick={(e) => radioHandler(1)}
                      />
                    }
                  />
                </Stack>
                <Stack direction="row" mb={2}>
                  <FormControlLabel
                    value="foretag"
                    label={
                      <Typography variant="body1">
                        Jag representerar ett företag som behöver upplysning om
                        vilka företag som har serveringstillstånd.
                      </Typography>
                    }
                    control={
                      <Radio
                        name="foretag"
                        checked={status === 2}
                        onClick={(e) => radioHandler(2)}
                      />
                    }
                  />
                </Stack>

                <Stack direction="row">
                  <FormControlLabel
                    value="ingetAvAlternativen"
                    label={
                      <Typography variant="body1">
                        Inget av ovan stämmer in på mig, men jag är intresserad
                        av att ta del av statistik relaterat till alkohol- och
                        tobaksregistret.
                      </Typography>
                    }
                    control={
                      <Radio
                        name="ingetAvAlternativen"
                        checked={status === 3}
                        onClick={(e) => radioHandler(3)}
                      />
                    }
                  />
                </Stack>
              </RadioGroup>
              {status === 1 && <ApplyForKommuner />}
              {status === 2 && <ApplyForForetag />}
              {status === 3 && <ApplyForOthers />}
            </FormControl>
          </Grid>
        </ApplicationPaper>
      </Container>
    </ApplicationContainer>
  );
};

export default ApplyUserAccount;
