import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
  TextOverInput,
} from "./mainForm";

export default function EgenkryddadSnaps(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  return (
    <Grid key={nr} item xs={12} marginBottom={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {nr}. Egenkryddad snaps (hela liter)
          </Typography>
          <Typography variant="caption">
            Om serveringsstället har gjort snaps under året anges antal liter
            egenkryddad snaps.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption caption="">
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Antal liter framställd snaps",
                  "antalLiterEgenKryddadSnaps",
                  props.values.antalLiterEgenKryddadSnaps,
                  props,
                  undefined,
                  undefined,
                  mode,
                  "Liter"
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Antal liter framställd snaps",
                      prevYearObj?.antalLiterEgenKryddadSnaps
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
