import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";
import { string } from "yup";
import { IMainBaseResponse } from "../Services/base";
import {
  GetToken,
  SetToken,
  ITokenStructure,
  ClearToken,
} from "./authProvider";

const Base_URL = process.env.REACT_APP_SERVER_URL;
const RefreshTokenUrl = "/auth/refresh";

const apiAtrClient = axios.create({
  baseURL: Base_URL,
});

let newTokenInc = false;
let unautherizationRequests: CallableFunction[] = [];

apiAtrClient.interceptors.request.use((request: AxiosRequestConfig) => {
  const accessToken = GetToken()?.accessToken.tokenString;

  if (accessToken && request?.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.AccessToken = accessToken;
  }
  return request;
});

const addToUnautherizationRequests = (callback: CallableFunction) => {
  unautherizationRequests.push(callback);
};

const onTokenRefreshed = (accessToken: string) => {
  unautherizationRequests = unautherizationRequests.filter((callback) =>
    callback(accessToken)
  );
};

//Add more navigates depending on different "external users root pages"
const checkPathToNavigateIfError = () => {
  if(window.location.pathname.startsWith("/report/registerreport")){
    window.location.pathname = "/report/login"
  }
  else {
    window.location.pathname = "/";
  }
}

enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  NotFound = 404,
}

apiAtrClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (!error.response) {
      if(error.message.includes("Network Error")){
        console.log(error.message)
        localStorage.setItem("Network_Error","Fel i kommunikationen med servern. Om felet kvarstår kontakta support!")
        ClearToken();
        checkPathToNavigateIfError()
      }
      throw error;
    }
    if (error.message === "Network Error") {
      // Redirect User to an Errorpage
      ClearToken();
      window.location.pathname = "/";
    }
    const { response, config } = error;

    switch (response?.status) {
      case StatusCode.Unauthorized: {
        if (response.data === "Invalid refresh token") {
          console.log("Invalid Refresh Token");
          ClearToken();
          checkPathToNavigateIfError()
        }
        console.log("StatusCode.Unauthorized");
        console.log(error);
        if (window.location.pathname !== "/" && window.location.pathname !== "/report/login") {
          // Not login page
          if (!newTokenInc) {
            console.log("Trying to get a new token");
            let token = GetToken();
            newTokenInc = true;
            const res = await apiAtrClient.post<ITokenStructure>(
              Base_URL + RefreshTokenUrl,
              {
                refreshToken: token?.refreshToken.tokenString,
                accessToken: token?.accessToken.tokenString,
              }
            );
            newTokenInc = false;
            if (res && res.status === 200) {
              console.log("UPDATES TOKEN");
              SetToken(res.data);

              onTokenRefreshed(res.data.accessToken.tokenString);
              if (config.headers)
                config.headers.Authorization = `Bearer ${res.data.accessToken.tokenString}`;
              return axios(config);
            } else {
              console.log("Clear");
              ClearToken();
              throw response;
            }
          }
          const retryOriginalRequest = new Promise((resolve) => {
            console.log("retry");
            addToUnautherizationRequests((accessToken: CallableFunction) => {
              if (config.headers)
                config.headers.Authorization = `Bearer ${accessToken}`;
              resolve(axios(config));
            });
          });
          return retryOriginalRequest;
        }
      }

      case StatusCode.BadRequest:{
        throw response
      }
      case StatusCode.Forbidden:
      case StatusCode.NotFound:
      {
        window.location.pathname = "/404";
      }
      case StatusCode.InternalServerError:
      case StatusCode.TooManyRequests:
      {
        window.location.pathname = "/500";
      }
      // HANDLE OTHER STATUS CODES.
      default: {
        window.location.pathname = "/500";
      }
    }
  }
);

export async function Get<D>(url: string): Promise<IMainBaseResponse<D>> {
  try {
    const { data, status } = await apiAtrClient.get<D>(url);
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    throw error;
  }
}

export async function Put<T, D>(
  url: string,
  obj: T
): Promise<IMainBaseResponse<D>> {
  try {
    const { data, status } = await apiAtrClient.put<D>(url, obj);
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function Post<T, D>(
  url: string,
  obj: T | null
): Promise<IMainBaseResponse<D>> {
  try {
    if (obj === null) {
      const { data, status } = await apiAtrClient.post<D>(url);
      return {
        message: "",
        object: data,
        status: status.toString(),
        success: true,
      };
    }

    const { data, status } = await apiAtrClient.post<D>(url, obj);
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error: any) {
    throw error;
  }
}

export async function Delete<D>(
  url: string,
  id: number
): Promise<IMainBaseResponse<D>> {
  try {
    const { data, status } = await apiAtrClient.delete<D>(url);
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function ExportFile<T>(
  url: string,
  obj: T,
  header: string = "attachment; filename=template.xlsx"
) {
  const extraConf: AxiosRequestConfig = {
    headers: {
      "Content-Disposition": header,
    },
    responseType: "arraybuffer",
  };
  try {
    const { data, status } = await apiAtrClient.post(url, obj, {
      ...extraConf,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function ExportFileViaGet<T>(
  url: string,
  header: string = "attachment; filename=template.xlsx"
) {
  const extraConf: AxiosRequestConfig = {
    headers: {
      "Content-Disposition": header,
    },
    responseType: "arraybuffer",
  };
  try {
    const { data, status } = await apiAtrClient.get(url, {
      ...extraConf,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function GetFile(url:string){
  const extraConf: AxiosRequestConfig = {
    headers: {
      "Content-Disposition": "attachment; filename=rapport.pdf",
    },
    responseType: "arraybuffer",
  };

  try {
    const { data, status } = await apiAtrClient.get(url, {
      ...extraConf,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}



export async function GetFiles<T>(url:string,obj:T){
  const extraConf: AxiosRequestConfig = {
    headers: {
      "Content-Disposition": "attachment; filename=rapporter.zip",
    },
    responseType: "blob",
  };

  try {
    const { data, status } = await apiAtrClient.post(url,obj, {
      ...extraConf,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}


export async function PostFile<T, D>(url: string, obj: FormData) {
  const requestHeaders: AxiosRequestConfig = {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  };
  try {
    console.log("final obj: ", obj.entries)
    const { data, status } = await apiAtrClient.post<D>(url, obj, {
      ...requestHeaders,
    });
    return {
      message: "",
      object: data,
      status: status.toString(),
      success: true,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }


  //HÄRIFRÅN FUNGERAR DET
  // const {data, status} = await axios.post(url, obj, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // })
  // return {
  //       message: "",
  //       object: data,
  //       status: status.toString(),
  //       success: true,
  //     };

      //HIT

//   try {
//    await axios.post(url, obj, )
//     .then(function (response) {
//       console.log(response);
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
//   }
//  catch (error) {
//     console.log(error);
//   //   throw error;
//   }
}

export default apiAtrClient;
