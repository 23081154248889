import React from "react";
import { InitMomentLocales } from "./Utilities/momentExt";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Utilities/authProvider";
import Layout from "./Views/layout";
import Home from "./Views/home";
import MyAccount from "./Views/myaccount";
import Main from "./Views/mainContentLayout";
import SignIn from "./Views/SignIn/signIn";
import User from "./Views/Users/user";
import New from "./Views/Users/new";
import Users from "./Views/Users/users";
import Contacts from "./Views/Contacts/contacts";
import { RequireAuth, RequireAuthRole } from "./Utilities/checkAuth";
import { Roles } from "./Utilities/roles";
import Error500 from "./Views/error500";
import Error404 from "./Views/error404";
import GlomtLosenord from "./Views/SignIn/glomtLosenord";
import ChangePassword from "./Views/SignIn/changepassword";
import UpdateForgottenPassword from "./Views/SignIn/updateForgottenPassword";
import NewContact from "./Views/Contacts/newContact";
import Contact from "./Views/Contacts/contact";
import SendEmail from "./Views/Contacts/sendEmail";
import ApplyUserAccount from "./Views/ApplyUserAccount/index";
import { NewPermit } from "./Views/Permit/new";
import Permits from "./Views/Permit/permits";
import Permit from "./Views/Permit/permit";
import EditPermit from "./Views/Permit/editPermit";
import UploadPermit from "./Views/Permit/uploadPermit";
import ReportRecords from "./Views/Report/records";
import ReportRecord from "./Views/Report/record";
import ReportNew from "./Views/Report/new";
import "./fonts.css";
import UploadedPermit from "./Views/Permit/uploadedPermit";
import { getIEVersion } from "./Utilities/getIEversion";
import IEerrorpage from "./Views/ieErrorPage";
import Applies from "./Views/ApplyUserAccount/applies";
import Applied from "./Views/ApplyUserAccount/applied";
import Licensors from "./Views/Licensors/licensors";
import LicensorsCreate from "./Views/Licensors/licensorCreate";
import EditLicensor from "./Views/Licensors/licensor";
import ExternalLogin from "./Views/Report/externalLogin";
import LayoutExt from "./Views/layoutext";
import RegistreraRapportIntern from "./Views/Report/registreraRapportIntern";
import CreateReportsForPeriod from "./Views/Report/createReportsPeriod";
import CreatePeriod from "./Views/Report/createPeriod";
import { licensorsUrl } from "./Utilities/constants";
import RegistreraRapportExtern from "./Views/Report/registreraRapportExtern";
import PopulationStatistics from "./Views/Statistics/PopulationStatistics";
import AlcoholStatistics from "./Views/Statistics/AlcoholStatistics";
import TobaccoStatistics from "./Views/Statistics/TobaccoStatistics";
import RegisterUtdragExportera from "./Views/Report/Registerutdrag/registerutdrag";
import SystemSettings from "./Views/Settings/SystemSettings";
import { FileManagementSKV } from "./Views/Permit/filemanagementSKV";
import Correct from "./Views/Permit/correct";

function App() {
  //Setting customized locale based on swedish standards
  InitMomentLocales();
  if (getIEVersion() === false) {
    return (
      <AuthProvider>
        <Routes>
          <Route path="/" element={<SignIn />} />

          <Route path="/applyforaccount" element={<ApplyUserAccount />} />
          <Route path="/report/login" element={<ExternalLogin />} />
          <Route
            path="/changepassword"
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route
            path="/updateforgottenpassword/:token"
            element={<UpdateForgottenPassword />}
          />

          <Route path="/glomtlosenord" element={<GlomtLosenord />} />
          <Route path="/500" element={<Error500 />} />
          <Route path="/404" element={<Error404 />} />

          <Route element={<Layout />}>
            <Route element={<Main />}>
              <Route
                path="/home"
                element={
                  <RequireAuthRole
                    roles={[
                      Roles.FohmAdmin,
                      Roles.FohmLasAlkohol,
                      Roles.FohmLasTobak,
                      Roles.KommunAdminAlkohol,
                      Roles.KommunAdminTobak,
                      Roles.KommunLasAlkohol,
                      Roles.KommunLasTobak,
                      Roles.LansstyrelseLasAlkohol,
                      Roles.Registerutdrag,
                    ]}
                    redirectTo="/home"
                  >
                    <Home />
                  </RequireAuthRole>
                }
              />
            </Route>

            <Route
              element={
                  <Main />
              }
            >
              <Route path="/myaccount" element={<MyAccount />} />
            </Route>

            {/* Nedan är exempel på hur vi kan rollstyra access till specifika paths */}
            <Route
              element={
                <RequireAuthRole
                  roles={[Roles.FohmAdmin, Roles.FohmLasAlkohol]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<User />} />
              <Route path="/users/new" element={<New />} />
              <Route path="users/applies" element={<Applies />} />
              <Route path="users/applies/:id" element={<Applied />} />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                    Roles.FohmLasAlkohol,
                    Roles.FohmLasTobak,
                    Roles.KommunAdminAlkohol,
                    Roles.KommunAdminTobak,
                    Roles.KommunLasAlkohol,
                    Roles.KommunLasTobak,
                    Roles.LansstyrelseLasAlkohol,
                  ]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/permits" element={<Permits />} />
              <Route path="/permits/:id" element={<Permit />} />
              <Route path="/permits/:id/edit" element={<EditPermit />} />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                    Roles.KommunAdminAlkohol,
                    Roles.KommunAdminTobak,
                  ]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/permits/new" element={<NewPermit />} />
            </Route>
            <Route
              element={
                <RequireAuthRole roles={[Roles.FohmAdmin]} redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/permits/upload" element={<UploadPermit />} />
              <Route path="/permits/upload/:id" element={<UploadedPermit />} />
            </Route>
            <Route
              element={
                <RequireAuthRole roles={[Roles.FohmAdmin, Roles.FohmLasAlkohol, Roles.Registerutdrag]} redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route
                path="/permits/records"
                element={<RegisterUtdragExportera />}
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                  ]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/permits/fileManagementSKV" element={<FileManagementSKV />} />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                  ]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/permits/:id/correct" element={<Correct />} />
            </Route>
            <Route element={<Main />}>
              <Route path="/reports" element={<ReportRecords />} />
              <Route path="/reports/search/:id" element={<ReportRecords />} />
              <Route path="/reports/:id/:prevYear" element={<ReportRecord />} />
              <Route path="/reports/:id" element={<ReportRecord />} />
              <Route path="/reports/new" element={<ReportNew />} />
              <Route
                path="/reports/new/:id"
                element={<RegistreraRapportIntern />}
              />
              <Route path="/reports/createperiod" element={<CreatePeriod />} />
              <Route
                path="/reports/registerReport"
                element={<RegistreraRapportExtern />}
              />
              <Route
                path="/reports/generateReports"
                element={<CreateReportsForPeriod />}
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                  ]}
                  redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/statistics" element={<PopulationStatistics />} />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                  ]}
                  redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route
                path="/statistics/population"
                element={<PopulationStatistics />}
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                    Roles.FohmLasAlkohol,
                    Roles.KommunAdminAlkohol,
                    Roles.KommunLasAlkohol,
                    Roles.LansstyrelseLasAlkohol
                  ]}
                  redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route
                path="/statistics/alcohol"
                element={<AlcoholStatistics />}
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                    Roles.FohmLasTobak,
                    Roles.KommunAdminTobak,
                    Roles.KommunLasTobak,
                    Roles.LansstyrelseLasAlkohol
                  ]}
                  redirectTo="/home">
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route
                path="/statistics/tobacco"
                element={<TobaccoStatistics />}
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[
                    Roles.FohmAdmin,
                    Roles.KommunAdminAlkohol,
                    Roles.KommunAdminTobak,
                    Roles.KommunLasAlkohol,
                    Roles.KommunLasTobak,
                    Roles.LansstyrelseLasAlkohol,
                  ]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route
                path="/contacts"
                element={
                  <RequireAuthRole
                    roles={[
                      Roles.FohmAdmin,
                      Roles.KommunAdminAlkohol,
                      Roles.KommunAdminTobak,
                      Roles.KommunLasAlkohol,
                      Roles.LansstyrelseLasAlkohol,
                    ]}
                    redirectTo="/home"
                  >
                    <Contacts />
                  </RequireAuthRole>
                }
              />
              <Route
                path="/contacts/:id"
                element={
                  <RequireAuthRole
                    roles={[
                      Roles.FohmAdmin,
                      Roles.KommunAdminAlkohol,
                      Roles.KommunAdminTobak,
                      Roles.KommunLasAlkohol,
                      Roles.LansstyrelseLasAlkohol,
                    ]}
                    redirectTo="/home"
                  >
                    <Contact />
                  </RequireAuthRole>
                }
              />
              <Route
                path="/contacts/new"
                element={
                  <RequireAuthRole
                    roles={[Roles.FohmAdmin, Roles.KommunAdminAlkohol]}
                    redirectTo="/home"
                  >
                    <NewContact />
                  </RequireAuthRole>
                }
              />
              <Route
                path="/contacts/sendemail"
                element={
                  <RequireAuthRole roles={[Roles.FohmAdmin]} redirectTo="/home">
                    <SendEmail />
                  </RequireAuthRole>
                }
              />
            </Route>
            <Route element={<Main />}>
              <Route
                path={licensorsUrl}
                element={
                  <RequireAuthRole
                    roles={[Roles.FohmAdmin, Roles.KommunAdminAlkohol]}
                    redirectTo="/home"
                  >
                    <Licensors />
                  </RequireAuthRole>
                }
              />
              <Route
                path={`${licensorsUrl}/new`}
                element={<LicensorsCreate />}
              />
              <Route
                path={`${licensorsUrl}/:id`}
                element={
                  <RequireAuthRole
                    roles={[Roles.FohmAdmin, Roles.KommunAdminAlkohol]}
                    redirectTo="/home"
                  >
                    <EditLicensor />
                  </RequireAuthRole>
                }
              />
            </Route>
            <Route
              element={
                <RequireAuthRole
                  roles={[Roles.FohmAdmin]}
                  redirectTo="/home"
                >
                  <Main />
                </RequireAuthRole>
              }
            >
              <Route path="/settings/systemsettings" element={<SystemSettings />} />
            </Route>
          </Route>
          {/* EXTERNAL ACCESS MIGHT BE HANDLED SEPERATLY */}
          <Route
            path="/report/registerReport/:org/:password"
            element={
              <RequireAuthRole
                roles={[Roles.RapportInlamnare]}
                redirectTo="/report/login"
              >
                <RegistreraRapportExtern />
              </RequireAuthRole>
            }
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </AuthProvider>
    );
  } else {
    return <IEerrorpage />;
  }
}

export default App;
