import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { IMainBaseResponse } from "../../Services/base";
import {
  exportKontakt,
  getEmailsContactsSearched,
  IKontaktGet,
  IKontaktSearchFilter,
  IKontaktSearchResponse,
  searchKontakter,
} from "../../Services/ContactService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { useFilter } from "../../Utilities/searchFilterProvider";
import { getKommunListInfo, kommunList } from "../../Services/infoService";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import InputTextField from "../../Components/textfield";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useAuth } from "../../Utilities/authProvider";
import { Roles } from "../../Utilities/roles";
import {
  KommunKontaktTyp,
  KommunKontaktTypes,
  KommunKontaktTypesForAlkoholAndTobak,
} from "../../Utilities/contactUtils";

export default function Contacts() {
  const filterProvider = useFilter();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState<any>();
  const [contacts, setContacts] = React.useState<
    IMainBaseResponse<ISearchResultBase<IKontaktSearchResponse>>
  >(null!);
  let auth = useAuth();
  const [errors, setErrors] = React.useState<any>(null);
  const BtnIcon = styled(ButtonBase)(({ theme }) => ({
    marginRight: "20px",
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        background: "white",
      },
    },
  }));
  function getBaseFilter(): ISearchBase<IKontaktSearchFilter> {
    return {
      filter: {
        fornamn: "",
        efternamn: "",
        epost: "",
        telefon: "",
        kommunKontaktTypId: 0,
        kommunId: 0,
        lanId: 0,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "efternamn",
      sorteringsOrdning: SortOrder.Fallande,
    };
  }

  const checkLocation = () => {
    const filter = filterProvider.getFilter("contacts");
    if (filter !== null && filter !== undefined) {
      return filter;
    } else return getBaseFilter();
  };

  const [filter, setFilter] = React.useState<ISearchBase<IKontaktSearchFilter>>(
    checkLocation()
  );
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "efternamn",
    direction: SortOrder.Fallande,
  });

  const headers: ITableHead<IKontaktSearchResponse>[] = [
    {
      id: "lanNamn",
      sortable: true,
      value: "Län",
    },
    {
      id: "kommunNamn",
      sortable: true,
      value: "Kommun",
    },
    {
      id: "kommunKontaktTypNamn",
      sortable: true,
      value: "Typ",
    },
    {
      id: "fornamn",
      sortable: true,
      value: "Förnamn",
    },
    {
      id: "efternamn",
      sortable: true,
      value: "Efternamn",
    },
    {
      id: "epost",
      sortable: true,
      value: "E-postadress",
    },
  ];

  const copyEmailUsersSearched = () => {
    setStatus({ sent: false });
    getEmailsContactsSearched(filter)
      .then((response) => {
        if (response.status === "200") {
          navigator.clipboard.writeText(
            response.object
              .map((x) => {
                return x;
              })
              .join(";")
          );
          setStatus({
            sent: true,
            title: "Mejladresserna har kopierats",
            msg: 'Starta ditt mejlprogram och klistra in dem (CTRL+V i "Hemlig kopia") och ange supportlådans adress som mottagare.',
            severity: "success",
          });
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const navigateToContact = (id: number) => {
    filterProvider.addFilter(filter, "contacts");
    navigate(`${id}`);
  };

  const SubmitSearch = (values: IKontaktSearchFilter) => {
    setFilter({ ...filter, filter: { ...values } });
  };

  const SubmitExport = (values: IKontaktSearchFilter) => {
    exportKontakt(values).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Kontakter.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    searchKontakter(filter)
      .then((data) => {
        setContacts({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setContacts(null!);
      });
  }, [filter]);

  useEffect(() => {
    getKommunListInfo(kommunList.all);
    searchKontakter(filter)
      .then((data) => {
        setContacts({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setContacts(null!);
      });
  }, []);

  const filterTypBasedOnRole = (): number[] | undefined => {
    if (auth.validateUserRoleAccess([Roles.LansstyrelseLasAlkohol])) {
      return KommunKontaktTypes.filter(
        (p) =>
          p.kommunKontakt === KommunKontaktTyp.TillstandsFragorLanet
      ).map((x) => {
        return x.id;
      });
    }
    if (
      auth.validateUserRoleAccess([Roles.KommunAdminAlkohol])
) {
return KommunKontaktTypesForAlkoholAndTobak.filter(
  (p) => p.kommunKontakt === KommunKontaktTyp.AnsokanAnvandarKonto
).map((x) => {
  return x.id;
});
}
if (
auth.validateUserRoleAccess([Roles.KommunAdminTobak]) 
) {
return KommunKontaktTypesForAlkoholAndTobak.filter(
  (p) => p.kommunKontakt === KommunKontaktTyp.AnsokanAnvandarKonto
).map((x) => {
  return x.id;
});
}
    if (auth.validateUserRoleAccess([Roles.KommunLasAlkohol])) {
      return KommunKontaktTypes.filter(
        (p) => p.kommunKontakt === KommunKontaktTyp.UtskickAvRestaurangRapport
      ).map((x) => {
        return x.id;
      });
    }

    return undefined;
  };

  useEffect(() => {
    if (contacts === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [contacts]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Sök kontakter
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} mb={2}>
        <Formik
          enableReinitialize
          initialValues={{ ...filter.filter }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SubmitSearch(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            handleSubmit,
            resetForm,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            setStatus,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        label="Typ"
                        defaultVal={false}
                        error={evalErrors(
                          errors,
                          touched,
                          "kommunKontaktTypId"
                        )}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "kommunKontaktTypId"
                        )}
                        infoType={InfoType.kommunKontaktTyper}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue("kommunKontaktTypId", data);
                        }}
                        name="kommunKontaktTypId"
                        onBlur={handleBlur}
                        selectedData={values.kommunKontaktTypId}
                        idsToKeep={filterTypBasedOnRole()}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        label="Län"
                        defaultVal={false}
                        error={evalErrors(errors, touched, "lanId")}
                        helperText={getErrorText(errors, touched, "lanId")}
                        infoType={InfoType.lanAnvandare}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue("lanId", data);
                        }}
                        name="lanId"
                        onBlur={handleBlur}
                        selectedData={values.lanId}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        label="Kommun"
                        defaultVal={false}
                        error={evalErrors(errors, touched, "kommunId")}
                        helperText={getErrorText(errors, touched, "kommunId")}
                        infoType={InfoType.kommunerAnvandare}
                        multiple={false}
                        onChange={(data) => {
                          setFieldValue("kommunId", data);
                        }}
                        name="kommunId"
                        onBlur={handleBlur}
                        selectedData={values.kommunId}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2} alignItems={"baseline"}>
                    <InputTextField
                      fullWidth
                      name="fornamn"
                      label="Förnamn"
                      onChange={handleChange}
                      value={values.fornamn}
                    />
                    <InputTextField
                      fullWidth
                      name="efternamn"
                      label="Efternamn"
                      onChange={handleChange}
                      value={values.efternamn}
                    />
                    <InputTextField
                      fullWidth
                      name="epost"
                      label="E-postadress"
                      onChange={handleChange}
                      value={values.epost}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault disabled={isSubmitting} type="submit">
                      Sök
                    </BtnDefault>
                    <BtnSecond
                      type="reset"
                      onClick={() => {
                        setFilter({ ...getBaseFilter() });
                        resetForm();
                      }}
                    >
                      Rensa
                    </BtnSecond>
                    <BtnSecond
                      disabled={isSubmitting}
                      onClick={() => {
                        SubmitExport(values);
                      }}
                      type="button"
                    >
                      Exportera resultat
                    </BtnSecond>
                    {auth.validateUserRoleAccess([
                      Roles.FohmAdmin,
                    ]) && (
                      <>
                        <BtnIcon
                          onClick={copyEmailUsersSearched}
                          id="kopiera-adresser"
                        >
                          <FileCopyIcon color="primary" />
                          <Typography
                            marginLeft={1}
                            color="primary"
                            variant="caption"
                            component="p"
                          >
                            Kopiera e-postadresser för framsökta användare
                          </Typography>
                        </BtnIcon>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Sökresultat
        </Typography>
      </Grid>
      {ShowAlertEval(status) && (
        <Grid item xs={12} md={10} mt={1}>
          <ShowAlert
            onClose={() => {
              setStatus(null);
            }}
            severity={status.severity}
            alertTitle={status.title}
            alertText={status.msg}
          />
        </Grid>
      )}
      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {contacts !== null && (
          <DefaultTable
            header={headers}
            activePage={filter.sidoNummer}
            data={mapToCellData(headers, contacts.object.sokResultat)}
            rowsPerPage={checkMenuItem(filter.sidoStorlek)}
            totalHits={contacts.object.totaltAntalTraffar}
            filter={filter}
            setFilterAction={setFilter}
            setSortOptions={setSortOptions}
            sortOptions={sortOptions}
            actions={[
              {
                action: navigateToContact,
                icon: ActionIcons.arrowForward,
                title: "Visa mer",
              },
            ]}
            tableAria="Kontakter"
          />
        )}
      </Grid>
    </Grid>
  );
}
