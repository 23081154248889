import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { InfoType } from "../../Components/selects";
import { LicensorCreateSchema } from "./validationLicensor";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { useNavigate } from "react-router-dom";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import InputTextField from "../../Components/textfield";
import {
  getKommunerWithoutTillstandshavare,
  ILicensorPost,
  postTillstandshavare,
} from "../../Services/LicensorService";

export interface IForm extends ILicensorPost {}

export default function LicensorsCreate() {
  const initialValues: IForm = {
    namn: "",
    forvaltning: "",
    adress: "",
    postnr: "",
    postOrt: "",
    kommunId: 0,
  };

  const [kommunlista, setKommunlista] = React.useState<number[]>([0]);
  const [loading, setLoading] = React.useState(true);

  const updateValuesKommunlista = () => {
    getKommunerWithoutTillstandshavare().then((res) => {
      console.log(res.object);
      setKommunlista([...res.object]);
    });
  };

  useEffect(() => {
    updateValuesKommunlista();
  }, []);

  useEffect(() => {
    if (kommunlista !== null || kommunlista !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [kommunlista]);

  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Registrera ny kommun/tillståndsgivare
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} mb={2}>
        <Formik
          enableReinitialize
          validationSchema={LicensorCreateSchema}
          initialValues={{
            ...initialValues,
            confirmepost: "",
            availableTypes: []!,
          }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            setSubmitting(true);
            postTillstandshavare(values)
              .then((response) => {
                setSubmitting(false);
                updateValuesKommunlista();
                resetForm();
                setStatus({
                  sent: true,
                  msg: `Kommun/Tillståndsgivare ${values.namn} skapades.`,
                  title: "Kommun/Tillståndsgivare skapad",
                  severity: "success",
                });
              })
              .catch((error) => {
                console.log("error");
                setStatus({
                  sent: true,
                  msg: error.data,
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            isSubmitting,
            setStatus,
            setFieldValue,
            handleSubmit,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {!loading ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                      <Typography variant="body1">För att lägga till en helt ny kommun/tillståndsgivare, krävs först manuell hantering direkt i databas</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <AutoCompleteAtr
                            onBlur={handleBlur}
                            infoType={InfoType.kommuner}
                            label="Kommun"
                            multiple={false}
                            defaultVal={false}
                            onChange={(data) => {
                              setFieldValue("kommunId", data);
                            }}
                            error={evalErrors(errors, touched, "kommunId")}
                            helperText={getErrorText(
                              errors,
                              touched,
                              "kommunId"
                            )}
                            name="kommunId"
                            selectedData={values.kommunId}
                            idsToKeep={[...kommunlista]}
                          />
                        </FormControl>
                      </Grid>
                      {values.kommunId !== 0 && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h3" component="h2" paragraph>
                              Adress till den kommun som hanterar
                              restaurangrapporterna
                            </Typography>
                            <Typography variant="caption" component="p" mb={1}>
                              Fält markerade med * är obligatoriska
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="baseline"
                            >
                              <InputTextField
                                fullWidth
                                name="namn"
                                label="Namn"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.namn}
                                error={evalErrors(errors, touched, "namn")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "namn"
                                )}
                                required
                                aria-required="true"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="baseline"
                            >
                              <InputTextField
                                fullWidth
                                name="forvaltning"
                                label="Förvaltning*"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.forvaltning}
                                error={evalErrors(
                                  errors,
                                  touched,
                                  "forvaltning"
                                )}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "forvaltning"
                                )}
                                aria-required="true"
                              />
                              <InputTextField
                                fullWidth
                                name="adress"
                                label="Adress"
                                onChange={handleChange}
                                value={values.adress}
                                onBlur={handleBlur}
                                error={evalErrors(errors, touched, "adress")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "adress"
                                )}
                                aria-required="true"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="baseline"
                            >
                              <InputTextField
                                fullWidth
                                name="postnr"
                                label="Postnummer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.postnr}
                                error={evalErrors(errors, touched, "postnr")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "postnr"
                                )}
                                required
                                aria-required="true"
                              />
                              <InputTextField
                                fullWidth
                                name="postOrt"
                                label="Postort"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.postOrt}
                                error={evalErrors(errors, touched, "postOrt")}
                                helperText={getErrorText(
                                  errors,
                                  touched,
                                  "postOrt"
                                )}
                                required
                                aria-required="true"
                              />
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                )}
                {ShowAlertEval(status) && (
                  <Grid item xs={12}>
                    <ShowAlert
                      onClose={() => {
                        setStatus(null);
                      }}
                      severity={status.severity}
                      alertTitle={status.title}
                      alertText={status.msg}
                    />
                  </Grid>
                )}
                {values.kommunId !== 0 && (
                  <Grid item xs={4}>
                    <Stack direction="row" spacing={2}>
                      <BtnDefault disabled={isSubmitting} type="submit">
                        Skapa
                      </BtnDefault>
                      {isSubmitting && <CircularProgress />}
                      <BtnSecond onClick={() => navigate("/licensors")}>
                        Avbryt
                      </BtnSecond>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
