import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IForm } from "../new";
import { IInfo } from "../../../Services/infoService";
import { FormTypeTillstandshavare } from "./tillstandshavareForm";
import { FormTypeObject } from "./objektForm";
import { Giltighetstid } from "./giltighetstidForm";
import { FormTypeTillfalligt } from "./taillfalligtForm";
import { Roles } from "../../../Utilities/roles";
import { useAuth } from "../../../Utilities/authProvider";
import { FormTypeServeringensOmfattning } from "./serveringensOmfattningForm";
import { FormTypeVerksamhetsomraden } from "./verksamhetsomradenForm";
import { FormTypeKategori } from "./kategoriForm";

import InputTextField from "../../../Components/textfield";
import { GetDataBasedOnType, IGroupData, InfoType } from "../../../Components/selects";
import { FormTypeLopnummer } from "./lopnummerForm";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";

export enum Permits {
  StadigvarandeServeringstillstand = 1,
  Upplagshavare = 5,
  RegistreradVarumottagare = 6,
  SkattebefriadForbrukare = 8,
  TobakstillstandDetaljhandelTillsvidare = 9,
  TobakstillstandDetaljhandelVissTid = 10,
  TobakstillstandPartihandel = 11,
  TillfalligtServeringstillstand = 12,
  IngetValt = 0,
}

interface IMap extends IInfo {
  permit: Permits;
}

export function MapToPermitType(id: number): Permits {
  const res = SearchPermitTypes.find((p) => p.id === id)?.permit;
  if (res === undefined) throw Error("Something went wrong");

  return res;
}

export function MapToPermitTypeName(id: number): string {
  const res = SearchPermitTypes.find((p) => p.id === id)?.namn;
  if (res === undefined) throw Error("Something went wrong");

  return res;
}

export function MapToSearchPermitType(id: number): Permits {
  const res = SearchPermitTypes.find((p) => p.id === id)?.permit;
  if (res === undefined) throw Error("Something went wrong");

  return res;
}

export const PermitTypes: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];

export const SearchPermitTypesAlkohol: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 6,
    namn: "Registrerad varumottagare",
    permit: Permits.RegistreradVarumottagare,
  },
  {
    id: 8,
    namn: "Skattebefriad förbrukare",
    permit: Permits.SkattebefriadForbrukare,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
  {
    id: 5,
    namn: "Upplagshavare",
    permit: Permits.Upplagshavare,
  },
];

export const SearchPermitTypesTobak: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];

export const SearchPermitTypesKommunAdmin: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];

export const SearchPermitTypesKommunAlkohol: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
];

export const SearchPermitTypesKommunTobak: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];
export const SearchPermitTypesLansstyrelseAlkoholTobak: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];

export const SearchPermitTypes: IMap[] = [
  {
    id: 0,
    namn: "Välj tillståndstyp",
    permit: Permits.IngetValt,
  },
  {
    id: 6,
    namn: "Registrerad varumottagare",
    permit: Permits.RegistreradVarumottagare,
  },
  {
    id: 8,
    namn: "Skattebefriad förbrukare",
    permit: Permits.SkattebefriadForbrukare,
  },
  {
    id: 1,
    namn: "Stadigvarande serveringstillstånd",
    permit: Permits.StadigvarandeServeringstillstand,
  },
  {
    id: 12,
    namn: "Tillfälligt serveringstillstånd",
    permit: Permits.TillfalligtServeringstillstand,
  },
  {
    id: 5,
    namn: "Upplagshavare",
    permit: Permits.Upplagshavare,
  },
  {
    id: 9,
    namn: "Tobakstillstånd detaljhandel tillsvidare",
    permit: Permits.TobakstillstandDetaljhandelTillsvidare,
  },
  {
    id: 10,
    namn: "Tobakstillstånd detaljhandel viss tid",
    permit: Permits.TobakstillstandDetaljhandelVissTid,
  },
  {
    id: 11,
    namn: "Tobakstillstånd partihandel",
    permit: Permits.TobakstillstandPartihandel,
  },
];

export enum FormTypes {
  TillstandsObjekt = "Tillstandsobjekt",
  TillstandsHavare = "Tillstandshavare",
  Webbutik = "Webbutik",
  Giltlighetstid = "Giltlighetstid",
  Tillfalligt = "Tillfalligt",
  ServeringensOmfattning = "ServeringensOmfattning",
  Verksamhetsomraden = "Verksamhetsomraden",
  Kategori = "Kategori",
  Lopnummer = "Lopnummer",
}

export enum Mode {
  Create = "Create",
  Edit = "Edit",
  Transfer = "Transfer",
  View = "View",
}

interface FormtypeToFunction {
  type: FormTypes;
  function: (
    props: FormikProps<IForm>,
    index: number,
    permit: Permits,
    roles: Roles[],
    mode: Mode,
    dataType?: IGroupData[],
  ) => React.ReactNode;
}

interface IFormStructure {
  Permit: Permits;
  FormTypes: FormTypes[];
}

//Defines a formstructure that is set up by choosing a permit type and then choosing which formtypes that should be rendered
export const FormStructures: IFormStructure[] = [
  {
    Permit: Permits.IngetValt,
    FormTypes: [],
  },
  //TOBAK - START
  {
    Permit: Permits.TobakstillstandDetaljhandelTillsvidare,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Webbutik,
      FormTypes.Giltlighetstid,
      FormTypes.Lopnummer,
    ],
  },
  {
    Permit: Permits.TobakstillstandDetaljhandelVissTid,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Webbutik,
      FormTypes.Giltlighetstid,
      FormTypes.Lopnummer,
    ],
  },
  {
    Permit: Permits.TobakstillstandPartihandel,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Webbutik,
      FormTypes.Giltlighetstid,
      FormTypes.Lopnummer,
    ],
  },
  //TOBAK - END
  //Alkohol - START
  {
    Permit: Permits.RegistreradVarumottagare,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Giltlighetstid,
      FormTypes.Kategori,
      FormTypes.Verksamhetsomraden,
    ],
  },
  {
    Permit: Permits.StadigvarandeServeringstillstand,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Giltlighetstid,
      FormTypes.ServeringensOmfattning,
    ],
  },
  {
    Permit: Permits.SkattebefriadForbrukare,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Giltlighetstid,
      FormTypes.Kategori,
      FormTypes.Verksamhetsomraden,
    ],
  },
  {
    Permit: Permits.Upplagshavare,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Giltlighetstid,
      FormTypes.Kategori,
      FormTypes.Verksamhetsomraden,
    ],
  },
  {
    Permit: Permits.TillfalligtServeringstillstand,
    FormTypes: [
      FormTypes.TillstandsHavare,
      FormTypes.TillstandsObjekt,
      FormTypes.Giltlighetstid,
      FormTypes.Tillfalligt,
      FormTypes.Lopnummer,
    ],
  },
];

//Defines a set of instructions mapped to a formtype that corresponds to a function that renders the correct section of the form
export const FormTypeToFunction: FormtypeToFunction[] = [
  {
    type: FormTypes.TillstandsHavare,
    function: FormTypeTillstandshavare,
  },
  {
    type: FormTypes.TillstandsObjekt,
    function: FormTypeObject,
  },
  {
    type: FormTypes.Webbutik,
    function: FormTypeWebbutik,
  },
  {
    type: FormTypes.Giltlighetstid,
    function: Giltighetstid,
  },
  {
    type: FormTypes.Tillfalligt,
    function: FormTypeTillfalligt,
  },
  {
    type: FormTypes.ServeringensOmfattning,
    function: FormTypeServeringensOmfattning,
  },
  {
    type: FormTypes.Verksamhetsomraden,
    function: FormTypeVerksamhetsomraden,
  },
  {
    type: FormTypes.Kategori,
    function: FormTypeKategori,
  },
  {
    type: FormTypes.Lopnummer,
    function: FormTypeLopnummer,
  },
];

export function GenerateFormStructure(
  permit: Permits,
  props: FormikProps<IForm>,
  mode: Mode = Mode.Create,
) {
  const auth = useAuth();
  const [dataType, setDataType] = React.useState<IGroupData[]>([]);
  const permitType = SearchPermitTypes.find((x) => x.id === props.values.tillstandsTypId)?.namn || "";

  const infoType = permitType === "Upplagshavare"
  ? InfoType.tillstandsKategoriUpplagshavare
  : permitType === "Skattebefriad förbrukare"
  ? InfoType.tillstandsKategoriSkattebefriadForbrukare
  : permitType === "tillstandsKategoriSkattebefriadForbrukare"
  ? InfoType.tillstandsKategoriSkattebefriadForbrukare
  : InfoType.tillstandsKategoriRegistreradVarumottagare;

  useEffect(() => {
      GetDataBasedOnType(
        infoType,
        undefined,
        true
      ).then((p) => {
          setDataType(p as IGroupData[]);
        }
      )
    }, []);

  const formStructure = FormStructures.find((p) => p.Permit === permit);

  if (formStructure === undefined) throw Error("Cannot find form");

  const formarr = formStructure.FormTypes.map((p, i) => {
    return FormTypeToFunction.filter((x) => x.type === p).map((x) =>
    x.type === "Kategori" ? (
      x.function(props, i, permit, auth.user.roles, mode, dataType)
    ) : (
      x.function(props, i, permit, auth.user.roles, mode)
    )
    );
  });

  return formarr.map((x, i) => (
    <Grid key={i} item xs={12}>
      {x}
    </Grid>
  ));
}

export const showHideFieldBasic = (permits: Permits[], permit: Permits) => {
  if (permits.findIndex((p) => p === permit) > -1) {
    return true;
  }
  return false;
};

export const showHideField = (
  permits: Permits[],
  permit: Permits,
  modes: Mode[],
  mode: Mode
) => {
  if (
    permits.findIndex((p) => p === permit) > -1 &&
    modes.findIndex((m) => m === mode) > -1
  ) {
    return true;
  }
  return false;
};

export const disabledEnabled = (permits: Permits[], permit: Permits) => {
  if (permits.findIndex((p) => p === permit) > -1) {
    return true;
  }
  return false;
};

const checkRequierdwebbutikNamn = (props: any): boolean => {
  {
    if ( (!props.values.tillstandsObjekt.webbutikNamn && props.values.tillstandsObjekt.webbutikAdress) 
    ) {
      return true;
    }
    return false;
  }
};

const checkRequierdwebbutikAdress = (props: any): boolean => {
  {
    if ((props.values.tillstandsObjekt.webbutikNamn && !props.values.tillstandsObjekt.webbutikAdress)  
    ) {
      return true;
    }
    return false;
  }
};

//WEBBUTIK START
export function FormTypeWebbutik(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode
) {
  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Webbutik</Typography>
      </Grid>
      <Grid item xs={6} key="tillstandsObjekt.webbutikNamn">
        <InputTextField
          fullWidth
          name="tillstandsObjekt.webbutikNamn"
          label="Webbutikens namn"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          required = {checkRequierdwebbutikNamn(props)}
          value={props.values.tillstandsObjekt.webbutikNamn ?? ""}
          error={evalErrors(
            props.errors.tillstandsObjekt,
            props.touched.tillstandsObjekt ?? {},
            "webbutikNamn"
          )}
          helperText={getErrorText(
            props.errors.tillstandsObjekt,
            props.touched.tillstandsObjekt ?? {},
            "webbutikNamn"
          )}
          disabled={mode === Mode.View}
        />
      </Grid>
      <Grid item xs={6} key="tillstandsObjekt.webbutikAdress">
        <InputTextField
          fullWidth
          name="tillstandsObjekt.webbutikAdress"
          label="Webbadress"
          required = {checkRequierdwebbutikAdress(props)}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.tillstandsObjekt.webbutikAdress ?? ""}
          error={evalErrors(
            props.errors.tillstandsObjekt,
            props.touched.tillstandsObjekt ?? {},
            "webbutikAdress"
          )}
          helperText={getErrorText(
            props.errors.tillstandsObjekt,
            props.touched.tillstandsObjekt ?? {},
            "webbutikAdress"
          )}
          disabled={mode === Mode.View}
        />
      </Grid>
    </Grid>
  );
}
//WEBBUTIK END

export function GetPermitTypes() {
  const auth = useAuth();
  if (auth.validateUserRoleAccess([Roles.FohmLasAlkohol])) {
    return SearchPermitTypes.filter(permit => [9, 10, 11].indexOf(permit.id) === -1)
  }
}
