import { AxiosRequestConfig } from "axios";
import apiAtrClient, { Get, Post, Put, Delete, ExportFile, PostFile } from "../Utilities/axiosBase";
import { IMainBaseResponse } from "./base";
import { ISearchBase, ISearchResultBase } from "./Searchbase";

export interface ITillstandshavare {
  namn: string;
  adress: string;
  postOrt: string;
  postnr: string;
}
export interface ITillstandsObjekt {
  namn: string;
  adress: string;
  postOrt: string;
  postnr: string;
  webbutikNamn: string;
  webbutikAdress: string;
}

export interface ITillstandsKategoriObjekt {
  id: number;
  tillstandsKategoriGruppId: number;
  kategoriKod: string;
  namn: string;
  tillstandsKategoriGrupp?: string;
}

export interface ITillstand {
  tillstandsTypId: number;
  kommunId: number;
  kommunNamn: string;
  orgNr: string;
  objektNr: string;
  beslutsDatum: string;
  aktivieringsDatum: string;
  id: number;
  andrad: string;
  andradAv: string;
  andradAvLatest?:string;
  andradDagLatest?:string;
  orsakTillAvslutId: number;
  avslutsDatum: string | null;
  tillstandsHavare: ITillstandshavare;
  tillstandsObjekt: ITillstandsObjekt;
  versionDetails: IVersionDetails;
  lopNr: string;
  versionId: number;
  registreradViaWebbtjanst: boolean;
  tillstandsKategoriIds: ITillstandsKategoriObjekt[] | number[] | null;
}

export interface ITillstandPost extends ITillstand {}

export interface IVersionDetails {
  versionsNr: number;
  versionsStart: string;
  provoTidTom: string | null;
  erbjudaProvsmakning: boolean;
  framstallaSnaps: boolean;
  antalPersonerMaxBeslutRaddningstjanst: boolean;
  stadigvTillstandFinns: boolean;
  servFleraTillfUnderPeriod: boolean;
  servtidAnnanInneFrom: string | null;
  servtidAnnanInneTom: string | null;
  servtidAnnanUteFrom: string | null;
  servtidAnnanUteTom: string | null;
  servtidNormaltidInne: boolean;
  servtidNormaltidUte: boolean;
  servtidSlutnaFrom: string | null;
  servtidSlutnaTom: string | null;
  servtidTillfFrom: string | null;
  servtidTillfTom: string | null;
  uteserveringFinns: boolean;
  antalPersonerMax: number | null;
  antalSittplatserInne: number | null;
  antalSittplatserUte: number | null;
  servUtrymmeStadigvarande: string | null;
  villkor: string | null;
  alkodrycksliknPreparat: boolean;
  servUtrymmeTypId: number | null;
  servUtrymmeString: string | null;
  verksamhetsOmrade1: string | null;
  verksamhetsOmrade2: string | null;
  verksamhetsOmrade3: string | null;
  verksamhetsOmrade4: string | null;
  verksamhetsOmrade5: string | null;
}
export interface ITillstandRapport {
  id: number;
  rapportTypId: number;
  orgNr: string | null;
  tillstandshavareNamn: string | null;
  objektNr: string | null;
  objektNamn: string | null;
  statusId: string | null;
  skapad: string;
  inkommen: string | null;
  registreradViaWebb: boolean;
  klar: string | null;
  rapportPeriodNamn:string;
}
export interface ITillstandGet extends ITillstand {
  avslutsDatum: string; //Datum
  lopNr: string;
  statusNamn: string;
  orsakTillAvslutName: string;
}

export interface ITillstandSearchFilter {
  orgNr: string;
  tillstandstypId: number;
  tillstandsGivareId: number;
  havareNamn: string,
  havareAdress: string,
  havarePostOrt: string,
  kommunId: number,
  lanId: number;
  objektNr: string;
  objektNamn: string;
  objektAdress: string,
  objektPostOrt: string,
  inkluderaFramtida: boolean,
  inkluderaAvslutade: boolean,
  inkluderaAldreAvslutade: boolean
}

export interface ITillstandSearchResult {
  tillstandsId: number;
  orgNr: string;
  objektNr: string;
  statusId: string;
  tillstandsHavareNamn: string;
  tillstandsHavareAdress: string;
  tillstandsHavarePostOrt: string;
  tillstandsObjektAdress: string;
  tillstandsObjektPostOrt: string;
  tillstandsObjektNamn: string;
  statusNamn: string
}

export interface ITillstandSkvImport {
  dryrun: boolean;
  file: (File | null)[];
}

export interface ICorrectTillstand {
  orgNr: string;
  objektNr: string;
  aktiveringsdatum: string;
  orsakTillAvslutId: number;
  avslutsDatum: string;
  beslutsDatum: string;
}

export interface ICloseTillstand {
  orsakTillAvslutId: number;
  avslutsdatum: string;
}

interface IErrorlist {
  dataRow: string;
  error: string;
}

interface IImportWarnings {
  warning: string;
}
export interface ISkvData {
  existingRegistreradVarumottagareAfterImport: number;
  existingRegistreradVarumottagareBeforeImport: number;
  existingSkattebefriadForbrukareAfterImport: number;
  existingSkattebefriadForbrukareBeforeImport: number;
  existingUpplagshavareAfterImport: number;
  existingUpplagshavareBeforeImport: number;
  existingUpplagshavareInSkvFile: number;
  canceledUpplagshavareInSkvFile: number;
  existingRegistreradVarumottagareInSkvFile: number;
  canceledRegistreradVarumottagareInSkvFile: number;
  existingSkattebefriadForbrukareInSkvFile: number;
  canceledSkattebefriadForbrukareInSkvFile: number;
  importedBy: string;
  newRegistreradVarumottagare: number;
  updatedRegistreradVarumottagare: number;
  canceledRegistreradVarumottagare: number;
  newSkattebefriadForbrukare: number;
  updatedSkattebefriadForbrukare: number;
  canceledSkattebefriadForbrukare: number;
  newUpplagshavare: number;
  updatedUpplagshavare: number;
  canceledUpplagshavare: number;
  numberOfErrors: number;
  timeOfImport: string;
  importWarnings: IImportWarnings[];
  numberOfWarnings: number;
  importErrors: IErrorlist[];
}

export interface ILastImportsData {
  id: number;
  timeOfImport: string;
  importedBy: string;
  existingUppslagshavareBeforeImport: number;
  existingUppslagshavareAfterImport: number;
  existingRegistreradVarumottagareBeforeImport: number;
  existingRegistreradVarumottagareAfterImport: number;
  existingSkattebefriadForbrukareBeforeImport: number;
  existingSkattebefriadForbrukareAfterImport: number;
  numberOfErrors: number;
  numberOfWarnings: number;
  importErrors: IImportWarnings[];
  importWarnings: IImportWarnings[];
}

export interface IHanteraSKVFil {
  artal: number
  kvartal: number
}

export interface IHanteraSKVGet extends IHanteraSKVFil {}

const tillstandbase: string = "/tillstand";
const tillstandRapportBase: string = "/rapport";


export const exportSKVTillstandFile = async (
  values: IHanteraSKVFil
): Promise<any> => {
  return await Post <IHanteraSKVFil, IHanteraSKVGet>(`${tillstandbase}/exportskvtillstandfile`, values);
};

export const postPermit = async (
  tillstand: ITillstandPost
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Post<ITillstandPost, ITillstandGet>(
    `${tillstandbase}`,
    tillstand
  );
};

export const checkRestaurantNumber = async (
  objectNr: string
): Promise<boolean> => {
  try {
    const response: IMainBaseResponse<boolean> = await Get<boolean>(
      `${tillstandbase}/throwIfObjectNrHaveExists/${objectNr}`
    );

    return response.object || false; 
  } catch (error) {
    console.error('Fel vid hämtning av restaurangnummer:', error);
    return false; // Returnera false om det uppstår ett fel
  }
};

export const searchPermits = async (
  filters: ISearchBase<ITillstandSearchFilter>
): Promise<IMainBaseResponse<ISearchResultBase<ITillstandSearchResult>>> => {
  return await Post<
    ISearchBase<ITillstandSearchFilter>,
    ISearchResultBase<ITillstandSearchResult>
  >(`${tillstandbase}/search`, filters);
};

export const GetTillstand = async (
  id: number
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Get<ITillstandGet>(`${tillstandbase}/${id}`);
};

export const GetTillstandhavareByOrgnr = async (
  orgNr: string
): Promise<IMainBaseResponse<ITillstandshavare>> => {
 return await Get(`${tillstandbase}/tillstandshavare/${orgNr}`);
};

export const correctTillstand = async (
  id: number,
  obj: ICorrectTillstand
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Put<ICorrectTillstand, ITillstandGet>(
    `${tillstandbase}/correct/${id}`,
    obj
  );
};

export const incorrectTillstand = async (
  id: number
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Put<any, ITillstandGet>(`${tillstandbase}/incorrect/${id}`, null);
};

export const closeTillstand = async (
  id: number,
  obj: ICloseTillstand
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Put<ICloseTillstand, ITillstandGet>(
    `${tillstandbase}/close/${id}`,
    obj
  );
};

export const deleteFutureVersionTillstand = async (
  id: number
): Promise<IMainBaseResponse<any>> => {
  return await Delete<IMainBaseResponse<any>>(
    `${tillstandbase}/version/${id}`,
    id
  );
};

export const updateTillstand = async (
  id: number,
  obj: ITillstandPost
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Put<ITillstandPost, ITillstandGet>(
    `${tillstandbase}/${id}`,
    obj
  );
};

export const getTillstandVersions = async (
  id: number
): Promise<IMainBaseResponse<ITillstandGet[]>> => {
  return await Get(`${tillstandbase}/${id}/versions`);
};

export const getTillstandVersion = async (
  permitId: number,
  versionId: number
): Promise<IMainBaseResponse<ITillstandGet>> => {
  return await Get(`${tillstandbase}/${permitId}/version/${versionId}`);
};
export const getTillstandRapport = async (
  id: number
): Promise<IMainBaseResponse<ITillstandRapport[]>> => {
  return await Get(`${tillstandRapportBase}${tillstandbase}/${id}`);
};

export const getTillstandAutofill = async (
  id: string
): Promise<IMainBaseResponse<ITillstandsObjekt>> => {
  return await Get(`${tillstandbase}/autofill/${id}`);
};

export const exportexcel = async (filters: ITillstandSearchFilter) => {
  return await ExportFile<ITillstandSearchFilter>(
    `${tillstandbase}/exportexcel`,
    filters
  );
};

export const uploadSkvFileDryrun = async (formData: FormData): Promise<IMainBaseResponse<ISkvData>> => {
  return await PostFile(`${tillstandbase}/importskvtillstand?dryRun=true`, formData);
};

export const uploadSkvFile = async (formData: FormData): Promise<IMainBaseResponse<ISkvData>> => {
  return await PostFile(`${tillstandbase}/importskvtillstand?dryRun=false`, formData);
};

export const getLastImports = async (): Promise<IMainBaseResponse<ILastImportsData>> => {
  return await Get(`${tillstandbase}/getlastimports`);
};

export const getSKVImport = async (id: number): Promise<IMainBaseResponse<ISkvData>> => {
  return await Get(`${tillstandbase}/import/${id}`);
};
