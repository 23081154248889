import {
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { FormikProps, setNestedObjectValues, validateYupSchema } from "formik";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BtnDefault, BtnSecond } from "../../../Components/buttons";
import CustomModal from "../../../Components/customModal";
import {
  getRapportPDF,
  GetUpdateRequest,
  IUpdateRapportDetails,
  IUpdateRestaurantReportStatus,
  RapportStatus,
  updateRestaurantReportStatus,
  resetRestaurantReportStatus
} from "../../../Services/reportService";
import { useAuth } from "../../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { Mode, RapportTyper, Rapporttyper } from "./formlogic";
import { SaveLocal, ifNullOrUndefined } from "./mainForm";

export function Skicka(
  props: FormikProps<IUpdateRapportDetails>,
  mode: Mode = Mode.Create,
  renderDescisionBtns: boolean = false,
  rapportStatusNamn: string = "",
  setRapportStatusLocal: (value: React.SetStateAction<string>) => void
) {
  const [open, setOpen] = React.useState(false);
  const auth = useAuth();
  const nav = useNavigate();

  const [modal, setModal] = React.useState({
    open: false,
    title: "",
    text: "",
    type: "",
  });

  const granskaReport = () => {
    const updateReport: IUpdateRestaurantReportStatus = {
      id:
        props.values.rapportId === undefined || props.values.rapportId === null
          ? 0
          : props.values.rapportId,
      status: "OrimligEllerFelRegistrerad",
    };

    updateRestaurantReportStatus(updateReport)
      .then((response) => {
        props.setStatus({
          sent: true,
          msg: `Rapporten för ${props.values.rapportTypId === Rapporttyper.Restaurangrapport ? props.values.objektOchOrgDetaljer?.objektNamn : props.values.objektOchOrgDetaljer?.havareNamn} fick status granskas. ${props.values.rapportTypId === Rapporttyper.Restaurangrapport ? 
            "Ett e-postmeddelande har skickats till kommunens kontaktperson för restaurangrapporter." : ""}`,
          severity: "success",
        });
        setRapportStatusLocal(RapportStatus.Granskas);
      })
      .catch((error) => {
        console.log("error on update: ", error);
        props.setStatus({
          sent: true,
          msg: `Något gick fel, ${error.data}`,
          severity: "error",
        });
      });
  };

  const approveReport = () => {
    console.log("approve");
    const updateReport: IUpdateRestaurantReportStatus = {
      id:
        props.values.rapportId === undefined || props.values.rapportId === null
          ? 0
          : props.values.rapportId,
      status: "Godkand",
    };
    updateRestaurantReportStatus(updateReport)
      .then((response) => {
        console.log("Sucess on update!");
        setRapportStatusLocal(RapportStatus.Klar);
        let message = `Rapporten för ${
          props.values.objektOchOrgDetaljer?.objektNamn
        } klarmarkerades ${moment().format("yyyy-MM-DD-HH:MM")}`

        if((props.values.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (props.values.rapportTypId ===  Rapporttyper.StatistikrapportForsaljningTillverkning)){
          message = `Rapporten för ${
            props.values.objektOchOrgDetaljer?.havareNamn
          } klarmarkerades ${moment().format("yyyy-MM-DD-HH:MM")}`
        }
        props.setStatus({
          sent: true,
          msg: message,
          severity: "success",
        });
      })
      .catch((error) => {
        console.log("error on update: ", error);
        props.setStatus({
          sent: true,
          msg: `Något gick fel, ${error.data}`,
          severity: "error",
        });
      });
  };

  const handleClickApprove = () => {

    let name = props.values.objektOchOrgDetaljer?.objektNamn;

    if((props.values.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (props.values.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)){
      name = props.values.objektOchOrgDetaljer?.havareNamn;
    }
          
    setModal({
      open: true,
      title: "Klarmarkera rapport",
      text: `Är du säker på att du vill klarmarkera rapporten för ${name}? Rapporten kommer därmed tas med i statistiksammanställningar.`,
      type: "Klarmarkera",
    });
  };

  const getFilledPdf = () => {
    if (props.values.rapportId === undefined || props.values.rapportId === null)
      return;
    getRapportPDF(props.values.rapportId).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rapport.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleClickCancel = () => {

    let name = props.values.objektOchOrgDetaljer?.objektNamn;

    if((props.values.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (props.values.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)){
      name = props.values.objektOchOrgDetaljer?.havareNamn;
    }         
    setModal({
      open: true,
      title: "Makulera rapport",
      text: `Är du säker på att du vill makulera rapporten för ${name}?`,
      type: "Makulera",
    });
  };

  const cancelReport = () => {
    const updateReport: IUpdateRestaurantReportStatus = {
      id:
        props.values.rapportId === undefined || props.values.rapportId === null
          ? 0
          : props.values.rapportId,
      status: "Makulerad",
    };
    updateRestaurantReportStatus(updateReport)
      .then((response) => {
        props.setStatus({
          sent: true,
          msg: "Rapporten har makulerats",
          severity: "success",
        });
        setRapportStatusLocal(RapportStatus.Makulerad);
      })
      .catch((error) => {
        props.setStatus({
          sent: true,
          msg: `Något gick fel, ${error.data}`,
          severity: "error",
        });
      });
  };

  const resetReport = () => {
    const updateReport: IUpdateRestaurantReportStatus = {
      id:
        props.values.rapportId === undefined || props.values.rapportId === null
          ? 0
          : props.values.rapportId,
      status: "Skapad",
    };
    resetRestaurantReportStatus(updateReport)
      .then((response) => {
        props.setStatus(
          
          {
          sent: true,
          msg: "Rapporten har återställts",
          severity: "success",
        });
        console.log(response);
        setRapportStatusLocal(RapportStatus.Skapad);
      })
      .catch((error) => {
        console.log(error);
        props.setStatus({
          sent: true,
          msg: `Något gick fel, ${error.data}`,
          severity: "error",
        });
      });
  };

  const handleClickIncorrect = () => {

    let name = props.values.objektOchOrgDetaljer?.objektNamn;

    if((props.values.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (props.values.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)){
      name = props.values.objektOchOrgDetaljer?.havareNamn;
    }
    setModal({
      open: true,
      title: "Markera rapport för granskning",
      text: `Är du säker på att du vill markera rapporten för ${name} för granskning?`,
      type: "Granska",
    });
  };

  const handleClickReset = () => {

    let name = props.values.objektOchOrgDetaljer?.objektNamn;

    if((props.values.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) || (props.values.rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning)){
      name = props.values.objektOchOrgDetaljer?.havareNamn;
    }
    setModal({
      open: true,
      title: "Återställa rapport",
      text: `Är du säker på att du vill återställa rapporten för ${name}? Rapporten kommer därmed återställas till status skapad och inte innehålla några uppgifter.`,
      type: "Återställ",
    });
  };

  const renderLogOutOrBack = () => {
    if (auth.user.roles.findIndex((x) => x === Roles.RapportInlamnare) > -1) {
      return;
    } else {
      return (
        <BtnSecond
          onClick={() => {
            nav(-1);
          }}
        >
          Tillbaka
        </BtnSecond>
      );
    }
  };

  const renderSkicka = () => {
    return (
      <BtnDefault
        disabled={props.isSubmitting || !props.dirty}
        type="button"
        onClick={() => {
          props.validateForm().then((errors) => {
            if (errors !== undefined && Object.keys(errors).length > 0) {
              props.setTouched(setNestedObjectValues(errors, true));
              document.getElementsByName(Object.keys(errors)[0])[0].focus();
            } else {
              setOpen(true);
            }
          });
        }}
      >
        {auth.user.roles.findIndex((x) => x === Roles.RapportInlamnare) > -1
          ? <>Skicka</>
          : <>Spara rapport</>
        }
      </BtnDefault>
    );
  };

  const renderSpara = () => {
    return (
      <BtnDefault
      disabled={props.isSubmitting || !props.dirty}
        onClick={() => {
          props.validateForm().then((errors) => {
            if (errors !== undefined && Object.keys(errors).length > 0) {
              props.setTouched(setNestedObjectValues(errors, true));
              document.getElementsByName(Object.keys(errors)[0])[0].focus();
            } else {
              props.setSubmitting(true)
              GetUpdateRequest(props.values?.rapportTypId, props.values)
                .then((res) => {
                  props.setStatus({
                    sent: true,
                    msg: `Rapporten har sparats`,
                    title: "Uppdaterat",
                    severity: "success",
                  });
                  props.setSubmitting(false)
                })
                .catch((err) => {
                  props.setStatus({
                    sent: true,
                    msg: `${err.data}`,
                    title: "Ett fel har inträffat",
                    severity: "error",
                  });
                  props.setSubmitting(false);
                });
            }
          });
        }}
      >
        Spara rapport
      </BtnDefault>
    );
  };

  const renderSkrivUtKnapp = () => {
    if (
      rapportStatusNamn === RapportStatus.Skapad ||
      rapportStatusNamn === RapportStatus.Inkommen ||
      rapportStatusNamn === RapportStatus.Granskas ||
      rapportStatusNamn === RapportStatus.Klar
    )
      return <BtnDefault onClick={getFilledPdf}>Skriv ut</BtnDefault>;
  };

  const checkIfVal = (val1: any, val2: any) => {
    if (
      val1 !== undefined &&
      val1 !== null &&
      val1 !== "" &&
      val1 > 0 &&
      (val2 === undefined || val2 === null || val2 === "")
    ) {
      return true;
    }

    return false;
  };

  const checkWarnings = (): string[] => {
    const warnings: string[] = [];
    if (
      checkIfVal(props.values.omsSprit, props.values.inkopSpritLag) &&
      checkIfVal(props.values.omsSprit, props.values.inkopSpritHog)
    ) {
      warnings.push(
        "Omsättning för sprit är angivet men inköpt volym av sprit är inte angivet."
      );
    }
    if (
      checkIfVal(props.values.inkopSpritLag, props.values.omsSprit) ||
      checkIfVal(props.values.inkopSpritHog, props.values.omsSprit)
    ) {
      warnings.push(
        "Inköpt volym för spritdrycker är angiven men ingen omsättning av spritdrycker inklusive spritdrinkar."
      );
    }
    if (
      checkIfVal(props.values.omsVin, props.values.inkopVinLag) &&
      checkIfVal(props.values.omsVin, props.values.inkopVinMellan) &&
      checkIfVal(props.values.omsVin, props.values.inkopVinHog)
    ) {
      warnings.push(
        "Omsättning för vin är angivet men inköpt volym av vin är inte angivet."
      );
    }
    if (
      checkIfVal(props.values.inkopVinLag, props.values.omsVin) ||
      checkIfVal(props.values.inkopVinMellan, props.values.omsVin) ||
      checkIfVal(props.values.inkopVinHog, props.values.omsVin)
    ) {
      warnings.push(
        "Inköpt volym, för vin är angiven men ingen omsättning av vin inklusive vindrinkar."
      );
    }
    if (
      checkIfVal(
        props.values.omsAndraJastaAlkoholdrycker,
        props.values.inkopAndraJastaAlkoholdryckerLag
      ) &&
      checkIfVal(
        props.values.omsAndraJastaAlkoholdrycker,
        props.values.inkopAndraJastaAlkoholdryckerMellan
      ) &&
      checkIfVal(
        props.values.omsAndraJastaAlkoholdrycker,
        props.values.inkopAndraJastaAlkoholdryckerHog
      )
    ) {
      warnings.push(
        "Omsättning för andra jästa alkoholdrycker är angivet men inköpt volym av andra jästa alkoholdrycker är inte angivet."
      );
    }
    if (
      checkIfVal(
        props.values.inkopAndraJastaAlkoholdryckerLag,
        props.values.omsAndraJastaAlkoholdrycker
      ) ||
      checkIfVal(
        props.values.inkopAndraJastaAlkoholdryckerMellan,
        props.values.omsAndraJastaAlkoholdrycker
      ) ||
      checkIfVal(
        props.values.inkopAndraJastaAlkoholdryckerHog,
        props.values.omsAndraJastaAlkoholdrycker
      )
    ) {
      warnings.push(
        "Inköpt volym för andra jästa alkoholdrycker är angiven men ingen omsättning av andra jästa alkoholdrycker"
      );
    }
    if (
      checkIfVal(props.values.omsStarkOl, props.values.inkopOlHog) &&
      checkIfVal(props.values.omsStarkOl, props.values.inkopOlLag)
    ) {
      warnings.push(
        "Omsättning för starköl är angivet men inköpt volym av starköl är inte angivet."
      );
    }
    if (
      checkIfVal(props.values.inkopOlHog, props.values.omsStarkOl) ||
      checkIfVal(props.values.inkopOlLag, props.values.omsStarkOl)
    ) {
      warnings.push(
        "Inköpt volym för starköl är angiven men ingen omsättning av starköl"
      );
    }
    if (
      checkIfVal(props.values.omsAlkoPrep, props.values.inkopAlkoPrepLag) &&
      checkIfVal(props.values.omsAlkoPrep, props.values.inkopAlkoPrepHog)
    ) {
      warnings.push(
        "Omsättning för alkoholsdrycksliknande preparat är angivet men inköpt volym av alkoholsdrycksliknande preparat är inte angivet."
      );
    }
    if (
      checkIfVal(props.values.inkopAlkoPrepLag, props.values.omsAlkoPrep) ||
      checkIfVal(props.values.inkopAlkoPrepHog, props.values.omsAlkoPrep)
    ) {
      warnings.push(
        "Inköpt volym alkoholdrycksliknande preparat är angiven men ingen omsättning av alkoholdrycksliknande preparat."
      );
    }

    return warnings;
  };

  const renderWarnings = () => {
    let p = checkWarnings();
    if (p.length === 0) return;

    return (
      <Grid item xs={12}>
        <Typography variant="h2">Varningar!</Typography>
        <List dense>
          {p.map((p: string, i) => {
            return (
              <ListItem key={i} sx={{ display: "list-item" }}>
                <Typography>- {p}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  };

  if (
    mode === Mode.Edit &&
    !renderDescisionBtns &&
    rapportStatusNamn === RapportStatus.Granskas &&
    auth.validateUserRoleAccess([Roles.FohmAdmin, Roles.KommunAdminAlkohol])
  ) {
    return (
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
        {renderSpara()}
        {renderLogOutOrBack()}
        </Stack>
      </Grid>
    );
  }

  if (
    (mode === Mode.Disabled && !renderDescisionBtns) ||
    (rapportStatusNamn === RapportStatus.Klar &&
      auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]))
  ) {
    return (
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          {renderSkrivUtKnapp()}
          {renderLogOutOrBack()}
        </Stack>
      </Grid>
    );
  }
  if (
    renderDescisionBtns &&
    auth.validateUserRoleAccess([Roles.FohmAdmin, Roles.KommunAdminAlkohol])
  ) {
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {auth.validateUserRoleAccess([Roles.FohmAdmin]) &&
            rapportStatusNamn !== RapportStatus.Skapad &&
            rapportStatusNamn !== RapportStatus.Klar &&
            rapportStatusNamn !== RapportStatus.Makulerad && (
              <Grid item>
                <BtnDefault onClick={() => handleClickApprove()}>
                  Klar
                </BtnDefault>
              </Grid>
            )}
          {auth.validateUserRoleAccess([Roles.FohmAdmin]) &&
            rapportStatusNamn !== RapportStatus.Makulerad && (
              <Grid item>
                <BtnDefault onClick={() => handleClickCancel()}>
                  Makulera
                </BtnDefault>
              </Grid>
            )}
          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.KommunAdminAlkohol,
          ]) &&
            rapportStatusNamn !== RapportStatus.Skapad &&
            rapportStatusNamn !== RapportStatus.Granskas &&
            rapportStatusNamn !== RapportStatus.Makulerad && (
              <Grid item>
                <BtnDefault onClick={() => handleClickIncorrect()}>
                  Granska
                </BtnDefault>
              </Grid>
            )}
            {auth.validateUserRoleAccess([
            Roles.FohmAdmin
          ])  &&
          rapportStatusNamn !== RapportStatus.Skapad &&
             (
              <Grid item>
                <BtnDefault onClick={() => handleClickReset()}>
                  Återställ
                </BtnDefault>
              </Grid>
            )}
          {auth.validateUserRoleAccess([
            Roles.FohmAdmin,
            Roles.KommunAdminAlkohol,
          ]) &&
            rapportStatusNamn !== RapportStatus.Skapad &&
            rapportStatusNamn !== RapportStatus.Klar &&
            rapportStatusNamn !== RapportStatus.Makulerad && (
              <Grid item>
                <BtnDefault
                  onClick={() => nav(`/reports/new/${props.values.rapportId}`)}
                >
                  Ändra
                </BtnDefault>
              </Grid>
            )}
          <Grid item>{renderSkrivUtKnapp()}</Grid>
          <Grid item>
            {renderLogOutOrBack()}
          </Grid>
          <CustomModal
            handleCloseModal={() =>
              setModal({ open: false, title: "", text: "", type: "" })
            }
            openModal={modal.open}
            title={modal.title}
          >
            <>
              <Typography variant="body1">{modal.text}</Typography>
              <Stack spacing={1} direction="row" sx={{ mt: "15px" }}>
                <BtnDefault
                  onClick={() => {
                    console.log("modal type: ", modal.type);
                    modal.type === "Klarmarkera" && approveReport();
                    modal.type === "Makulera" && cancelReport();
                    modal.type === "Granska" && granskaReport();
                    modal.type === "Återställ" && resetReport();
                    setModal({
                      open: false,
                      title: "",
                      text: "",
                      type: "",
                    });
                  }}
                >
                  {modal.type}
                </BtnDefault>
                <BtnSecond
                  type="button"
                  onClick={() =>
                    setModal({
                      open: false,
                      title: "",
                      text: "",
                      type: "",
                    })
                  }
                >
                  Avbryt
                </BtnSecond>
              </Stack>
            </>
          </CustomModal>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={6}>
          <CustomModal
            title="Bekräfta"
            handleCloseModal={() => setOpen(false)}
            openModal={open}
          >
            <Grid container>
              {props.values.rapportTypId === Rapporttyper.Restaurangrapport &&
                renderWarnings()}
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <BtnDefault
                    disabled={
                      props.isSubmitting ||
                      (props.errors !== undefined &&
                        Object.keys(props.errors).length > 0)
                    }
                    onClick={() => {
                      console.log(props.errors);
                      props.submitForm();
                      setOpen(false);
                    }}
                  >
                    {auth.user.roles.findIndex((x) => x === Roles.RapportInlamnare) > -1
                      ? <>Skicka</>
                      : <>Spara</>
                    }
                  </BtnDefault>
                  <BtnSecond
                    type="button"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Avbryt
                  </BtnSecond>
                </Stack>
              </Grid>
            </Grid>
          </CustomModal>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {!auth.validateUserRoleAccess([
              Roles.FohmLasAlkohol,
              Roles.FohmLasTobak,
            ]) && (
              <>
                {renderSkicka()}
                {renderSkrivUtKnapp()}
              </>
            )}
            {renderLogOutOrBack()}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}
