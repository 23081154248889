import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
  Popper,
  IconButton,
  Stack,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { Box } from "@mui/system";
import {
  StatisticsAreas,
  StatisticsGrouping,
  AlcoholStatisticsReportTypeOptions,
  exportStatistikServeringstider,
  exportStatistikInkoptaVolymer,
  IStatisticsFilterRapportPeriodId,
  IStatisticsFilter,
  exportStatistikNormalpris,
  exportStatistikOmsattningIKr,
  exportStatistikStadigvarandeBefolkning,
  exportStatistikStadigvarandeAllman,
  exportStatistikStadigvarandeSlutna,
  exportStatistikTillfalligaBefolkning,
  exportStatistikTillfalligaAllman,
  exportStatistikTillfalligaSlutna,
  exportStatistikPartihandelsTillstand,
  exportTekniskSprit,
  exportBortfallsbeskrivning,
  IAlkoholStatistics,
  IStatisticsToFrom,
  IStatisticsReportTypeOptions,
  exportStatistikForsaljningImportExportTillvAvAlkoholdrycker
} from "../../Services/StatisticsService";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { DatePickerCustom } from "../../Components/datePicker";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { InfoType, SelectSingle } from "../../Components/selects";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { StatisticsSchema } from "./statisticsValidation";
import { getRapportPeriod, IInfo } from "../../Services/infoService";
import { useAuth } from "../../Utilities/authProvider";
import { Roles } from "../../Utilities/roles";

export default function AlcoholStatistics() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popperOpen, setPopperOpen] = React.useState<null | number>(null);
  const [loading, setLoading] = React.useState(true);
  const [perioder, setPerioder] = React.useState<IInfo[]>(null!);
  const [alchoholStatisticsOptions, setAlchoholStatisticsOptions] = React.useState<IStatisticsReportTypeOptions[]>(AlcoholStatisticsReportTypeOptions);
  const auth = useAuth();

  useEffect(() => {
    if (perioder !== null && perioder !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [perioder]);

  useEffect(() => {
    getRapportPeriod().then((res) => {
      setPerioder([...res]);
    });

    if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && auth.validateUserRoleAccess([Roles.KommunAdminTobak])) {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions.filter((option) => [8, 11, 12, 13, 14, 15,16, 17].indexOf(option.key) === -1));
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol])) {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions.filter((option) => [8, 11, 12, 13, 14, 15,16, 17].indexOf(option.key) === -1));
    } else if (auth.validateUserRoleAccess([Roles.KommunLasAlkohol]) && auth.validateUserRoleAccess([Roles.KommunLasTobak])) {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions.filter((option) => [8, 11, 12, 13, 14, 15, 16, 17].indexOf(option.key) === -1));
    } else if (auth.validateUserRoleAccess([Roles.KommunLasAlkohol])) {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions.filter((option) => [8, 11, 12, 13, 14, 15, 16, 17].indexOf(option.key) === -1));
    } else if (auth.validateUserRoleAccess([Roles.LansstyrelseLasAlkohol])) {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions.filter((option) => [8, 11, 12, 13, 14, 15, 16, 17].indexOf(option.key) === -1));
    } else {
      setAlchoholStatisticsOptions(AlcoholStatisticsReportTypeOptions);
    }
  }, []);

  const defaultVals: IAlkoholStatistics = {
    reportType: 1,
    datum: "",
    fromDatum: "",
    tomDatum: "",
    area: 1,
    area2: 2,
    lanId: [],
    kommunId: [],
    grouping: 1,
    grouping2: 2,
    displayOptions: 1,
    reportPeriodId: perioder ? perioder[1].id : 15,
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (index === popperOpen) {
      setPopperOpen(null);
      setAnchorEl(null);
    } else {
      setPopperOpen(index);
      setAnchorEl(event.currentTarget);
    }
  };

  let filter: IStatisticsFilter = {
    datum: "",
    gruppering: "",
    lanIdn: [],
    kommunIdn: [],
    rapportPeriodId: null
  };

  let filterRapportPeriodId: IStatisticsFilterRapportPeriodId = {
    gruppering: "",
    lanIdn: [],
    kommunIdn: [],
    rapportPeriodId: 0,
  };
  let filterToFromDatum: IStatisticsToFrom = {
    fromDatum: "",
    tomDatum: "",
    gruppering: "",
    lanIdn: [],
    kommunIdn: [],
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
        Statistiksammanställningar alkohol
        </Typography>
      </Grid>
      {!loading && (
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validationSchema={StatisticsSchema}
            initialValues={defaultVals}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              filter = {
                datum: values.datum,
                gruppering: values.grouping === 1 ? "Kommun" : "Lan",
                lanIdn: values.area === 2 ? values.lanId : [],
                kommunIdn: values.area === 3 ? values.kommunId : [],
                rapportPeriodId: values.reportPeriodId
              };
              filterToFromDatum = {
                fromDatum: values.fromDatum,
                tomDatum: values.tomDatum,
                gruppering: values.grouping === 1 ? "Kommun" : "Lan",
                lanIdn: values.area === 2 ? values.lanId : [],
                kommunIdn: values.area === 3 ? values.kommunId : [],
              };
              filterRapportPeriodId = {
                gruppering: values.grouping === 1 ? "Kommun" : "Lan",
                lanIdn: values.area === 2 ? values.lanId : [],
                kommunIdn: values.area === 3 ? values.kommunId : [],
                rapportPeriodId: values.reportPeriodId,
              }          
              switch (values.reportType) {
                case 1:
                  return exportStatistikStadigvarandeBefolkning(filter).then(
                    (res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Stadigvarande-befolkning.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    }
                  );
                case 2:
                  return exportStatistikStadigvarandeAllman(filter).then(
                    (res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Stadigvarande-allmanheten.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    }
                  );
                case 3:
                  return exportStatistikStadigvarandeSlutna(filter).then(
                    (res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Stadigvarande-slutet-sallskap.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    }
                  );
                case 4:
                  return exportStatistikTillfalligaBefolkning(
                    filterToFromDatum
                  ).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tillfalliga-befolkning.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                case 5:
                  return exportStatistikTillfalligaAllman(
                    filterToFromDatum
                  ).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tillfalliga-allmanheten.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                case 6:
                  return exportStatistikTillfalligaSlutna(
                    filterToFromDatum
                  ).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Tillfalliga-slutna-sallskap.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                case 7:
                  return exportStatistikServeringstider(filter).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Serveringstider.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                case 9:
                    return exportStatistikInkoptaVolymer(filter).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Inkopta-volymer-i-liter-per-invanare.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    });
                case 10:
                      return exportStatistikOmsattningIKr(filterRapportPeriodId).then((res) => {
                        const url = window.URL.createObjectURL(
                          new Blob([res.object], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "Omsattning-i-kronor.xlsx"
                        );
                        document.body.appendChild(link);
                        link.click();
                        setSubmitting(false);
                      });
                case 11:
                  return exportStatistikNormalpris(filter).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Normalpris.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                  case 14:
                    return exportStatistikForsaljningImportExportTillvAvAlkoholdrycker(filterRapportPeriodId).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Försäljning-import-export-tillverkning.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    });
                case 15:
                  return exportStatistikPartihandelsTillstand(
                    values.datum
                  ).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.object], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      "Partihandels-tillstand.xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSubmitting(false);
                  });
                  case 16:
                    return exportTekniskSprit(filter).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Teknisk-sprit.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    });
                  case 17:
                    return exportBortfallsbeskrivning(filter).then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.object], {
                          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "Bortfallsbeskrivning-restaurangrapport.xlsx"
                      );
                      document.body.appendChild(link);
                      link.click();
                      setSubmitting(false);
                    });
              }
            }}
          >
            {({
              values,
              handleChange,
              isSubmitting,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" rowGap={2}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="alcohol-statistics-reporttype-label"
                      defaultValue={1}
                      name="alcohol-statistics-report-type-radio-group"
                      onChange={(e) => {
                        setFieldValue("reportType", +e.target.value)
                        setFieldValue("area", 1);
                      }
                        
                        
                        
                      }
                    >
                      {alchoholStatisticsOptions.sort((a, b) => (a.key > b.key ? 1 : -1)).map((data, index) => (
                        
                        <div key={index}>
                          <div className="checkbox-container">
                            <FormControlLabel
                              value={data.key}
                              control={<Radio />}
                              label={data.label}
                              key={index}
                              checked={data.key === values.reportType}
                            />
                            <IconButton
                              aria-label={`Visa mer information om rapporttyp ${data.label}`}
                              aria-describedby="statistics-type-popper"
                              onClick={(e) => handleClick(e, index)}
                              aria-expanded={popperOpen === index}
                            >
                              <HelpIcon color="primary" />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                      <Popper
                        id="statistics-type-popper"
                        open={popperOpen !== null}
                        anchorEl={anchorEl}      
                      >
                        <Box
                          sx={{
                            width: "400px",
                            border: 1,
                            p: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                          }}
                        >
                          {popperOpen !== null && (
                            <Typography sx={{ mt: "0px" }}>
                              {
                                AlcoholStatisticsReportTypeOptions[popperOpen]
                                  .tooltip
                              }
                            </Typography>
                          )}
                          <CloseIcon
                            aria-label="Stäng info"
                            onClick={() => (
                              setPopperOpen(null), setAnchorEl(null)
                            )}
                          />
                        </Box>
                      </Popper>
                    </RadioGroup>
                  </FormControl>
                  {(values.reportType === 4 ||
                    values.reportType === 5 ||
                    values.reportType === 6) && (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ width: "50%", mt: 3 }}
                    >
                      <DatePickerCustom
                        label="Från datum"
                        value={values.fromDatum}
                        onChange={(e) => setFieldValue("fromDatum", e)}
                        name="fromDatum"
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "fromDatum")}
                        helperText={getErrorText(errors, touched, "fromDatum")}
                        required={true}
                      />
                      <DatePickerCustom
                        label="Till datum"
                        value={values.tomDatum}
                        onChange={(e) => setFieldValue("tomDatum", e)}
                        name="tomDatum"
                        onBlur={handleBlur}
                        error={evalErrors(errors, touched, "tomDatum")}
                        helperText={getErrorText(errors, touched, "tomDatum")}
                        required={true}
                      />
                    </Stack>
                  )}
                  {values.reportType !== 4 &&
                    values.reportType !== 5 &&
                    values.reportType !== 6 &&
                    !(values.reportType >= 8 && 
                      values.reportType <= 14) && 
                      values.reportType !== 16 &&
                      values.reportType !== 17 && (
                      <Box sx={{ mt: 3, width: 300 }}>
                        <DatePickerCustom
                          label="Datum"
                          value={values.datum ?? null}
                          onChange={(e) => setFieldValue("datum", e)}
                          name="datum"
                          onBlur={handleBlur}
                          error={evalErrors(errors, touched, "datum")}
                          helperText={getErrorText(errors, touched, "datum")}
                          required={true}
                        />
                      </Box>
                    )}
                  {((values.reportType >= 8 && values.reportType <= 14) 
                  || values.reportType === 16 
                  || values.reportType === 17) && (
                    <Box sx={{ mt: 3, width: 300 }}>
                      <SelectSingle
                        label="Rapportperiod"
                        fullWidth
                        data={perioder.map((p) => {
                          return { key: p.id, label: p.namn };
                        })}
                        defaultValue={true}
                        selected={values.reportPeriodId}
                        onChange={handleChange}
                        name="reportPeriodId"
                      />
                    </Box>
                  )}
                  <Stack direction="row" spacing={5}>
                    {values.reportType < 13 && (
                      <FormControl>
                        <FormLabel id="alcohol-statistics-area-label">
                          Region
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="alcohol-statistics-area-label"
                          name="alcohol-statistics-area-radio-group"
                          defaultValue={values.reportType === 11 ? 2 : 1}
                          onChange={(e) =>
                            setFieldValue("area", +e.target.value)
                          }
                        >
                          {StatisticsAreas.map((data, index) => (
                            <FormControlLabel
                              value={data.key}
                              control={<Radio />}
                              label={data.label}
                              key={index}
                              disabled={
                                (values.reportType === 11 ||
                                  values.reportType === 12) &&
                                (  data.key === 3)
                              }
                              checked={data.key === values.area }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {values.area === 2 && (
                      <Box sx={{ width: 300, pt: "28px" }}>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={InfoType.lan}
                          label="Län"
                          multiple={true}
                          defaultVal={false}
                          onChange={(data) => setFieldValue("lanId", data)}
                          error={evalErrors(errors, touched, "lanId")}
                          helperText={getErrorText(errors, touched, "lanId")}
                          selectedData={values.lanId}
                          name="lanId"
                        />
                      </Box>
                    )}
                    {values.area === 3 && (
                      <Box sx={{ width: 300, pt: "28px" }}>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={InfoType.kommuner}
                          label="Kommuner"
                          multiple={true}
                          defaultVal={false}
                          onChange={(data) => setFieldValue("kommunId", data)}
                          error={evalErrors(errors, touched, "kommunId")}
                          helperText={getErrorText(errors, touched, "kommunId")}
                          selectedData={values.kommunId}
                          name="kommunId"
                        />
                      </Box>
                    )}
                    {values.area !== 3 && values.reportType < 13 && (
                      <FormControl>
                        <FormLabel id="alcohol-statistics-grouping-label">
                          Gruppering
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="alcohol-statistics-grouping-label"
                          name="alcohol-statistics-grouping-radio-group"
                          defaultValue={1}
                          onChange={(e) =>
                            setFieldValue("grouping", +e.target.value)
                          }
                        >
                          {StatisticsGrouping.map((data, index) => (
                            <FormControlLabel
                              value={data.key}
                              control={<Radio />}
                              label={data.label}
                              key={index}
                              disabled={
                                (values.reportType === 11 ||
                                  values.reportType === 12) &&
                                data.key === 1
                              }
                              checked={values.reportType === 11 ? data.key === values.grouping2 : data.key === values.grouping }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Stack>
                  <Grid container spacing={2} sx={{ mb: "18px" }}>
                    <Grid item>
                      <BtnDefault disabled={isSubmitting} type="submit">
                        Ladda ner som Excel
                      </BtnDefault>
                    </Grid>
                    <Grid item>
                      <BtnSecond
                        disabled={isSubmitting}
                        onClick={() => resetForm()}
                      >
                        Rensa
                      </BtnSecond>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}
