import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";

export const isNullOrUndefinedReturnZero = (
  val: number | null | undefined | string
): number => {
  if (val === null || val === undefined || val === "") {
    return 0;
  }
  if (typeof val === "string") {
    return 0;
  }
  return val;
};

export const sumValues = (values: (number | undefined | null)[]): number => {
  return values
    .map((p) => {
      return isNullOrUndefinedReturnZero(p);
    })
    .reduce((a, b) => a + b);
};

export const CalcSum = (

  props: FormikProps<IUpdateRapportDetails>,
  st: keyof IUpdateRapportDetails,
  values: (number | undefined | null)[]
) => {
  props.setFieldValue(st, sumValues(values));
};

export default function ForsaljningSverige(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.spritSaljSystembolagetLag,
    props.values.spritSaljSystembolagetHog,
    props.values.spritSaljPartihandlareLag,
    props.values.spritSaljPartihandlareHog,
    props.values.spritSaljInnehavareLag,
    props.values.spritSaljInnehavareHog,
    props.values.vinSaljSystembolagetLag,
    props.values.vinSaljSystembolagetMellan,
    props.values.vinSaljSystembolagetHog,
    props.values.vinSaljPartihandlareLag,
    props.values.vinSaljPartihandlareMellan,
    props.values.vinSaljPartihandlareHog,
    props.values.vinSaljInnehavareLag,
    props.values.vinSaljInnehavareMellan,
    props.values.vinSaljInnehavareHog,
    props.values.andraJastaSaljSystembolagetLag,
    props.values.andraJastaSaljSystembolagetMellan,
    props.values.andraJastaSaljSystembolagetHog,
    props.values.andraJastaSaljPartihandlareLag,
    props.values.andraJastaSaljPartihandlareMellan,
    props.values.andraJastaSaljPartihandlareHog,
    props.values.andraJastaSaljInnehavareLag,
    props.values.andraJastaSaljInnehavareMellan,
    props.values.andraJastaSaljInnehavareHog,
    props.values.starkOlSaljSystembolagetLag,
    props.values.starkOlSaljSystembolagetHog,
    props.values.starkOlSaljPartihandlareLag,
    props.values.starkOlSaljPartihandlareHog,
    props.values.starkOlSaljInnehavareLag,
    props.values.starkOlSaljInnehavareHog,
    props.values.alkoPrepSaljSystembolaget,
    props.values.alkoPrepSaljPartihandlare,
    props.values.alkoPrepSaljInnehavare,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);
  

  const sumAllValues = (): void => {
    CalcSum(props, "saljTotalt", values);
  };

  return (
    <Grid key={nr} item xs={12}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Försäljning inom Sverige (hela liter)
          </Typography>
          <Typography variant="caption">
            Försäljning ska delas upp på Systembolaget, andra partihandlare och innehavare av serveringstillstånd (se även anvisningen längst upp på sidan).
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Section head3="Spritdrycker">
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till Systembolaget  AB</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritSaljSystembolagetLag",
                  props.values.spritSaljSystembolagetLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljSystembolagetHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritSaljSystembolagetHog",
                  props.values.spritSaljSystembolagetHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljSystembolagetLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritSaljSystembolagetLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritSaljSystembolagetHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till andra partihandlare</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritSaljPartihandlareLag",
                  props.values.spritSaljPartihandlareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljPartihandlareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritSaljPartihandlareHog",
                  props.values.spritSaljPartihandlareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljPartihandlareLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritSaljPartihandlareLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritSaljPartihandlareHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till innehavare av serveringstillstånd</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritSaljInnehavareLag",
                  props.values.spritSaljInnehavareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljInnehavareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritSaljInnehavareHog",
                  props.values.spritSaljInnehavareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritSaljInnehavareLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritSaljInnehavareLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritSaljInnehavareHog
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section head3="Vin">
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till Systembolaget AB</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinSaljSystembolagetLag",
                  props.values.vinSaljSystembolagetLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljSystembolagetMellan", "vinSaljSystembolagetHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinSaljSystembolagetMellan",
                  props.values.vinSaljSystembolagetMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljSystembolagetLag", "vinSaljSystembolagetHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinSaljSystembolagetHog",
                  props.values.vinSaljSystembolagetHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljSystembolagetMellan", "vinSaljSystembolagetLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinSaljSystembolagetLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinSaljSystembolagetMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinSaljSystembolagetHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till andra partihandlare</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinSaljPartihandlareLag",
                  props.values.vinSaljPartihandlareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljPartihandlareMellan", "vinSaljPartihandlareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinSaljPartihandlareMellan",
                  props.values.vinSaljPartihandlareMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljPartihandlareHog", "vinSaljPartihandlareLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinSaljPartihandlareHog",
                  props.values.vinSaljPartihandlareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljPartihandlareLag", "vinSaljPartihandlareMellan"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinSaljPartihandlareLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinSaljPartihandlareMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinSaljPartihandlareHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till innehavare av serveringstillstånd</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinSaljInnehavareLag",
                  props.values.vinSaljInnehavareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljInnehavareMellan", "vinSaljInnehavareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinSaljInnehavareMellan",
                  props.values.vinSaljInnehavareMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljInnehavareHog", "vinSaljInnehavareLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinSaljInnehavareHog",
                  props.values.vinSaljInnehavareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinSaljInnehavareMellan", "vinSaljInnehavareLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinSaljInnehavareLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinSaljInnehavareMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinSaljInnehavareHog
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section head3="Andra jästa alkoholdrycker ">
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till Systembolaget AB</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaSaljSystembolagetLag",
                  props.values.andraJastaSaljSystembolagetLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljSystembolagetMellan",
                    "andraJastaSaljSystembolagetHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaSaljSystembolagetMellan",
                  props.values.andraJastaSaljSystembolagetMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljSystembolagetLag",
                    "andraJastaSaljSystembolagetHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaSaljSystembolagetHog",
                  props.values.andraJastaSaljSystembolagetHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljSystembolagetMellan",
                    "andraJastaSaljSystembolagetLag",
                  ]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaSaljSystembolagetLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaSaljSystembolagetMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaSaljSystembolagetHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till andra partihandlare</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaSaljPartihandlareLag",
                  props.values.andraJastaSaljPartihandlareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljPartihandlareMellan",
                    "andraJastaSaljPartihandlareHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaSaljPartihandlareMellan",
                  props.values.andraJastaSaljPartihandlareMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljPartihandlareLag",
                    "andraJastaSaljPartihandlareHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaSaljPartihandlareHog",
                  props.values.andraJastaSaljPartihandlareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljPartihandlareLag",
                    "andraJastaSaljPartihandlareMellan",
                  ]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaSaljPartihandlareLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaSaljPartihandlareMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaSaljPartihandlareHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till innehavare av serveringstillstånd</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaSaljInnehavareLag",
                  props.values.andraJastaSaljInnehavareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljInnehavareMellan",
                    "andraJastaSaljInnehavareHog",
                  ]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaSaljInnehavareMellan",
                  props.values.andraJastaSaljInnehavareMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaSaljInnehavareHog", "andraJastaSaljInnehavareLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaSaljInnehavareHog",
                  props.values.andraJastaSaljInnehavareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  [
                    "andraJastaSaljInnehavareMellan",
                    "andraJastaSaljInnehavareLag",
                  ]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaSaljInnehavareLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaSaljInnehavareMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaSaljInnehavareHog
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section head3="Starköl">
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till Systembolaget AB</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlSaljSystembolagetLag",
                  props.values.starkOlSaljSystembolagetLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljSystembolagetHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlSaljSystembolagetHog",
                  props.values.starkOlSaljSystembolagetHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljSystembolagetLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlSaljSystembolagetLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlSaljSystembolagetHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till andra partihandlare</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlSaljPartihandlareLag",
                  props.values.starkOlSaljPartihandlareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljPartihandlareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlSaljPartihandlareHog",
                  props.values.starkOlSaljPartihandlareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljPartihandlareLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlSaljPartihandlareLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlSaljPartihandlareHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till innehavare av serveringstillstånd</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlSaljInnehavareLag",
                  props.values.starkOlSaljInnehavareLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljInnehavareHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlSaljInnehavareHog",
                  props.values.starkOlSaljInnehavareHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlSaljInnehavareLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlSaljInnehavareLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlSaljInnehavareHog
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section head3="Alkoholdrycksliknande preparat">
              <Grid item xs={12}>
                <Typography variant="caption">
                  Gäller endast skattepliktiga varor enligt 9 eller 12§ lagen
                  (1994:1564) om alkoholskatt
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till Systembolaget AB</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisning",
                  "alkoPrepSaljSystembolaget",
                  props.values.alkoPrepSaljSystembolaget,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "",
                      prevYearObj?.alkoPrepSaljSystembolaget
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till andra partihandlare</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisning",
                  "alkoPrepSaljPartihandlare",
                  props.values.alkoPrepSaljPartihandlare,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "",
                      prevYearObj?.alkoPrepSaljPartihandlare
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item>
                <Typography variant="h6" component="h4">Försäljning till innehavare av serveringstillstånd</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Se anvisning",
                  "alkoPrepSaljInnehavare",
                  props.values.alkoPrepSaljInnehavare,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "",
                      prevYearObj?.alkoPrepSaljInnehavare
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            <Section>
              <Grid item xs={12}>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Summa försäljning inom Sverige</Typography>
              </Grid>
                <Typography variant="caption">
                  Ange 0 i summa om du inte har någonting att redovisa.
                </Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Summa",
                  "saljTotalt",
                  props.values.saljTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Summa angiven försåld volym. Ange 0 i summa om du inte har något att redovisa.",
                      prevYearObj?.saljTotalt
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
