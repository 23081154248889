import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Action } from "history";
import React, { useEffect } from "react";
import { BtnDefault, BtnSecond } from "../Components/buttons";

interface IModalProvider {
  showDialog: (options: IDialogOptions) => void;
}

export enum ActionType {
  default = "default",
  second = "second",
}

interface IActions {
  btn: ActionType;
  text: string;
  action?: () => any;
}

interface IDialogOptions {
  title?: string;
  open: boolean;
  message?: string;
  actions?: IActions[];
}

export let ModalContext = React.createContext<IModalProvider>(null!);

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [dialogOptions, setDialogOptions] = React.useState<IDialogOptions>({
    open: false,
    title: "",
    message: "",
    actions: [],
  });

  const handleClose = () => {
    setDialogOptions({ ...dialogOptions, open: false });
  };

  const showDialog = (options: IDialogOptions) => {
    setDialogOptions({ ...options });
  };

  const value = { showDialog };

  const handleDefaultClick = (action: IActions) => {
    if(action.action !== undefined){
      action.action();
    }
    handleClose();
  };

  const renderDialogActions = () => {
    if (dialogOptions.actions === null || dialogOptions.actions?.length === 0)
      return null;

    const checkBtn = (action: IActions, index: number) => {
      switch (action.btn) {
        case ActionType.default: {
          return (
            <BtnDefault
              key={index}
              onClick={() =>
                action.action === undefined ? handleClose() : handleDefaultClick(action)
              }
            >
              {action.text}
            </BtnDefault>
          );
        }
        case ActionType.second: {
          return (
            <BtnSecond
              key={index}
              onClick={() =>
                action.action === undefined ? handleClose() : action.action()
              }
            >
              {action.text}
            </BtnSecond>
          );
        }
      }
    };

    return (
      <DialogActions>
        {dialogOptions.actions?.map((a, index) => {
          return checkBtn(a, index);
        })}
      </DialogActions>
    );
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Dialog onClose={handleClose} open={dialogOptions.open}>
        <DialogTitle>{dialogOptions.title}</DialogTitle>
        <DialogContent>
          <Typography>{dialogOptions.message}</Typography>
        </DialogContent>
        {renderDialogActions()}
      </Dialog>
    </ModalContext.Provider>
  );
}

export function useModal() {
  return React.useContext(ModalContext);
}
