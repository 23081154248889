import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import { IForm } from "../../Views/Permit/new";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { GetCheckBox } from "../../Components/checkbox";
import { Input } from "../../Components/inputs";
import { RadioCustom } from "../../Components/radioCustom";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import {
  approveuserapplication,
  getAnsokan,
  IApplyGet,
  rejectuserapplication,
  updateuserapplication,
} from "../../Services/AppliesService";
import { useAuth } from "../../Utilities/authProvider";
import {
  errorsExists,
  evalErrors,
  getErrorText,
} from "../../Utilities/formikErrorEval";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import {
  Cat,
  GetCorrectRolesBasedOnKey,
  MapToCorrectCat,
  MapToCorrectKey,
  RolesMapReadAble,
  ShowDropdown
} from "../../Utilities/roles";
import { newAppliedSchema } from "./validationApplied";

export default function Applied() 
  {
  //props: FormikProps<IForm>;
  const navigate = useNavigate();
  const modal = useModal();
  let { id } = useParams();
  const [ansokan, setAnsokan] = React.useState<IApplyGet>(null!);
  const [loading, setLoading] = React.useState(true);

 


  useEffect(() => {
    if (id === null || id === undefined) {
      throw Error("No id provided to route / view");
    } else {
      getAnsokan(parseInt(id)).then((res) => {
        setAnsokan({ ...res.object });
      });
    }
  }, []);

  useEffect(() => {
    if (ansokan === null || ansokan === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ansokan]);

  const avslaAnsokan = (
    setStatus: (status?: any) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    rejectuserapplication(ansokan.id)
      .then((res) => {
        setStatus({
          sent: true,
          msg: "Användaransökan har avslagits och tagits bort",
          title: "Avslag",
          severity: "success",
          hideForm: true,
        });
        setSubmitting(false);
        modal.showDialog({open:false});
      })
      .catch((error) => {
        setStatus({
          sent: true,
          msg: `Något gick fel vid avlsag. Kunde ej ta bort ansökan ${error.data}`,
          title: "Ett fel uppstod",
          severity: "error",
        });
        setSubmitting(false);
        modal.showDialog({open:false});
      });
  };

  const godkannAnsokan = (
    setStatus: (status?: any) => void,
    values: IApplyGet,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    //Vi uppdaterar ansökan först innan vi godkänner den ifall en användare har gjort förändringar som inte redan sparats.
    updateuserapplication(
      { 
        created: values.created,
        hanteringStartad: values.hanteringStartad,
        adress: values.adress,
        efternamn: values.efternamn,
        epost: values.epost,
        foretagsnamn: values.foretagsnamn,
        fornamn: values.fornamn,
        kommunIdn: values.kommunIdn,
        orgNr: values.orgNr,
        postOrt: values.postOrt,
        postNr: values.postNr,
        roller: values.roller,
        syfte: values.syfte,
        telefon: values.telefon,
        kreditupplysningsForetag:
          values.kreditupplysningsForetag === null
            ? false
            : values.kreditupplysningsForetag,
        samtligaAktuellaServTillstand: true,
        samtligaServtillstandAvslutadeForeVisstDatum: true,
        lanId: values.lanId,
        systemId: values.systemId,
        system: values.system
      },
      ansokan.id
    )
      .then((res) => {
        approveuserapplication(ansokan.id, values.anvandarnamnForslag)
          .then((res) => {
            setStatus({
              sent: true,
              msg: `Ett e-postmeddelande skickades till ${res.object.fornamn} ${res.object.efternamn} (${res.object.epost})`,
              title: "Godkänd ansökan",
              severity: "success",
              hideForm: true,
            });
            setSubmitting(false);
            modal.showDialog({ open: false });
          })
          .catch((error: any) => {
            setStatus({
              sent: true,
              msg: `${error.data}`,
              title: "Ett fel uppstod",
              severity: "error",
            });
            modal.showDialog({ open: false });
            setSubmitting(false);
          });
      })
      .catch((error: any) => {
        setStatus({
          sent: true,
          msg: `Något gick fel vid godkän ansökan: ${error.data}`,
          title: "Ett fel uppstod",
          severity: "error",
        });
        modal.showDialog({ open: false });
        setSubmitting(false);
      });
  };



  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">Ansökan</Typography>
      </Grid>
      {!loading ? (
        <React.Fragment>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} md={10} xl={6}>
                <Formik
                  initialValues={{
                    ...ansokan,
                    epost2: ansokan.epost,
                  }}
                  validationSchema={newAppliedSchema}
                  onSubmit={(
                    values,
                    { setSubmitting, setStatus, setValues }
                  ) => {
                    setSubmitting(true);
                    updateuserapplication(
                      {  
                        created: values.created,
                        adress: values.adress,
                        efternamn: values.efternamn,
                        epost: values.epost,
                        foretagsnamn: values.foretagsnamn,
                        fornamn: values.fornamn,
                        kommunIdn: values.kommunIdn,
                        orgNr: values.orgNr,
                        postOrt: values.postOrt,
                        postNr: values.postNr,
                        roller: values.roller,
                        system: values.system,
                        syfte: values.syfte,
                        telefon: values.telefon,
                        kreditupplysningsForetag:
                          values.kreditupplysningsForetag === null
                            ? false
                            : values.kreditupplysningsForetag,
                        samtligaAktuellaServTillstand: true,
                        samtligaServtillstandAvslutadeForeVisstDatum: true,
                        lanId: values.lanId,
                        systemId: values.systemId,
                        hanteringStartad: values.hanteringStartad,
                      },
                      ansokan.id
                    )
                      .then((res) => {
                        setValues({
                          ...res.object,
                          epost2: res.object.epost,
                          anvandarnamnForslag: values.anvandarnamnForslag,
                        });
                        setAnsokan({ ...res.object });
                        setStatus({
                          sent: true,
                          msg: `Användaransökan har uppdaterats`,
                          title: "Uppdaterad",
                          severity: "success",
                        });
                        setSubmitting(false);
                      })
                      .catch((error) => {
                        setStatus({
                          sent: false,
                          msg: `Något gick fel vid uppdatera ansökan: ${error.data}`,
                          title: "Ett fel uppstod",
                          severity: "error",
                        });
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setStatus,
                    setSubmitting,
                    status,
                    isSubmitting,
                  }) => (
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        {!status?.hideForm && (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Grid container spacing={2} direction="row">


                              <Grid item xs={12}>
                              <Grid container spacing={2} direction="row">
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="Created"
                                    value={values.created}
                                    aria-required="true"
                                    label="Inkom datum"
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Typography variant="body1" component="p">
                                  Hanteringen påbörjad
                                </Typography>
                                  <RadioCustom
                                    showLabel={false}
                                    defaultValue="Nej"
                                    label="Ansökan påbörjad"
                                    name="hanteringStartad"
                                    onChange={(e) =>
                                      setFieldValue( "hanteringStartad", e === "Ja" ? true : false)                   
                                    }
                                    radios={[
                                      { label: "Ja", value: "Ja" },
                                      { label: "Nej", value: "Nej" },
                                    ]}
                                    row={true}
                                    value=  {values.hanteringStartad === true
                                      ? "Ja"
                                      : "Nej" }                                                      
                                  />
                               </Grid>
                              </Grid>
                            </Grid>


                                <Grid item xs={12} md={6}>
                                <FormControl
                          fullWidth
                          error={evalErrors(errors, touched, "roller")}
                        >

                          <InputLabel id="roller-label">Roll</InputLabel>
                          <SelectSingleWihtoutData
                            label="Roll"
                            labelId="roller-label"
                            infoType={InfoType.rollerGrouped}
                            selected={MapToCorrectKey(values.roller)}
                            onChange={(evt) => {
                              setFieldValue(
                                "roller",
                                GetCorrectRolesBasedOnKey(evt.target.value)
                              );
                            }}
                            name="roller"
                            required
                            rolesToFilter={values.roller}
                            aria-required="true"
                            onBlur={handleBlur}
                            defaultVal={false}
                          />
                          <FormHelperText>
                            {getErrorText(errors, touched, "roller")}
                          </FormHelperText>
                        </FormControl>
                                  
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="anvandarnamnForslag"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.anvandarnamnForslag}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "anvandarnamnForslag"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "anvandarnamnForslag"
                                    )}
                                    required
                                    aria-required="true"
                                    label="Föreslaget användarnamn"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2} direction="row">
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="fornamn"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fornamn}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "fornamn"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "fornamn"
                                    )}
                                    required
                                    aria-required="true"
                                    label="Förnamn"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="efternamn"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.efternamn}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "efternamn"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "efternamn"
                                    )}
                                    required
                                    aria-required="true"
                                    label="Efternamn"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {(MapToCorrectCat(values.roller) === Cat.kommun ||
                              MapToCorrectCat(values.roller) === Cat.lan) && (
                              <Grid item xs={12}>
                                <Grid container direction="row" spacing={2}>
                                  {MapToCorrectCat(values.roller) ===
                                    Cat.kommun && (
                                    <Grid item xs={12} md={6}>
                                      <FormControl fullWidth>
                                        <AutoCompleteAtr
                                          onBlur={handleBlur}
                                          infoType={InfoType.kommuner}
                                          label="Kommuner"
                                          multiple={true}
                                          defaultVal={false}
                                          onChange={(data) =>
                                            setFieldValue("kommunIdn", data)
                                          }
                                          error={evalErrors(
                                            errors,
                                            touched,
                                            "kommunIdn"
                                          )}
                                          helperText={getErrorText(
                                            errors,
                                            touched,
                                            "kommunIdn"
                                          )}
                                          selectedData={values.kommunIdn}
                                          name="kommunIdn"
                                        />
                                      </FormControl>
                                    </Grid>
                                  )}
                                  {MapToCorrectCat(values.roller) ===
                                    Cat.lan && (
                                    <Grid item xs={12} md={6}>
                                      <FormControl fullWidth>
                                        <AutoCompleteAtr
                                          onBlur={handleBlur}
                                          infoType={InfoType.lan}
                                          label="Län"
                                          multiple={false}
                                          defaultVal={false}
                                          onChange={(data) =>
                                            setFieldValue("lanId", data)
                                          }
                                          error={evalErrors(
                                            errors,
                                            touched,
                                            "lanId"
                                          )}
                                          helperText={getErrorText(
                                            errors,
                                            touched,
                                            "lanId"
                                          )}
                                          selectedData={values.lanId}
                                          name="lanId"
                                        />
                                      </FormControl>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                         {ShowDropdown(values.roller) && (
                           <FormControl
                              fullWidth
                              error={evalErrors(errors, touched, "system")}
                            >
                              <AutoCompleteAtr
                                      defaultVal={false}
                                      infoType={InfoType.system}
                                      multiple={false}
                                      label="System"
                                      name="system"
                                      required
                                      onChange={(data) => {
                                        setFieldValue(
                                          "systemId", data                                         
                                        );                                      
                                      }}
                                      onBlur={handleBlur}
                                      selectedData={values.systemId}
                                      error={evalErrors(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                      helperText={getErrorText(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                    />
                              <FormHelperText>
                                {getErrorText(errors, touched, "system")}
                              </FormHelperText>
                            </FormControl>)}
                          </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="epost"
                                    label="E-postadress"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.epost}
                                    error={evalErrors(errors, touched, "epost")}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "epost"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Input
                                    fullWidth
                                    name="epost2"
                                    label="Bekräfta e-postadress"
                                    onChange={handleChange}
                                    value={values.epost2}
                                    onBlur={handleBlur}
                                    error={evalErrors(
                                      errors,
                                      touched,
                                      "epost2"
                                    )}
                                    helperText={getErrorText(
                                      errors,
                                      touched,
                                      "epost2"
                                    )}
                                    required
                                    aria-required="true"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {MapToCorrectCat(values.roller) === Cat.ext && (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="orgNr"
                                        label="Organisationsnummer"
                                        onChange={handleChange}
                                        value={values.orgNr}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "orgNr"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "orgNr"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="foretagsnamn"
                                        label="Företag"
                                        onChange={handleChange}
                                        value={values.foretagsnamn}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "foretagsnamn"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "foretagsnamn"
                                        )}
                                        required
                                        aria-required="true"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="adress"
                                        label="Adress"
                                        onChange={handleChange}
                                        value={values.adress}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "adress"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "adress"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="postNr"
                                        label="Postnummer"
                                        onChange={handleChange}
                                        value={values.postNr}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "postNr"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "postNr"
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>

                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="postOrt"
                                        label="Postort"
                                        onChange={handleChange}
                                        value={values.postOrt}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "postOrt"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "postOrt"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        fullWidth
                                        name="telefon"
                                        label="Telefonnummer"
                                        onChange={handleChange}
                                        value={values.telefon}
                                        onBlur={handleBlur}
                                        error={evalErrors(
                                          errors,
                                          touched,
                                          "telefon"
                                        )}
                                        helperText={getErrorText(
                                          errors,
                                          touched,
                                          "telefon"
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                          <GetCheckBox
                                            checked={
                                              values.kreditupplysningsForetag
                                            }
                                            label="Kreditupplysningsföretag"
                                            name="kreditupplysningsForetag"
                                            onChange={(e, c) =>
                                              setFieldValue(
                                                "kreditupplysningsForetag",
                                                c
                                              )
                                            }
                                            mainLabel={
                                              "Kreditupplysningsföretag"
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                          <Input
                                            multiline={true}
                                            minRows={5}
                                            fullWidth
                                            name="syfte"
                                            label="Syfte med ansökan"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.syfte}
                                            error={evalErrors(
                                              errors,
                                              touched,
                                              "syfte"
                                            )}
                                            helperText={getErrorText(
                                              errors,
                                              touched,
                                              "syfte"
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        <Grid item xs={12}>
                          <Grid container direction="row" spacing={2}>
                            {ShowAlertEval(status) && (
                              <Grid item xs={12}>
                                <ShowAlert
                                  onClose={() => {
                                    setStatus(null);
                                  }}
                                  severity={status.severity}
                                  alertTitle={status.title}
                                  alertText={status.msg}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={2}>
                                <BtnDefault
                                  disabled={
                                    isSubmitting ||
                                    status?.hideForm ||
                                    errorsExists(errors)
                                  }
                                  type="submit"
                                >
                                  Spara
                                </BtnDefault>
                                <BtnSecond
                                  disabled={isSubmitting}
                                  type="button"
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                >
                                  Tillbaka
                                </BtnSecond>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" spacing={2}>
                                <BtnDefault
                                  disabled={
                                    isSubmitting ||
                                    status?.hideForm ||
                                    errorsExists(errors)
                                  }
                                  onClick={() => {
                                    modal.showDialog({
                                      title: "Bekräfta",
                                      open: true,
                                      message:
                                        "Vill du godkänna ansökan om användarkonto?",
                                      actions: [
                                        {
                                          action: () => {
                                            godkannAnsokan(
                                              setStatus,
                                              values,
                                              setSubmitting
                                            );
                                          },
                                          btn: ActionType.default,
                                          text: "Godkänn",
                                        },
                                        {
                                          btn: ActionType.second,
                                          text: "Avbryt",
                                        },
                                      ],
                                    });
                                  }}
                                  type="button"
                                >
                                  Godkänn ansökan
                                </BtnDefault>
                                <BtnSecond
                                  disabled={
                                    isSubmitting ||
                                    status?.hideForm ||
                                    errorsExists(errors)
                                  }
                                  type="button"
                                  onClick={() => {
                                    modal.showDialog({
                                      title: "Bekräfta",
                                      open: true,
                                      message:
                                        "Vill du avslå ansökan om användarkonto?",
                                      actions: [
                                        {
                                          action: () => {
                                            avslaAnsokan(
                                              setStatus,
                                              setSubmitting
                                            );
                                          },
                                          btn: ActionType.default,
                                          text: "Avslå",
                                        },
                                        {
                                          btn: ActionType.second,
                                          text: "Avbryt",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Avslå ansökan
                                </BtnSecond>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
