import { IInfo } from "../Services/infoService";

export enum KommunKontaktTyp {
  UtskickAvRestaurangRapport = "UtskickAvRestaurangRapport",
  AnsokanAnvandarKonto = "AnsokanAnvandarKonto",
  TillstandsFragorLanet = "TillstandsFragorLanet",
  AnsokanAnvandarKontoTobak = "AnsokanAnvandarKontoTobak",
}

interface IMap extends IInfo {
  kommunKontakt: KommunKontaktTyp;
}

export function MapToKommunKontaktTypName(id: number): string {
  const res = KommunKontaktTypes.find((p) => p.id === id)?.namn;
  if (res === undefined) throw Error("Something went wrong");

  return res;
}

export const KommunKontaktTypes: IMap[] = [
  {
    id: 1,
    namn: "Kontaktperson - restaurangrapporter",
    kommunKontakt: KommunKontaktTyp.UtskickAvRestaurangRapport, 
  },
  {
    id: 2,
    namn: "Kontaktperson - användarkonton alkohol",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKonto,
  },
  {
    id: 3,
    namn: "Kontaktperson - tillståndsfrågor länsstyrelsen",
    kommunKontakt: KommunKontaktTyp.TillstandsFragorLanet,
  },

  {
    id: 4,
    namn: "Kontaktperson - användarkonton tobak",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKontoTobak,
  },
];

export const KommunKontaktTypesForAlkoholAndTobak: IMap[] = [
  {
    id: 1,
    namn: "Kontaktperson - restaurangrapporter",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKonto, 
  },
  {
    id: 2,
    namn: "Kontaktperson - användarkonton alkohol",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKonto,
  },
  {
    id: 3,
    namn: "Kontaktperson - tillståndsfrågor länsstyrelsen",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKonto,
  },

  {
    id: 4,
    namn: "Kontaktperson - användarkonton tobak",
    kommunKontakt: KommunKontaktTyp.AnsokanAnvandarKonto,
  },
];
