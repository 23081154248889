import React from "react";
import { Formik } from "formik";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { FileManagementSKVValidation } from "./validation";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import {IHanteraSKVFil, exportSKVTillstandFile} from "../../Services/PermitService";
import { getErrorText } from "../../Utilities/formikErrorEval";

export interface IForm extends IHanteraSKVFil {}

export const defaultVals: IForm = {
  artal: 2023,
  kvartal: 1,
};


export function FileManagementSKV() {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography paragraph variant="h1" component="h1">Skapa fil till Skatteverket</Typography>
        <Typography variant="body1">Skapa fil över tillståndshavare med stadigvarande serveringstillstånd</Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          validationSchema={FileManagementSKVValidation}
          initialValues={{ ...defaultVals }}
          enableReinitialize={true}
          onSubmit={(values,  { setSubmitting }) => {
           setSubmitting(true);
            exportSKVTillstandFile(values).then((res) => {
              {
                setSubmitting(false);
                const url = window.URL.createObjectURL(
                  new Blob([res.object], {
                    type: "text/plain",
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                   "AI" + values.artal + "_T" + values.kvartal
                );
                document.body.appendChild(link);
                link.click();
              }
      
            });
            
    
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
            setStatus,
            setSubmitting,
            status,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="artal-label">År</InputLabel>
                    <SelectSingleWihtoutData
                      label="År"
                      labelId="artal-label"
                      infoType={InfoType.artal}
                      selected={values.artal}
                      onChange={handleChange}
                      name="artal"
                      required
                      aria-required="true"
                    />
                    <FormHelperText>
                      {getErrorText(errors, touched, "artal")}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="kvartal-label">Kvartal</InputLabel>
                    <SelectSingleWihtoutData
                      label="Kvartal"
                      labelId="kvartal-label"
                      infoType={InfoType.kvartal}
                      selected={values.kvartal}
                      onChange={handleChange}
                      name="kvartal"
                      required
                      aria-required="true"
                    />
                    <FormHelperText>
                      {getErrorText(errors, touched, "kvartal")}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Stack>

              <Grid item xs={12} sx={{ mt: "18px" }}>
                <Button
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress color="primary" size={"1em"} />
                    ) : (
                      <div></div>
                    )
                  }
                  disabled={isSubmitting}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Skapa fil
                </Button>
                {ShowAlertEval(status) && (
                  <Grid item xs={12}>
                    <ShowAlert
                      onClose={() => {
                        setStatus(null);
                      }}
                      severity={status.severity}
                      alertTitle={status.title}
                      alertText={status.msg}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
