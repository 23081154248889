import React from "react";
import { Container, Paper } from "@mui/material";
import { RequireAuthNegeted } from "../../Utilities/checkAuth";
import { theme } from "../../theme";

export default function UnauthLayout(props: any) {
  return (
    <RequireAuthNegeted>
      <div className="loginBackground">
        <main>
          <Container className="loginContainer">
            <Paper sx={{ padding: theme.spacing(2) }} elevation={5}>
              {props.children}
            </Paper>
          </Container>
        </main>
      </div>
    </RequireAuthNegeted>
  );
}
