import * as Yup from "yup";
import {
  maxLength,
  noNumbersRegX,
  noStaringWhitespaces,
  toShort,
} from "../../Utilities/common";

export const LicensorCreateSchema = Yup.object().shape({
  namn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .matches(noStaringWhitespaces)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett namn"),
  forvaltning: Yup.string()
    .nullable()
    .matches(noStaringWhitespaces)
    .max(50, maxLength)
    .required("Du måste ange förvaltning"),
  adress: Yup.string()
    .nullable()
    .matches(noStaringWhitespaces)
    .max(50, maxLength),
  postnr: Yup.string()
    .nullable()
    .matches(noStaringWhitespaces)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett postnummer"),
  postOrt: Yup.string()
    .nullable()
    .matches(noStaringWhitespaces)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange en postort"),
});
