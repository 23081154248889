import { FilterVintage } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { serialize } from "v8";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import CustomModal from "../../Components/customModal";
import { InfoType } from "../../Components/selects";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  IActions,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import InputTextField from "../../Components/textfield";
import { IMainBaseResponse } from "../../Services/base";
import { getRapportPeriod, IInfo } from "../../Services/infoService";
import {
  approveSelected,
  downloadOldDataExcel,
  exportexcel,
  getStatistikRestaurangrapportering,
  IReportSearchFilter,
  IReportSearchResult,
  RapportStatusId,
  searchReports,
  getReportById,
} from "../../Services/reportService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { useAuth } from "../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { Roles } from "../../Utilities/roles";
import { useFilter } from "../../Utilities/searchFilterProvider";
import { Rapporttyper } from "./ReportForm/formlogic";

export interface ICommonPropsRecord {
  typ: number;
  period: number;
  status: number;
  lan: number;
  kommun: number;
}

interface IModalProps {
  action: "godkänn";
  show: boolean;
}

export interface IRecordSearchFilter extends ICommonPropsRecord {}

export default function ReportRecords() {
  //Usings
  const filterProvider = useFilter();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [loadingFuncs, setLoadingFuncs] = React.useState(false);
  const [errors, setErrors] = React.useState<any>(null);
  const [results, setResults] = React.useState<
    IMainBaseResponse<ISearchResultBase<IReportSearchResult>>
  >(null!);
  const [marked, setMarked] = React.useState<number[]>([]);
  const [selectedRapporttyp, setSelectedRapporttyp] = React.useState<number>(
    Rapporttyper.Restaurangrapport
  );
  const [perioder, setPerioder] = React.useState<IInfo[]>(null!);
  const [modal, setModalOpen] = React.useState<IModalProps>({
    show: false,
    action: "godkänn",
  });
  const auth = useAuth();
  const [filter, setFilter] = React.useState<ISearchBase<IReportSearchFilter> | null>(null);
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "id",
    direction: SortOrder.Fallande,
  });

  let searchId  = useParams();

  const getBaseFilter = (
    period: IInfo | null
  ): ISearchBase<IReportSearchFilter> => {
    return {
      filter: {
        rapportId: 0,
        rapportTypId: Rapporttyper.Restaurangrapport,
        rapportPeriodId: period !== null ? period.id : 0,
        rapportStatusId: 0,
        orgNr: "",
        objektNr: "",
        tillstandsGivareId: 0,
        lanId: 0,
        jamforForegaendeAr: false,
        jamforOmsattningVolym: false,
        kommunId: 0,
        kommentarFinns: false,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "id",
      sorteringsOrdning: SortOrder.Fallande,
    };
  };

  const getFilter = (filter : any): ISearchBase<IReportSearchFilter> => {
    return {
      filter: {
        rapportId: 0,
        rapportTypId: filter.object.rapportTypId,
        rapportPeriodId: filter.object.rapportperiodId,       
        rapportStatusId: 4,
        orgNr: "",
        objektNr: filter.object.objektOchOrgDetaljer.objektNr,
        tillstandsGivareId: 0,
        lanId: 0,
        jamforForegaendeAr: true,
        jamforOmsattningVolym: true,
        kommunId: 0,
        kommentarFinns: true,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "id",
      sorteringsOrdning: SortOrder.Fallande,
    };
  };

  useEffect(() => {
    const selectedReportId = localStorage.getItem('selectedReportId');

    if(selectedReportId !== null && (selectedReportId !== undefined)){
      const searchReport = getReportById(selectedReportId).then((res) => {
        setFilter({ ...getFilter(res)});
      });

      localStorage.removeItem('selectedReportId');
    }
    else{
        const filter = filterProvider.getFilter("records");
        if (filter !== null && filter !== undefined) {
          setFilter(filter);
        }
      
      getRapportPeriod().then((res) => {
        setPerioder([...res]);
        if (filter === null || filter === undefined) {
          setFilter({ ...getBaseFilter(res[1]) });
        }
      });
    }
  }, []);

  useEffect(() => {
    doSearch();
  }, [filter]);

  const headers: ITableHead<IReportSearchResult>[] = [
    {
      id: "orgNr",
      sortable: true,
      value: "Organisationsnummer",
    },
    {
      id: "tillstandshavare",
      sortable: true,
      value: "Tillståndshavare namn",
    },
    {
      id: "objektNr",
      sortable: true,
      value: "ObjektId / Restaurangnummer",
    },
    {
      id: "skapad",
      sortable: true,
      value: "Skapad",
    },
    {
      id: "inkommen",
      sortable: true,
      value: "Inkommen",
    },
    {
      id: "klar",
      sortable: true,
      value: "Klar",
    },
  ];

  const headersRestaurangrapport: ITableHead<IReportSearchResult>[] = [
    {
      id: "orgNr",
      sortable: true,
      value: "Organisationsnummer",
    },
    {
      id: "tillstandshavare",
      sortable: true,
      value: "Tillståndshavare namn",
    },
    {
      id: "objektNr",
      sortable: true,
      value: "ObjektId / Restaurangnummer",
    },
    {
      id: "serveringsstalle",
      sortable: true,
      value: "Objektets namn",
    },
    {
      id: "skapad",
      sortable: true,
      value: "Skapad",
    },
    {
      id: "inkommen",
      sortable: true,
      value: "Inkommen",
    },
    {
      id: "klar",
      sortable: true,
      value: "Klar",
    },
  ];

  const headersJamforRestRapport: ITableHead<IReportSearchResult>[] = [
    {
      id: "avvikelseOmsSprit",
      sortable: false,
      value: "Spritdryck",
    },
    {
      id: "avvikelseOmsVin",
      sortable: false,
      value: "Vin",
    },
    {
      id: "avvikelseOmsAndraJastaAlkoholdrycker",
      sortable: false,
      value: "Andra jästa alkoholdrycker",
    },
    {
      id: "avvikelseOmsStarkol",
      sortable: false,
      value: "Starköl",
    },
    {
      id: "avvikelseOmsAlkoPrep",
      sortable: false,
      value: "Alkoholdrycks- liknande preparat",
    },
  ];

  const headersJamforStatistikRapport: ITableHead<IReportSearchResult>[] = [
    {
      id: "avvikelseForsaljning",
      sortable: false,
      value: "Summa försäljning",
    },
    {
      id: "avvikelseImport",
      sortable: false,
      value: "Summa import/införsel",
    },
    {
      id: "avvikelseExport",
      sortable: false,
      value: "Summa export/utförsel",
    },
    {
      id: "avvikelseTillverkning",
      sortable: false,
      value: "Summa tillverkning",
    },
    {
      id: "avvikelseBeraknadTillverkning",
      sortable: false,
      value: "Summa beräknad tillverkning",
    },
  ];

  const downloadOldData = (id: number) => {
    downloadOldDataExcel([id])
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.object], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Rapporter.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  };

  const statistikRestaurangrapportering = (setStatus: (obj: any) => void) => {
    getStatistikRestaurangrapportering(
      filter?.filter.rapportTypId ?? 0,
      filter?.filter.rapportPeriodId ?? 0
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res?.object], {
            type: "application/xlsx",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Statusrapportering.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setStatus({
          alertText: `${error.data}`,
          severity: "error",
          show: true,
        });
      });
  };

  const checkHeadersToRender = (
    rapportTypId: Rapporttyper,
    jamfor: boolean
  ) => {
    let headersMerged: ITableHead<IReportSearchResult>[] = [...headers];
    if (rapportTypId === Rapporttyper.Restaurangrapport) {
      headersMerged = [...headersRestaurangrapport];
    }
    if (rapportTypId === Rapporttyper.Restaurangrapport && jamfor) {
      console.log("hej");
      headersMerged = [...headersMerged, ...headersJamforRestRapport];
    }
    if (
      rapportTypId === Rapporttyper.StatistikrapportForsaljningTillverkning &&
      jamfor
    ) {
      headersMerged = [...headersMerged, ...headersJamforStatistikRapport];
    }
    return headersMerged;
  };

  const SubmitSearch = (values: any) => {
    setMarked([]);
    setFilter({ ...filter, filter: { ...values } });
  };

  const navigateToUrl = (id: number) => {
    if (filter !== null) {
      filterProvider.addFilter(filter, "records");
    }

    navigate(
      `${id}/${
        filter?.filter.jamforForegaendeAr ||
        filter?.filter.jamforOmsattningVolym
          ? "true"
          : "false"
      }`
    );
  };

  const doSearch = () => {
    setLoading(true);
    if (filter !== null) {
      searchReports(filter)
        .then((data) => {
          setResults({ ...data });
          setLoading(false);
        })
        .catch((error) => {
          setErrors(error);
          setResults(null!);
          setLoading(false);
        });
    }
  };

  function isReportOld(sokResultat: IReportSearchResult[]): boolean {
    let isOld = false;
    sokResultat.map((data, i) => {
      let year = parseInt(data.rapportPeriodNamn);
      isOld = year >= 2020 ? false : true
    })
    return isOld
  }

  const getActions = (): IActions[] => {
    let arr: IActions[] = [];
    if (
      isReportOld(results.object.sokResultat)
    ) {
      arr.push({
        action: downloadOldData,
        icon: ActionIcons.print,
        title: "Skriv ut excel",
      });
    } else {
      arr.push({
        action: navigateToUrl,
        icon: ActionIcons.arrowForward,
        title: "Visa mer",
      });

    }

    return arr;
  };

  const approveSelectedRapporter = (
    setStatus: (status?: any) => void,
    values: any
  ) => {
    setLoadingFuncs(true);
    setModalOpen({ action: "godkänn", show: false });
    approveSelected(marked)
      .then((res) => {
        setLoadingFuncs(false);
        setStatus({
          sent: true,
          msg: "Markerade rapporter har godkänts!",
          title: "Rapporterna har godkänts",
          severity: "success",
        });

        setMarked([]);
        setFilter({ ...filter, filter: { ...values } });
      })
      .catch((err) => {
        setLoadingFuncs(false);
        setStatus({
          sent: true,
          msg: "Ett fel inträffade som gjorde att det inte gick att godkänna en eller flera rapporter",
          title: "Fel vid godkänn",
          severity: "error",
        });
      });
  };

  const SubmitExport = (setSubmitting: (isSubmitting: boolean) => void) => {
    if (filter === null) return;
    setSubmitting(true);
    exportexcel(filter).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Rapporter.xlsx");
      document.body.appendChild(link);
      link.click();
      setSubmitting(false);
    });
  };

  return (
    
    
    <Grid container>
      <Grid item xs={12}>
      {!auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && 
        <Typography variant="h1" component="h1" paragraph>
          Sök rapport
        </Typography>
      }
      {auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && 
        <Typography variant="h1" component="h1" paragraph>
          Sök restaurangrapport 
        </Typography>
      }
      </Grid>
      {filter !== null && (
        <Grid item xs={12} md={10} mb={2}>
          <Formik
            enableReinitialize
            initialValues={{ ...filter.filter }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              SubmitSearch(values);
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              setFieldValue,
              errors,
              touched,
              values,
              handleChange,
              setStatus,
              status,
              isSubmitting,
              setSubmitting,
              resetForm,
            }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {auth.validateUserRoleAccess([
                        Roles.FohmAdmin,
                        Roles.FohmLasAlkohol,
                      ]) && (
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <AutoCompleteAtr
                              onBlur={handleBlur}
                              infoType={InfoType.rapportTypId}
                              disableClearable={true}
                              label="Rapporttyp"
                              multiple={false}
                              defaultVal={false}
                              onChange={(data) => {
                                setFieldValue("rapportTypId", data);
                                setSelectedRapporttyp(
                                  typeof data === "number" ? data : 0
                                );
                                setFieldValue("jamforOmsattningVolym", false);
                                setFieldValue("jamforForegaendeAr", false);
                              }}
                              error={evalErrors(
                                errors,
                                touched ?? {},
                                "rapportTypId"
                              )}
                              helperText={getErrorText(
                                errors,
                                touched ?? {},
                                "rapportTypId"
                              )}
                              name="rapportTypId"
                              selectedData={
                                values.rapportTypId === 0
                                  ? Rapporttyper.Restaurangrapport
                                  : values.rapportTypId
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <AutoCompleteAtr
                            onBlur={handleBlur}
                            infoType={InfoType.rapportPeriodId}
                            disableClearable={true}
                            label="Rapportperiod"
                            multiple={false}
                            defaultVal={false}
                            onChange={(data) => {
                              setFieldValue("rapportPeriodId", data);
                            }}
                            error={evalErrors(
                              errors,
                              touched ?? {},
                              "rapportPeriodId"
                            )}
                            helperText={getErrorText(
                              errors,
                              touched ?? {},
                              "rapportPeriodId"
                            )}
                            name="rapportPeriodId"
                            selectedData={values.rapportPeriodId}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <AutoCompleteAtr
                            onBlur={handleBlur}
                            infoType={InfoType.rapportStatusEjSkapad}
                            disableClearable={true}
                            label="Rapportstatus"
                            multiple={false}
                            defaultVal={false}
                            onChange={(data) => {
                              setFieldValue("rapportStatusId", data);
                            }}
                            error={evalErrors(
                              errors,
                              touched ?? {},
                              "rapportStatusId"
                            )}
                            helperText={getErrorText(
                              errors,
                              touched ?? {},
                              "rapportStatusId"
                            )}
                            name="rapportStatusId"
                            selectedData={values.rapportStatusId}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {auth.validateUserRoleAccess([
                        Roles.FohmAdmin,
                        Roles.FohmLasAlkohol,
                      ]) && (
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <AutoCompleteAtr
                              onBlur={handleBlur}
                              infoType={InfoType.lan}
                              disableClearable={true}
                              label="Län"
                              multiple={false}
                              defaultVal={false}
                              onChange={(data) => {
                                setFieldValue("lanId", data);
                              }}
                              error={evalErrors(errors, touched ?? {}, "lanId")}
                              helperText={getErrorText(
                                errors,
                                touched ?? {},
                                "lanId"
                              )}
                              name="lanId"
                              selectedData={values.lanId}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <AutoCompleteAtr
                            onBlur={handleBlur}
                            infoType={InfoType.kommunerAnvandare}
                            disableClearable={false}
                            label="Kommun"
                            multiple={false}
                            defaultVal={false}
                            onChange={(data) => {
                              setFieldValue("tillstandsGivareId", data);
                            }}
                            error={evalErrors(
                              errors,
                              touched ?? {},
                              "tillstandsGivareId"
                            )}
                            helperText={getErrorText(
                              errors,
                              touched ?? {},
                              "tillstandsGivareId"
                            )}
                            name="tillstandsGivareId"
                            selectedData={values.tillstandsGivareId}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                      <InputTextField
                        fullWidth
                        name="objektNr"
                        label="ObjektId/Restaurangnummer"
                        onChange={handleChange}
                        value={values.objektNr}
                      />
                    </Grid>
                    </Grid>
                  </Grid>
                  {(values.rapportTypId === Rapporttyper.Restaurangrapport ||
                    values.rapportTypId ===
                      Rapporttyper.StatistikrapportForsaljningTillverkning) &&
                    auth.validateUserRoleAccess([
                      Roles.FohmAdmin,
                      Roles.KommunAdminAlkohol,
                    ]) && (
                      <Grid item xs={4}>
                        <FormControl>
                          <FormControlLabel
                            label={
                              values.rapportTypId ===
                              Rapporttyper.Restaurangrapport
                                ? "Jämför omsättning och inköpt volym"
                                : values.rapportTypId ===
                                  Rapporttyper.StatistikrapportForsaljningTillverkning
                                ? "Jämför med föregående år"
                                : "Ser du detta meddelande hanteras inte jämför korrekt kontakta support"
                            }
                            control={
                              <Checkbox
                                onChange={(e, b) => {
                                  if (
                                    values.rapportTypId ===
                                    Rapporttyper.Restaurangrapport
                                  ) {
                                    setFieldValue("jamforOmsattningVolym", b);
                                    setFieldValue("jamforForegaendeAr", false);
                                  } else if (
                                    values.rapportTypId ===
                                    Rapporttyper.StatistikrapportForsaljningTillverkning
                                  ) {
                                    setFieldValue(
                                      "jamforOmsattningVolym",
                                      false
                                    );
                                    setFieldValue("jamforForegaendeAr", b);
                                  }
                                }}
                                checked={
                                  values.jamforForegaendeAr ||
                                  values.jamforOmsattningVolym
                                }
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {(values.rapportTypId === Rapporttyper.Restaurangrapport) &&
                    auth.validateUserRoleAccess([
                      Roles.FohmAdmin,
                      Roles.KommunAdminAlkohol,
                    ]) && (
                      <Grid item xs={8}>
                        <FormControl>
                          <FormControlLabel
                            label={ "Kommentar finns" }
                            control={
                              <Checkbox
                                onChange={(e, b) => {
                                    setFieldValue("kommentarFinns", b);
                                }}
                                checked={
                                  values.kommentarFinns 
                                }
                              />
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                          <BtnDefault type="submit">Sök</BtnDefault>
                          <BtnSecond
                            onClick={() => {
                              setFilter({ ...getBaseFilter(null) });
                              resetForm();
                            }}
                          >
                            Rensa
                          </BtnSecond>
                          {auth.validateUserRoleAccess([Roles.FohmAdmin]) &&
                            (filter.filter.rapportStatusId ===
                              RapportStatusId.Inkomen ||
                            filter.filter.rapportStatusId ===
                              RapportStatusId.Granskas) &&
                              (filter.filter.jamforOmsattningVolym ||
                              filter.filter.jamforForegaendeAr) && (
                              <BtnSecond
                                onClick={() => {
                                  if (marked.length === 0) {
                                    setStatus({
                                      sent: true,
                                      msg: "Inga rapporter är valda",
                                      title: "Varning",
                                      severity: "warning",
                                    });
                                  } else {
                                    setModalOpen({
                                      action: "godkänn",
                                      show: true,
                                    });
                                  }
                                }}
                              >
                                Klarmarkera valda rapporter
                              </BtnSecond>
                            )}
                          <BtnSecond
                            startIcon={
                              isSubmitting === true && (
                                <CircularProgress
                                  color="primary"
                                  size={"1em"}
                                />
                              )
                            }
                            disabled={isSubmitting}
                            onClick={() => {
                              SubmitExport(setSubmitting);
                            }}
                            type="button"
                          >
                            Exportera resultat
                          </BtnSecond>

                          {(filter.filter.rapportTypId ===
                            Rapporttyper.Restaurangrapport ||
                            filter.filter.rapportTypId ===
                              Rapporttyper.StatistikrapportForsaljningTillverkning  || filter.filter.rapportTypId === Rapporttyper.StatistikrapportTekniskSprit) && (
                              <BtnSecond
                                onClick={() => {
                                  statistikRestaurangrapportering(setStatus);
                                }}
                              >
                                Hämta statusrapport
                              </BtnSecond>
                            )}

                          {loadingFuncs && <CircularProgress />}
                        </Stack>
                      </Grid>
                      {ShowAlertEval(status) && (
                        <Grid item xs={12} md={10} mt={1}>
                          <ShowAlert
                            onClose={() => {
                              setStatus(null);
                            }}
                            severity={status.severity}
                            alertTitle={status.title}
                            alertText={status.msg}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomModal
                    title="Bekräfta"
                    handleCloseModal={() => {
                      setModalOpen({ action: "godkänn", show: false });
                    }}
                    openModal={modal.show}
                  >
                    <Grid container spacing={2}>
                      {modal.action === "godkänn" && (
                        <>
                          <Grid item xs={12}>
                            <Typography>
                              Är du säker på att du vill godkänna markerade
                              rapporter?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <BtnDefault
                                onClick={() => {
                                  approveSelectedRapporter(setStatus, values);
                                }}
                              >
                                Bekräfta
                              </BtnDefault>
                              <BtnSecond
                                onClick={() => {
                                  setModalOpen({
                                    action: "godkänn",
                                    show: false,
                                  });
                                }}
                              >
                                Avbryt
                              </BtnSecond>
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CustomModal>
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      )}
      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}
      {!loading && filter !== null ? (
        <Grid item xs={12}>
          <>
            {results !== null && (
              <Typography variant="h2" gutterBottom>
                Sökresultat
              </Typography>
            )}
            <Typography
              variant="body1"
              component="p"
              paragraph
              id="Rapporter"
              aria-live="assertive"
              aria-atomic={true}
              className="visuallyHidden"
            >
              {results.object.sokResultat &&
              results.object.sokResultat.length > 0
                ? `Hittade ${results.object.sokResultat.length} rapporter.`
                : ""}
            </Typography>
            {results !== null && (
              <DefaultTable
                header={checkHeadersToRender(
                  filter.filter.rapportTypId,
                  filter.filter.jamforForegaendeAr ||
                    filter.filter.jamforOmsattningVolym
                )}
                activePage={filter.sidoNummer}
                data={mapToCellData(
                  checkHeadersToRender(
                    filter.filter.rapportTypId,
                    filter.filter.jamforForegaendeAr ||
                      filter.filter.jamforOmsattningVolym
                  ),
                  results.object.sokResultat
                )}
                rowsPerPage={checkMenuItem(filter.sidoStorlek)}
                totalHits={results.object.totaltAntalTraffar}
                filter={filter}
                setFilterAction={setFilter}
                setSortOptions={setSortOptions}
                sortOptions={sortOptions}
                actions={getActions()}
                tableAria="Rapporter"
                checkbox={
                  auth.validateUserRoleAccess([Roles.FohmAdmin]) &&
                  (filter.filter.rapportStatusId === RapportStatusId.Inkomen ||
                  filter.filter.rapportStatusId === RapportStatusId.Granskas) &&
                  (filter.filter.jamforOmsattningVolym || filter.filter.jamforForegaendeAr)
                }
                marked={marked}
                setMarked={setMarked}
              />
            )}
          </>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack alignContent="center">
            <CircularProgress />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
