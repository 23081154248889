import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { InfoType, SelectSingleWihtoutData } from "../../Components/selects";
import { ICreateUser, postApply } from "../../Services/UserService";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import {
  Cat,
  GetCorrectRolesBasedOnKey,
  MapToCorrectCat,
  MapToCorrectKey,
  ShowDropdown,
} from "../../Utilities/roles";
import { applyUserSchemaKommuner } from "./validationKommuner";
import ApplyforKommunLanModal from "./applyforKommunerModal";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import InputTextField from "../../Components/textfield";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { GetCheckBox } from "../../Components/checkbox";
import { BtnSecond } from "../../Components/buttons";
import { SystemList} from "../../Utilities/systemUtils";

const ApplyForKommuner: React.FC = () => {
  const captchaRef = useRef(null);
  const [token, setToken] = useState("");
  const [showForm, setShowForm] = useState(true);
  let navigate = useNavigate();

  const initialValues: ICreateUser = {
    ref: "",
    id: 0,
    fornamn: "",
    efternamn: "",
    epost: "",
    foretagsnamn: "",
    adress: "",
    orgNr: "",
    postNr: "",
    postOrt: "",
    telefon: "",
    syfte: "",
    roller: [],
    kommunIdn: [],
    system: [],
    systemId: 0,
    lanId: 0,
    gdpr: false,
    samtligaAktuellaServTillstand: true,
    samtligaServtillstandAvslutadeForeVisstDatum: true,
    kreditupplysningsForetag: true,
  };

  const gdprCheck = "gdpr";

  return (
    <Grid container spacing={2} mt={0}>
      <Grid item xs={12}>
        <Formik
          validationSchema={applyUserSchemaKommuner}
          initialValues={{ ...initialValues, epost2: "" }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            values = {
              ...values,
            };
            if (values.gdpr !== true) {
              setSubmitting(false);
              setStatus({
                sent: false,
                msg: "Du måste godkänna personuppgiftshanteringen",
                severity: "error",
              });
            } else if (!token) {
              setSubmitting(false);
              setStatus({
                sent: false,
                msg: "Du måste kryssa i att du är en människa",
                severity: "error",
              });
            } else {
              setSubmitting(true);
              postApply({ captchaToken: token, data: { ...values } })
                .then((res) => {
                  setSubmitting(false);
                  resetForm();
                  setStatus({
                    sent: true,
                    msg: "Din ansökan om användarkonto är nu skickad till Folkhälsomyndigheten",
                    title: "Ansökan är registrerad",
                    severity: "success",
                  });
                  setShowForm(false);
                })
                .catch((error) => {
                  console.log(error);
                  setStatus({
                    sent: true,
                    msg: JSON.stringify(error.data),
                    title: "Ett fel uppstod",
                    severity: "error",
                  });
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            values,
            touched,
            isSubmitting,
            status,
            setStatus,
          }) => (
            <Box component="form" onSubmit={handleSubmit} id="form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <ApplyforKommunLanModal />
                  </Box>
                  <FormControl
                    fullWidth
                    error={evalErrors(errors, touched, "gdpr")}
                  >
                    <GetCheckBox
                      checked={values.gdpr ?? false}
                      label="Jag har läst om hur Folkhälsomyndigheten hanterar personuppgifter"
                      mainLabel=""
                      name="godkann"
                      onChange={(e, v) => setFieldValue("gdpr", v)}
                    />
                    <FormHelperText>
                      {getErrorText(errors, touched, "gdpr")}
                    </FormHelperText>
                  </FormControl>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h4" component="h2">
                        Kommun- eller länsstyrelseansökan
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {showForm && (
                      <Grid item xs={12}>
                        <Typography variant="caption" component="p">
                          Fält markerade med{" "}
                          <abbr title="Obligatoriskt fält">*</abbr> är
                          obligatoriska
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <InputTextField
                              fullWidth
                              name="fornamn"
                              label="Förnamn"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.fornamn}
                              error={evalErrors(errors, touched, "fornamn")}
                              helperText={getErrorText(
                                errors,
                                touched,
                                "fornamn"
                              )}
                              required
                              aria-required="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputTextField
                              fullWidth
                              name="efternamn"
                              label="Efternamn"
                              onChange={handleChange}
                              value={values.efternamn}
                              onBlur={handleBlur}
                              error={evalErrors(errors, touched, "efternamn")}
                              helperText={getErrorText(
                                errors,
                                touched,
                                "efternamn"
                              )}
                              required
                              aria-required="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputTextField
                              fullWidth
                              name="epost"
                              label="E-postadress"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.epost}
                              error={evalErrors(errors, touched, "epost")}
                              helperText={getErrorText(
                                errors,
                                touched,
                                "epost"
                              )}
                              required
                              aria-required="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputTextField
                              fullWidth
                              name="epost2"
                              label="Bekräfta e-postadress"
                              onChange={handleChange}
                              value={values.epost2}
                              onBlur={handleBlur}
                              error={evalErrors(errors, touched, "epost2")}
                              helperText={getErrorText(
                                errors,
                                touched,
                                "epost2"
                              )}
                              required
                              aria-required="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl
                              fullWidth
                              error={evalErrors(errors, touched, "roller")}
                            >
                              <InputLabel id="roller-label">Roller</InputLabel>
                              <SelectSingleWihtoutData
                                label="Roller"
                                labelId="roller-label"
                                infoType={InfoType.rollerApplyForAccount}
                                selected={MapToCorrectKey(values.roller)}
                                onChange={(evt) => {
                                  setFieldValue("lanId", 0);
                                  setFieldValue("kommunIdn", []);
                                  setFieldValue(
                                    "roller",
                                    GetCorrectRolesBasedOnKey(evt.target.value)
                                  );
                                }}
                                name="roller"
                                defaultlabel="Välj roll *"
                                required
                                aria-required="true"
                                onBlur={handleBlur}
                              />
                              <FormHelperText>
                                {getErrorText(errors, touched, "roller")}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {MapToCorrectCat(values.roller) === Cat.kommun && (
                              <FormControl fullWidth>
                                <AutoCompleteAtr
                                  onBlur={handleBlur}
                                  infoType={InfoType.kommuner}
                                  label="Kommuner"
                                  multiple={true}
                                  defaultVal={false}
                                  onChange={(data) => {
                                    setFieldValue("kommunIdn", data);
                                  }}
                                  error={evalErrors(
                                    errors,
                                    touched,
                                    "kommunIdn"
                                  )}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "kommunIdn"
                                  )}
                                  name="kommunIdn"
                                  selectedData={values.kommunIdn}
                                />
                              </FormControl>
                            )}
                            {MapToCorrectCat(values.roller) === Cat.lan && (
                              <FormControl fullWidth>
                                <AutoCompleteAtr
                                  error={evalErrors(errors, touched, "lanId")}
                                  onBlur={handleBlur}
                                  infoType={InfoType.lan}
                                  multiple={false}
                                  defaultVal={false}
                                  label="Län"
                                  onChange={(data) => {
                                    setFieldValue("lanId", data);
                                  }}
                                  helperText={getErrorText(
                                    errors,
                                    touched,
                                    "lanId"
                                  )}
                                  name="lanId"
                                  selectedData={values.lanId}
                                />
                              </FormControl>
                            )}
                          </Grid>
                         
                         <Grid item xs={12} md={6}>
                         {ShowDropdown(values.roller) && (
                           <FormControl
                              fullWidth
                              error={evalErrors(errors, touched, "system")}
                            >
                              <AutoCompleteAtr
                                      defaultVal={false}
                                      infoType={InfoType.system}
                                      multiple={false}
                                      label="System"
                                      name="system"
                                      onChange={(data) => {
                                        setFieldValue(
                                          "systemId", data                                         
                                        );                                      
                                      }}
                                      onBlur={handleBlur}
                                      selectedData={values.systemId}
                                      error={evalErrors(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                      helperText={getErrorText(
                                        errors,
                                        touched,
                                        "system"
                                      )}
                                    />
                              <FormHelperText>
                                {getErrorText(errors, touched, "system")}
                              </FormHelperText>
                            </FormControl>)}
                          </Grid>
                          <Grid item xs={12}>
                            <HCaptcha
                              sitekey={
                                process.env
                                  .REACT_APP_HCAPTCHA_SITE_KEY as string
                              }
                              onVerify={setToken}
                              languageOverride="sv"
                              ref={captchaRef}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            mt={1}
                          >
                            {showForm && (
                              <Button
                                color="primary"
                                title="skicka ansökan"
                                type="submit"
                                size="large"
                                variant="contained"
                                disabled={isSubmitting}
                              >
                                Skicka in ansökan
                              </Button>
                            )}
                            <BtnSecond onClick={() => navigate("/")}>
                              Tillbaka
                            </BtnSecond>
                            {isSubmitting && <CircularProgress />}
                          </Stack>
                          {ShowAlertEval(status) && (
                            <Grid item xs={12}>
                              <ShowAlert
                                onClose={() => {
                                  setStatus(null);
                                }}
                                severity={status.severity}
                                alertTitle={status.title}
                                alertText={status.msg}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
export default ApplyForKommuner;
