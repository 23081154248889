import * as Yup from "yup";
import {
  maxLength,
  noNumbersRegX,
  noStaringWhitespaces,
  notWhiteSpace,
  onlyNumbers,
  swedishPhoneNumber,
  toShort,
} from "../../Utilities/common";
import { Cat, MapToCorrectCat } from "../../Utilities/roles";

export const applyUserSchemaForetag = Yup.object().shape({
  foretagsnamn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett företagsnamn"),
  fornamn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett förnamn"),
  syfte: Yup.string().min(2, toShort).required("Du måste ange ett syfte"),
  efternamn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett efternamn"),
  epost: Yup.string()
    .lowercase()
    .email("Du måste ange en giltig e-postadress")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .required("Du måste ange en e-postadress"),
  epost2: Yup.string()
    .lowercase()
    .matches(noStaringWhitespaces, notWhiteSpace)
    .required("Du måste bekräfta din e-postadress")
    .email("E-postadresserna matchar inte")
    .oneOf([Yup.ref("epost"), null], "E-postadresserna matchar inte"),
  kommunIdn: Yup.array().when("roller", {
    is: (val: any) => {
      let x = MapToCorrectCat([...val]);
      if (x === Cat.kommun) {
        return true;
      } else return false;
    },
    then: (schema) =>
      schema.min(
        1,
        "Minst en kommun måste vara vald när en kommunroll är vald"
      ),
  }),
  lanId: Yup.number().when("roller", {
    is: (val: any) => {
      let x = MapToCorrectCat([...val]);
      if (x === Cat.lan) {
        return true;
      } else return false;
    },
    then: (schema) =>
      schema
        .required("Du måste välja ett län")
        .min(1, "Du måste välja ett län"),
  }),
  roller: Yup.array().min(1, "Du måste välja en roll"),
  postNr: Yup.string()
    .matches(/^(\d{4}$)|(^\d{3} ?\d{2})$/, "Ange på formatet nnnn eller nnn nn")
    .required("Du måste ange ett postnummer"),
  adress: Yup.string()
    .min(2, toShort)
    .max(100, maxLength)
    .required("Du måste ange en adress"),
  postOrt: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange postort"),
  gdpr: Yup.bool().isTrue("Läs om hur vi behandlar personuppgifter och markera kryssrutan"),
});
