import "moment/locale/sv";
import moment, { Moment } from "moment";

//Should only be executed ones from App()
export function InitMomentLocales() {
    moment().locale("sv")
}

export enum formats {
    dateWithTime = "YYYY-MM-DD HH:mm",
    date = "YYYY-MM-DD",
    dateFormatBe = "YYYY-MM-DDTHH:mm:ss",
    compactDate = "YYYYMMDD"
}


export function GetTodaysDateFormatted(format:formats = formats.date){
    return moment().format(format.toString());
}
export function GetDateFormatted(time:string | null, format:formats = formats.date){
    return moment(time).format(format.toString());
}

export function GetDateFormattedCompact(time:string | null, format:formats = formats.compactDate){
    return moment(time).format(format.toString());
}

export function GetDateFormattedUsingDate(time:string, format:formats = formats.date){
    let date = new Date(time)
    return moment(date).format(format.toString());
}
