import * as React from "react";
import {
  AppBar,
  Button,
  Chip,
  Container,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { useAuth } from "../Utilities/authProvider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { Roles } from "../Utilities/roles";
import { downloadPDF } from "../Services/UserService";
interface ISubpage {
  name: string;
  link: string;
  allowedRoles: Roles[];
}
interface IPage {
  name: string;
  allowedRoles: Roles[];
  subpages: ISubpage[];
}

const pages: IPage[] = [
  {
    name: "Tillstånd",
    allowedRoles: [
      Roles.FohmAdmin,
      Roles.FohmLasAlkohol,
      Roles.FohmLasTobak,
      Roles.KommunAdminAlkohol,
      Roles.KommunAdminTobak,
      Roles.KommunLasAlkohol,
      Roles.KommunLasTobak,
      Roles.LansstyrelseLasAlkohol,
      Roles.Registerutdrag,
    ],
    subpages: [
      {
        name: "Sök",
        link: "/permits",
        allowedRoles: [
          Roles.FohmAdmin,
          Roles.FohmLasAlkohol,
          Roles.FohmLasTobak,
          Roles.KommunAdminAlkohol,
          Roles.KommunAdminTobak,
          Roles.KommunLasAlkohol,
          Roles.KommunLasTobak,
          Roles.LansstyrelseLasAlkohol,
        ],
      },
      {
        name: "Registrera",
        link: "/permits/new",
        allowedRoles: [
          Roles.FohmAdmin,
          Roles.KommunAdminAlkohol,
          Roles.KommunAdminTobak,
        ],
      },
      {
        name: "Registerutdrag",
        link: "/permits/records",
        allowedRoles: [
          Roles.FohmAdmin,
          Roles.WebbtjanstRegisterutdrag,
          Roles.FohmLasAlkohol,
          Roles.Registerutdrag
        ],
      },
      {
        name: "Filimport SKV",
        link: "/permits/upload",
        allowedRoles: [Roles.FohmAdmin],
      },
      {
        name: "Skapa fil SKV",
        link: "/permits/FileManagementSKV",
        allowedRoles: [Roles.FohmAdmin],
      },
    ],
  },
  {
    name: "Rapporter",
    allowedRoles: [
      Roles.FohmAdmin,
      Roles.FohmLasAlkohol,
      Roles.KommunAdminAlkohol,
    ],
    subpages: [
      {
        name: "Sök",
        link: "/reports",
        allowedRoles: [
          Roles.FohmAdmin,
          Roles.FohmLasAlkohol,
          Roles.KommunAdminAlkohol,
        ],
      },
      {
        name: "Registrera",
        link: "/reports/new",
        allowedRoles: [Roles.FohmAdmin, Roles.KommunAdminAlkohol,],
      },
      {
        name: "Skapa rapporter",
        link: "/reports/generatereports",
        allowedRoles: [Roles.FohmAdmin],
      },
      {
        name: "Skapa period",
        link: "/reports/createperiod",
        allowedRoles: [Roles.FohmAdmin],
      }
    ],
  },
  {
    name: "Statistik",
    allowedRoles: [
      Roles.FohmAdmin,
      Roles.FohmLasAlkohol,
      Roles.FohmLasTobak,
      Roles.KommunAdminAlkohol,
      Roles.KommunAdminTobak,
      Roles.KommunLasAlkohol,
      Roles.KommunLasTobak,
      Roles.LansstyrelseLasAlkohol,
    ],
    subpages: [
      {
        name: "Alkohol",
        link: "/statistics/alcohol",
        allowedRoles: [Roles.FohmAdmin, Roles.FohmLasAlkohol, Roles.KommunAdminAlkohol, Roles.KommunLasAlkohol, Roles.LansstyrelseLasAlkohol],
      },
      {
        name: "Tobak",
        link: "/statistics/tobacco",
        allowedRoles: [Roles.FohmAdmin, Roles.FohmLasTobak, Roles.KommunAdminTobak, Roles.KommunLasTobak, Roles.LansstyrelseLasAlkohol],
      },
      {
        name: "Befolkningsstatistik",
        link: "/statistics/population",
        allowedRoles: [Roles.FohmAdmin],
      },
    ],
  },
  {
    name: "Kontakter",
    allowedRoles: [
      Roles.FohmAdmin,
      Roles.KommunAdminAlkohol,
      Roles.KommunAdminTobak,
    ],
    subpages: [
      {
        name: "Sök",
        link: "/contacts/",
        allowedRoles: [
          Roles.FohmAdmin,
          Roles.KommunAdminAlkohol,
          Roles.KommunAdminTobak,
        ],
      },
      {
        name: "Registrera",
        link: "/contacts/new/",
        allowedRoles: [Roles.FohmAdmin],
      },
      {
        name: "Skicka e-post",
        link: "/contacts/sendemail",
        allowedRoles: [Roles.FohmAdmin],
      },
    ],
  },
  {
    name: "Kommuner",
    allowedRoles: [
      Roles.FohmAdmin,
      Roles.KommunAdminAlkohol,
    ],
    subpages: [
      {
        name: "Sök",
        link: "/licensors/",
        allowedRoles: [Roles.FohmAdmin, Roles.KommunAdminAlkohol,]
      },
      {
        name: "Registrera",
        link: "/licensors/new/",
        allowedRoles: [Roles.FohmAdmin],
      },
    ],
  },
  {
    name: "Användare",
    allowedRoles: [Roles.FohmAdmin],
    subpages: [
      { name: "Sök", link: "/users/", allowedRoles: [Roles.FohmAdmin] },
      {
        name: "Registrera",
        link: "/users/new",
        allowedRoles: [Roles.FohmAdmin],
      },
      {
        name: "Ansökningar",
        link: "/users/applies",
        allowedRoles: [Roles.FohmAdmin],
      },
    ],
  },
];

export enum UserLinks {
  email = "servering@folkhalsomyndigheten.se",
}

export default function Header() {
  const [activeMenu, setActiveMenu] = React.useState<null | string>(null);
  const [activeSubpage, setActiveSubpage] = React.useState<null | number>(null);
  const [navOpen, setNavOpen] = React.useState<boolean>(false);
  const [userOpen, setUserOpen] = React.useState<boolean>(false);
  const [twoGuides, setTwoGuides] = React.useState<boolean>(false);
  const FOHM_EMAIL = UserLinks.email;

  const auth = useAuth();
  const navigate = useNavigate();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const handleToggleNavMenu = (key: string) => {
    setUserOpen(false);
    setActiveMenu(key);
    if (activeMenu === key && navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  const handleToggleUserMenu = () => {
    const user = auth.getActiveUser()

    if (
      (user.roles.findIndex((x) => x === Roles.KommunAdminAlkohol) >= 0 ||  user.roles.findIndex((x) => x === Roles.KommunLasAlkohol) >= 0) &&
      (user.roles.findIndex((x) => x === Roles.KommunAdminTobak) >= 0 || user.roles.findIndex((x) => x === Roles.KommunLasTobak) >= 0 )
    ) {
      setTwoGuides(true);
    }

    setUserOpen(!userOpen);
  };
  const handleClickaway = () => {
    setNavOpen(false);
    setUserOpen(false);
  };
  const handleSupportClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    handleClickaway();
    window.location.href = `mailto:${FOHM_EMAIL}`;
    e.preventDefault();
  };

  const onLinkClick = (
    link: string,
    index: number | null,
    callback?: CallableFunction
  ) => {
    callback && callback();
    setActiveSubpage(index);
    handleNavigate(link);
  };

  const handleNavigate = (link: string) => {
    const pathsToReload = ["/reports", "/contacts/", "/licensors/", "/users/", "/permits"]; 
    
    if(pathsToReload.includes(link)){
      navigate(link);
      window.location.reload();
    }
    else{
      navigate(link);
    }
    
  };

  const isPathActive = (subpages: ISubpage[]): boolean => {
    if (window.location.href.indexOf(subpages[0].link) > -1) {
      return true;
    }
    return false;
  };

  function downloadFile(isTobak: boolean) {
    downloadPDF(isTobak).then((response) => {
      if (response.success) {
        const url = window.URL.createObjectURL(
          new Blob([response.object], {
            type: "application/pdf",
          })
        );
        window.open(url);
      }
    });
    handleClickaway();
  }



  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              flexDirection: { md: "row" },
              alignItems: "center",
              overflow: "unset",
            }}
          >
            <div>ATR</div>
            {environment?.toUpperCase() !== "PROD" && (
              <Chip
                sx={{ ml: 1 }}
                size="small"
                label={environment}
                color="warning"
              />
            )}
          </Typography>
          <nav>
            <ul className="nav-ul">
              {pages.map((page, index) => {
                return (
                  page.allowedRoles !== undefined &&
                  auth.validateUserRoleAccess(page.allowedRoles) && (
                    <li key={index}>
                      <Button
                        id={`menu-button menu-button-${page.name}`}
                        aria-controls={navOpen ? "fade-menu" : undefined}
                        aria-haspopup={true}
                        aria-expanded={navOpen ? true : false}
                        onClick={() => handleToggleNavMenu(page.name)}
                        color="inherit"
                        endIcon={<ArrowDropDownIcon />}
                        key={page.name}
                        sx={{
                          ".MuiButton-endIcon": { marginLeft: "0px" },
                          ":nth-of-type(n+1)": { marginLeft: "5px" },
                          ":hover": {
                            backgroundColor: "white",
                            color: "#2d5492",
                          },
                          ":focus": {
                            backgroundColor: "white",
                            color: "#2d5492",
                          },
                        }}
                        className={
                          isPathActive(page.subpages)
                            ? "menu-button-active"
                            : ""
                        }
                        onBlur={() => handleClickaway()}
                      >
                        {page.name}

                        {page.name === activeMenu &&
                          page.subpages !== undefined && (
                            <ul
                              id="fade-menu"
                              className={
                                navOpen ? "dropdown dropdown-open" : "dropdown"
                              }
                            >
                              {page.name === activeMenu &&
                                page.subpages.map((subpage, index) => {
                                  return (
                                    page.allowedRoles !== undefined &&
                                    auth.validateUserRoleAccess(
                                      subpage.allowedRoles
                                    ) && (
                                      <li
                                        onClick={() => {
                                          onLinkClick(subpage.link, index);
                                        }}
                                        className={
                                          activeSubpage === index &&
                                          isPathActive(page.subpages)
                                            ? "dropdown-item dropdown-item-active"
                                            : "dropdown-item"
                                        }
                                        aria-label={`undermeny ${subpage.name}`}
                                        key={index}
                                      >
                                        <Typography textAlign="center">
                                          {subpage.name}
                                        </Typography>
                                      </li>
                                    )
                                  );
                                })}
                            </ul>
                          )}
                      </Button>
                    </li>
                  )
                );
              })}
            </ul>
          </nav>

          <div className="nav-user">
            {auth.validateUserRoleAccess([
              Roles.FohmAdmin,
              Roles.FohmLasAlkohol,
              Roles.FohmLasTobak,
              Roles.KommunAdminAlkohol,
              Roles.KommunAdminTobak,
              Roles.KommunLanstyrelse,
              Roles.KommunLasAlkohol,
              Roles.KommunLasTobak,
              Roles.LansstyrelseLasAlkohol,
              Roles.Registerutdrag
            ]) ? (
              <Box sx={{ flexGrow: 0, position: "relative" }}>
                <Button
                  aria-controls={"useraccount-menu"}
                  aria-haspopup={true}
                  aria-expanded={userOpen ? true : false}
                  aria-label="Hantera konto"
                  endIcon={<ArrowDropDownIcon />}
                  color="inherit"
                  onClick={() => handleToggleUserMenu()}
                  sx={{
                    ".MuiButton-endIcon": { marginLeft: "0px" },
                    ":hover": {
                      backgroundColor: "white",
                      color: "#2d5492",
                    },
                  }}
                  className={
                    window.location.href.indexOf("myaccount") > -1
                      ? "menu-button-active"
                      : ""
                  }
                >
                  {auth.user.username}
                </Button>
                <ul
                  id="useraccount-menu"
                  className={
                    userOpen
                      ? "dropdown dropdown-open menu-user-ul"
                      : "dropdown"
                  }
                >
                  {auth.validateUserRoleAccess([
                    Roles.FohmAdmin,
                    Roles.FohmLasAlkohol,
                    Roles.FohmLasTobak,
                    Roles.KommunAdminAlkohol,
                    Roles.KommunAdminTobak,
                    Roles.KommunLanstyrelse,
                    Roles.KommunLasAlkohol,
                    Roles.KommunLasTobak,
                    Roles.LansstyrelseLasAlkohol,
                    Roles.Registerutdrag
                  ]) && (
                    <>
                      <li
                        key="Användarkonto"
                        className="dropdown-item"
                        onClick={() => {
                          handleClickaway();
                          onLinkClick("/myaccount", null);
                        }}
                        aria-label="undermeny användarkonto"
                      >
                        <Typography>Användarkonto</Typography>
                      </li>
                      {auth.validateUserRoleAccess([
                        Roles.FohmAdmin,
                        Roles.FohmLasAlkohol,
                        Roles.FohmLasTobak,
                        Roles.KommunAdminAlkohol,
                        Roles.KommunAdminTobak,
                        Roles.KommunLanstyrelse,
                        Roles.KommunLasAlkohol,
                        Roles.KommunLasTobak,
                        Roles.LansstyrelseLasAlkohol
                      ]) && (
                          <li
                            className="dropdown-item"
                            key="Användarguide"
                            aria-label="undermeny användarguide"
                            onClick={() => downloadFile(false)}
                          >
                       {twoGuides ? (
                          <Typography>Användarguide - Alkohol</Typography>
                        ) : (
                          <Typography>Användarguide</Typography> 
                        )}  
                     </li>
                      )}
                        {auth.validateUserRoleAccess([
                        Roles.KommunAdminTobak,
                        Roles.KommunLasTobak,
                      ]) && twoGuides && (
                          <li
                            className="dropdown-item"
                            key="Användarguide"
                            aria-label="undermeny användarguide"
                            onClick={() => downloadFile(true)}
                          >
                            <Typography>Användarguide - Tobak</Typography>
                          </li>
                      )}
                    {auth.validateUserRoleAccess([
                      Roles.FohmAdmin,
                    ]) && (
                      <>
                        <li
                          key="Systeminställningar"
                          onClick={() => {
                            handleClickaway();
                            onLinkClick("/settings/systemsettings", null);
                          }}
                          aria-label="undermeny systeminställningar"
                          className="dropdown-item"
                        >
                          <Typography textAlign="center">
                            Systeminställningar
                          </Typography>
                        </li>
                      </>
                    )}
                    </>
                  )}
                </ul>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0, position: "relative" }}>
                <Typography>Inloggad som: {auth.user.username}</Typography>
              </Box>
            )}
            <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
              <Button
                onClick={() => auth.signOutUser()}
                color="inherit"
                variant="outlined"
                size="small"
              >
                Logga ut
              </Button>
            </Box>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
