import { Get } from "../Utilities/axiosBase";
import { tryParseJson } from "../Utilities/common";
import { IMainBaseResponse } from "./base";

export interface IInfo {
  id: number;
  namn: string;
}

export interface IDeployment {
  value: string;
}

export interface ILan extends IInfo {}

export interface IKommunList extends IInfo {}

export interface IRapporttypList extends IInfo{
  groupId:number,
  groupName:string
}

export interface IKommunKontaktTypList extends IInfo {
  kommunKontaktTyp: number;
}

export interface ITillstandsKategorier extends IInfo {
  groupId: number;
  groupName: string;
}

const infoBase: string = "/info";
const reportBase: string = "/rapport";
const deploymentStorageKey = "deploymentInfo";
const lanlistStorageKey = "lanList";
const lanlistUserStorageKey = "lanListUser";
const kommunlistStorageKey = "kommunList";
const kommunlistUserStorageKey = "kommunListUser";
const kommunKontaktTyplistStorageKey = "kommunKontaktTyplist";
const tillstandsTypAllaStorageKey = "tillstandsTypAlla";
const tillstandsTypStadigvarandeStorageKey = "tillstandsTypStadigvarande";
const tillstandsTypTillfalligtStorageKey = "tillstandsTypTillfalligt";
const tillstandsTypUpplagshavareStorageKey = "tillstandsTypUpplagshavare";
const tillstandsTypSkattebefriadForbrukareStorageKey = "tillstandsTypSkattebefriadForbrukare";
const tillstandsTypRegistreradVarumottagareStorageKey = "tillstandsTypRegistreradVarumottagare";
const rapporttyplistStorageKey = "rapporttypListStorageKey";
const serveringsUtrymmeTyperStorageKey = "serveringsutrymmetyper";
const orsakTillAvslutStorageKey = "orsakTillAvslut";
const serveringsAvslutStorageKey = "serveringsAvslut";
const tobakAvslutStorageKey = "tobakAvslut";
const partiAvslutStorageKey = "partiAvslut";
const okandAvslutStorageKey = "okandAvslut";
const rapportTypStorageKey = "rapportTyp";
const rapportPeriodStorageKey = "rapportPeriod";
const rapportStatusStorageKey = "rapportStatus";

function CheckLocalStorage<T>(key: string): T {
  let x: string = localStorage.getItem(key) ?? null!;
  if (x !== null) {
    const o = tryParseJson(x);
    if (o) {
      return o;
    }
    return x as typeof o;
  }

  return null!;
}

export const getDeployment = async (): Promise<IMainBaseResponse<string>> => {
  return await Get<string>(`${infoBase}/deployment`);
};

export function getDeploymentInfo(): IDeployment {
  let x: string = CheckLocalStorage<string>(deploymentStorageKey);
  if (x !== null) return { value: x };

  getDeployment()
    .then((res) => {
      localStorage.setItem(deploymentStorageKey, res.object);
      x = res.object;
    })
    .catch((error) => {
      console.log(error);
      return null!;
    });

  return {
    value: x,
  };
}

export const getLanLista = async (url:lanList): Promise<IMainBaseResponse<ILan[]>> => {
  switch(url){
    case(lanList.all):
      return await Get<ILan[]>(`${infoBase}/lanlist`);
    case(lanList.userSpecific):
      return await Get<ILan[]>(`${infoBase}/lanlistforuser`)
  }
};


export const getRapporttypLista = async(): Promise<IMainBaseResponse<IRapporttypList[]>> => {
  return await Get<IRapporttypList[]>(`rapport/rapporttyp`)
}

export const getRapporttypListaInfo = async(): Promise<IRapporttypList[]> => {
  let rapporttyp = CheckLocalStorage<IRapporttypList[]>(rapporttyplistStorageKey);
  if(rapporttyp != null)return rapporttyp;

  rapporttyp = (await getRapporttypLista()).object;
  localStorage.setItem(rapporttyplistStorageKey,JSON.stringify(rapporttyp));
  return rapporttyp;
}

export enum kommunList {
  all = "kommunlist",
  userSpecific = "kommunlistforuser",
}

export enum lanList  {
  all = "lanlist",
  userSpecific = "lanlistforuser"
}

export const getKommunList = async (
  url: kommunList
): Promise<IMainBaseResponse<IKommunList[]>> => {
  return await Get<IKommunList[]>(`${infoBase}/${url}`);
};


export const getLanListInfo = async (url:lanList): Promise<ILan[]> => {
  switch(url){
    case lanList.all:{
      let lan = CheckLocalStorage<ILan[]>(lanlistStorageKey);
      if (lan !== null) return lan;
    }
    case lanList.userSpecific:{
      let lan = CheckLocalStorage<ILan[]>(lanlistUserStorageKey);
      if (lan !== null) return lan;
    }
  }

  let lan = (await getLanLista(url)).object;

  switch(url) {
    case lanList.all:{
      localStorage.setItem(lanlistStorageKey, JSON.stringify(lan));
      break;
    }
    case lanList.userSpecific:{
      localStorage.setItem(lanlistUserStorageKey, JSON.stringify(lan));
      break;
    }
  }
  return lan;
};


export const getKommunListInfo = async (
  url: kommunList
): Promise<IKommunList[]> => {
  switch (url) {
    case kommunList.all: {
      let temp = CheckLocalStorage<IKommunList[]>(kommunlistStorageKey);
      if (temp !== null) return temp;
    }
    case kommunList.userSpecific: {
      let temp = CheckLocalStorage<IKommunList[]>(kommunlistUserStorageKey);
      if (temp !== null) return temp;
    }
  }

  let kommuner = (await getKommunList(url)).object;
  switch (url) {
    case kommunList.all:
      localStorage.setItem(kommunlistStorageKey, JSON.stringify(kommuner));
      break;
    case kommunList.userSpecific:
      localStorage.setItem(kommunlistUserStorageKey, JSON.stringify(kommuner));
      break;
  }
  return kommuner;
};

export const getTillstandsKategorier = async (type: string | null): Promise<IMainBaseResponse<ITillstandsKategorier[]>> => {
  return await Get<ITillstandsKategorier[]>(`${infoBase}/tillstandskategorier/${type}`);
};

export const getTillstandsKategorierData = async (type: string | null): Promise<ITillstandsKategorier[]> => {
  let temp;
  switch (type) {
    case "StadigvarandeServeringstillstand":
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypStadigvarandeStorageKey);
      if (temp !== null) return temp;
      break;
    case "TillfalligtServeringstillstand":
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypTillfalligtStorageKey);
      if (temp !== null) return temp;
      break;
    case "Upplagshavaretillstand":
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypUpplagshavareStorageKey);
      if (temp !== null) return temp;
      break;
    case "SkattebefriadForbrukaretillstand":
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypSkattebefriadForbrukareStorageKey);
      if (temp !== null) return temp;
      break;
    case "RegistreradVarumottagaretillstand":
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypRegistreradVarumottagareStorageKey);
      if (temp !== null) return temp;
      break;
    default:
      temp = CheckLocalStorage<ITillstandsKategorier[]>(tillstandsTypAllaStorageKey);
      if (temp !== null) return temp;
      break;
  }

  let data = (await getTillstandsKategorier(type)).object;

  switch (type) {
    case "StadigvarandeServeringstillstand":
      localStorage.setItem(tillstandsTypStadigvarandeStorageKey, JSON.stringify(data));
      break;
    case "TillfalligtServeringstillstand":
      localStorage.setItem(tillstandsTypTillfalligtStorageKey, JSON.stringify(data));
      break;
    case "SkattebefriadForbrukare":
      localStorage.setItem(tillstandsTypSkattebefriadForbrukareStorageKey, JSON.stringify(data));
      break;
    case "RegistreradVarumottagare":
      localStorage.setItem(tillstandsTypRegistreradVarumottagareStorageKey, JSON.stringify(data));
      break;
    case "Upplagshavare":
      localStorage.setItem(tillstandsTypUpplagshavareStorageKey, JSON.stringify(data));
      break;
    default:
      localStorage.setItem(tillstandsTypAllaStorageKey, JSON.stringify(data));
      break;
  }

  return data;
}

export const getServeringsUtrymmeTyper = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/serveringsutrymmetyper`);
}

export const getServeringsUtrymmeTyperData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(serveringsUtrymmeTyperStorageKey);
  if (data !== null) return data;

  data = (await getServeringsUtrymmeTyper()).object;
  localStorage.setItem(serveringsUtrymmeTyperStorageKey, JSON.stringify(data));

  return data;
}

export const getOrsakTillAvslut = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/avslutsorsaker`);
}

export const getOrsakTillAvslutData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(orsakTillAvslutStorageKey);
  if (data !== null) return data;

  data = (await getOrsakTillAvslut()).object;
  localStorage.setItem(orsakTillAvslutStorageKey, JSON.stringify(data));

  return data;
}

export const getServeringsAvslut = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/statuslist?typer=2`);
}

export const getServeringsAvslutData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(serveringsAvslutStorageKey);
  if (data !== null) return data;

  data = (await getServeringsAvslut()).object;
  localStorage.setItem(serveringsAvslutStorageKey, JSON.stringify(data));

  return data;
}

export const getTobakAvslut = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/statuslist?typer=6`);
}

export const getTobakAvslutData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(tobakAvslutStorageKey);
  if (data !== null) return data;

  data = (await getTobakAvslut()).object;
  localStorage.setItem(tobakAvslutStorageKey, JSON.stringify(data));

  return data;
}

export const getPartiAvslut = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/statuslist?typer=5`);
}

export const getPartiAvslutData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(partiAvslutStorageKey);
  if (data !== null) return data;

  data = (await getPartiAvslut()).object;
  localStorage.setItem(partiAvslutStorageKey, JSON.stringify(data));

  return data;
}

export const getOkandAvslut = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${infoBase}/statuslist?typer=100`);
}

export const getOkandAvslutData = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(okandAvslutStorageKey);
  if (data !== null) return data;

  data = (await getOkandAvslut()).object;
  localStorage.setItem(okandAvslutStorageKey, JSON.stringify(data));

  return data;
}

const getRapportTypData = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${reportBase}/rapporttyp`)
}

export const getRapportTyp = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(rapportTypStorageKey);
  if (data !== null) return data;

  data = (await getRapportTypData()).object;
  localStorage.setItem(rapportTypStorageKey, JSON.stringify(data));

  return data;
}

const getRapportPeriodData = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${reportBase}/rapportperiod`)
}

export const getRapportPeriod = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(rapportPeriodStorageKey);
  if (data !== null) return data;

  data = (await getRapportPeriodData()).object;
  localStorage.setItem(rapportPeriodStorageKey, JSON.stringify(data));

  return data;
}

const getRapportStatusData = async (): Promise<IMainBaseResponse<IInfo[]>> => {
  return await Get<IInfo[]>(`${reportBase}/rapportstatus`)
}

export const getRapportStatus = async (): Promise<IInfo[]> => {
  let data = CheckLocalStorage<IInfo[]>(rapportStatusStorageKey);
  if (data !== null) return data;

  data = (await getRapportStatusData()).object;
  localStorage.setItem(rapportStatusStorageKey, JSON.stringify(data));

  return data;
}
