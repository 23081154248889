import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { BtnDefault } from "../../Components/buttons";
import { SelectSingle } from "../../Components/selects";
import { ActionType, useModal } from "../../Utilities/modalProvider";
import {
  createPeriod,
  getRapportPeriod,
  IRapportPeriodGet,
} from "../../Services/reportService";

export default function CreatePeriod() {
  const modal = useModal();
  const [rapportPerioder, setRapportPerioder] = React.useState<
    IRapportPeriodGet[]
  >(null!);
  const [loading, setLoading] = React.useState(true);

  const transformPeriod = (
    perioder: IRapportPeriodGet[]
  ): IRapportPeriodGet[] => {
    let years: IRapportPeriodGet[] = [];
    for (let i = -2; i <= 5; i++) {
      let year = moment().add(i, "y").year();
      if (perioder.findIndex((y) => y.namn === year.toString()) === -1) {
        years.push({
          id: year,
          namn: year.toString(),
          groupId: 0,
          groupName: "",
        });
      }
    }
    return years;
  };

  useEffect(() => {
    //Gets the report periods that we can't create.
    getRapportPeriod().then((res) => {
      let years = transformPeriod(res.object);
      setRapportPerioder([...years]);
    });
  }, []);

  useEffect(() => {
    if (rapportPerioder !== null && rapportPerioder.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [rapportPerioder]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Skapa rapportperiod
        </Typography>
        <Typography>
          Varje period gäller helårsvis 01-01 - 12-31 för valt år
        </Typography>
      </Grid>
      {!loading ? (
        <Grid item xs={12}>
          <Formik
            initialValues={{ rapportPeriod: rapportPerioder[0].id }}
            onSubmit={(values, { setStatus, setSubmitting, setValues }) => {
              setSubmitting(false);
              modal.showDialog({
                open: true,
                actions: [
                  {
                    action: () => {
                      setSubmitting(true);
                      createPeriod(values.rapportPeriod)
                        .then((res) => {
                          setStatus({
                            sent: true,
                            msg: "Den valda perioden skapades.",
                            title: "Period skapad",
                            severity: "success",
                          });                    
                          getRapportPeriod().then((res) => {
                            let years = transformPeriod(res.object);
                            setValues({ rapportPeriod: years[0].id });
                            setRapportPerioder([...years]);
                          });
                          setSubmitting(false);
                        })
                        .catch((error) => {
                          setStatus({
                            sent: true,
                            msg: `Det gick inte att skapa en ny period: ${error.data}`,
                            title: "Ett fel uppstod",
                            severity: "error",
                          });
                          setSubmitting(false);
                        });
                    },
                    btn: ActionType.default,
                    text: "Ok",
                  },
                  {
                    text: "Avbryt",
                    btn: ActionType.second
                  },
                ],
                title: "Bekräfta",
                message: "Är du säker på att en ny rapportperiod ska skapas?",

              });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isSubmitting,
              status,
              setStatus,
            }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        {!isSubmitting && (
                          <SelectSingle
                            fullWidth
                            data={rapportPerioder.map((r) => ({
                              key: r.id,
                              label: r.namn,
                            }))}
                            selected={values.rapportPeriod}
                            onChange={handleChange}
                            name="rapportPeriod"
                            label="Rapportperiod"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container mt={2}>
                      <Grid item xs={3}>
                        <BtnDefault
                          disabled={isSubmitting}
                          type="submit">
                          Skapa Period
                        </BtnDefault>
                      </Grid>
                    </Grid>
                  </Grid>
                  {ShowAlertEval(status) && (
                    <Grid item xl={4} md={6}>
                      <ShowAlert
                        onClose={() => {
                          setStatus(null);
                        }}
                        severity={status.severity}
                        alertTitle={status.title}
                        alertText={status.msg}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CircularProgress/>
        </Grid>
      )}
    </Grid>
  );
}
