import {
  FormControl,
  Grid,
  Typography,
  Box,
  Stack,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShowAlert } from "../../Components/alerts";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { InfoType } from "../../Components/selects";
import {
  ActionIcons,
  checkMenuItem,
  DefaultTable,
  getMenuItems,
  ISortOptions,
  ITableHead,
  mapToCellData,
} from "../../Components/tables";
import InputTextField from "../../Components/textfield";
import { IMainBaseResponse } from "../../Services/base";
import {
  exportexcel,
  ITillstandSearchFilter,
  ITillstandSearchResult,
  searchPermits,
} from "../../Services/PermitService";
import {
  ISearchBase,
  ISearchResultBase,
  SortOrder,
} from "../../Services/Searchbase";
import { useAuth } from "../../Utilities/authProvider";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { Roles } from "../../Utilities/roles";
import { useFilter } from "../../Utilities/searchFilterProvider";
import { GetPermitTypes } from "./PermitsFormsUtils/permitsUtils";
import { useLocation } from 'react-router-dom';

export default function Permits() {
  //Usings
  const filterProvider = useFilter();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>(null);
  const [tillstand, setTillstand] = React.useState<
    IMainBaseResponse<ISearchResultBase<ITillstandSearchResult>>
  >(null!);
  const [sortOptions, setSortOptions] = React.useState<ISortOptions>({
    id: "orgNr",
    direction: SortOrder.Fallande,
  });
  const [searchPermitTypes, setSearchPermitTypes] = React.useState<InfoType>(
    InfoType.sokTillstandsTyper
  );
  const [lan, setLan] = React.useState<InfoType>(
    InfoType.lan
  );
  const [kommuner, setKommuner] = React.useState<InfoType>(
    InfoType.kommuner
  );

  const auth = useAuth();

  const getBaseFilter = (): ISearchBase<ITillstandSearchFilter> => {
    return {
      filter: {
        orgNr: "",
        tillstandstypId: 0,
        tillstandsGivareId: 0,
        havareNamn: "",
        havareAdress: "",
        havarePostOrt: "",
        objektNr: "",
        objektNamn: "",
        objektAdress: "",
        objektPostOrt: "",
        lanId: 0,
        kommunId: 0,
        inkluderaFramtida: false,
        inkluderaAvslutade: false,
        inkluderaAldreAvslutade: false,
      },
      sidoNummer: 1,
      sidoStorlek: getMenuItems[0].value,
      sorteringsNamn: "orgNr",
      sorteringsOrdning: SortOrder.Fallande,
    };
  };

  const checkLocation = () => {
    const filter = filterProvider.getFilter("permits");
    const key = filterProvider.getKey();
    if (filter !== null && filter !== undefined) {
            return filter;
    } else return getBaseFilter();
  };

  const [filter, setFilter] = React.useState<ISearchBase<ITillstandSearchFilter>>(checkLocation());

  useEffect(() => {
    searchPermits(filter)
      .then((data) => {
        setTillstand({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setTillstand(null!);
      });
    if (auth.validateUserRoleAccess([Roles.FohmLasAlkohol])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperAlkohol);
    } else if (auth.validateUserRoleAccess([Roles.FohmLasTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperTobak);
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol]) && auth.validateUserRoleAccess([Roles.KommunAdminTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAdmin);
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminAlkohol])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAlkohol);
    } else if (auth.validateUserRoleAccess([Roles.KommunAdminTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunTobak);
    } else if (auth.validateUserRoleAccess([Roles.KommunLasAlkohol]) && auth.validateUserRoleAccess([Roles.KommunLasTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAdmin);
    } else if (auth.validateUserRoleAccess([Roles.KommunLasAlkohol])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunAlkohol);
    } else if (auth.validateUserRoleAccess([Roles.KommunLasTobak])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperKommunTobak);
    } else if (auth.validateUserRoleAccess([Roles.LansstyrelseLasAlkohol])) {
      setSearchPermitTypes(InfoType.sokTillstandsTyperLanstyrelseAlkoholTobak);
      setLan(InfoType.lanAnvandare);
      setKommuner(InfoType.kommunerAnvandare);
    } else {
      setSearchPermitTypes(InfoType.sokTillstandsTyper);
    }
  }, []);

  useEffect(() => {
    searchPermits(filter)
      .then((data) => {
        setTillstand({ ...data });
      })
      .catch((error) => {
        setErrors(error);
        setTillstand(null!);
      });
  }, [filter]);

  const headers: ITableHead<ITillstandSearchResult>[] = [
    {
      id: "orgNr",
      sortable: true,
      value: "Organisationsnummer",
    },
    {
      id: "tillstandsHavareNamn",
      sortable: true,
      value: "Tillståndshavarens namn",
    },
    {
      id: "objektNr",
      sortable: true,
      value: "ObjektId / Restaurangnummer",
    },
    {
      id: "tillstandsObjektNamn",
      sortable: true,
      value: "Objektets namn",
    },
    {
      id: "tillstandsObjektAdress",
      sortable: true,
      value: "Adress",
    },
    {
      id: "tillstandsObjektPostOrt",
      sortable: true,
      value: "Postort",
    },
    {
      id: "typNamn",
      sortable: true,
      value: "Tillståndstyp",
    },
    {
      id: "statusNamn",
      sortable: true,
      value: "Status",
    },
  ];

  const navigateToTillstand = (id: number) => {
    filterProvider.addFilter(filter, "permits");
    navigate(`${id}`);
  };

  const SubmitSearch = (values: ITillstandSearchFilter) => {
    //Special we need to map the current rolesID added in the form to the correct match in the mapper table get the roles that needs to be passed.
    setFilter({ ...filter, filter: { ...values } });
  };

  const SubmitExport = (
    values: ITillstandSearchFilter,
  ) => {
    setLoading(true);
    exportexcel(values).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.object], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Tillstand.xlsx");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    }); 
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Sök tillstånd
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Formik
          enableReinitialize
          initialValues={{ ...filter.filter }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            SubmitSearch(values);
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            values,
            handleChange,
            isSubmitting,
            resetForm
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={searchPermitTypes}
                          label="Tillståndstyp"
                          multiple={false}
                          defaultVal={false}
                          onChange={(data) => {
                            setFieldValue("tillstandstypId", data);
                          }}
                          error={evalErrors(
                            errors,
                            touched ?? {},
                            "tillstandstypId"
                          )}
                          helperText={getErrorText(
                            errors,
                            touched ?? {},
                            "tillstandstypId"
                          )}
                          name="tillstandstypId"
                          selectedData={values.tillstandstypId}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={lan}
                          label="Län"
                          multiple={false}
                          defaultVal={false}
                          onChange={(data) => {
                            setFieldValue("lanId", data);
                          }}
                          error={evalErrors(errors, touched ?? {}, "lanId")}
                          helperText={getErrorText(
                            errors,
                            touched ?? {},
                            "lanId"
                          )}
                          name="lanId"
                          selectedData={values.lanId}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <AutoCompleteAtr
                          onBlur={handleBlur}
                          infoType={kommuner}
                          label="Kommun"
                          multiple={false}
                          defaultVal={false}
                          onChange={(data) => {
                            setFieldValue("kommunId", data);
                          }}
                          error={evalErrors(errors, touched ?? {}, "kommunId")}
                          helperText={getErrorText(
                            errors,
                            touched ?? {},
                            "kommunId"
                          )}
                          name="kommunId"
                          selectedData={values.kommunId}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="orgNr"
                        onChange={handleChange}
                        label="Organisationsnummer"
                        value={values.orgNr}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="havareNamn"
                        onChange={handleChange}
                        label="Tillståndshavarens namn"
                        value={values.havareNamn}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="havareAdress"
                        label="Tillståndshavarens adress"
                        onChange={handleChange}
                        value={values.havareAdress}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="havarePostOrt"
                        label="Tillståndshavarens postort"
                        onChange={handleChange}
                        value={values.havarePostOrt}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="objektNr"
                        label="ObjektId/Restaurangnummer"
                        onChange={handleChange}
                        value={values.objektNr}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="objektNamn"
                        onChange={handleChange}
                        label="Objektets namn"
                        value={values.objektNamn}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="objektAdress"
                        onChange={handleChange}
                        label="Objektets adress"
                        value={values.objektAdress}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputTextField
                        fullWidth
                        name="objektPostOrt"
                        label="Objektets postort"
                        onChange={handleChange}
                        value={values.objektPostOrt}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack mb={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{ "aria-label": "controlled" }}
                            name="inkluderaFramtida"
                            onChange={(e, checked) =>
                              setFieldValue("inkluderaFramtida", checked)
                            }
                            value={values.inkluderaFramtida}
                            checked={values.inkluderaFramtida}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Inkludera framtida
                          </Typography>
                        }
                      />
                    </Stack>
                    <Stack mb={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{ "aria-label": "controlled" }}
                            name="inkluderaAvslutade"
                            onChange={(e, checked) =>
                              setFieldValue("inkluderaAvslutade", checked)
                            }
                            value={values.inkluderaAvslutade}
                            checked={values.inkluderaAvslutade}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Inkludera avslutade
                          </Typography>
                        }
                      />
                    </Stack>
                    <Stack mb={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{ "aria-label": "controlled" }}
                            name="inkluderaAldreAvslutade"
                            onChange={(e, checked) =>
                              setFieldValue("inkluderaAldreAvslutade", checked)
                            }
                            value={values.inkluderaAldreAvslutade}
                            checked={values.inkluderaAldreAvslutade}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Inkludera äldre avslutade
                          </Typography>
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <BtnDefault disabled={isSubmitting} type="submit">
                    Sök
                  </BtnDefault>
                  <BtnSecond
                    type="reset"
                    onClick={() => {
                      setFilter({ ...getBaseFilter() });
                      resetForm();
                    }}
                  >
                    Rensa
                  </BtnSecond>
                  {(
                    <BtnSecond
                      startIcon={
                        loading ? (
                          <CircularProgress color="primary" size={"1em"} />
                        ) : (
                          <div></div>
                        )
                      }
                      disabled={loading}
                      onClick={() => {
                        SubmitExport(values);
                      }}
                      type="button"
                    >
                      Exportera resultat
                    </BtnSecond>
                  )}
                </Stack>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      {errors && (
        <Grid item xl={4}>
          <ShowAlert
            onClose={() => setErrors(null)}
            alertText={errors.data}
            alertTitle={"Ett fel uppstod"}
            severity="error"
          />
        </Grid>
      )}
      {tillstand && (
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Sökresultat
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {tillstand && tillstand.object.sokResultat.length <= 0 ? (
          <Typography variant="body1" component="p" paragraph>
            Hittade {tillstand.object.sokResultat.length} tillstånd.
          </Typography>
        ) : (
          tillstand &&
          tillstand.object.sokResultat.length > 0 && (
            <DefaultTable
              header={headers}
              activePage={filter.sidoNummer}
              data={mapToCellData(headers, tillstand.object.sokResultat)}
              rowsPerPage={checkMenuItem(filter.sidoStorlek)}
              totalHits={tillstand.object.totaltAntalTraffar}
              sortOptions={sortOptions}
              actions={[
                {
                  action: navigateToTillstand,
                  icon: ActionIcons.arrowForward,
                  title: "Visa mer",
                },
              ]}
              filter={filter}
              setFilterAction={setFilter}
              setSortOptions={setSortOptions}
              tableAria="Tillstånd"
            />
          )
        )}
      </Grid>
    </Grid>
  );
}
