import * as Yup from "yup";
import { onlyNumbers } from "../../../Utilities/common";
import { noNumbers } from "./s_validation";

const min = "Lägsta tillåtna värde är 0";
const heltal = "Endast heltal är tillåtna";

const numbervalidation = () => {
  return Yup.number().nullable().min(0, min);
};

const sumCalc = (total: number, value: number) => {
  if (value !== undefined && value !== null && value >= 0) {
    return (total += value);
  }
  return total;
};

interface IValObj {
  field: string;
  text: string;
}

const normalVolym = (val: IValObj) => {
  return Yup.number()
    .nullable().integer(heltal)
    .when(val.field, {
      is: (x: number) => {
        //Om värdet för field är ifyllt så ska vi visa texten.
        if (x > 0) return true;
        return false;
      },
      then: Yup.number().nullable().min(0, min).required(val.text),
      otherwise: numbervalidation(),
    });
};

export const permitRestaurangrapportSchema = Yup.object().shape(
  {
    //Normalpriser
    normalPrisSprit: Yup.number()
      .nullable()
      .min(0, min).integer(heltal)
      .test(
        "c",
        "Normalpris saknas trots att omsättning är angiven",
        (val, context) => {
          const { omsSprit } = context.parent;

          if (
            (val === null || val === undefined) &&
            omsSprit !== null &&
            omsSprit !== undefined &&
            omsSprit > 0
          ) {
            return false;
          }
          return true;
        }
      ),
    normalPrisVin: Yup.number()
      .nullable()
      .min(0, min).integer(heltal)
      .test(
        "c",
        "Normalpris saknas trots att omsättning är angiven",
        (val, context) => {
          const { omsVin } = context.parent;

          if (
            (val === null || val === undefined) &&
            omsVin !== null &&
            omsVin !== undefined &&
            omsVin > 0
          ) {
            return false;
          }
          return true;
        }
      ),
    normalPrisAndraJastaAlkoholdrycker: normalVolym({
      field: "normalVolymAndraJastaAlkoholdrycker",
      text: "Volym är angivet, ange kronor",
    }).integer(heltal).test(
      "c",
      "Normalpris saknas trots att omsättning är angiven",
      (val, context) => {
        const { omsAndraJastaAlkoholdrycker } = context.parent;
        if (
          (val === null || val === undefined) &&
          omsAndraJastaAlkoholdrycker !== null &&
          omsAndraJastaAlkoholdrycker !== undefined &&
          omsAndraJastaAlkoholdrycker > 0
        ) {
          return false;
        }
        return true;
      }
    ),
    normalPrisStarkOl: normalVolym({
      field: "normalVolymStarkOl",
      text: "Volym är angivet, ange kronor",
    }).integer(heltal).test(
      "c",
      "Normalpris saknas trots att omsättning är angiven",
      (val, context) => {
        const { omsStarkOl } = context.parent;
        if (
          (val === null || val === undefined) &&
          omsStarkOl !== null &&
          omsStarkOl !== undefined &&
          omsStarkOl > 0
        ) {
          return false;
        }
        return true;
      }
    ),
    normalPrisOl: normalVolym({
      field: "normalVolymOl",
      text: "Volym är angivet, ange kronor",
    }).integer(heltal).test(
      "c",
      "Normalpris saknas trots att omsättning är angiven",
      (val, context) => {
        const { omsOl } = context.parent;

        if (
          (val === null || val === undefined) &&
          omsOl !== null &&
          omsOl !== undefined &&
          omsOl > 0
        ) {
          return false;
        }
        return true;
      }
    ),
    normalPrisAlkoPrep: normalVolym({
      field: "normalVolymAlkoPrep",
      text: "Volym är angivet, ange kronor",
    }).integer(heltal).test(
      "c",
      "Normalpris saknas trots att omsättning är angiven",
      (val, context) => {
        const { omsAlkoPrep } = context.parent;

        if (
          (val === null || val === undefined) &&
          omsAlkoPrep !== null &&
          omsAlkoPrep !== undefined &&
          omsAlkoPrep > 0
        ) {
          return false;
        }
        return true;
      }
    ),
    //NormalVolym Special Validering Beroenden
    normalVolymStarkOl: normalVolym({
      field: "normalPrisStarkOl",
      text: "Normalpris är angivet, ange volym",
    }).integer(heltal),
    normalVolymOl: normalVolym({
      field: "normalPrisOl",
      text: "Normalpris är angivet, ange volym",
    }).integer(heltal),
    normalVolymAndraJastaAlkoholdrycker: normalVolym({
      field: "normalPrisAndraJastaAlkoholdrycker",
      text: "Normalpris är angivet, ange volym",
    }).integer(heltal),
    normalVolymAlkoPrep: normalVolym({
      field: "normalPrisAlkoPrep",
      text: "Normalpris är angivet, ange volym",
    }).integer(heltal),
    //Egenkryddad Snaps
    antalLiterEgenKryddadSnaps: Yup.number().nullable().integer(heltal).min(0, min),
    //Omsättning
    omsMat: Yup.number().nullable().integer(heltal).min(0, min),
    omsOvrigt: Yup.number().nullable().integer(heltal).min(0, min),
    omsSprit: Yup.number().nullable().integer(heltal)
      .min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisSprit } = context.parent;
        if (
          normalPrisSprit !== null &&
          normalPrisSprit !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsStarkOl: Yup.number().nullable().integer(heltal)
      .min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisStarkOl } = context.parent;
        if (
          normalPrisStarkOl !== null &&
          normalPrisStarkOl !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsVin: Yup.number().nullable().integer(heltal)
      .min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisVin } = context.parent;
        if (
          normalPrisVin !== null &&
          normalPrisVin !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsOl: Yup.number().nullable().integer(heltal)
      .min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisOl } = context.parent;
        if (
          normalPrisOl !== null &&
          normalPrisOl !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsAndraJastaAlkoholdrycker: Yup.number().nullable().integer(heltal)
      .min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisAndraJastaAlkoholdrycker } = context.parent;
        if (
          normalPrisAndraJastaAlkoholdrycker !== null &&
          normalPrisAndraJastaAlkoholdrycker !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsAlkoPrep: Yup.number().nullable()
      .integer(heltal).min(0, min)
      .test("z", "Normalpris är angivet, ange omsättning", (val, context) => {
        const { normalPrisAlkoPrep } = context.parent;
        if (
          normalPrisAlkoPrep !== null &&
          normalPrisAlkoPrep !== undefined &&
          (val === null || val === undefined)
        ) {
          return false;
        }
        return true;
      }),
    omsTotalt: Yup.number()
      .nullable().integer(heltal)
      .min(0, min)
      .test(
        "omsTotalt1",
        "Om det inte har funnits någon omsättning måste du skriva '0' i fältet för total omsättning",
        (val, context) => {
          const {
            omsMat,
            omsSprit,
            omsVin,
            omsStarkOl,
            omsOl,
            omsAndraJastaAlkoholdrycker,
            omsAlkoPrep,
            omsOvrigt,
          } = context.parent;

          if (
            [
              omsMat,
              omsSprit,
              omsVin,
              omsStarkOl,
              omsOl,
              omsAndraJastaAlkoholdrycker,
              omsAlkoPrep,
              omsOvrigt,
            ].every((p) => p === undefined || p === null) &&
            val !== 0
          ) {
            console.log("false 229");
            return false;
          }
          return true;
        }
      )
      .test(
        "omsTotalt",
        "Summa omsättning är inte lika stor som summan av de enskillda omsättningsuppgifterna",
        (val, context) => {
          const {
            omsMat,
            omsSprit,
            omsVin,
            omsStarkOl,
            omsOl,
            omsAndraJastaAlkoholdrycker,
            omsAlkoPrep,
            omsOvrigt,
          } = context.parent;
          if (
            [
              omsMat,
              omsSprit,
              omsVin,
              omsStarkOl,
              omsOl,
              omsAndraJastaAlkoholdrycker,
              omsAlkoPrep,
              omsOvrigt,
            ].every((p) => p === undefined || p === null) &&
            val !== 0
          ) {
            return false;
          }

          let sum = 0;

          sum = sumCalc(sum, omsMat);
          sum = sumCalc(sum, omsSprit);
          sum = sumCalc(sum, omsVin);
          sum = sumCalc(sum, omsStarkOl);
          sum = sumCalc(sum, omsOl);
          sum = sumCalc(sum, omsAndraJastaAlkoholdrycker);
          sum = sumCalc(sum, omsAlkoPrep);
          sum = sumCalc(sum, omsOvrigt);

          if (
            ![
              omsMat,
              omsSprit,
              omsVin,
              omsStarkOl,
              omsOl,
              omsAndraJastaAlkoholdrycker,
              omsAlkoPrep,
              omsOvrigt,
            ].every((p) => p === undefined || p === null) &&
            sum !== val
          ) {
            return false;
          }
          return true;
        }
      ),

    //Inköpta Volymer
    inkopSpritLag: Yup.number().nullable().integer(heltal).min(0, min),
    inkopSpritHog: Yup.number().nullable().integer(heltal).min(0, min),
    inkopVinLag: Yup.number().nullable().integer(heltal).min(0, min),
    inkopVinMellan: Yup.number().nullable().integer(heltal).min(0, min),
    inkopVinHog: Yup.number().nullable().integer(heltal).min(0, min),
    inkopAndraJastaAlkoholdryckerLag: Yup.number().nullable().integer(heltal).min(0, min),
    inkopAndraJastaAlkoholdryckerMellan: Yup.number().nullable().integer(heltal).min(0, min),
    inkopAndraJastaAlkoholdryckerHog: Yup.number().nullable().integer(heltal).min(0, min),
    inkopOlLag: Yup.number().nullable().integer(heltal).min(0, min),
    inkopOlHog: Yup.number().nullable().integer(heltal).min(0, min),
    inkopAlkoPrepLag: Yup.number().nullable().integer(heltal).min(0, min),
    inkopAlkoPrepHog: Yup.number().nullable().integer(heltal).min(0, min),
    inkopTotalt: Yup.number()
      .nullable().integer(heltal)
      .min(0, min)
      .test(
        "z",
        "Summa inköpta volymer är inte lika stor som summan av de enskilda volymuppgifterna.",
        (val, context) => {
          const {
            inkopSpritHog,
            inkopSpritLag,
            inkopOlLag,
            inkopOlHog,
            inkopVinLag,
            inkopVinMellan,
            inkopVinHog,
            inkopAndraJastaAlkoholdryckerLag,
            inkopAndraJastaAlkoholdryckerMellan,
            inkopAndraJastaAlkoholdryckerHog,
            inkopAlkoPrepLag,
            inkopAlkoPrepHog,
          } = context.parent;

          let sum = 0;
          sum = sumCalc(sum, inkopSpritHog);
          sum = sumCalc(sum, inkopSpritLag);
          sum = sumCalc(sum, inkopOlLag);
          sum = sumCalc(sum, inkopOlHog);
          sum = sumCalc(sum, inkopVinLag);
          sum = sumCalc(sum, inkopVinMellan);
          sum = sumCalc(sum, inkopVinHog);
          sum = sumCalc(sum, inkopAndraJastaAlkoholdryckerLag);
          sum = sumCalc(sum, inkopAndraJastaAlkoholdryckerMellan);
          sum = sumCalc(sum, inkopAndraJastaAlkoholdryckerHog);
          sum = sumCalc(sum, inkopAlkoPrepLag);
          sum = sumCalc(sum, inkopAlkoPrepHog);

          if (
            ![
              inkopSpritHog,
              inkopSpritLag,
              inkopOlLag,
              inkopOlHog,
              inkopVinLag,
              inkopVinMellan,
              inkopVinHog,
              inkopAndraJastaAlkoholdryckerLag,
              inkopAndraJastaAlkoholdryckerMellan,
              inkopAndraJastaAlkoholdryckerHog,
              inkopAlkoPrepLag,
              inkopAlkoPrepHog,
            ].every((p) => p === undefined || p === null) &&
            sum !== val
          ) {
            return false;
          }
          return true;
        }
      ),
    //Godkänna
    uppgiftslamnare: Yup.string()
      .nullable()
      .required("Ange namn på uppgiftslämnare")
      .matches(noNumbers,"Inga siffror är tillåtna"),
    kontaktPersonNamn: Yup.string()
      .nullable()
      .required("Ange en kontaktperson")
      .matches(noNumbers,"Inga siffror är tillåtna"),
    kontaktPersonTelefon: Yup.string()
      .nullable()
      .matches(onlyNumbers, "Endast siffror är tillåtet")
      .when(["kontaktPersonEpost"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Ange ett telefonnr och/eller en e-postadress")
          .matches(onlyNumbers, "Endast siffror är tillåtet"),
      }),
    kontaktPersonEpost: Yup.string()
      .nullable()
      .email("Ange en korrekt e-postadress")
      .when(["kontaktPersonTelefon"], {
        is: (t: string) => {
          if (t === undefined || t === "" || t === null) return true;

          return false;
        },
        then: Yup.string()
          .nullable()
          .required("Ange ett telefonnr och/eller en e-postadress")
          .email("Ange en korrekt e-postadress"),
      }),
  },

  [
    [
      "normalPrisAndraJastaAlkoholdrycker",
      "normalVolymAndraJastaAlkoholdrycker",
    ],
    ["normalPrisStarkOl", "normalVolymStarkOl"],
    ["normalPrisOl", "normalVolymOl"],
    ["normalPrisAlkoPrep", "normalVolymAlkoPrep"],
    ["omsSprit", "inkopSpritLag"],
    ["omsSprit", "inkopSpritHog"],
    ["omsStarkOl", "inkopOlHog"],
    ["omsStarkOl", "inkopOlLag"],
    ["omsVin", "inkopVinLag"],
    ["omsVin", "inkopVinMellan"],
    ["omsVin", "inkopVinHog"],
    ["omsAndraJastaAlkoholdrycker", "inkopAndraJastaAlkoholdryckerLag"],
    ["omsAndraJastaAlkoholdrycker", "inkopAndraJastaAlkoholdryckerMellan"],
    ["omsAndraJastaAlkoholdrycker", "inkopAndraJastaAlkoholdryckerHog"],
    ["omsAlkoPrep", "inkopAlkoPrepLag"],
    ["omsAlkoPrep", "inkopAlkoPrepHog"],
    ["kontaktPersonTelefon", "kontaktPersonEpost"],
  ]
);
