import { Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export default function Tillverkning(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: (number | undefined | null)[] = [
    props.values.spritTillverkningLag,
    props.values.spritTillverkningHog,
    props.values.vinTillverkningLag,
    props.values.vinTillverkningMellan,
    props.values.vinTillverkningHog,
    props.values.andraJastaTillverkningLag,
    props.values.andraJastaTillverkningMellan,
    props.values.andraJastaTillverkningHog,
    props.values.starkOlTillverkningLag,
    props.values.starkOlTillverkningHog,
    props.values.folkOlTillverkning,
    props.values.tillverkning,
  ];

  useEffect(() => {
    sumAllValues();
  }, []);
  

  const sumAllValues = (): void => {
    CalcSum(props, "tillverkningTotalt", values);
  };

  const test = () => {
    if(props.values.rapportTypId === 7)
    return "Tillverkning avser sådan sprit som är avsedd att användas som teknisk sprit.";

    else{
    return "De alkoholdrycker som under året har tappats för försäljning ska redovisas dvs. endast färdigtappad vara (se även anvisningen längst upp på sidan)."
    }
  };

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">{nr}. Tillverkning (hela liter)</Typography>
          <Typography variant="caption" >
            {test()}
          </Typography>
        </Grid>
        <>
        {props.values.rapportTypId == 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
              <GridWithCaption>
              {InputWithDefaults<IUpdateRapportDetails>(
              "TEKNISK SPRIT",
              "tillverkning",
              props.values.tillverkning,
              props,
              sumAllValues,
              undefined,
              mode,
              "Mängd uttryckt i liter ren alkohol",
              "number",
              ["tillverkning"]
              )}
              </GridWithCaption>
            </Section>
          </Grid>
        </Grid>
        )}
        </>
        <>
        {props.values.rapportTypId != 7 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Section>
            <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Spritdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 22,0 volymprocent",
                  "spritTillverkningLag",
                  props.values.spritTillverkningLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritTillverkningHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 22,0 volymprocent",
                  "spritTillverkningHog",
                  props.values.spritTillverkningHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["spritTillverkningLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 22,0 volymprocent",
                      prevYearObj?.spritTillverkningLag
                    )}
                    {InputComparePrevYear(
                      "Över 22,0 volymprocent",
                      prevYearObj?.spritTillverkningHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Vin</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "vinTillverkningLag",
                  props.values.vinTillverkningLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinTillverkningMellan", "vinTillverkningHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "vinTillverkningMellan",
                  props.values.vinTillverkningMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinTillverkningHog", "vinTillverkningLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "vinTillverkningHog",
                  props.values.vinTillverkningHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["vinTillverkningMellan", "vinTillverkningLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.vinTillverkningLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.vinTillverkningMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.vinTillverkningHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Andra jästa alkoholdrycker</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,25, högst 7,0 volymprocent",
                  "andraJastaTillverkningLag",
                  props.values.andraJastaTillverkningLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaTillverkningMellan", "andraJastaTillverkningHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 7,0, högst 15,0 volymprocent",
                  "andraJastaTillverkningMellan",
                  props.values.andraJastaTillverkningMellan,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaTillverkningHog", "andraJastaTillverkningLag"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 15,0, högst 22,0 volymprocent",
                  "andraJastaTillverkningHog",
                  props.values.andraJastaTillverkningHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["andraJastaTillverkningMellan", "andraJastaTillverkningLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,25, högst 7,0 volymprocent",
                      prevYearObj?.andraJastaTillverkningLag
                    )}
                    {InputComparePrevYear(
                      "Över 7,0, högst 15,0 volymprocent",
                      prevYearObj?.andraJastaTillverkningMellan
                    )}
                    {InputComparePrevYear(
                      "Över 15,0, högst 22,0 volymprocent",
                      prevYearObj?.andraJastaTillverkningHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Starköl</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 3,5, högst 5,6 volymprocent",
                  "starkOlTillverkningLag",
                  props.values.starkOlTillverkningLag,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlTillverkningHog"]
                )}
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 5,6 volymprocent",
                  "starkOlTillverkningHog",
                  props.values.starkOlTillverkningHog,
                  props,
                  sumAllValues,
                  undefined,
                  mode,
                  "Liter",
                  "number",
                  ["starkOlTillverkningLag"]
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 3,5, högst 5,6 volymprocent",
                      prevYearObj?.starkOlTillverkningLag
                    )}
                    {InputComparePrevYear(
                      "Över 5,6 volymprocent",
                      prevYearObj?.starkOlTillverkningHog
                    )}
                  </GridWithCaption>
                )}
              </>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Folköl</Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "Över 2,5, högst 3,5",
                  "folkOlTillverkning",
                  props.values.folkOlTillverkning,
                  props,
                  sumAllValues,
                  undefined,
                  mode
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear(
                      "Över 2,5, högst 3,5",
                      prevYearObj?.folkOlTillverkning
                    )}
                  </GridWithCaption>
                )}
              </>
            </Section>
            
            <Section>
              <Grid item xs={12}>
              <Grid item sx={{mt: "10px"}}>
                <Typography variant="h6" component="h3">Summa tillverkning</Typography>
              </Grid>
                <Typography variant="caption">
                  Ange 0 i summa om du inte
                  har någonting att redovisa.
                </Typography>
              </Grid>
              <GridWithCaption>
                {InputWithDefaults<IUpdateRapportDetails>(
                  "",
                  "tillverkningTotalt",
                  props.values.tillverkningTotalt,
                  props,
                  undefined,
                  undefined,
                  Mode.Disabled,
                )}
              </GridWithCaption>
              <>
                {prevYear && (
                  <GridWithCaption>
                    {InputComparePrevYear("", prevYearObj?.tillverkningTotalt)}
                  </GridWithCaption>
                )}
              </>
            </Section>
          </Grid>
        </Grid>
        )}
        </>
      </Grid>
    </Grid>
  );
}
