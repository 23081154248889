import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Box, CircularProgress } from "@mui/material";
import { IAlert, ShowAlert } from "../../Components/alerts";
import { getmostrecentyearofimport, uploadKommunbefolkningFile } from "../../Services/StatisticsService";

export default function PopulationStatistics() {
  const [statisticsFile, setStatisticsFile] = useState<File | null>(null);
  const [latestYearOfImport, setLatestYearOfImport] = useState<string>("");
  const [status, setStatus] = useState<IAlert>({
    show: false,
    alertText: "",
    severity: "success",
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    getmostrecentyearofimport()
    .then((response) => {
      setLatestYearOfImport(response.object)
    });
  });

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setStatisticsFile(file);
    }
  };

  const uploadFile = () => {
    setIsUploading(true);
    const formData = new FormData();
    if (statisticsFile) {
      formData.append("files", statisticsFile);
    } else {
      setStatus({
        alertText: "Du måste välja en fil att ladda upp.",
        severity: "error",
        show: true,
      });
      setIsUploading(false);
      return;
    };
    uploadKommunbefolkningFile(formData)
    .then((response) => {
      setStatus({
        show: true,
        alertText: `Importen lyckades. ${response.object}st rader importerades.`,
        severity: "success",
      });
      setIsUploading(false);
    })
    .catch((error) => {
      console.log("error: ", error);
        setStatus({
          alertText: `${error.data}`,
          severity: "error",
          show: true,
        });
      });
    setIsUploading(false);
    };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Befolkningsstatistik
        </Typography>
      </Grid>
      <Grid item xs={12} >
          <Typography variant="body1" paragraph>Senaste importerade år: {latestYearOfImport} </Typography>
      </Grid>
      <Grid container direction="row">
      <Typography variant="h2" component="h2" paragraph>
          Importera ny fil
        </Typography>
        <Grid item xs={12} >
          <label htmlFor="files">
            <input
              type="file"
              id="files"
              name="files"
              accept="text/csv"
              onChange={handleFile}
            />
          </label>
        </Grid>
        <Grid item xs={12} sx={{ mt: "20px" }}>
          <Button
          startIcon={
            isUploading ? (
              <CircularProgress color="primary" size={"1em"} />
            ) : (
              <div></div>
            )
          }
            disabled={isUploading}
            variant="contained"
            onClick={() => uploadFile()}
          >
            Importera Befolkningsstatistik
          </Button>
          {status.show === true && (
            <Box sx={{ width: "275px" }}>
              <ShowAlert
                onClose={() => {
                  setStatus({
                    show: false,
                    alertText: "",
                    severity: "success",
                  });
                }}
                severity={status.severity}
                alertTitle=""
                alertText={status.alertText}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
