import { Container, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

export default function Error404() {
  let navigate = useNavigate();

  return (
    <Container className="contentContainer">
      <main id="mainContent" tabIndex="-1">
        <Typography variant="h1" component="h1" paragraph>
          Sidan du försökte nå finns inte eller så har du inte behörighet att se
          den
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Om du tror att något är fel kan du mejla oss på{" "}
          <Link component="a" href="mailto:servering@folkhalsomyndigheten.se">
            servering@folkhalsomyndigheten.se
          </Link>
        </Typography>
        <Typography>
          <Link component="a" onClick={() => navigate("/")} tabIndex={0}>
            Tillbaka till startsidan
          </Link>
        </Typography>
      </main>
    </Container>
  );
}
