import { FormikErrors, FormikTouched } from "formik";

export function evalErrors<T, K extends keyof T>(
  errors: FormikErrors<T> | undefined,
  touched: FormikTouched<T>,
  key: K
): boolean {
  if (
    errors === null ||
    errors === undefined ||
    errors[key] === undefined ||
    touched[key] === undefined
  ) {
    return false;
  } else return true;
}

export function getErrorText<T, K extends keyof T>(
  errors: FormikErrors<T> | undefined,
  touched: FormikTouched<T>,
  key: K,
  neighbours?:Array<keyof T>
): string {
  if (
    errors !== undefined &&
    touched !== undefined &&
    neighbours !== undefined &&
    neighbours?.findIndex((x) => errors[x] !== undefined) > -1 &&
    neighbours?.findIndex((x) => touched[x] !== undefined) > -1 &&
    (errors[key] === "" || errors[key] === undefined)
  ) {
    return " ";
  }
  else if(
    errors !== null && errors !== undefined &&
    errors[key] !== undefined &&
    touched[key] === undefined
  ){
    return " ";
  }
  else if (
    errors !== undefined &&
    neighbours !== undefined &&
    neighbours?.findIndex((x) => errors[x] !== undefined) > -1 &&
    (errors[key] === "" || errors[key] === undefined)) {
      return " ";
    }
  else if (
    errors === null ||
    errors === undefined ||
    errors[key] === undefined ||
    touched[key] === undefined
  ) {
    return "";
  } else {
    return errors[key]?.toString() ?? " ";
  }
}

export function errorsExists<T>(errors: FormikErrors<T> | undefined) {
  if (errors !== undefined && Object.keys(errors).length > 0) {
    return true;
  }
  return false;
}
