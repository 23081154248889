import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { IUpdateRapportDetails } from "../../../Services/reportService";
import { Mode } from "./formlogic";
import {
  GridWithCaption,
  InputComparePrevYear,
  InputWithDefaults,
  Section,
} from "./mainForm";
import { CalcSum } from "./s_ForsaljningSverige";

export default function AnvandningsomradeTekniskSprit(
  props: FormikProps<IUpdateRapportDetails>,
  nr: number,
  mode: Mode,
  prevYear: boolean = false,
  prevYearObj: IUpdateRapportDetails | null
) {
  const values: ( boolean | undefined | null)[] = [
    props.values.alkPrepEjAvseddaIntas,
    props.values.alkPrepLivsmedel,
    props.values.lakemedel,
    props.values.ovrigtTekniskIndustriellt,
    props.values.vetenskapligt,
  ];

  return (
    <Grid key={nr} item xs={12} sx={{mt: "18px"}}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2">
            {nr}. Användningsområde för teknisk sprit
          </Typography>
          <Typography variant="caption">
            Användningsområde för sprit som du tillverkat, fört in eller importerat och använt som teknisk sprit i den egna verksamheten.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <GridWithCaption>
            <FormControlLabel
              label={
                <Typography variant="body2">
                Alkoholhaltiga preparat som inte är avsedda att intas 
                </Typography>
              }
              control={
                <Checkbox
                  size="medium"
                  name="alkPrepEjAvseddaIntas"
                  onChange={(e) => props.setFieldValue("alkPrepEjAvseddaIntas", e.target.checked ? true : false)}
                  checked={props.values.alkPrepEjAvseddaIntas === true}
                  disabled={mode === Mode.Disabled}
                />
              }
            />    
          </GridWithCaption>  
        <GridWithCaption>
          <FormControlLabel
            label={
              <Typography variant="body2">
              Alkoholhaltiga preparat som utgör livsmedel
              </Typography>
            }
            control={
              <Checkbox
                size="medium"
                name="alkPrepLivsmedel"
                onChange={(e) => props.setFieldValue("alkPrepLivsmedel", e.target.checked ? true : false)}
                checked={props.values.alkPrepLivsmedel === true}
                disabled={mode === Mode.Disabled}
              />
            }
          />    
        </GridWithCaption> 
        <GridWithCaption>
          <FormControlLabel
            label={
              <Typography variant="body2">
              Läkemedel
              </Typography>
            }
            control={
              <Checkbox
                size="medium"
                name="lakemedel"
                onChange={(e) => props.setFieldValue("lakemedel", e.target.checked ? true : false)}
                checked={props.values.lakemedel === true}
                disabled={mode === Mode.Disabled}
              />
            }
          />    
        </GridWithCaption>  
        <GridWithCaption>
          <FormControlLabel
            label={
              <Typography variant="body2">
             Övrigt tekniskt eller industriellt ändamål
              </Typography>
            }
            control={
              <Checkbox
                size="medium"
                name="ovrigtTekniskIndustriellt"
                onChange={(e) => props.setFieldValue("ovrigtTekniskIndustriellt", e.target.checked ? true : false)}
                checked={props.values.ovrigtTekniskIndustriellt === true}
                disabled={mode === Mode.Disabled}
              />
            }
          />    
        </GridWithCaption>         
        <GridWithCaption>
          <FormControlLabel
            label={
              <Typography variant="body2">
              Vetenskapligt ändamål
              </Typography>
            }
            control={
              <Checkbox
                size="medium"
                name="vetenskapligt"
                onChange={(e) => props.setFieldValue("vetenskapligt", e.target.checked ? true : false)}
                checked={props.values.vetenskapligt === true}
                disabled={mode === Mode.Disabled}
              />
            }
          />    
        </GridWithCaption>            
        </Grid>
      </Grid>
    </Grid>
  );
}
