import {
  CircularProgress,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { InfoType } from "../../Components/selects";
import { Input } from "../../Components/inputs";
import { newKontaktSchema } from "./validationContact";
import { BtnDefault, BtnSecond } from "../../Components/buttons";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import {
  checkIfKontaktCandBeCreated,
  IKontaktPost,
  postKontakt,
} from "../../Services/ContactService";
import { useNavigate } from "react-router-dom";
import { AutoCompleteAtr } from "../../Components/autoComplete";
import { KommunKontaktTypes } from "../../Utilities/contactUtils";
import InputTextField from "../../Components/textfield";

export interface IForm extends IKontaktPost {}

export default function NewContact() {
  const initialValues: IForm = {
    fornamn: "",
    efternamn: "",
    epost: "",
    telefon: "",
    kommunKontaktTypId: 0,
    kommunId: 0,
  };

  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" paragraph>
          Registrera kontakt
        </Typography>
      </Grid>

      <Grid item xs={12} md={10}>
        <Typography variant="caption" component="p" mb={1}>
          Fält markerade med * är obligatoriska
        </Typography>
        <Formik
          enableReinitialize
          validationSchema={newKontaktSchema}
          initialValues={{
            ...initialValues,
            confirmepost: "",
            availableTypes: []!,
          }}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            setSubmitting(true);
            postKontakt(values)
              .then((response) => {
                setSubmitting(false);
                resetForm();
                setStatus({
                  sent: true,
                  msg: `Kontakt ${values.fornamn} ${values.efternamn} registrerades.`,
                  title: "Kontakt skapad",
                  severity: "success",
                });
              })
              .catch((error) => {
                console.log(error);
                setStatus({
                  sent: true,
                  msg: error.data,
                  title: "Ett fel uppstod",
                  severity: "error",
                });
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            isSubmitting,
            setStatus,
            setFieldValue,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <AutoCompleteAtr
                      onBlur={handleBlur}
                      infoType={InfoType.kommuner}
                      label="Kommun"
                      multiple={false}
                      defaultVal={false}
                      onChange={(data) => {
                        setFieldValue("kommunId", data);
                        if (data === 0) return;
                        // Check for available types for kommun
                        checkIfKontaktCandBeCreated(data as number)
                          .then((response) => {
                            setFieldValue("availableTypes", [
                              ...response.object.availableTypes,
                            ]);
                            if (response.object.availableTypes.length === 0) {
                              setStatus({
                                sent: true,
                                msg: "Det finns redan maximalt antal kontakter av samtliga typer för denna kommun.",
                                title: "Maximal antal för kommun",
                                severity: "error",
                              });
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setFieldValue("availableTypes", []);
                            setStatus({
                              sent: true,
                              msg: error.data,
                              title: "Ett fel uppstod",
                              severity: "error",
                            });
                          });
                      }}
                      error={evalErrors(errors, touched, "kommunId")}
                      helperText={getErrorText(errors, touched, "kommunId")}
                      name="kommunId"
                      selectedData={values.kommunId}
                      required={true}
                      aria-required="true"
                    />
                  </FormControl>
                </Grid>
                {values.availableTypes.length > 0 && values.kommunId !== 0 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <AutoCompleteAtr
                        label="Typ"
                        infoType={InfoType.kommunKontaktTyper}
                        selectedData={values.kommunKontaktTypId}
                        multiple={false}
                        defaultVal={false}
                        onChange={(data) => {
                          setFieldValue("kommunKontaktTypId", data);
                        }}
                        error={evalErrors(
                          errors,
                          touched,
                          "kommunKontaktTypId"
                        )}
                        name="kommunKontaktTypId"
                        onBlur={handleBlur}
                        helperText={getErrorText(
                          errors,
                          touched,
                          "kommunKontaktTypId"
                        )}
                        idsToKeep={values.availableTypes}
                        required={true}
                        aria-required="true"
                      />
                    </FormControl>
                  </Grid>
                )}
                {values.kommunKontaktTypId !== null &&
                  values.kommunKontaktTypId !== 0 &&
                  values.kommunId !== 0 && (
                    <>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                        >
                          <InputTextField
                            fullWidth
                            name="fornamn"
                            label="Förnamn"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fornamn}
                            error={evalErrors(errors, touched, "fornamn")}
                            helperText={getErrorText(
                              errors,
                              touched,
                              "fornamn"
                            )}
                            required
                            aria-required="true"
                          />
                          <InputTextField
                            fullWidth
                            name="efternamn"
                            label="Efternamn"
                            onChange={handleChange}
                            value={values.efternamn}
                            onBlur={handleBlur}
                            error={evalErrors(errors, touched, "efternamn")}
                            helperText={getErrorText(
                              errors,
                              touched,
                              "efternamn"
                            )}
                            required
                            aria-required="true"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                        >
                          <InputTextField
                            fullWidth
                            name="epost"
                            label="E-postadress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.epost}
                            error={evalErrors(errors, touched, "epost")}
                            helperText={getErrorText(errors, touched, "epost")}
                            required
                            aria-required="true"
                          />
                          <InputTextField
                            fullWidth
                            name="confirmepost"
                            label="Bekräfta e-postadress"
                            onChange={handleChange}
                            value={values.confirmepost}
                            onBlur={handleBlur}
                            error={evalErrors(errors, touched, "confirmepost")}
                            helperText={getErrorText(
                              errors,
                              touched,
                              "confirmepost"
                            )}
                            required
                            aria-required="true"
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="baseline"
                        >
                        </Stack>
                      </Grid>
                    </>
                  )}
              </Grid>
              <Grid item xs={6} mt={2} >
                {ShowAlertEval(status) && (
                  <Grid item xs={12}>
                    <ShowAlert
                      onClose={() => {
                        setStatus(null);
                      }}
                      severity={status.severity}
                      alertTitle={status.title}
                      alertText={status.msg}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <BtnDefault
                      disabled={
                        isSubmitting ||
                        values.kommunId === 0 ||
                        values.kommunKontaktTypId === 0
                      }
                      type="submit"
                    >
                      Skapa
                    </BtnDefault>
                    {isSubmitting && <CircularProgress />}
                    <BtnSecond onClick={() => navigate("/contacts")}>
                      Avbryt
                    </BtnSecond>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
