import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { DatePickerCustom } from "../../../Components/datePicker";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { disabledEnabled, Mode, Permits, showHideField } from "./permitsUtils";


const checkProvoTidInteForeAktivieringsDatum = (props: any): boolean => {{
    if ((props.values.aktivieringsDatum > props.values.versionDetails.provoTidTom)  
    ) {
      return true;
    }
    return false;
  }
};

const feltextProvoTidInteForeAktivieringsDatum = (props: any, mode: Mode): string => {
  {
    if(mode == Mode.View)
    return "";
    if (checkProvoTidInteForeAktivieringsDatum(props)
    ) {
      return "Prövotid kan inte vara tidigare än giltigt fr.o.m.";
    }
    return "";
  }
};

export function Giltighetstid(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode
) {
  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Giltighetstid</Typography>
      </Grid>
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={2} key="beslutsDatum">
          <DatePickerCustom
            label="Beslutsdatum"
            value={props.values.beslutsDatum ?? null}
            onChange={(ev) => props.setFieldValue("beslutsDatum", ev)}
            error={evalErrors(props.errors, props.touched, "beslutsDatum")}
            helperText={getErrorText(
              props.errors,
              props.touched,
              "beslutsDatum"
            )}
            name="beslutsDatum"
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TobakstillstandPartihandel,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TobakstillstandPartihandel,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.Edit || mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={2} key="aktivieringsDatum">
          <DatePickerCustom
            label="Giltigt fr.o.m."
            value={props.values.aktivieringsDatum ?? null}
            onChange={(ev) => props.setFieldValue("aktivieringsDatum", ev)}
            onAccept={(date) => {
              props.setFieldTouched("aktivieringsDatum", true, false);
            }}
            error={evalErrors(props.errors, props.touched, "aktivieringsDatum") 
          }
            helperText={getErrorText(
              props.errors,
              props.touched,
              "aktivieringsDatum"
            ) 
          }
            name="aktivieringsDatum"
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TobakstillstandPartihandel,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TobakstillstandPartihandel,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.Edit || mode === Mode.View}
          />
        </Grid>
      )}

      {showHideField(
        [
          Permits.StadigvarandeServeringstillstand
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={2} key="versionDetails.provoTidTom">
          <DatePickerCustom
            label="Prövotid t.o.m."
            value={props.values.versionDetails.provoTidTom ?? null}
            onChange={(ev) => {
              props.setFieldValue("versionDetails.provoTidTom", ev);
            }}
            onAccept={(date) => {
              props.setFieldTouched("versionDetails.provoTidTom", true, false);
            }}
            error={evalErrors(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "provoTidTom"
            )
            || checkProvoTidInteForeAktivieringsDatum(props)
          }
            helperText={getErrorText(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "provoTidTom"
            )  || feltextProvoTidInteForeAktivieringsDatum(props, mode) 
          }
            name="versionDetails.provoTidTom"
            onBlur={props.handleBlur}
            required={false}
            disabled={mode === Mode.View}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={2} key="avslutsDatum">
          <DatePickerCustom
            label="Giltigt t.o.m."
            value={props.values.avslutsDatum ?? null}
            onChange={(ev) =>
              props.setFieldValue("avslutsDatum", ev)
            }
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "avslutsDatum"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "avslutsDatum"
            )}
            name="avslutsDatum"
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.Edit || mode === Mode.Transfer || mode === Mode.View}
          />
        </Grid>
      )}
      {props.values.avslutsDatum !== null && showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
        ],
        permit,
        [Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={2} key="avslutsDatum">
          <DatePickerCustom
            label="Giltigt t.o.m."
            value={props.values.avslutsDatum ?? null}
            onChange={(ev) =>
              props.setFieldValue("avslutsDatum", ev)
            }
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "avslutsDatum"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "avslutsDatum"
            )}
            name="avslutsDatum"
            onBlur={props.handleBlur}
            required={false}
            aria-required="false"
            disabled={true}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.StadigvarandeServeringstillstand,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit],
        mode
      ) && (
        <Grid item xs={2} key="versionDetails.versionsStart">
          <DatePickerCustom
            label="Ändring gäller fr.o.m."
            value={props.values.versionDetails.versionsStart ?? null}
            onChange={(ev) => props.setFieldValue("versionDetails.versionsStart", ev)}
            error={evalErrors(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "versionsStart"
            )}
            helperText={getErrorText(
              props.errors.versionDetails,
              props.touched.versionDetails ?? {},
              "versionsStart"
            )}
            name="versionDetails.versionsStart"
            onBlur={props.handleBlur}
            required
          />
        </Grid>
      )}
      {showHideField(
        [Permits.TillfalligtServeringstillstand],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid key="versionDetails.servFleraTillfUnderPeriod" item xs={12}>
          <Stack mb={1}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="versionDetails.servFleraTillfUnderPeriod"
                  onChange={(e) => props.setFieldValue("versionDetails.servFleraTillfUnderPeriod", e.target.checked ? true : false)}
                  disabled={mode === Mode.View}
                  checked={props.values.versionDetails.servFleraTillfUnderPeriod}
                />
              }
              label={
                <Typography variant="body2">
                  Servering sker vid flera tillfällen under perioden
                </Typography>
              }
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
