import React, { useEffect } from "react";
import {
  CircularProgress,
  Stack,
  Typography,
  Box,
  Link,
  Grid,
} from "@mui/material";
import { useAuth } from "../../Utilities/authProvider";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { SignInSchema } from "../../Utilities/validationSchema";
import OutlinedTextField from "../../Components/textfield";
import UnauthLayout from "../SignIn/unauthLayout";
import { BtnDefault } from "../../Components/buttons";
import { getDeploymentInfo } from "../../Services/infoService";
import { theme } from "../../theme";
import { evalErrors, getErrorText } from "../../Utilities/formikErrorEval";
import { ShowAlert, ShowAlertEval } from "../../Components/alerts";

export default function SignIn() {
  const logo = require("../../Assets/FHM-Logotyp-Symbol.png");
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [networkError, setNetworkError] = React.useState(false);

  useEffect(() => {
    let storage = localStorage.getItem("Network_Error");

    if (storage === null || storage === undefined) {
      setNetworkError(false);
    } else {
      setNetworkError(true);
    }
  }, [networkError]);

  const renderForm = () => {
    return (
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={SignInSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setLoading(true);
          auth
            .signInUser(values.username, values.password)
            .then((resp) => {
              if (resp.success === false) {
                setLoading(false);
                setSubmitting(false);
                setStatus({
                  sent: true,
                  msg: resp.object || "Ett oväntat fel uppstod",
                  severity: "error",
                });
                return;
              }
              localStorage.removeItem("Network_Error");
              resp.object?.changePassword === true
                ? navigate("/changepassword", {
                    state: { oldpassword: values.password },
                  })
                : navigate("/home");
              getDeploymentInfo();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              setSubmitting(false);
              setStatus({
                sent: true,
                msg: error.data,
                title: "Ett fel uppstod",
                severity: "error",
              });
            });
          getDeploymentInfo();
        }}
      >
        {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setStatus,
        }) => (
          <>
            <Box sx={{ margin: theme.spacing(2) }}>
              <Stack spacing={2} display="flex">
                <form onSubmit={handleSubmit}>
                  <Typography
                    sx={{ mb: theme.spacing(1) }}
                    variant="caption"
                    component="p"
                  >
                    Fält markerade med <abbr title="Obligatoriskt fält">*</abbr>{" "}
                    är obligatoriska
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <OutlinedTextField
                        type="username"
                        name="username"
                        label="Användarnamn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        fullWidth
                        error={evalErrors(errors, touched, "username")}
                        helperText={getErrorText(errors, touched, "username")}
                        aria-required="true"
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedTextField
                        type="password"
                        name="password"
                        label="Lösenord"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={evalErrors(errors, touched, "password")}
                        helperText={getErrorText(errors, touched, "password")}
                        aria-required="true"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <BtnDefault
                          size="large"
                          type="submit"
                          title={"Logga in"}
                          disabled={isSubmitting}
                          variant="contained"
                          sx={{ minWidth: "125px", alignSelf: "center" }}
                        >
                          Logga in
                        </BtnDefault>
                        {loading && <CircularProgress color="primary" />}
                      </Stack>

                      {ShowAlertEval(status) && (
                        <Grid item xs={12}>
                          <ShowAlert
                            onClose={() => {
                              setStatus(null);
                            }}
                            severity={status.severity}
                            alertTitle={status.title}
                            alertText={status.msg}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Stack>
            </Box>
          </>
        )}
      </Formik>
    );
  };

  const checkIfServerError = () => {
    const message = localStorage.getItem("Network_Error");
    
    if (message !== null) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <ShowAlert
              onClose={() => {
                localStorage.removeItem("Network_Error");
                setNetworkError(false)
              }}
              severity={"error"}
              alertTitle={"Ett oväntat fel inträffade"}
              alertText={message}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <UnauthLayout>
      <img className="login-fohm-logo" src={logo} alt="Logotyp ATR" />
      <Box sx={{ margin: "24px 0" }}>
        <Typography variant="h1" component="h1" align="center" gutterBottom>
          Alkohol- och tobaksregistret
        </Typography>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Nationellt register för tillsyn och uppföljning
        </Typography>
      </Box>
      <Typography align="center" variant="body2" paragraph>
      Vi rekommenderar att du använder webbläsare Edge eller Chrome
      </Typography>

      {renderForm()}

      <Box sx={{ m: 2 }}>
        <Stack spacing={2} direction="row">
          <Link component={RouterLink} to="/applyforaccount" tabIndex={0}>
            Ansök om användarkonto
          </Link>
          <Link component={RouterLink} to="/glomtlosenord" tabIndex={0}>
            Glömt lösenord
          </Link>
        </Stack>
        {networkError && checkIfServerError()}
      </Box>
    </UnauthLayout>
  );
}
