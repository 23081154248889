import React from "react";
import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Link,
  Typography,
} from "@mui/material";
import { Card as MuiCard } from "@mui/material";
import { CardProps as MuiCardProps } from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";

export interface CardProps extends MuiCardProps {
  heading: string;
  text: string;

  icon?: JSX.Element;
  link?: string;
}
export interface CardWithBreaklineProps extends MuiCardProps {
  heading: string;
  text: string;
  text1: string;
  icon?: JSX.Element;
  link?: string;
}

export interface AccordionCardProps extends MuiCardProps {
  heading: JSX.Element;
  text: string;
  icon?: JSX.Element;
  link?: string;
  expandedContent: JSX.Element;
}

export interface AccordionCardLineBreakProps extends MuiCardProps {
  heading: JSX.Element;
  text1: string;
  text2: string;
  icon?: JSX.Element;
  link?: string;
  expandedContent: JSX.Element;
}

export const Card = (props: CardProps) => {
  return (
    <MuiCard sx={{ minHeight: "150px"}}>
      <CardContent>
        <div className="cardHeading">
          <Typography
            gutterBottom
            component="h3"
            variant="h3"
            className="alignText"
          >
            {props.icon}
            {props.heading}
          </Typography>
        </div>
        <Typography variant="body1" component="p">
          {props.text}
        </Typography>
      </CardContent>
      {props.link && (
        <CardActions className="cardActions">
          <Link
            tabIndex={0}
            aria-label={`Gå till ${props.heading}`}
            href={props.link}
          >
            Läs mer
          </Link>
        </CardActions>
      )}
    </MuiCard>
  );
};

export const CardWithLinebreak = (props: CardWithBreaklineProps) => {
  return (
    <MuiCard sx={{ minHeight: "150px" }}>
      <CardContent>
        <div className="cardHeading">
          <Typography
            gutterBottom
            component="h3"
            variant="h3"
            className="alignText"
          >
            {props.icon}
            {props.heading}
          </Typography>
        </div>
        <Typography variant="body1" component="p">
          {props.text}
        </Typography>
        <br/>
        <Typography variant="body1" component="p">
          {props.text1}
        </Typography>
      </CardContent>
      {props.link && (
        <CardActions className="cardActions">
          <Link
            tabIndex={0}
            aria-label={`Gå till ${props.heading}`}
            href={props.link}
          >
            Läs mer
          </Link>
        </CardActions>
      )}
    </MuiCard>
  );
};

export const CardWithIcon = (props: CardProps) => {
  return (
    <MuiCard sx={{ minHeight: "150px" }}>
      <CardContent>
        <div className="cardHeading">
          {props.icon}
          <Typography
            gutterBottom
            component="h3"
            variant="h3"
            className="alignText"
          >
            {props.heading}
          </Typography>
        </div>
        <Typography variant="body1" component="p">
          {props.text}
        </Typography>
      </CardContent>
      {props.link && (
        <CardActions className="cardActions">
          <Link
            tabIndex={0}
            aria-label={`Gå till ${props.heading}`}
            href={props.link}
          >
            Läs mer
          </Link>
        </CardActions>
      )}
    </MuiCard>
  );
};

export const AccordionCard = (props: AccordionCardProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiCard sx={{ minHeight: "150px" }}>
      <CardContent>
        <div className="cardHeading">
          {props.icon}
          {props.heading}
        </div>
        <Typography variant="body1" component="p">
          {props.text}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{props.expandedContent}</CardContent>
      </Collapse>
      <CardActions className="cardActions">
        <Link
          component={Button}
          className="button-lowercase"
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label={expanded === true ? "Visa mindre" : "Läs mer"}
        >
          {expanded === true ? "Visa mindre" : "Läs mer"}
        </Link>
      </CardActions>
    </MuiCard>
  );
};

export const AccordionCardTextLinebreak = (props: AccordionCardLineBreakProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiCard sx={{ minHeight: "150px" }}>
      <CardContent>
        <div className="cardHeading">
          {props.icon}
          {props.heading}
        </div>
        <Typography variant="body1" component="p">
          {props.text1}
        </Typography>
        <br/>
        <Typography variant="body1" component="p">
          {props.text2}
        </Typography>
        
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{props.expandedContent}</CardContent>
      </Collapse>
      <CardActions className="cardActions">
        <Link
          component={Button}
          className="button-lowercase"
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label={expanded === true ? "Visa mindre" : "Läs mer"}
        >
          {expanded === true ? "Visa mindre" : "Läs mer"}
        </Link>
      </CardActions>
    </MuiCard>
  );
};


