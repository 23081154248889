import * as Yup from "yup";
import moment from "moment";
import { CollectionsOutlined } from "@mui/icons-material";


const required = "Obligatoriskt";
const toShort = "Texten är för kort";
const maxLength = "Texten är för lång";
const noNumbers = /(^[\D ]*$)/;
const noStartingWhitespaces = /^[^\s]+(\s+[^\s]+)*/;
const notWhiteSpace = "Inga inledande blanksteg";

export const SignInSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett användarnamn"),
  password: Yup.string()
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett lösenord"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  epost: Yup.string()
    .lowercase()
    .email("Du måste ange en giltig e-postadress")
    .matches(noStartingWhitespaces, notWhiteSpace)
    .required("Du måste ange en e-postadress"),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Du måste ange ditt nuvarande lösenord"),
  newPassword: Yup.string()
    .matches(noStartingWhitespaces, notWhiteSpace)
    .required("Du måste ange ett lösenord"),
  confirmPassword: Yup.string()
    .required("Du måste ange ett lösenord")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "De angivna lösenorden stämmer inte överens. Vänligen kontrollera lösenorden."
    ),
});

export const UpdateForgotPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(noStartingWhitespaces, notWhiteSpace)
    .required("Du måste ange ett lösenord"),
  confirmPassword: Yup.string()
    .required("Du måste ange ett lösenord")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "De angivna lösenorden stämmer inte överens. Vänligen kontrollera lösenorden."
    ),
});

export const ClosePermitSchema = (tillstandBeslutsDatum: any) => {
  return Yup.object().shape({
    orsakTillAvslutId: Yup.number().required('Välj en orsak till avslut'),
    avslutsdatum: Yup.date()
      .test('is-before-beslutsdatum', 'Avslutsdatum kan inte vara före giltigt fr.o.m.', function (value) {
        if (value !== undefined && value !== null) {
          const isBeforBeslutsDatum = moment(tillstandBeslutsDatum).isBefore(moment(value))
          return isBeforBeslutsDatum;
        }
        return true;
      })
      .typeError("Avslutsdatum har inte rätt datumformat (åååå-mm-dd)")
      .required("Avslutsdatum måste anges")
      .nullable(),
  });
} 