import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { performance } from "perf_hooks";
import React, { useEffect } from "react";
import InputTextField from "../../../Components/textfield";
import { getTillstandAutofill } from "../../../Services/PermitService";
import { evalErrors, getErrorText } from "../../../Utilities/formikErrorEval";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import Permit from "../permit";
import { disabledEnabled, Mode, Permits, showHideField } from "./permitsUtils";


export const getTitleBasedOnType = (permit: Permits, p :any) => {
  

  switch (permit) {
    case Permits.TobakstillstandDetaljhandelVissTid:
    case Permits.TobakstillstandDetaljhandelTillsvidare:
      return "Försäljningsställe";
    case Permits.TobakstillstandPartihandel:
      return "Lagerlokal";
    case Permits.RegistreradVarumottagare:
    case Permits.SkattebefriadForbrukare:
    case Permits.Upplagshavare:
      return "Tillståndsuppgifter";
    case Permits.StadigvarandeServeringstillstand:
    case Permits.TillfalligtServeringstillstand:
      return "Serverings- eller tillredningsställe";
    default:
      return "Objekt";
  }
};

export function FormTypeObject(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode
) {

  
  const check = (mode: Mode): boolean => {
    let check = false;
    if(mode === Mode.View || permit === Permits.TobakstillstandPartihandel){
      check = true;
    }

    return check;
  };

  const checkObjektNr = (mode: Mode): boolean => {
    let check = false;
    check = disabledEnabled(
      [
        Permits.TobakstillstandDetaljhandelTillsvidare,
        Permits.TobakstillstandDetaljhandelVissTid,
        Permits.TobakstillstandPartihandel,
        Permits.RegistreradVarumottagare,
        Permits.SkattebefriadForbrukare,
        Permits.Upplagshavare,
        Permits.TillfalligtServeringstillstand,
      ],
      permit
    );
    if (permit === Permits.StadigvarandeServeringstillstand) {
      check =
        mode === Mode.Edit || mode === Mode.Transfer || mode === Mode.View;
    }
    return check;
  };

  const getAutofillObject = async (e: any) => {
    const id = e.target.value;
    await getTillstandAutofill(id)
      .then((data) => {
        props.setFieldValue("tillstandsObjekt.adress", data.object.adress);
        props.setFieldValue("tillstandsObjekt.namn", data.object.namn);
        props.setFieldValue("tillstandsObjekt.postOrt", data.object.postOrt);
        props.setFieldValue("tillstandsObjekt.postnr", data.object.postnr);
        props.setFieldTouched("tillstandsObjekt.adress", true);
        props.setFieldTouched("tillstandsObjekt.namn", true);
        props.setFieldTouched("tillstandsObjekt.postOrt", true);
        props.setFieldTouched("tillstandsObjekt.postnr", true);
        props.handleBlur(e);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  

  return (
    <Grid key={index} container spacing={2} direction="row">
      <Grid item xs={12}>
        <Typography variant="h2">{getTitleBasedOnType(permit, props)}</Typography>
      </Grid>
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.StadigvarandeServeringstillstand,
          Permits.RegistreradVarumottagare,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={6} key="objektNr">
          <InputTextField
            fullWidth
            name="objektNr"
            label={ 
              permit === Permits.StadigvarandeServeringstillstand
                ? "Restaurangnummer"
                : "ObjektId"
            }
            error={evalErrors(
              props.errors,
              props.touched ?? {},
              "objektNr"
            )}
            helperText={getErrorText(
              props.errors,
              props.touched ?? {},
              "objektNr"
            )}
            onChange={props.handleChange}
            onBlur={(e) => {
              if (permit === Permits.StadigvarandeServeringstillstand) {
                const value = e.target.value;
                if (value) {
                  getAutofillObject(e);
                }
                props.setTouched({objektNr: true});
              } else {               
                props.handleBlur(e);
              }
            }}
            value={props.values.objektNr ?? ""}
            disabled={checkObjektNr(mode)}
            required={disabledEnabled(
              [Permits.StadigvarandeServeringstillstand],
              permit
            )}
            aria-required={
              disabledEnabled(
                [Permits.StadigvarandeServeringstillstand],
                permit
              )
                ? "true"
                : "false"
            }
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.StadigvarandeServeringstillstand,
          Permits.TillfalligtServeringstillstand,
          Permits.TobakstillstandPartihandel,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={6} key="tillstandsObjekt.namn">
          <InputTextField
            fullWidth
            name="tillstandsObjekt.namn"
            label="Namn"
            onChange={props.handleChange}
            value={
              permit === Permits.TobakstillstandPartihandel ? "Lagerlokal" : 
              props.values.tillstandsObjekt.namn ?? ""}
            error={evalErrors(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "namn"
            )}
            helperText={getErrorText(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "namn"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand, 
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={check(mode)}
          />
        </Grid>
      )}

      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsObjekt.adress">
          <InputTextField
            fullWidth
            name="tillstandsObjekt.adress"
            label="Adress"
            onChange={props.handleChange}
            value={props.values.tillstandsObjekt.adress ?? ""}
            error={evalErrors(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "adress"
            )}
            helperText={getErrorText(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "adress"
            )}

            onBlur={(e) => {
              if(permit === Permits.TobakstillstandPartihandel){
                props.values.tillstandsObjekt.namn = "Lagerlokal";
                props.handleBlur(e);
              }
              else{
                props.handleBlur(e);
              }

            }}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TobakstillstandPartihandel,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TobakstillstandPartihandel,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.View || disabledEnabled(
              [
                Permits.Upplagshavare,
                Permits.RegistreradVarumottagare,
                Permits.SkattebefriadForbrukare,
              ],
              permit
            )}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsObjekt.postnr">
          <InputTextField
            fullWidth
            name="tillstandsObjekt.postnr"
            label="Postnummer"
            onChange={props.handleChange}
            value={props.values.tillstandsObjekt.postnr ?? ""}
            error={evalErrors(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "postnr"
            )}
            helperText={getErrorText(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "postnr"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TobakstillstandPartihandel,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TobakstillstandPartihandel,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.View || disabledEnabled(
              [
                Permits.Upplagshavare,
                Permits.RegistreradVarumottagare,
                Permits.SkattebefriadForbrukare,
              ],
              permit
            )}
          />
        </Grid>
      )}
      {showHideField(
        [
          Permits.TobakstillstandDetaljhandelTillsvidare,
          Permits.TobakstillstandDetaljhandelVissTid,
          Permits.TobakstillstandPartihandel,
          Permits.RegistreradVarumottagare,
          Permits.StadigvarandeServeringstillstand,
          Permits.SkattebefriadForbrukare,
          Permits.Upplagshavare,
          Permits.TillfalligtServeringstillstand,
        ],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={4} key="tillstandsObjekt.postOrt">
          <InputTextField
            fullWidth
            name="tillstandsObjekt.postOrt"
            label="Postort"
            onChange={props.handleChange}
            value={props.values.tillstandsObjekt.postOrt ?? ""}
            error={evalErrors(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "postOrt"
            )}
            helperText={getErrorText(
              props.errors.tillstandsObjekt,
              props.touched.tillstandsObjekt ?? {},
              "postOrt"
            )}
            onBlur={props.handleBlur}
            required={disabledEnabled(
              [
                Permits.TobakstillstandDetaljhandelTillsvidare,
                Permits.TobakstillstandDetaljhandelVissTid,
                Permits.TobakstillstandPartihandel,
                Permits.StadigvarandeServeringstillstand,
                Permits.TillfalligtServeringstillstand,
              ],
              permit
            )}
            aria-required={
              disabledEnabled(
                [
                  Permits.TobakstillstandDetaljhandelTillsvidare,
                  Permits.TobakstillstandDetaljhandelVissTid,
                  Permits.TobakstillstandPartihandel,
                  Permits.StadigvarandeServeringstillstand,
                  Permits.TillfalligtServeringstillstand,
                ],
                permit
              )
                ? "true"
                : "false"
            }
            disabled={mode === Mode.View || disabledEnabled(
              [
                Permits.Upplagshavare,
                Permits.RegistreradVarumottagare,
                Permits.SkattebefriadForbrukare,
              ],
              permit
            )}
          />
        </Grid>
      )}
      {showHideField(
        [Permits.TillfalligtServeringstillstand],
        permit,
        [Mode.Edit, Mode.Create, Mode.Transfer, Mode.View],
        mode
      ) && (
        <Grid item xs={12} key="versionDetails.stadigvTillstandFinns">
          <Grid item xs={3}>
            <Stack mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="versionDetails.stadigvTillstandFinns"
                    onChange={(e) => props.setFieldValue("versionDetails.stadigvTillstandFinns", e.target.checked ? true : false)}
                    disabled={mode === Mode.View}
                    checked={props.values.versionDetails.stadigvTillstandFinns}
                  />
                }
                label={
                  <Typography variant="body2">
                    Stadigvarande serveringstillstånd finns
                  </Typography>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
