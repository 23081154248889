import { FormGroup, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { GetCheckBox } from "../../../Components/checkbox";
import { IGroupData } from "../../../Components/selects";
import { Roles } from "../../../Utilities/roles";
import { IForm } from "../new";
import { Mode, Permits, SearchPermitTypes } from "./permitsUtils";

export function FormTypeKategori(
  props: FormikProps<IForm>,
  index: number,
  permit: Permits,
  roles: Roles[],
  mode: Mode,
  dataType?: IGroupData[],
) {
  let selectedKategorier: number[] = [];
  if (props.values.tillstandsKategoriIds === null) {
    return 0;
  } else {
    props.values.tillstandsKategoriIds.map((i) => {
      typeof i !== "number" ? selectedKategorier.push(i.id) : selectedKategorier.push(i)
    });
  }

  return (
    <Grid
      key={index}
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h2">Kategori</Typography>
      </Grid>
      <Grid item xs={6} key="godkann">
          <FormGroup >
            {dataType && dataType.map(( data, i ) => {
              return data.label !== "Alla" && (
                <GetCheckBox
                checked={selectedKategorier.includes(data.key)}
                label={data.label}
                mainLabel=""
                name="godkann"
                onChange={() => void 0}
                disabled={true}
        />
              )
            })}
          </FormGroup>
      </Grid>
    </Grid>
  );
}
