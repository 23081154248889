import * as Yup from "yup";
import {
  maxLength,
  swedishPhoneNumber,
  noNumbersRegX,
  noStaringWhitespaces,
  notWhiteSpace,
  toShort,
} from "../../Utilities/common";

export const newKontaktSchema = Yup.object().shape({
  fornamn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett förnamn"),
  efternamn: Yup.string()
    .matches(noNumbersRegX, "Tillåtna tecken är bokstäver och bindestreck")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .min(2, toShort)
    .max(50, maxLength)
    .required("Du måste ange ett efternamn"),
  epost: Yup.string()
    .lowercase()
    .email("Du måste ange en giltig e-postadress")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .required("Du måste ange en e-postadress"),
  confirmepost: Yup.string()
    .lowercase()
    .email("Du måste ange en giltig e-postadress")
    .required("Du måste bekräfta e-postadress")
    .equals([Yup.ref('epost')], "E-postadresserna matchar inte"),
  telefon: Yup.string().nullable()
    .max(20, "20 Tecken är max antal")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .matches(swedishPhoneNumber, "Du måste ange ett telefonnummer"),
});

export const sendEmailValidation = Yup.object().shape({
  amne: Yup.string()
    .min(2, toShort)
    .max(100, maxLength)
    .required("Du måste ange ett amne"),
  meddelande: Yup.string().required("Du måste ange ett meddelande"),
  namn: Yup.string()
    .min(2, toShort)
    .max(100, maxLength)
    .required("Du måste ange ett namn"),
  epost: Yup.string()
    .lowercase()
    .email("Du måste ange en giltig e-postadress")
    .matches(noStaringWhitespaces, notWhiteSpace)
    .required("Du måste ange en e-postadress")
});
